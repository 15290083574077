import {View, Text, SafeAreaView, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import React, {useState, useEffect} from 'react';
import LogoHeader from '../../components/header/LogoHeader';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
import SearchBox from '../../components/search/SearchBox';
import FilterBox from '../../components/filter/FilterBox';
import TakeAwayCard from '../../components/card/TakeAwayCard';
import HeaderSheet from '../../components/header/HeaderSheet';
import ScreenTitle from '../../components/header/ScreenTitle';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import FilterSheet from '../../components/bottomsheet/FilterSheet';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import WebCartSheet from '../../components/cart/WebCartSheet';
import WebHeader from '../../components/header/WebHeader';
import RedButton from '../../components/button/RedButton';
const {width, height} = Dimensions.get('window');
const TakeAway = ({navigation}) => {
  const [restaurantOpen, setRestaurantOpen] = useState(true);
  const [resData, setResData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [appReady, setAppReady] = useState(false);
  const [empty, setEmpty] = useState(false);
  useEffect(() => {
    axios
      .post(`${axiosConfig.serverIp}/getTakeAwayPost`, {
        province: MainStore.selectedAddress.province,
        district: MainStore.selectedAddress.district,
        neighbourhood: MainStore.selectedAddress.neighbourhood,
      })
      .then((res) => {
        if (res.data.length > 0) {
          setResData(res.data);
          setAppReady(true);
        } else {
          setEmpty(true);
          setAppReady(true);
        }
      });

    return () => {
      setAppReady(false);
      setResData(null);
    };
  }, [MainStore.selectedAddress.neighbourhood]);

  return appReady ? (
    !empty ? (
      <View style={{flex: 1, backgroundColor: 'white'}}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0}}
        >
          {width > 1279 ? (
            <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
              <WebHeader navigation={navigation} />
            </View>
          ) : (
            <View>
              <HeaderSheet navigation={navigation} />
            </View>
          )}

          <View style={{backgroundColor: 'white', flex: 1}}>
            {width > 1279 ? (
              <></>
            ) : (
              <View>
                <ScreenTitle
                  title={MainStore.langPref == 'tr' ? 'Gel-Al' : 'Take-away'}
                  navigation={navigation}
                />
              </View>
            )}
            <ScrollView>
              <View style={{flexDirection: width > 1279 ? 'row' : 'column'}}>
                <View style={{maxWidth: width > 1279 ? 1025 : width}}>
                  <View>
                    <SearchBox navigation={navigation} />
                  </View>
                  <View>
                    <FilterBox
                      setShowFilter={setShowFilter}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                    />
                  </View>

                  {/*
                  <View style={{marginTop: 17, marginLeft: 12}}>
                    <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 16, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr'
                        ? 'Gel-Al İndirimli Restoranlar'
                        : 'Take-Away Discounted Restaurants '}
                    </Text>
                  </View>
                  */}

                  {resData.map((item) => (
                    <TouchableOpacity
                      onPress={() => navigation.navigate('TakeAwayRestorant', {id: item._id})}
                      key={item._id}
                    >
                      <TakeAwayCard
                        navigation={navigation}
                        restData={item}
                        foodList={item.foodList}
                        restaurantOpen={item.visible}
                      />
                    </TouchableOpacity>
                  ))}
                </View>
                {width > 1279 ? <WebCartSheet navigation={navigation} /> : <></>}
              </View>
            </ScrollView>
          </View>
          {showFilter && resData?.length > 0 ? (
            <FilterSheet
              selectedFilter={selectedFilter}
              data={resData}
              sort={selectedFilter == 'sort' ? true : false}
              category={selectedFilter == 'category' ? true : false}
              navigation={navigation}
              visible={showFilter}
              setVisible={setShowFilter}
            />
          ) : (
            <></>
          )}
        </ScrollView>
      </View>
    ) : (
      <SafeAreaView style={style.emptyMain}>
        {width > 1279 ? <WebHeader navigation={navigation} /> : <></>}
        <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
          <View>
            <Image
              source={require('../../assets/image/emptyRest.png')}
              style={style.emptyImage}
            />
          </View>
          <View style={{marginVertical: 17}}>
            <Text style={style.emptyMainText}>{MainStore.langPref == 'tr' ? 'Üzgünüz...' : 'Sorry...'}</Text>
          </View>
          <View style={{width: width / 1.5}}>
            <Text style={style.emptyDescText}>
              {MainStore.langPref == 'tr'
                ? 'Bulunuzduğunuz konumda herhangi bir restoran bulunmamaktadır.'
                : 'There are no restaurants at your location.'}
            </Text>
          </View>
          <View style={{marginTop: 17}}>
            <RedButton
              clickEvent={() => navigation.navigate('MyAddress')}
              text={MainStore.langPref == 'tr' ? 'Adresimi Değiştir' : 'Change My Address'}
            />
          </View>
        </View>
      </SafeAreaView>
    )
  ) : (
    <LoadingSpinner />
  );
};
const style = StyleSheet.create({
  main: {flex: 1, backgroundColor: 'white'},
  logoArea: {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0},
  addressArea: {flexDirection: 'row', alignItems: 'center', marginRight: 15},
  addressText: {fontFamily: 'Gilroy-Medium', fontSize: 18},
  emptyMain: {flex: 1, backgroundColor: 'white'},
  emptyImage: {width: 93, height: 93},
  emptyMainText: {fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.textColor},
  emptyDescText: {fontFamily: 'Gilroy-Regular', fontSize: 14, color: 'rgba(30, 30, 30, 0.8)', textAlign: 'center'},
});

export default observer(TakeAway);
