import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  TextInput,
  ScrollView,
  Modal,
  Pressable,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import MainStore from '../../store/MainStore';
import GoBackTitle from '../../components/header/GoBackTitle';
import CartIcon from '../../assets/svg/CartIcon';
import {Feather} from '@expo/vector-icons';
import WalletIcon from '../../assets/svg/WalletIcon';
import SuccessIcon from '../../assets/svg/SuccessIcon';
import colorSchema from '../../config/colorSchema';
import TakeAwayIcon from '../../assets/svg/TakeAwayIcon';
import PayDoorIcon from '../../assets/svg/PayDoorIcon';
import PromotionIcon from '../../assets/svg/PromotionIcon';
import {TimePicker, ValueMap} from 'react-native-simple-time-picker';
import {BottomSheet} from 'react-native-btr';
import {observer} from 'mobx-react';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import {WebView} from 'react-native-webview';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import WebHeader from '../../components/header/WebHeader';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import io from 'socket.io-client';
import {useToast} from '@gluestack-ui/themed';
const socket = io.connect(`https://api.yemek.club`);

const {width, height} = Dimensions.get('window');

const Checkout = ({navigation}) => {
  const date = new Date();
  const [note, setnote] = useState('');
  const [contract, setContract] = useState(true);
  const [paymentType, setPaymentType] = useState(MainStore.isTakeAway ? 'takeAway' : '');
  const [deliveryOptions, setDeliveryOptions] = useState(MainStore.isTakeAway ? 'takeAway' : '');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [successPromo, setSuccessPromo] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [takeAwayTime, setTakeAwayTime] = useState({
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: 0,
  });
  const [takeAwayTimeVisible, setTakeAwayTimeVisible] = useState(false);
  const [restData, setRestData] = useState(null);
  const [appReady, setAppReady] = useState(false);
  useEffect(() => {
    if (MainStore.cartItem[0].category == 'Su-Damacana') {
      axios.get(`${axiosConfig.serverIp}/getWaterDealer/${MainStore.cartItem[0].rId}`).then((res) => {
        setRestData(res.data);
        setAppReady(true);
      });
    } else {
      axios.get(`${axiosConfig.serverIp}/getRestaurant/${MainStore.cartItem[0].rId}`).then((res) => {
        setRestData(res?.data?.restaurantData);
        setAppReady(true);
      });
    }
  }, []);

  const toast = useToast();

  const sendOrder = async () => {
    if (contract) {
      if (paymentType == 'payAtTheDoor') {
        axios
          .post(
            `${axiosConfig.serverIp}/createOrder`,
            {
              customerData: {
                customerName: MainStore.myData.name + MainStore.myData.surName,
                customerId: MainStore.myData._id,
                city: MainStore.selectedAddress.province,
                customerAddress: `${MainStore.selectedAddress.neighbourhood} ${MainStore.selectedAddress.fullAdress} ${MainStore.selectedAddress.province}/${MainStore.selectedAddress.district}`,
              },
              restaurantData: {
                rId: MainStore.cartItem[0].rId,
                rName: MainStore.cartItem[0].restaurantName,
              },
              deliveryOptions,
              promoCode,
              paymentType,
              orderedFood: MainStore.cartItem,
              totalPaid: MainStore.calcutePrice,
              customerNote: note,
            },
            {headers: {Authorization: `Bearer ${MainStore.token}`}}
          )
          .then((res) => {
            if (res.data.success) {
              socket.emit('newOrder', {
                rId: MainStore.cartItem[0].rId,
              });
              navigation.navigate('SuccessScreen');
            }
          })
          .catch((err) => console.log(err));
      } else if (paymentType == 'takeAway' || paymentType == 'onlinePayment') {
        navigation.navigate('OnlinePayment', {
          customerNote: note,
          takeAwayTime,
          paymentType,
          deliveryOptions,
          promoCode,
        });
      } else {
        ErrorToastMessage(
          toast,
          MainStore.langPref == 'tr' ? 'Lütfen bir ödeme yöntemi seçiniz' : 'Please choose a payment method'
        );
      }
    } else {
      ErrorToastMessage(
        toast,
        MainStore.langPref == 'tr' ? 'Lütfen kullanım koşullarını kabul edin.' : 'Please accept the terms of use.'
      );
    }
  };

  return appReady ? (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
        }}
      >
        {width > 1279 ? (
          <View
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 999,
            }}
          >
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <Modal
          animationType='slide'
          transparent={true}
          visible={showModal}
          onRequestClose={() => {
            setShowModal(!showModal);
          }}
        >
          <View
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <View style={styles.modalView}>
              <TouchableOpacity
                onPress={() => setShowModal(!showModal)}
                style={{position: 'absolute', left: 10, top: 5}}
              >
                <Feather
                  name='x-circle'
                  size={15}
                  color='black'
                />
              </TouchableOpacity>
              <View>
                <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 15}}>Lütfen Kampanya Kodunu Gir</Text>
              </View>
              <View style={{marginTop: 46}}>
                <TextInput
                  value={promoCode}
                  onChangeText={(text) => setPromoCode(text)}
                  style={{
                    width: 196,
                    height: 60,
                    backgroundColor: 'rgba(211,214,219,0.3)',
                    borderRadius: 10,
                    paddingLeft: 12,
                    fontFamily: 'Gilroy-Regular',
                  }}
                  placeholderTextColor='rgba(30,30,30,0.6)'
                  placeholder='Kod'
                />
              </View>
              <View>
                <TouchableOpacity
                  onPress={() => {
                    axios.get(`${axiosConfig.serverIp}/testPromotion/${promoCode}`).then((res) => {
                      if (res?.data?.error) {
                        setSuccessPromo(false);
                        ErrorToastMessage(toast, res.data.error);
                      } else {
                        setSuccessPromo(true);
                        setDiscountAmount(discountAmount + res.data.discountAmount);
                        setShowModal(!showModal);
                      }
                    });
                  }}
                  style={{
                    backgroundColor: colorSchema.mainColor,
                    width: 200,
                    height: 45,
                    borderRadius: 20,
                    marginTop: 46,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                    }}
                  >
                    Devam Et
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <ScrollView>
          <View>
            <GoBackTitle
              navigation={navigation}
              title={MainStore.langPref == 'tr' ? 'Ödeme' : 'Checkout'}
            />
          </View>

          <View style={{marginTop: 17}}>
            <View
              style={{
                marginTop: 17,
                flexDirection: 'row',
                justifyContent: width > 1279 ? 'center' : 'space-evenly',
                alignItems: 'center',
              }}
            >
              <View>
                <CartIcon black />
              </View>
              <View style={{marginHorizontal: 30}}>
                <Feather
                  name='chevron-right'
                  size={13}
                  color={'black'}
                />
              </View>
              <View
                style={{
                  backgroundColor: 'black',
                  padding: 6,
                  borderRadius: 50,
                }}
              >
                <WalletIcon black={false} />
              </View>
              <View style={{marginHorizontal: 30}}>
                <Feather
                  name='chevron-right'
                  size={13}
                  color={'black'}
                />
              </View>
              <View style={{marginRight: 12}}>
                <SuccessIcon black={true} />
              </View>
            </View>
          </View>

          {restData?.deliveryOptions?.onlinePayment ? (
            <View style={{marginTop: 17}}>
              <View style={{marginLeft: 10}}>
                <Text
                  style={{
                    color: colorSchema.textColor,
                    fontSize: 18,
                    fontFamily: 'Gilroy-Bold',
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Ödeme Yöntemi' : 'Payment Method'}
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,
                  width: width - 20,
                  alignSelf: 'center',
                  marginTop: 5,
                }}
              />
            </View>
          ) : (
            <></>
          )}

          <View style={{marginTop: MainStore.isTakeAway ? 0 : 17, marginLeft: 10}}>
            {!MainStore.isTakeAway && (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 17,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: width > 1279 ? 300 : width,
                }}
              >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View>
                    <WalletIcon black />
                  </View>
                  <View style={{marginLeft: 10}}>
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 14,
                        color: colorSchema.textColor,
                      }}
                    >
                      {MainStore.langPref == 'tr' ? 'Online Ödeme' : 'Online Payment'}
                    </Text>
                  </View>
                </View>

                <View style={{marginRight: 17}}>
                  <TouchableOpacity
                    onPress={() => {
                      setPaymentType('onlinePayment');
                      setDeliveryOptions('getHome');
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: 'rgba(211,214,219,0.8)',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: paymentType == 'onlinePayment' ? 'red' : 'rgba(211,214,219,0.8)',
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                      }}
                    ></View>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {restData?.deliveryOptions?.takeAway && (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 27,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: width > 1279 ? 300 : width,
                }}
              >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View>
                    <TakeAwayIcon selected={false} />
                  </View>
                  <View style={{marginLeft: 10}}>
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 14,
                        color: colorSchema.textColor,
                      }}
                    >
                      {MainStore.langPref == 'tr' ? 'Gel - Al' : 'Take-Away'}
                    </Text>
                  </View>
                </View>

                <View style={{marginRight: 17}}>
                  <TouchableOpacity
                    onPress={() => {
                      setPaymentType('takeAway');
                      setDeliveryOptions('takeAway');
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: 'rgba(211,214,219,0.8)',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: paymentType == 'takeAway' ? 'red' : 'rgba(211,214,219,0.8)',
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                      }}
                    ></View>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {paymentType == 'takeAway' ? (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: width > 1279 ? 300 : width,
                }}
              >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <TouchableOpacity
                    onPress={() => setTakeAwayTimeVisible(!takeAwayTimeVisible)}
                    style={{marginLeft: 10}}
                  >
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 14,
                        color: colorSchema.textColor,
                      }}
                    >
                      {MainStore.langPref == 'tr' ? 'Ne Zaman Yemek İstersin  ' : 'Ne Zaman Yemek İstersin'}
                    </Text>
                  </TouchableOpacity>
                </View>

                <View style={{marginRight: 17}}>
                  <TouchableOpacity
                    onPress={() => setTakeAwayTimeVisible(!takeAwayTimeVisible)}
                    style={{marginLeft: 10}}
                  >
                    <View>
                      <Text>{`${takeAwayTime.hours}:${takeAwayTime.minutes}`}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <></>
            )}

            {restData?.deliveryOptions?.cashPayment ||
              (restData?.deliveryOptions?.creditCard && !MainStore.isTakeAway && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 27,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: width > 1279 ? 300 : width,
                  }}
                >
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View>
                      <PayDoorIcon />
                    </View>
                    <View style={{marginLeft: 10}}>
                      <Text
                        style={{
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 14,
                          color: colorSchema.textColor,
                        }}
                      >
                        {MainStore.langPref == 'tr' ? 'Kapıda Ödeme' : 'Pay at the Door'}
                      </Text>
                    </View>
                  </View>

                  <View style={{marginRight: 17}}>
                    <TouchableOpacity
                      onPress={() => {
                        setPaymentType('payAtTheDoor');
                      }}
                      style={{alignItems: 'center', justifyContent: 'center'}}
                    >
                      {paymentType == 'payAtTheDoor' ? (
                        <Feather
                          name='chevron-up'
                          size={20}
                          color='black'
                        />
                      ) : (
                        <Feather
                          name='chevron-down'
                          size={20}
                          color='black'
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              ))}

            {paymentType == 'payAtTheDoor' ? (
              <View>
                {restData?.deliveryOptions?.cashPayment ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 10,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      maxWidth: width > 1279 ? 300 : width,
                    }}
                  >
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <View style={{marginLeft: 10}}>
                        <Text
                          style={{
                            fontFamily: 'Gilroy-Medium',
                            fontSize: 14,
                            color: colorSchema.textColor,
                          }}
                        >
                          {MainStore.langPref == 'tr' ? 'Kapıda Nakit' : 'Cash'}
                        </Text>
                      </View>
                    </View>

                    <View style={{marginRight: 17}}>
                      <TouchableOpacity
                        onPress={() => {
                          setDeliveryOptions('cash');
                        }}
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          backgroundColor: 'rgba(211,214,219,0.8)',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: deliveryOptions == 'cash' ? 'red' : 'rgba(211,214,219,0.8)',
                            width: 15,
                            height: 15,
                            borderRadius: 50,
                          }}
                        ></View>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {restData?.deliveryOptions?.creditCard ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 10,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      maxWidth: width > 1279 ? 300 : width,
                    }}
                  >
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <View style={{marginLeft: 10}}>
                        <Text
                          style={{
                            fontFamily: 'Gilroy-Medium',
                            fontSize: 14,
                            color: colorSchema.textColor,
                          }}
                        >
                          {MainStore.langPref == 'tr' ? 'Kapıda Kredi Kartı' : 'Credit Card on delivery'}
                        </Text>
                      </View>
                    </View>

                    <View style={{marginRight: 17}}>
                      <TouchableOpacity
                        onPress={() => {
                          setDeliveryOptions('creditCard');
                        }}
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          backgroundColor: 'rgba(211,214,219,0.8)',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: deliveryOptions == 'creditCard' ? 'red' : 'rgba(211,214,219,0.8)',
                            width: 15,
                            height: 15,
                            borderRadius: 50,
                          }}
                        ></View>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            ) : (
              <></>
            )}

            <View style={{marginTop: 27}}>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 14,
                    color: colorSchema.textColor,
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Eklemek istediğiniz bir not varsa ;' : ' Special Instructions'}
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <TextInput
                  multiline
                  value={note}
                  onChangeText={(value) => setnote(value)}
                  style={{
                    color: colorSchema.textColor,
                    fontSize: 13,
                    fontFamily: 'Gilroy-Regular',
                    width: width > 1279 ? 700 : width - 20,
                    borderWidth: 0.3,
                    borderRadius: 5,
                    height: 80,
                    paddingTop: 11,
                    paddingLeft: 11,
                  }}
                  placeholder={
                    MainStore.langPref == 'tr'
                      ? 'Notunuzu Giriniz'
                      : 'Please let us know if you are allergic to anything or if we need to avoid anything You can write your special instructions here'
                  }
                />
              </View>
            </View>

            <View
              style={{
                marginTop: 27,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View>
                <PromotionIcon />
              </View>
              <TouchableOpacity
                onPress={() => setShowModal(!showModal)}
                style={{marginLeft: 17}}
              >
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 14,
                    color: discountAmount == 0 ? colorSchema.textColor : colorSchema.mainColor,
                  }}
                >
                  {promoCode.length == 0
                    ? MainStore.langPref == 'tr'
                      ? 'Promosyon Kodu Kullan'
                      : 'Apply a voucher'
                    : promoCode}
                </Text>
              </TouchableOpacity>
            </View>

            <View style={{marginTop: 27}}>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 16,
                    color: colorSchema.textColor,
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Tüm Siparişler' : 'All Orders'}
                </Text>
              </View>
              {MainStore.cartItem.map((item, index) => (
                <View key={index}>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Regular',
                      fontSize: 15,
                      color: colorSchema.textColor,
                    }}
                  >{`${item.name}`}</Text>
                </View>
              ))}
              <View
                style={{
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.5,
                  width: width - 20,
                  marginTop: 5,
                }}
              />
            </View>

            <View style={{marginTop: 27}}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: 32,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Ara Toplam' : 'Subtotal'}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: colorSchema.greenColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                    }}
                  >{`${MainStore.calcuteTotalPrice} TL`}</Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: 32,
                  marginVertical: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Gönderim Ücreti' : 'Shipping Costs'}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: colorSchema.textColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Ücretsiz' : 'Free'}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: 32,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'İndirim' : 'Discount'}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: colorSchema.mainColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                    }}
                  >{`${parseFloat(MainStore.calcuteTotalPrice - MainStore.calcutePrice + discountAmount)} TL`}</Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: 32,
                  marginTop: 20,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 20,
                      color: colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Toplam' : 'Total'}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: colorSchema.greenColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 20,
                    }}
                  >{`${parseFloat(MainStore.calcutePrice - discountAmount).toFixed(2)} TL`}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginVertical: 37,
                marginBottom: 150,
                alignItems: 'center',
              }}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{marginRight: 10}}>
                  <TouchableOpacity
                    onPress={() => setContract(!contract)}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: contract ? colorSchema.mainColor : 'rgba(211,214,219,0.4)',
                      borderWidth: 1,
                      borderColor: 'rgba(211,214,219,1)',
                    }}
                  />
                </View>
                <View>
                  {MainStore.langPref == 'tr' ? (
                    <TouchableOpacity onPress={() => navigation.navigate('SalesAgree')}>
                      <Text
                        style={{
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 14,
                          color: colorSchema.mainColor,
                        }}
                      >
                        Kullanım Koşulları
                        <Text style={{color: 'rgba(30,30,30,0.8)'}}>'ni okudum, kabul ediyorum</Text>
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 14,
                        color: 'rgba(30,30,30,0.8)',
                      }}
                    >
                      I have read and accept the
                      <Text style={{color: colorSchema.mainColor}}> Terms of Use</Text>
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            flex: 1,
            bottom: 20,
            alignSelf: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() => sendOrder()}
            style={{
              width: width > 1279 ? 400 : width - 28,
              marginTop: 15,
              backgroundColor: colorSchema.mainColor,
              borderRadius: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <View style={{marginLeft: 11, marginVertical: 9}}>
              <CartIcon black={false} />
            </View>
            <View style={{marginLeft: 11}}>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 19,
                    color: colorSchema.cartColor,
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Siparişi Ver' : 'Order Now'}
                </Text>
              </View>
            </View>
            <View style={{marginRight: 11}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: 14,
                  color: 'white',
                }}
              >{`${parseFloat(MainStore.calcutePrice - discountAmount).toFixed(2)} TL`}</Text>
            </View>
          </TouchableOpacity>
        </View>

        {takeAwayTimeVisible ? (
          <View style={{position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'white', height: 350}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <TouchableOpacity onPress={() => setTakeAwayTimeVisible(!takeAwayTimeVisible)}>
                <Text
                  style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor, marginLeft: 10}}
                >
                  Kapat
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setTakeAwayTimeVisible(!takeAwayTimeVisible)}>
                <Text
                  style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor, marginRight: 10}}
                >
                  Seç
                </Text>
              </TouchableOpacity>
            </View>
            <View>
              <TimePicker
                zeroPadding={true}
                value={takeAwayTime}
                onChange={(time) => {
                  setTakeAwayTime({
                    hours: time.hours,
                    minutes: time.minutes,
                    seconds: time.seconds,
                  });
                }}
              />
            </View>
          </View>
        ) : (
          <></>
        )}
      </ScrollView>
    </SafeAreaView>
  ) : (
    <LoadingSpinner />
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 35,
    alignItems: 'center',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
export default observer(Checkout);
