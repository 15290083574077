import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import colorSchema from '../../config/colorSchema'
import WebFooter from '../../components/footer/WebFooter'
import { Feather, FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import * as Yup from 'yup'
import MapPin from '../../assets/svg/MapPin'
import RedButton from '../../components/button/RedButton'
import { Formik } from 'formik'
import axiosConfig from '../../config/axiosConfig'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage'
import axios from 'axios'
import WebHeaderB1 from '../../components/header/WebHeaderB1'
import LogoBackHeader from '../../components/header/LogoBackHeader'
import SuccessToastMessage from '../../components/feedback/SuccessToastMessage'
import { useToast } from '@gluestack-ui/themed'
const { width, height } = Dimensions.get('window')

const initialValues = { name: '', mail: '', phoneNumber: '', subject: '', message: '' };


const Contact = ({ navigation }) => {
    const toast = useToast()

    const handleSubmit = (values, { resetForm }) => {
        axios.post(`${axiosConfig.serverIp}/sendContactForm`, {
            name: values.name,
            mail: values.mail,
            phoneNumber: values.phoneNumber,
            subject: values.subject,
            message: values.message
        }).then(res => {
            if (res.data.success) {
                SuccessToastMessage(toast, res.data.success);
                resetForm(initialValues); // tüm alanları boş string yap
            } else {
                ErrorToastMessage(toast, 'Bir sorun oluştu lütfen daha sonra tekrar deneyiniz.');
            }
        });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Soyad alanı zorunludur'),
        mail: Yup.string().email('Hatalı mail formatı').required('Email alanı zorunludur'),
        phoneNumber: Yup.string().required('Telefon Numarası alanı zorunludur'),
        subject: Yup.string().required('Konu alanı zorunludur'),
        message: Yup.string().required('Mesaj alanı zorunludur').min(25, 'Mesaj alanı minimum 25 karakterden oluşmalıdır.')
    })

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <SafeAreaView>
                <View>
                    {width > 1279 ?
                        <WebHeaderB1 navigation={navigation} />
                        :
                        <LogoBackHeader navigation={navigation} />
                    }
                </View>

                <View style={{ flexWrap: 'wrap', flexDirection: 'row', marginTop: 34, marginLeft: width > 1279 ? 80 : 17 }}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}

                        validationSchema={validationSchema}
                    >
                        {({ values, handleSubmit, handleChange, errors }) => (
                            <View style={{ width: width > 500 ? 400 : width - 100 }}>
                                <View style={{ marginBottom: 17 }}>
                                    <TextInput value={values.name} onChangeText={handleChange('name')} style={style.input} placeholder='Ad Soyad' />
                                    <Text style={{ marginBottom: 20, color: colorSchema.mainColor, marginLeft: 3, fontSize: 15, fontFamily: 'Gilroy-SemiBold' }}>{errors.name}</Text>
                                </View>
                                <View style={{ marginBottom: 17 }}>
                                    <TextInput value={values.mail} onChangeText={handleChange('mail')} style={style.input} placeholder='E-mail' />
                                    <Text style={{ marginBottom: 20, color: colorSchema.mainColor, marginLeft: 3, fontSize: 15, fontFamily: 'Gilroy-SemiBold' }}>{errors.mail}</Text>

                                </View>
                                <View style={{ marginBottom: 17 }}>
                                    <TextInput value={values.phoneNumber} onChangeText={handleChange('phoneNumber')} style={style.input} placeholder='Cep Telefonu' />
                                    <Text style={{ marginBottom: 20, color: colorSchema.mainColor, marginLeft: 3, fontSize: 15, fontFamily: 'Gilroy-SemiBold' }}>{errors.phoneNumber}</Text>

                                </View>
                                <View style={{ marginBottom: 17 }}>
                                    <TextInput value={values.subject} onChangeText={handleChange('subject')} style={style.input} placeholder='Konu' />
                                    <Text style={{ marginBottom: 20, color: colorSchema.mainColor, marginLeft: 3, fontSize: 15, fontFamily: 'Gilroy-SemiBold' }}>{errors.subject}</Text>

                                </View>
                                <View style={{ marginBottom: 17 }}>
                                    <TextInput value={values.message} onChangeText={handleChange('message')} style={[style.input, { height: 202 }]} placeholder='Mesaj' multiline />
                                    <Text style={{ marginBottom: 20, color: colorSchema.mainColor, marginLeft: 3, fontSize: 15, fontFamily: 'Gilroy-SemiBold' }}>{errors.message}</Text>

                                </View>
                                <View style={{ marginTop: 140 }}>
                                    <RedButton clickEvent={() => handleSubmit()} text='Gönder' />
                                </View>
                            </View>
                        )}
                    </Formik>

                    <View style={{ width: width > 500 ? 400 : width - 100, marginLeft: width > 1279 ? 50 : 0 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ marginRight: 17 }}>
                                <FontAwesome name='send' size={21} color={'black'} />
                            </View>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 20, color: colorSchema.textColor }}>0850 888 0707</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 17 }}>
                            <View style={{ marginRight: 17 }}>
                                <FontAwesome name='envelope' size={21} color={'black'} />
                            </View>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 20, color: colorSchema.textColor }}>info@yemek.club</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ marginRight: 17 }}>
                                <MapPin />
                            </View>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 20, color: colorSchema.textColor }}>Altındağ Mh 100.Yıl Bulvarı No:12 CityPlaza Kat:5 D:23 Muratpaşa/ANTALYA</Text>
                            </View>
                        </View>
                    </View>


                </View>


            </SafeAreaView >

            <View style={{ marginTop: 34, flex: 1, position: 'relative', bottom: 0, alignSelf: 'center' }}>
                <WebFooter navigation={navigation} />
            </View>
        </SafeAreaView>
    )
}
const style = StyleSheet.create(
    {
        input: { borderRadius: 10, width: width > 500 ? 400 : width - 100, height: 43, backgroundColor: 'rgba(211,214,219,0.2)', paddingLeft: 10, fontFamily: 'Gilroy-Medium', fontSize: 15, borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)', padding: 12 },
    }
)
export default Contact