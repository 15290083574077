import * as React from "react"
import Svg, { Ellipse, Circle, Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const ProfileIconWhite = (props) => (
    props.selected ?
        <Svg
            width={18}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 10.75a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0 12.5c4.832 0 8.75-2.239 8.75-5s-3.918-5-8.75-5-8.75 2.239-8.75 5 3.918 5 8.75 5Z"
                fill="#FA0C08"
            />
        </Svg>
        :
        <Svg
            width={30}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Ellipse
                cx={15}
                cy={21.875}
                rx={8.75}
                ry={4.375}
                stroke={'white'}
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
            <Circle
                cx={15}
                cy={8.75}
                r={5}
                stroke={'white'}
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
        </Svg>
)

export default ProfileIconWhite
