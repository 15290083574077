import {View, Text, StyleSheet, Dimensions, ScrollView} from 'react-native';
import React from 'react';
import {SafeAreaView} from 'react-native';
import colorSchema from '../../config/colorSchema';
import WebHeader from '../../components/header/WebHeader';
import LogoHeader from '../../components/header/LogoHeader';
import WebFooter from '../../components/footer/WebFooter';
import WebHeaderB2 from '../../components/header/WebHeaderB2';
import LogoBackHeader from '../../components/header/LogoBackHeader';

const {width, height} = Dimensions.get('window');

const Aydinlatma = ({navigation, route}) => {
  return (
    <View style={{backgroundColor: 'white'}}>
      <SafeAreaView>
        <View>
          {width > 1279 ? <WebHeaderB2 navigation={navigation} /> : <LogoBackHeader navigation={navigation} />}
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginBottom: 120}}
        >
          <View style={{width: width > 1279 ? 894 : width - 34, marginLeft: width > 1279 ? 80 : 17}}>
            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİNAYDINLATMA METNİ
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Yemek Club olarak, siz müşterilerimize ait kişisel verilerin, 6698 sayılı Kişisel Verilerin Korunması
                  Kanunu (“KVKK”), ikincil düzenlemeleri ve Kişisel Verileri Koruma Kurulu Kararlarına uygun olarak
                  işlenmesi ve korunması için azami hassasiyeti göstermekteyiz. Bu aydınlatma metni (“Aydınlatma
                  Metni”), “Yemek Club” mobil uygulaması (“Uygulama”) aracılığıyla, kişisel verilerinizi veri sorumlusu
                  sıfatıyla nasıl işlediğimize dair sizleri bilgilendirmek amacıyla hazırlanmıştır.
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Kişisel verilerinizin korunmasına yönelik aldığımız tedbirler ve kişisel verileri işlerken uyum
                  sağladığımız kurallar hakkında detaylı bilgi için ayrıca Kişisel Verilerin İşlenmesi ve Korunması
                  Politikamıza göz atmanızı öneririz.
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Kullanıcı dostu arayüzü ile yemek siparişi vermenizi çok kolaylaştırdık. Dilediğiniz yemeği binlerce
                  seçenek arasından saniyeler içinde bulabilirsiniz. Yemeğinizi bulduktan sonra da gerisini bize
                  bırakın! Kapıda veya online ödedikten sonra hızlı servis sistemimizle en sevdiğiniz restoran anında
                  kapınızda belirebilir!
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Yemek Club Hangi Kişisel Verilerinizi İşler? Kişisel Verilerinizi Hangi Yöntemlerle, Amaçlarla ve
                  Hangi Hukuki Sebeplere Dayanarak İşler?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Uygulama üzerinden, çağrı merkezi aracılığıyla telefon üzerinden ve bizimle e-posta aracılığıyla
                  iletişim kurmanız halinde e-posta üzerinden tamamen otomatik ve kısmen otomatik yöntemler ile elde
                  ettiğimiz kişisel verilerinizi, KVKK'nın 5. maddesinde belirtilen hukuki sebeplere dayalı olarak
                  işliyoruz. Kişisel verilerinizi işleme amaçlarımız ve dayandığımız hukuki sebeplere aşağıda yer
                  verdik: Size sunduğumuz hizmet kapsamında aşağıda belirtilen kişisel verileri altında belirtilen
                  amaçlar kapsamında işliyoruz:
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  <Text style={{color: colorSchema.mainColor}}>1. Kimlik Bilgileri :</Text> Ad-soyad,
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  İşleme Amaçları;
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  · Hukuki talep ve başvurularınızın incelenmesi, değerlendirilmesi ve sonuçlandırılması; olası hukuki
                  uyuşmazlıkların giderilmesi ve bu amaçla yetkili kişi, kurum ve kuruluşlarla bilgi paylaşımında
                  bulunulması.
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  · Uygulama üzerinden verdiğiniz siparişlere ilişkin sözleşme ve satış süreçlerinin yürütülmesi,
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  · Ödemelere ilişkin finans ve muhasebe işlerinin yürütülmesi, siparişiniz sonucunda fatura
                  düzenlenmesi.
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  · Faaliyetlerimizin mevzuata uygun olarak yürütülmesi ve hukuki yükümlülüklerimiz kapsamında
                  saklanması zorunlu olan verilerin muhafazası.
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  · Hizmetlerimizden yararlanabilmeniz için bilgilerinizin doğrulanması, Uygulamada üyelik kaydınızın
                  oluşturulması ve hesabınıza giriş imkânı sağlanması.
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      {width > 1279 && (
        <View style={{marginTop: 34}}>
          <WebFooter navigation={navigation} />
        </View>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  headText: {fontFamily: 'Gilroy-Bold', fontSize: 20, color: colorSchema.mainColor},
  descText: {fontFamily: 'Gilroy-Regular', fontSize: 15},
});

export default Aydinlatma;
