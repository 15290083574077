import { View, Text, SafeAreaView, Image, TouchableOpacity, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import StarIcon from '../../assets/svg/StarIcon'
import GoBackTitle from '../../components/header/GoBackTitle'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import WebHeader from '../../components/header/WebHeader'
const { width, height } = Dimensions.get('window')

const RestaurantAbout = ({ navigation, route }) => {
    const { data } = route.params
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView style={{ maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0 }}>
                {width > 1279 ?
                    <WebHeader navigation={navigation} />

                    :
                    <></>
                }
                <View>
                    <View>
                        <GoBackTitle title={MainStore.langPref == 'tr' ? 'Hakkımızda' : 'About Us'} navigation={navigation} />
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 29, justifyContent: 'space-between', marginBottom: 51 }}>
                        <View style={{ marginLeft: 20 }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Heavy', fontSize: 24, color: '#1E1E1E' }}>{data.restaurantName} <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 22, color: 'rgba(0,0,0,0.5)' }}>({data.district})</Text></Text>
                            </View>
                            <View style={{ marginTop: 15, width: width > 1279 ? 500 : width - 130 }}>
                                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16 }}>Adres: <Text style={{ fontFamily: 'Gilroy-Medium' }}>{data.fullAddress} </Text></Text>
                            </View>

                        </View>

                        <View>
                            <View style={{ borderWidth: 5, borderRadius: 50, width: 65, height: 65, alignItems: 'center', justifyContent: 'center', marginRight: 31 }}>
                                <View>
                                    <Text style={{ fontSize: 24, fontFamily: 'Gilroy-Medium', color: '#1E1E1E' }}>{data.rating}</Text>
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 10, color: '#1E1E1E' }}>({data.comments.length})</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{ marginLeft: 15, marginBottom: 17 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16 }}>Çalışma Saatleri</Text>
                        </View>


                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Pazartesi</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.monday}</Text>
                            </View>
                        </View>


                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Salı</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.tuesday}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Çarşamba</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.wednesday}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Perşembe</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.thursday}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Cuma</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.friday}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Cumartesi</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.saturday}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                            <View style={{ marginLeft: 23 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>Pazar</Text>
                            </View>
                            <View style={{ marginRight: 36 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{data.workingHours.sunday}</Text>
                            </View>
                        </View>


                    </View>

                    <View>
                        <View style={{ marginLeft: 15, marginBottom: 17 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16 }}>{MainStore.langPref == 'tr' ? 'Ödeme Seçenekleri' : 'Payment options'}</Text>
                        </View>

                        {data.deliveryOptions.takeAway
                            ?
                            <View style={{ marginLeft: 23, marginBottom: 17 }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Gilroy-Regular' }}>Gel - Al</Text>
                            </View>
                            :
                            <></>
                        }
                        {data.deliveryOptions.onlinePayment
                            ?
                            <View style={{ marginLeft: 23, marginBottom: 17 }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Gilroy-Regular' }}>Online Ödeme</Text>
                            </View>
                            :
                            <></>
                        }

                        {data.deliveryOptions.cashPayment
                            ?
                            <View style={{ marginLeft: 23, marginBottom: 17 }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Gilroy-Regular' }}>Nakit</Text>
                            </View>
                            :
                            <></>
                        }
                        {data.deliveryOptions.creditCard
                            ?
                            <View style={{ marginLeft: 23, marginBottom: 17 }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Gilroy-Regular' }}>Kredi/Banka Kartı</Text>
                            </View>
                            :
                            <></>
                        }




                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default observer(RestaurantAbout)