import { View, Text, SafeAreaView, TouchableOpacity, Dimensions, Image, ScrollView, FlatList } from 'react-native'
import React, { useState, useEffect, useRef } from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import StarIcon from '../../assets/svg/StarIcon'
import PlusIcon from '../../assets/svg/PlusIcon'
import MinusIcon from '../../assets/svg/MinusIcon'
import CartIcon from '../../assets/svg/CartIcon'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import LoadingSpinner from '../../components/feedback/LoadingSpinner'
import { useToast } from '@gluestack-ui/themed'

const { width, height } = Dimensions.get('window')
const FoodDetail = ({ navigation, route }) => {
    const { id } = route.params
    const [amount, setamount] = useState(1)
    const [selected, setSelected] = useState('Seçim')
    const [isRender, setIsRender] = useState(false)
    const [changed, setChanged] = useState(null)
    const [changedIndex, setChangedIndex] = useState(null)
    const [visible, setVisible] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [foodData, setFoodData] = useState(null)
    const [appReady, setAppReady] = useState(false)
    const [step, setStep] = useState(1)
    const pickerRef = useRef();
    const toast = useToast()
    useState(() => {
        axios.post(`${axiosConfig.serverIp}/getWater/${id}`, { neighbourhood: MainStore.selectedAddress.neighbourhood }).then(res => {
            if (!res.data.restaurantOpen) {

                setFoodData(res.data)
                setAppReady(true)
            }
            else {
                ErrorToastMessage(toast, MainStore.langPref == 'tr' ? 'Üzgünüz şuan seçtiğiniz restoran hizmet veremiyor...' : 'Sorry, the restaurant you selected is currently unavailable.')
                navigation.goBack()

            }

        })
    }, [])



    const toggleBottomNavigationView = () => {
        setIsRender(!isRender)
        setVisible(!visible);
    };



    return (
        appReady
            ?
            <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <View style={{ marginLeft: 22 }}>
                        <Feather name='chevron-left' size={25} color='black' />
                    </View>
                </TouchableOpacity>
                <ScrollView style={{ marginTop: 22, marginLeft: 14 }}>
                    <View>
                        <Text style={{ fontFamily: 'Gilroy-Heavy', fontSize: 32 }}>{foodData.restaurantName}</Text>
                    </View>

                    <View style={{ marginTop: 17 }}>
                        <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.textColor }}>{foodData.name}</Text>
                    </View>

                    <View style={{ width: width, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'column' }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 14, color: '#8E8B8B' }}>{foodData.description}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View>
                                        <StarIcon />
                                    </View>
                                    <View style={{ marginLeft: 4 }}>
                                        <Text style={{ fontSize: 10, fontFamily: 'Gilroy-Light' }}>{foodData.rating} ({foodData.comments?.length})</Text>
                                    </View>
                                </View>
                                <View>
                                    <Text style={{ color: colorSchema.greenColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }}>{foodData.price} TL</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ marginRight: 21 }}>
                            <Image source={{ uri: foodData.foodImage }} style={{ width: 125, height: 125, borderRadius: 5 }} />
                        </View>
                    </View>

                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: 90, height: 27, borderRadius: 6, borderWidth: 0.3, borderColor: 'rgba(30,30,30,0.6)', alignItems: 'center' }}>
                            <View style={{ marginLeft: 6 }}>
                                {amount > 1
                                    ?
                                    <TouchableOpacity onPress={() => {
                                        setamount(amount - 1)
                                    }} style={{ width: 20, height: 27, alignItems: 'center', justifyContent: 'center' }}>
                                        <MinusIcon width={10} height={2} />
                                    </TouchableOpacity>
                                    :
                                    <></>
                                }
                            </View>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.textColor }}>{amount}</Text>
                            </View>
                            <View style={{ marginRight: 6 }}>
                                <TouchableOpacity style={{ width: 20, height: 27, alignItems: 'center', justifyContent: 'center' }} onPress={() => setamount(amount + 1)}>
                                    <PlusIcon width={10} height={10} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>


                </ScrollView >

                <View style={{ position: 'absolute', flex: 1, bottom: 20, alignSelf: 'center' }}>
                    <TouchableOpacity onPress={() => {
                        if (step == 1) {
                            MainStore.addCart(foodData, amount)
                            setStep(2)
                        }
                        else {
                            navigation.navigate('Cart')
                        }

                    }} style={{ width: width - 28, backgroundColor: step == 1 ? 'rgba(30,30,30,0.8)' : colorSchema.waterColor, borderRadius: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginLeft: 11, marginVertical: 9 }}>
                            <CartIcon />
                        </View>
                        <View style={{ justifyContent: 'center', alignSelf: 'center' }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 12, color: colorSchema.cartColor, textAlign: 'center' }}>{foodData.name}</Text>
                            </View>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 19, color: colorSchema.cartColor }}>{step == 1 ? MainStore.langPref == 'tr' ? 'Sepete Ekle' : 'Add to cart' : MainStore.langPref == 'tr' ? 'Sepete Git' : 'Go to cart'}</Text>
                            </View>
                        </View>
                        <View style={{ marginLeft: 40 }} />
                    </TouchableOpacity>


                </View>


            </SafeAreaView >
            :
            <LoadingSpinner />
    )
}

export default observer(FoodDetail)


