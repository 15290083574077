import * as React from "react"
import Svg, { Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const HeartIcon = (props) => (
    <Svg
        width={25}
        height={23}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M7.683 1.667c-3.7 0-6.183 2.994-6.183 6.692 0 4.196 5.862 9.21 10.885 12.558 5.024-3.348 10.818-8.442 10.887-12.558.061-3.698-2.333-6.692-6.032-6.692-1.579 0-3.18.834-4.855 2.507-1.675-1.673-3.124-2.507-4.702-2.507Z"
            fill={props.liked ? colorSchema.mainColor : 'rgba(0,0,0,0.3)'}
            style={{
                mixBlendMode: "soft-light",
            }}
        />
        <Path
            d="M7.683 1.667c-3.7 0-6.183 2.994-6.183 6.692 0 4.196 5.862 9.21 10.885 12.558 5.024-3.348 10.818-8.442 10.887-12.558.061-3.698-2.333-6.692-6.032-6.692-1.579 0-3.18.834-4.855 2.507-1.675-1.673-3.124-2.507-4.702-2.507Z"
            stroke="#FCFCFC"
            strokeWidth={3}
        />
    </Svg>
)

export default HeartIcon
