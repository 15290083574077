import {View, Text, Dimensions, StatusBar, Platform, TouchableOpacity} from 'react-native';
import React from 'react';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
const {width, height} = Dimensions.get('window');

const LogoBackHeader = ({water, navigation}) => {
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 8}}>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Feather
          name='chevron-left'
          size={25}
          color='black'
        />
      </TouchableOpacity>
      <Text
        style={{
          fontFamily: 'Gilroy-Heavy',
          fontSize: 24,
          lineHeight: 31,
          color: water ? colorSchema.waterColor : colorSchema.mainColor,
          marginLeft: 9,
        }}
      >
        {water ? 'Yemek.Club Su' : 'Yemek.Club'}
      </Text>
    </View>
  );
};

export default LogoBackHeader;
