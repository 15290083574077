import * as React from "react";
import Svg, { Path } from "react-native-svg";
import colorSchema from "../../config/colorSchema";

function WorlIcon(props) {
  return (
    <Svg
      width={28}
      height={27}
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M26.64 13.577c0 6.939-5.624 12.564-12.563 12.564M26.64 13.577c0-6.939-5.625-12.564-12.564-12.564M26.64 13.577c0-2.082-5.625-3.77-12.564-3.77-6.94 0-12.564 1.688-12.564 3.77m25.128 0c0 2.082-5.625 3.77-12.564 3.77-6.94 0-12.564-1.688-12.564-3.77m12.564 12.564c-6.94 0-12.564-5.625-12.564-12.564m12.564 12.564c2.775 0 5.025-5.625 5.025-12.564s-2.25-12.564-5.025-12.564m0 25.128c-2.776 0-5.026-5.625-5.026-12.564s2.25-12.564 5.026-12.564M1.513 13.577c0-6.939 5.625-12.564 12.564-12.564"
        stroke={props.selected ? colorSchema.mainColor : "#1E1E1E"}
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export default WorlIcon;