import * as React from "react";
import Svg, { Path } from "react-native-svg";

function WalletIcon(props) {
    return (
        <Svg
            width={26}
            height={26}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M23.833 6.5H6.167a4 4 0 00-4 4v9.333a4 4 0 004 4h13.666a4 4 0 004-4V6.5zM23.833 6.167a4 4 0 00-4-4h-7.166a4 4 0 00-4 4V6.5h15.166v-.333z"
                stroke={props.black ? 'black' : '#FCFCFC'}
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
            <Path
                d="M2.167 13v4.333H6.5c1.197 0 2.167-.97 2.167-2.166v0C8.667 13.97 7.697 13 6.5 13H2.167z"
                stroke={props.black ? 'black' : '#FCFCFC'}
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default WalletIcon;