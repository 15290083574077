import { View, Text } from 'react-native'
import React from 'react'
import { Center, Spinner } from '@gluestack-ui/themed'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import colorSchema from '../../config/colorSchema'
const LoadingSpinner = () => {
    return (
        <View style={{ flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
            <Center>
                <Spinner size={'large'} color={colorSchema.mainColor} accessibilityLabel="" />
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 21, color: colorSchema.mainColor }}>{MainStore.auth ? `${MainStore.langPref == 'tr' ? 'Yükleniyor...' : 'Loading...'}` : 'Yükleniyor...'}</Text>
            </Center>
        </View>
    )
}

export default observer(LoadingSpinner)