import { View, Text, Dimensions, StatusBar, Platform } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
const { width, height } = Dimensions.get('window')

const LogoHeader = ({ water }) => {
    return (
        <View style={{ marginLeft: 17}}>
            <Text style={{ fontFamily: 'Gilroy-Heavy', fontSize: 24, lineHeight: 31, color: water ? colorSchema.waterColor : colorSchema.mainColor }}>{water ? 'Yemek.Club Su' : 'Yemek.Club'}</Text>
        </View>
    )
}

export default LogoHeader