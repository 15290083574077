import { View, Text, SafeAreaView, Dimensions, Image, ScrollView } from 'react-native'
import React, { useState, useEffect } from 'react'
import GoBackTitle from '../../components/header/GoBackTitle'
import CartIcon from '../../assets/svg/CartIcon'
import WalletIcon from '../../assets/svg/WalletIcon'
import SuccessIcon from '../../assets/svg/SuccessIcon'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import RedButton from '../../components/button/RedButton'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import WebHeader from '../../components/header/WebHeader'
import LastOrder from '../lastorder/LastOrder'

const { width, height } = Dimensions.get('window')

const SuccessScreen = ({ navigation }) => {
    useEffect(() => {
        MainStore.emptyCart()
    }, [])
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView style={{ maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0 }}>
                {width > 1279 ?
                    <WebHeader navigation={navigation} />

                    :
                    <></>
                }

                <View style={{ marginTop: 47, flexDirection: 'row', justifyContent: width > 1279 ? 'center' : 'space-evenly', alignItems: 'center' }}>
                    <View>
                        <CartIcon black />
                    </View>
                    <View style={{ marginHorizontal: 30 }}>
                        <Feather name='chevron-right' size={13} color={'black'} />
                    </View>
                    <View  >
                        <WalletIcon black={true} />
                    </View>
                    <View style={{ marginHorizontal: 30 }}>
                        <Feather name='chevron-right' size={13} color={'black'} />
                    </View>
                    <View style={{ marginRight: 12, backgroundColor: 'black', padding: 6, borderRadius: 100 }}>
                        <SuccessIcon size={13} black={false} />
                    </View>
                </View>

                <View style={{ marginTop: 47, width: width > 1279 ? 1250 : width - 100, marginLeft: 50 }}>
                    <Text style={{ alignSelf: 'center', textAlign: 'center', fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.textColor }}>Siparişin için teşekkürler! Şimdiden afiyet olsun 😍</Text>
                </View>

                <View style={{ marginTop: 47, alignItems: 'center' }}>
                    <Image source={require('../../assets/image/Rectangle.gif')} style={{ width: 200, height: 200 }} />
                </View>


            </ScrollView>
            <View style={{ position: width > 1279 ? 'relative' : 'absolute', marginTop: 100, bottom: 20, alignSelf: 'center' }}>
                <RedButton clickEvent={() => {
                    width > 1279 ? navigation.push('LastOrder') : navigation.navigate('LastOrder')
                }} text='Siparişimi Görüntüle' />
            </View>
        </SafeAreaView>
    )
}

export default observer(SuccessScreen)