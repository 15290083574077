import { View, Text, ScrollView, Dimensions } from 'react-native'
import React from 'react'
import GoBackTitle from '../../components/header/GoBackTitle'
import colorSchema from '../../config/colorSchema'
import { Feather } from '@expo/vector-icons'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'
import WebHeader from '../../components/header/WebHeader'
import { TouchableOpacity } from 'react-native'

const { width, height } = Dimensions.get('window')

const Help = ({ navigation }) => {
    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView showsVerticalScrollIndicator={false} style={{ marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0 }}>

                <View>
                    {
                        width > 1279 ?
                            <WebHeader navigation={navigation} />
                            :
                            <></>
                    }
                    <View>
                        <GoBackTitle title='Yardım' navigation={navigation} />
                    </View>

                    <View>
                        <TouchableOpacity onPress={() => navigation.navigate('AboutUs')} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, alignItems: 'center', marginTop: 17, maxWidth: width > 1279 ? 500 : width }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Hakkımızda' : 'About Us'}</Text>
                            </View>
                            <View>
                                <RightArrowIcon />
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate(('KVKK'))} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, alignItems: 'center', marginTop: 17, maxWidth: width > 1279 ? 500 : width }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Müşteri Kişisel Verilerin Korunması Politikası' : 'Data Protection and Privacy Legislation '}</Text>
                            </View>
                            <View>
                                <RightArrowIcon />
                            </View>
                        </TouchableOpacity>



                        <TouchableOpacity onPress={() => navigation.navigate('FAQ')} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, alignItems: 'center', marginTop: 17, maxWidth: width > 1279 ? 500 : width }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'SSS' : 'FAQ'}</Text>
                            </View>
                            <View>
                                <RightArrowIcon />
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate('Aydinlatma')} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, alignItems: 'center', marginTop: 17, maxWidth: width > 1279 ? 500 : width }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Müşteri Kişisel Verileri Aydınlatma Metni' : 'Processing and Protection of Personal Data Clarification Text'}</Text>
                            </View>
                            <View>
                                <RightArrowIcon />
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate('Privacy')} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, alignItems: 'center', marginTop: 17, maxWidth: width > 1279 ? 500 : width }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Gizlilik Politikası' : 'Privacy Policy'}</Text>
                            </View>
                            <View>
                                <RightArrowIcon />
                            </View>
                        </TouchableOpacity>


                    </View>

                </View>
            </ScrollView>
        </View>
    )
}

export default observer(Help)