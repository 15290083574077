import * as React from "react";
import Svg, { Path } from "react-native-svg";

function StarIconEmpty(props) {
    return (
        <Svg
            width={11}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M4.254 1.636c.388-.848 1.511-.848 1.9 0L6.826 3.1c.154.336.452.57.797.623l1.502.235c.87.136 1.216 1.286.588 1.946l-1.087 1.14c-.25.262-.364.64-.305 1.01l.257 1.61c.148.93-.76 1.641-1.538 1.202l-1.343-.76a.993.993 0 00-.986 0l-1.344.76c-.777.44-1.685-.271-1.537-1.203l.257-1.61a1.196 1.196 0 00-.305-1.01L.695 5.905c-.629-.66-.282-1.809.587-1.945l1.502-.235c.345-.054.644-.287.798-.623l.672-1.465z"
                stroke="#1E1E1E"
                strokeWidth={0.5}
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default StarIconEmpty;