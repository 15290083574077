import {
  View,
  Text,
  SafeAreaView,
  Keyboard,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ScrollView,
  Dimensions,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import {BottomSheet} from 'react-native-btr';
import countryCode from '../../config/countryCode';
import LogoHeader from '../../components/header/LogoHeader';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
import {Formik} from 'formik';
import * as Yup from 'yup';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import SuccessToastMessage from '../../components/feedback/SuccessToastMessage';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import {Picker} from '@react-native-picker/picker';
import WebHeader from '../../components/header/WebHeader';
import {useToast} from '@gluestack-ui/themed';

const {width, height} = Dimensions.get('window');
const Register = ({navigation}) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('90');
  const [contract, setContract] = useState(false);
  const [advPermission, setAdvPermission] = useState(false);
  const toggleBottomNavigationView = () => {
    setVisible(!visible);
  };
  const changePhoneCode = (item) => {
    setSelected(item.code);
  };

  const registerButton = () => {
    if (name.length > 3 && phoneNumber.length > 5 && email.length > 3) {
    } else {
    }
  };

  const toast = useToast();

  return (
    <SafeAreaView style={style.main}>
      <View
        onStartShouldSetResponder={() => Keyboard.dismiss()}
        style={style.main}
      >
        {width > 1279 ? (
          <View style={{marginLeft: 80, marginRight: 80}}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <View>
            <LogoHeader />
          </View>
        )}
        <View style={style.infoTextArea}>
          <Text style={style.infoText}>Üyelik</Text>
        </View>

        <Formik
          initialValues={{phoneNumber: '', email: '', name: '', surName: ''}}
          onSubmit={(value) => {
            if (selected == '90' && value.phoneNumber.length != 10) {
              ErrorToastMessage(toast, 'Telefon Numarası Alanı Minimum 10 Karakter Olmalıdır.');
            } else {
              axios
                .post(`${axiosConfig.serverIp}/register`, {
                  name: value.name,
                  surName: value.surName,
                  email: value.email,
                  phoneNumber: `${selected}${value.phoneNumber}`,
                  advPermission,
                })
                .then((res) => {
                  if (res.data.success) {
                    SuccessToastMessage(toast, res.data.success);
                    navigation.navigate('Verification', {
                      phoneNumber: value.phoneNumber,
                      countryCode: selected,
                    });
                  } else if (res.data.error) {
                    ErrorToastMessage(toast, res.data.error);
                  } else {
                    ErrorToastMessage(toast, 'Bir sorunla karşılaştık lütfen daha sonra tekrar deneyiniz');
                  }
                });
            }
          }}
          validationSchema={Yup.object().shape({
            phoneNumber: Yup.number().required('Telefon Numarası Alanı Zorunlu'),
            email: Yup.string().email('Lütfen Geçerli Bir Mail Formatı Giriniz').required('Mail Alanı Zorunlu'),
            name: Yup.string()
              .matches(/^[a-zA-ZğüşıöçĞÜŞİÖÇ]+$/, 'İsim Alanı Sayı İçeremez')
              .required('Ad Alanı Zorunlu'),
            surName: Yup.string()
              .matches(/^[a-zA-ZğüşıöçĞÜŞİÖÇ]+$/, 'Soyadı Alanı Sayı İçeremez')
              .required('Soyadı Alanı Zorunlu'),
          })}
        >
          {({values, handleSubmit, handleChange, errors}) => (
            <View style={style.board}>
              <View style={{marginLeft: width > 1279 ? 80 : 0}}>
                <View style={{flexDirection: 'row'}}>
                  <View style={style.mainCodeSelectArea}>
                    <TouchableOpacity onPress={toggleBottomNavigationView}>
                      <View style={style.secCodeSelectArea}>
                        <View>
                          <Text style={style.codeSelectText}>+{selected}</Text>
                        </View>
                        <View>
                          <Feather
                            name='chevron-down'
                            size={20}
                            color='#28303F'
                          />
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View style={style.phoneInputBoard}>
                    <TextInput
                      value={values.phoneNumber}
                      onChangeText={handleChange('phoneNumber')}
                      keyboardType='number-pad'
                      style={[
                        style.phoneInput,
                        {
                          borderColor: errors.phoneNumber ? colorSchema.mainColor : 'rgba(30,30,30,0.1)',
                          borderWidth: errors.phoneNumber ? 1 : 0.5,
                        },
                      ]}
                      placeholder='Cep Telefonu'
                      placeholderTextColor='rgba(30,30,30,0.6)'
                    />
                  </View>
                </View>

                <View>
                  <TextInput
                    value={values.name}
                    onChangeText={handleChange('name')}
                    style={[
                      style.input,
                      {
                        borderColor: errors.name ? colorSchema.mainColor : 'rgba(30,30,30,0.1)',
                        borderWidth: errors.name ? 1 : 0.5,
                      },
                    ]}
                    placeholder='Ad'
                    placeholderTextColor='rgba(30,30,30,0.6)'
                  />
                </View>
                <View>
                  <TextInput
                    value={values.surName}
                    onChangeText={handleChange('surName')}
                    style={[
                      style.input,
                      {
                        borderColor: errors.surName ? colorSchema.mainColor : 'rgba(30,30,30,0.1)',
                        borderWidth: errors.surName ? 1 : 0.5,
                      },
                    ]}
                    placeholder='Soyad'
                    placeholderTextColor='rgba(30,30,30,0.6)'
                  />
                </View>
                <View>
                  <TextInput
                    autoCapitalize='none'
                    value={values.email}
                    keyboardType='email-address'
                    onChangeText={handleChange('email')}
                    style={[
                      style.input,
                      {
                        borderColor: errors.email ? colorSchema.mainColor : 'rgba(30,30,30,0.1)',
                        borderWidth: errors.email ? 1 : 0.5,
                      },
                    ]}
                    placeholder='Email'
                    placeholderTextColor='rgba(30,30,30,0.6)'
                  />
                </View>
              </View>
              <View style={style.radioArea}>
                <View style={{marginRight: 10}}>
                  <TouchableOpacity
                    onPress={() => setContract(!contract)}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: contract ? colorSchema.mainColor : 'rgba(211,214,219,0.4)',
                      borderWidth: 1,
                      borderColor: 'rgba(211,214,219,1)',
                    }}
                  />
                </View>
                <View>
                  <TouchableOpacity onPress={() => navigation.navigate('KVKK')}>
                    <Text style={style.radioText}>
                      Kullanıcı Sözleşmesi , KVK Politikası ve Aydınlatma Metni
                      <Text style={{color: 'rgba(30,30,30,0.8)'}}>'ni okudum, kabul ediyorum</Text>
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={style.radioArea}>
                <View style={{marginRight: 10}}>
                  <TouchableOpacity
                    onPress={() => setAdvPermission(!advPermission)}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: advPermission ? colorSchema.mainColor : 'rgba(211,214,219,0.4)',
                      borderWidth: 1,
                      borderColor: 'rgba(211,214,219,1)',
                    }}
                  />
                </View>
                <View>
                  <Text style={[style.radioText, {color: 'rgba(30,30,30,0.8)'}]}>
                    Yemek.Club'ın bana özel kampanyalardan, fırsatlardan ve duyurulardan SMS ve e-posta ile haberdar
                    olmak istiyorum.
                  </Text>
                </View>
              </View>

              <View style={style.buttonArea}>
                <TouchableOpacity
                  onPress={() => {
                    if (errors.name && errors.email && errors.phoneNumber && errors.surName) {
                    } else {
                      if (contract) {
                        handleSubmit();
                      } else {
                        ErrorToastMessage(toast, 'Devam edebilmek için sözleşmeyi okuyup kabul etmeniz gerekiyor.');
                      }
                    }
                  }}
                  style={style.registerButton}
                >
                  <Text style={style.registerText}>Üye Ol</Text>
                </TouchableOpacity>
                <View style={{alignSelf: 'center', marginTop: 10}}>
                  <Text style={style.loginText}>
                    Hesabınız var mı?{' '}
                    <Text
                      onPress={() => navigation.navigate('SignIn')}
                      style={{color: colorSchema.mainColor}}
                    >
                      Giriş Yap
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          )}
        </Formik>

        <BottomSheet
          visible={visible}
          onBackButtonPress={toggleBottomNavigationView}
          onBackdropPress={toggleBottomNavigationView}
        >
          <View style={style.bottomNavigationView}>
            <Picker
              selectedValue={selected}
              onValueChange={(itemValue) => setSelected(itemValue)}
            >
              {countryCode.map((item, index) => (
                <Picker.Item
                  key={index}
                  label={`${item.country} (${item.code})`}
                  value={item.code}
                />
              ))}
            </Picker>
            <ScrollView></ScrollView>
          </View>
        </BottomSheet>
      </View>
    </SafeAreaView>
  );
};

const style = StyleSheet.create({
  bottomNavigationView: {
    backgroundColor: '#fff',
    width: '100%',
    height: 300,
  },
  main: {backgroundColor: 'white', flex: 1},
  board: {alignItems: width >= 1279 ? 'flex-start' : 'center', flex: 1, marginTop: 47},
  codeBoard: {marginBottom: 8, marginLeft: 10},
  codeText: {fontFamily: 'Gilroy-Bold', fontSize: 18, color: 'gray'},
  infoText: {fontFamily: 'Gilroy-Bold', fontSize: 24, color: colorSchema.mainColor},
  infoTextArea: {marginTop: 60, marginLeft: width > 1279 ? 80 : 17},
  mainCodeSelectArea: {
    borderRadius: 10,
    width: 70,
    height: 43,
    backgroundColor: 'rgba(211,214,219,0.4)',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(30,30,30,0.1)',
  },
  secCodeSelectArea: {flexDirection: 'row', alignItems: 'center'},
  codeSelectText: {fontFamily: 'Gilroy-Medium', fontSize: 15},
  phoneInputBoard: {marginLeft: 5},
  phoneInput: {
    borderRadius: 10,
    width: width > 500 ? 330 : width - 175,
    height: 43,
    backgroundColor: 'rgba(211,214,219,0.4)',
    paddingLeft: 10,
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
    marginBottom: 20,
    borderWidth: 0.5,
    borderColor: 'rgba(30,30,30,0.1)',
  },
  input: {
    borderRadius: 10,
    width: width > 500 ? 400 : width - 100,
    height: 43,
    backgroundColor: 'rgba(211,214,219,0.4)',
    paddingLeft: 10,
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
    marginBottom: 20,
    borderWidth: 0.5,
    borderColor: 'rgba(30,30,30,0.1)',
  },
  radioArea: {
    width: width - 100,
    marginBottom: 20,
    marginHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: width > 1279 ? 80 : 0,
  },
  radioText: {width: width - 130, fontFamily: 'Gilroy-Medium', fontSize: 12, color: colorSchema.mainColor},
  buttonArea: {flex: 1, position: width > 1279 ? 'relative' : 'absolute', bottom: 40, margin: width > 1279 ? 80 : 0},
  registerButton: {
    borderRadius: 20,
    backgroundColor: colorSchema.mainColor,
    width: width > 400 ? 400 : width - 28,
    height: 43,
    alignItems: 'center',
    justifyContent: 'center',
  },
  registerText: {color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15},
  loginText: {color: 'rgba(30,30,30,0.8)', fontFamily: 'Gilroy-Regular', fontSize: 15},
});

export default Register;
