import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SuccessIcon(props) {
    return (
        <Svg
            width={18}
            height={13}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M1.417 8.083l3.82 3.057c.896.717 2.196.61 2.964-.243l8.382-9.314"
                stroke={props.black ? 'black' : '#FCFCFC'}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default SuccessIcon;