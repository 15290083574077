import { View, Text, SafeAreaView, TouchableOpacity, StyleSheet, Image, Dimensions, Platform, StatusBar, ImageBackground, ScrollView, TextInput } from 'react-native'
import React, { useEffect, useState } from 'react'
import colorSchema from '../../config/colorSchema'
import LogoHeader from '../../components/header/LogoHeader'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import { Formik } from 'formik'
import axiosConfig from '../../config/axiosConfig';
import axios from 'axios';
import * as Yup from 'yup'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import { Feather } from '@expo/vector-icons';
import { BottomSheet } from 'react-native-btr';
import { Picker } from '@react-native-picker/picker';
import countryCode from '../../config/countryCode'
import WebFooter from '../../components/footer/WebFooter'
import WhiteHeader from '../../components/header/WhiteHeader'
import { useToast } from '@gluestack-ui/themed'
import SuccessToastMessage from '../../components/feedback/SuccessToastMessage'


const { width, height } = Dimensions.get('window')

const Splash = ({ navigation }) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState('90')
  const [contract, setContract] = useState(false);
  const [promotion, setPromotion] = useState(false);

  const toggleBottomNavigationView = () => {
    setVisible(!visible);
  }
  const toast = useToast()
  return (
    Platform.OS == 'web'
      ?
      <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
        <View>
          {
            width > 600 ?
              <ImageBackground
                source={require('../../assets/image/5.webp')}
                style={{ width: width, height: height }}
                resizeMode='stretch'
              >
                <View>
                  {width > 1279 ?
                    <View style={{ marginLeft: 80, marginRight: 80 }}>
                      <WhiteHeader navigation={navigation} />
                    </View>
                    :
                    <View>
                      <LogoHeader />
                    </View>}

                  <View>

                    <View style={{ width: 474, top: 109, left: 80 }}>
                      <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 28, color: 'white' }}>Siparişini verdikten sonra şöyle bi kenara yaslan ve yemeğin en hızlı, sıcacık haliyle kapına gelsin, sana da keyfini çıkarmak kalsın.</Text>
                    </View>
                  </View>



                </View>

                <View style={{ position: 'absolute', bottom: 34, left: 24 }}>
                  <TouchableOpacity onPress={() => MainStore.changeGuestLogin()} style={{ backgroundColor: '#FCFCFC', borderRadius: 20, paddingVertical: 16, paddingHorizontal: 46 }}>
                    <Text style={{ fontFamily: 'Gilroy-SemiBold', fontSize: 22 }}>Üye Olmadan Devam Et</Text>
                  </TouchableOpacity>
                </View>
              </ImageBackground>
              :
              <View style={{ backgroundColor: 'white', width: 572, height: 570, borderRadius: 20, top: 49, alignSelf: 'center' }}>
                <View style={{ alignSelf: 'center' }}>
                  <Text style={style.infoText}>Üyelik</Text>
                </View>

                <Formik
                  initialValues={{ phoneNumber: '', email: '', name: '', surName: '' }}
                  onSubmit={value => {
                    if (selected == '90' && value.phoneNumber.length != 10) {
                      ErrorToastMessage(toast, 'Telefon Numarası Alanı Minimum 10 Karakter Olmalıdır.')
                    }
                    else {
                      axios.post(`${axiosConfig.serverIp}/register`, {
                        name: value.name,
                        surName: value.surName,
                        email: value.email,
                        phoneNumber: `${selected}${value.phoneNumber}`
                      }).then(res => {
                        if (res.data.success) {
                          SuccessToastMessage(toast, res.data.success)
                          navigation.navigate('Verification', {
                            phoneNumber: value.phoneNumber,
                            countryCode: selected
                          })
                        }
                        else if (res.data.error) {
                          ErrorToastMessage(toast, 'white', res.data.error)

                        }
                        else {
                          ErrorToastMessage(toast, 'Bir sorunla karşılaştık lütfen daha sonra tekrar deneyiniz')

                        }
                      })
                    }
                  }}

                  validationSchema={Yup.object().shape({
                    phoneNumber: Yup.number().required('Telefon Numarası Alanı Zorunlu'),
                    email: Yup.string().email('Lütfen Geçerli Bir Mail Formatı Giriniz').required('Mail Alanı Zorunlu'),
                    name: Yup.string().required('Ad Alanı Zorunlu'),
                    surName: Yup.string().required('Soyad Alanı Zorunlu')
                  })}
                >
                  {({ values, handleSubmit, handleChange, errors }) => (
                    <View style={style.boardForm}>
                      <View style={{ marginLeft: width > 1279 ? 80 : 0 }}>
                        <View style={{ flexDirection: 'row' }}>
                          <View style={style.mainCodeSelectArea}>
                            <TouchableOpacity onPress={toggleBottomNavigationView}>
                              <View style={style.secCodeSelectArea}>
                                <View>
                                  <Text style={style.codeSelectText}>+{selected}</Text>
                                </View>
                                <View>
                                  <Feather name='chevron-down' size={20} color='#28303F' />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                          <View style={style.phoneInputBoard}>
                            <TextInput value={values.phoneNumber} onChangeText={handleChange('phoneNumber')} keyboardType='number-pad' style={[style.phoneInput, { borderColor: errors.phoneNumber ? colorSchema.mainColor : 'rgba(30,30,30,0.1)', borderWidth: errors.phoneNumber ? 1 : 0.5 }]} placeholder='Cep Telefonu' placeholderTextColor='rgba(30,30,30,0.6)' />
                          </View>
                        </View>

                        <View>
                          <TextInput value={values.name} onChangeText={handleChange('name')} style={[style.input, { borderColor: errors.name ? colorSchema.mainColor : 'rgba(30,30,30,0.1)', borderWidth: errors.name ? 1 : 0.5 }]} placeholder='Ad' placeholderTextColor='rgba(30,30,30,0.6)' />
                        </View>
                        <View>
                          <TextInput value={values.surName} onChangeText={handleChange('surName')} style={[style.input, { borderColor: errors.surName ? colorSchema.mainColor : 'rgba(30,30,30,0.1)', borderWidth: errors.surName ? 1 : 0.5 }]} placeholder='Soyad' placeholderTextColor='rgba(30,30,30,0.6)' />
                        </View>
                        <View>
                          <TextInput autoCapitalize='none' value={values.email} keyboardType='email-address' onChangeText={handleChange('email')} style={[style.input, { borderColor: errors.email ? colorSchema.mainColor : 'rgba(30,30,30,0.1)', borderWidth: errors.email ? 1 : 0.5 }]} placeholder='Email' placeholderTextColor='rgba(30,30,30,0.6)' />
                        </View>
                      </View>
                      <View style={style.radioArea}>
                        <View style={{ marginRight: 10 }}>
                          <TouchableOpacity onPress={() => setContract(!contract)} style={{ width: 20, height: 20, borderRadius: 50, backgroundColor: contract ? colorSchema.mainColor : 'rgba(211,214,219,0.4)', borderWidth: 1, borderColor: 'rgba(211,214,219,1)' }} />
                        </View>
                        <View>
                          <TouchableOpacity><Text style={style.radioText}>Kullanıcı Sözleşmesi , KVK Politikası ve Aydınlatma Metni<Text style={{ color: 'rgba(30,30,30,0.8)' }}>'ni okudum, kabul ediyorum</Text></Text></TouchableOpacity>
                        </View>
                      </View>
                      <View style={style.radioArea}>
                        <View style={{ marginRight: 10 }}>
                          <TouchableOpacity onPress={() => setPromotion(!promotion)} style={{ width: 20, height: 20, borderRadius: 50, backgroundColor: promotion ? colorSchema.mainColor : 'rgba(211,214,219,0.4)', borderWidth: 1, borderColor: 'rgba(211,214,219,1)' }} />
                        </View>
                        <View>
                          <Text style={[style.radioText, { color: 'rgba(30,30,30,0.8)' }]}>Yemek.Club'ın bana özel kampanyalardan, fırsatlardan ve duyurulardan SMS ve e-posta ile haberdar olmak istiyorum.</Text>
                        </View>
                      </View>


                      <View style={style.buttonArea}>
                        <TouchableOpacity onPress={() => {
                          if (errors.name && errors.email && errors.phoneNumber && errors.surName) {

                          }
                          else {
                            if (contract) {
                              handleSubmit()
                            }
                            else {
                              ErrorToastMessage(toast, 'Devam edebilmek için sözleşmeyi okuyup kabul etmeniz gerekiyor.')

                            }
                          }
                        }} style={style.registerButton}>
                          <Text style={style.registerText}>Üye Ol</Text>
                        </TouchableOpacity>
                        <View style={{ alignSelf: 'center', marginTop: 10 }}>
                          <Text style={style.loginText}>Hesabınız var mı? <Text onPress={() => navigation.navigate('SignIn')} style={{ color: colorSchema.mainColor }}>Giriş Yap</Text></Text>
                        </View>
                      </View>



                    </View>
                  )}
                </Formik>





                <BottomSheet visible={visible} onBackButtonPress={toggleBottomNavigationView} onBackdropPress={toggleBottomNavigationView}>
                  <View style={style.bottomNavigationView}>
                    <Picker
                      selectedValue={selected}
                      onValueChange={(itemValue) => setSelected(itemValue)}
                    >
                      {countryCode.map((item, index) =>
                        <Picker.Item key={index} label={`${item.country} (${item.code})`} value={item.code} />

                      )}
                    </Picker>
                    <ScrollView>

                    </ScrollView>
                  </View>
                </BottomSheet>
              </View>
          }

        </View>

        <View style={{ paddingVertical: 170, backgroundColor: 'white', flexDirection: width > 600 ? 'row' : 'column', justifyContent: 'space-around', alignItems: 'center', marginTop: 50 }}>
          <View style={{ alignItems: 'center' }}>
            <View>
              <Image source={require('../../assets/image/1.webp')} style={{ width: 245, height: 200 }} />
            </View>
            <View style={{ marginVertical: 34 }}>
              <Text style={{ textAlign: 'center', fontFamily: 'Gilroy-Bold', fontSize: 26 }}>İş ortağımız ol</Text>
            </View>
            <View>
              <Text style={{ width: 400, textAlign: 'center', fontFamily: 'Gilroy-Medium', fontSize: 24, color: '#1E1E1E' }}>Müşterilerinize en kısa sürede ulaşarak onlara en iyi hizmeti verebilirsiniz.</Text>
            </View>
          </View>

          <View style={{ alignItems: 'center', marginVertical: 34 }}>
            <View>
              <Image source={require('../../assets/image/4.webp')} style={{ width: 245, height: 200 }} />
            </View>
            <View style={{ marginVertical: 34 }}>
              <Text style={{ textAlign: 'center', fontFamily: 'Gilroy-Bold', fontSize: 26 }}>Uygulamayı dene</Text>
            </View>
            <View>
              <Text style={{ width: 400, textAlign: 'center', fontFamily: 'Gilroy-Medium', fontSize: 24, color: '#1E1E1E' }}>Kullanıcı dostu uygulamamız, hem işletmeler için hem de kullanıcılar için eşsiz bir deneyim sunuyor. </Text>
            </View>
          </View>

          <View style={{ alignItems: 'center' }}>
            <View>
              <Image source={require('../../assets/image/3.webp')} style={{ width: 245, height: 200 }} />
            </View>
            <View style={{ marginVertical: 34 }}>
              <Text style={{ textAlign: 'center', fontFamily: 'Gilroy-Bold', fontSize: 26 }}>Sipariş ver</Text>
            </View>
            <View>
              <Text style={{ width: 400, textAlign: 'center', fontFamily: 'Gilroy-Medium', fontSize: 24, color: '#1E1E1E' }}>Favori restoranından en sevdiğin yemeği sipariş et ve uygun fiyatlı yemeğin tadını çıkar.</Text>
            </View>
          </View>
        </View>

        <WebFooter navigation={navigation} />
      </ScrollView>
      :

      <SafeAreaView style={style.main}>
        <View>
          <LogoHeader />
        </View>
        <View style={style.board}>
          <View>
            <Image source={require('../../assets/image/DeliveryBoy.gif')} style={style.gif} />
          </View>
          <View style={style.boardArea}>
            <TouchableOpacity onPress={() => navigation.navigate('Register')} style={style.button}>
              <Text style={style.buttonText}>Üye Ol</Text>
            </TouchableOpacity>
          </View>
          <View style={style.boardArea}>
            <TouchableOpacity onPress={() => navigation.navigate('SignIn')} style={style.button}>
              <Text style={style.buttonText}>Giriş Yap</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={() => MainStore.changeGuestLogin()} style={style.boardArea}>
            <Text style={style.authText}>Üye olmadan devam et</Text>
          </TouchableOpacity>
        </View>

      </SafeAreaView>
  )
}

const style = StyleSheet.create({
  main: { backgroundColor: 'white', flex: 1, paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0 },
  board: { justifyContent: 'center', alignItems: 'center', flex: 1 },
  gif: { width: 300, height: 300 },
  button: { width: width - 30, height: 45, justifyContent: 'center', alignItems: 'center', borderWidth: 0.5, borderRadius: 20 },
  buttonText: { fontFamily: 'Gilroy-Bold', fontSize: 17 },
  boardArea: { marginBottom: 17 },
  authText: { fontFamily: 'Gilroy-Medium', fontSize: 15, color: 'rgba(30,30,30,0.7)' },
  bottomNavigationView: {
    backgroundColor: '#fff',
    width: '100%',
    height: 300,
  },
  boardForm: { alignItems: width >= 1279 ? 'flex-start' : 'center', flex: 1, marginTop: 47 },
  codeBoard: { marginBottom: 8, marginLeft: 10 },
  codeText: { fontFamily: 'Gilroy-Bold', fontSize: 18, color: 'gray' },
  infoText: { fontFamily: 'Gilroy-Bold', fontSize: 24, color: colorSchema.mainColor },
  infoTextArea: { marginTop: 17, marginLeft: width > 1279 ? 80 : 17 },
  mainCodeSelectArea: { borderRadius: 10, width: 70, height: 43, backgroundColor: 'rgba(211,214,219,0.4)', alignItems: 'center', justifyContent: 'center', borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
  secCodeSelectArea: { flexDirection: 'row', alignItems: 'center' },
  codeSelectText: { fontFamily: 'Gilroy-Medium', fontSize: 15 },
  phoneInputBoard: { marginLeft: 5 },
  phoneInput: { borderRadius: 10, width: width > 500 ? 330 : width - 175, height: 43, backgroundColor: 'rgba(211,214,219,0.4)', paddingLeft: 10, fontFamily: 'Gilroy-Medium', fontSize: 15, marginBottom: 20, borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
  input: { borderRadius: 10, width: width > 500 ? 400 : width - 100, height: 43, backgroundColor: 'rgba(211,214,219,0.4)', paddingLeft: 10, fontFamily: 'Gilroy-Medium', fontSize: 15, marginBottom: 20, borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
  radioArea: { marginBottom: 20, marginHorizontal: 10, flexDirection: 'row', alignItems: 'center', marginLeft: width > 1279 ? 80 : 0 },
  radioText: { width: 350, fontFamily: 'Gilroy-Medium', fontSize: 12, color: colorSchema.mainColor },
  buttonArea: { flex: 1, position: width > 1279 ? 'relative' : 'relative', bottom: 40, margin: width > 1279 ? 80 : 0, marginTop: 40 },
  registerButton: { borderRadius: 20, backgroundColor: colorSchema.mainColor, width: width > 400 ? 400 : width - 28, height: 43, alignItems: 'center', justifyContent: 'center' },
  registerText: { color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 },
  loginText: { color: 'rgba(30,30,30,0.8)', fontFamily: 'Gilroy-Regular', fontSize: 15 }
})

export default observer(Splash)