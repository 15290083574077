import * as React from "react"
import Svg, { Path } from "react-native-svg"

const RightArrowIcon = (props) => (
    <Svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm1.53-13.53 3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H6a.75.75 0 0 1 0-1.5h6.19l-1.72-1.72a.75.75 0 0 1 1.06-1.06Z"
            fill="#1E1E1E"
        />
    </Svg>
)

export default RightArrowIcon
