import { View, Text, SafeAreaView, Dimensions, Image, StyleSheet, TextInput, TouchableOpacity } from 'react-native'
import React from 'react'
import LogoHeader from '../../components/header/LogoHeader'
import colorSchema from '../../config/colorSchema'

const { width, height } = Dimensions.get('window')

const NotificationScreen = () => {
  return (
    <SafeAreaView style={style.main}>
      <View>
        <LogoHeader />
      </View>
      <View style={style.board}>
        <View style={{ marginBottom: 73 }}>
          <Image source={require('../../assets/image/bell.gif')} style={{ width: 150, height: 150 }} />
        </View>
        <View style={{ width: width - 70, alignItems: 'center' }}>
          <View style={{ marginBottom: 30 }}>
            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, textAlign: 'center' }}>Yemek.Club siparişlerinizle ilgili bildirimleri kaçırmayın!</Text>
          </View>
          <View>
            <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, textAlign: 'center' }}>Bildirimlere izin vererek siparişinizi takip edebilir ve size özel kampanyalardan haberdar olabilirsiniz.</Text>
          </View>
        </View>
        <View style={style.buttonArea}>
          <TouchableOpacity style={style.registerButton}>
            <Text style={style.registerText}>Bildirim Tercihini Belirle</Text>
          </TouchableOpacity>
          
        </View>
      </View>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  main: { backgroundColor: 'white', flex: 1 },
  board: { alignItems: 'center', flex: 1, marginTop: 47 },
  buttonArea: { flex: 1, position: 'absolute', bottom: 80 },
  registerButton: { borderRadius: 20, backgroundColor: colorSchema.mainColor, width: width - 28, height: 43, alignItems: 'center', justifyContent: 'center' },
  registerText: { color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 },

})

export default NotificationScreen