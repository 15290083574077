import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import colorSchema from '../../config/colorSchema'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'

const FilterBoxWater = (props) => {

    return (
        <View>
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                <TouchableOpacity onPress={() => {
                    props.setSelectedFilter('category')
                    props.setShowFilter(true)

                }} style={{ backgroundColor: props.category ? colorSchema.textColor : 'rgba(211,214,219,0.3)', borderRadius: 20, paddingHorizontal: 24, paddingVertical: 5, marginLeft: 5 }}>
                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: props.category ? 'white' : colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Markalar' : 'Cuisines'}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    props.setSelectedFilter('sort')
                    props.setShowFilter(true)

                }} style={{ backgroundColor: props.sort ? colorSchema.textColor : 'rgba(211,214,219,0.3)', borderRadius: 20, paddingHorizontal: 24, paddingVertical: 5, marginLeft: 5 }}>
                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: props.sort ? 'white' : colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Sıralama' : 'Sort'}</Text>
                </TouchableOpacity>
               

            </ScrollView>
        </View>
    )
}

export default observer(FilterBoxWater)