import * as React from "react"
import Svg, { Path } from "react-native-svg"

const RIcon = (props) => (
    <Svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M13.632 12.158a1.474 1.474 0 0 1-1.474 1.474H1.842A1.474 1.474 0 0 1 .37 12.158V1.842A1.474 1.474 0 0 1 1.842.368h10.316a1.474 1.474 0 0 1 1.474 1.474v10.316Z"
            fill="#FA0C08"
        />
        <Path
            d="M6.053 6.517h1.176c.628 0 1.063-.376 1.063-1.016 0-.651-.435-1.005-1.063-1.005H6.053v2.021ZM4.339 3.81c0-.548.309-.89.88-.89h2.01c1.76 0 2.845.787 2.845 2.581 0 1.256-.948 1.976-2.114 2.159l1.943 2.079c.159.171.228.342.228.502 0 .446-.354.88-.857.88a.882.882 0 0 1-.662-.297L6.076 7.75h-.023v2.479c0 .571-.366.89-.857.89s-.857-.319-.857-.89V3.81Z"
            fill="#fff"
        />
    </Svg>
)

export default RIcon
