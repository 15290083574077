import * as React from "react"
import Svg, { Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const LastOrderIcon = (props) => (
  props.selected ?

    <Svg
      width={26}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 3.847a1.465 1.465 0 0 1-.104-.066C9.491 1.92 5.766 1.077 2.993.764 1.62.61.5 1.755.5 3.17v14.08c0 1.415 1.12 2.561 2.493 2.716 2.773.314 6.498 1.157 9.153 3.017.034.024.069.046.104.066v-19.2Zm1.5 19.201c.035-.02.07-.042.104-.066 2.655-1.86 6.38-2.703 9.153-3.017 1.372-.155 2.493-1.301 2.493-2.715V3.169c0-1.414-1.12-2.56-2.493-2.405-2.773.313-6.498 1.156-9.153 3.017a1.465 1.465 0 0 1-.104.066v19.201ZM3.509 7.204a.75.75 0 0 1 .856-.627c1.619.25 3.44.67 5.156 1.333a.75.75 0 0 1-.542 1.4C7.39 8.694 5.68 8.298 4.135 8.06a.75.75 0 0 1-.626-.856Zm.856 4.373a.75.75 0 0 0-.23 1.482c.78.121 1.602.282 2.432.491a.75.75 0 0 0 .366-1.454c-.88-.222-1.749-.392-2.568-.519Z"
        fill="#FA0C08"
      />
    </Svg>
    :
    <Svg
      width={28}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5.25 7.318c1.582.245 3.347.653 5 1.292m-5 3.708c.8.124 1.645.29 2.5.505M14 4.567v17.811M3.993.764c2.773.313 6.498 1.156 9.153 3.017.51.357 1.198.357 1.708 0 2.655-1.86 6.38-2.704 9.153-3.017C25.38.61 26.5 1.755 26.5 3.17v14.08c0 1.415-1.12 2.561-2.493 2.716-2.773.314-6.498 1.157-9.153 3.017a1.494 1.494 0 0 1-1.708 0c-2.655-1.86-6.38-2.703-9.153-3.017C2.62 19.81 1.5 18.664 1.5 17.25V3.169C1.5 1.755 2.62.609 3.993.764Z"
        stroke={colorSchema.textColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
)

export default LastOrderIcon
