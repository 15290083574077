import * as React from "react"
import Svg, { Ellipse, Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const TakeAwayIcon = (props) => (
    props.selected ?
        <Svg
            width={19}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8.661 6.947a24.386 24.386 0 0 0 2.135-.434c1.66-.418 3.745-1.12 5.47-2.349.387-.276.446-.775.134-1.116-.313-.34-.879-.394-1.265-.118-1.422 1.02-3.307 1.677-4.83 2.056a22.47 22.47 0 0 1-2.459.47l-.11.012H3.45l-.047.002a2.37 2.37 0 0 0-.918.212c-.604.264-1.256.843-1.712 1.87C.31 8.58 0 10.068 0 12.316c0 .581.021 1.213.067 1.9.028.423.424.748.897.748l.053-.001c.496-.026.874-.401.845-.839a27.41 27.41 0 0 1-.064-1.808c-.003-2.402.393-3.751.783-4.456v5.159c0 .03.003.061.007.091.009 1.718.277 4.117.543 6.14.076.58.152 1.125.222 1.61a8.144 8.144 0 0 0 1.803-2.592 7.78 7.78 0 0 0 .491-1.585 46.232 46.232 0 0 1-.233-2.897h.519c.053.36.094.797.094 1.286 0 .955-.156 2.1-.661 3.267-.508 1.17-1.355 2.369-2.803 3.485-.585.45-.646 1.233-.136 1.75.278.28.668.425 1.06.425.327 0 .656-.1.922-.305 1.804-1.385 2.93-2.957 3.582-4.47.654-1.514.846-2.963.846-4.152 0-.79-.085-1.469-.18-1.985a.69.69 0 0 0 .004-.068V6.947ZM5.35 4.721c1.477 0 2.674-1.056 2.674-2.36C8.024 1.057 6.827 0 5.35 0 3.872 0 2.675 1.057 2.675 2.36c0 1.305 1.197 2.361 2.675 2.361Z"
                fill="#FA0C08"
            />
            <Path fill="#FA0C08" d="M12.911 6.72h5.533v6.72h-5.533z" />
            <Path
                stroke="#FA0C08"
                strokeWidth={0.3}
                strokeLinecap="round"
                d="M15.828 3.99v3.54"
            />
        </Svg>
        :
        <Svg
            width={30}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Ellipse
                cx={11.267}
                cy={6}
                rx={2.267}
                ry={2}
                stroke={colorSchema.textColor}
                strokeWidth={0.9}
                strokeLinejoin="round"
            />
            <Path
                d="m21.817 8.02.402-.38.141-.133a.435.435 0 0 0-.056-.678l-.075-.05a.872.872 0 0 0-.861-.066l-.355.167-.658.416a5 5 0 0 1-1.164.54l-1.795.565-1.485.45a5 5 0 0 1-1.071.2l-1.463.11H8.554l-.195.062a3.135 3.135 0 0 0-1.945 1.77l-.164.387c-.125.295-.22.6-.287.913l-.299 1.416a5 5 0 0 0-.103.8L5.5 15.812l.063 1.341a.744.744 0 0 0 .881.696l.355-.067a.372.372 0 0 0 .303-.384l-.075-1.482v-1.23a5 5 0 0 1 .097-.98l.225-1.123.176-.5c.146-.416.423-.774.788-1.021l-.08.95v4.753l.16 1.521.563 4.943.794-.75a4.167 4.167 0 0 0 1.273-3.527l-.218-1.807v-.38h.965l.074.53c.058.406.065.817.022 1.224l-.056.527a4.996 4.996 0 0 1-.138.751l-.214.808a5 5 0 0 1-.228.67l-.113.265c-.154.364-.35.709-.586 1.027l-.35.473a3.88 3.88 0 0 1-1.016.953l-.823.531a1.158 1.158 0 0 0-.476.623l-.033.103a.83.83 0 0 0 .624 1.064l.3.062c.382.078.78-.011 1.092-.245l1.27-.953c.378-.284.713-.621.995-1.002l.476-.643c.15-.202.284-.416.401-.638l.401-.76c.15-.284.273-.582.366-.89l.262-.868.189-.623c.141-.47.213-.956.213-1.446v-.158c0-.304-.027-.607-.083-.906l-.075-.41a5 5 0 0 1-.083-.906v-5.627l2.411-.38 2.01-.608 2.411-.913.804-.38Zm0 0v2.282m0 0h-2.813v6.084h4.823v-6.084h-2.01Z"
                stroke={colorSchema.textColor}
                strokeWidth={0.9}
            />
        </Svg>
)

export default TakeAwayIcon
