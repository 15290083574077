import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
const TimeIcon = (props) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    width={18}
    height={18}
    fill='none'
    style={{marginRight: 2}}
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        fill={props.color}
        d='M9 9h-.75c0 .323.207.61.513.712zm.75-3.75a.75.75 0 0 0-1.5 0zm1.263 5.211a.75.75 0 0 0 .474-1.423zM9.75 9V5.25h-1.5V9zm-.987.712 2.25.75.474-1.424-2.25-.75zM15.75 9A6.75 6.75 0 0 1 9 15.75v1.5A8.25 8.25 0 0 0 17.25 9zM9 15.75A6.75 6.75 0 0 1 2.25 9H.75A8.25 8.25 0 0 0 9 17.25zM2.25 9A6.75 6.75 0 0 1 9 2.25V.75A8.25 8.25 0 0 0 .75 9zM9 2.25A6.75 6.75 0 0 1 15.75 9h1.5A8.25 8.25 0 0 0 9 .75z'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path
          fill='#fff'
          d='M0 0h18v18H0z'
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default TimeIcon;
