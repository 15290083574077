import { View, Text, Image, TouchableOpacity, Dimensions } from 'react-native'
import React from 'react'
import RedButtonCart from '../button/RedButtonCart'
import MainStore from '../../store/MainStore'
import MinusIcon from '../../assets/svg/MinusIcon'
import TrashIcon from '../../assets/svg/TrashIcon'
import colorSchema from '../../config/colorSchema'

import PlusIcon from '../../assets/svg/PlusIcon'
import { observer } from 'mobx-react'

const { width, height } = Dimensions.get('window')

const WebCartSheet = (props) => {
    return (
        <View style={{ marginLeft: 17, maxWidth: 300, height: height, borderLeftWidth: 0.9, borderLeftColor: 'rgba(30,30,30,0.2)', paddingLeft: 10 }}>
            <View style={{ borderBottomWidth: 0.9, borderBottomColor: 'rgba(30,30,30,0.2)', paddingBottom: 17 }}>
                <RedButtonCart clickEvent={() => { if (MainStore.cartItem.length > 0) { props.navigation.navigate('Cart') } }} text={MainStore.cartItem.length > 0 ? `${MainStore.calcutePrice} TL` : 'Sepetiniz Boş'} width={255} />
            </View>
            {MainStore.cartItem.length > 0
                ?
                <View style={{ marginTop: 17, borderBottomWidth: 0.9, borderBottomColor: 'rgba(30,30,30,0.2)', paddingBottom: 17 }}>
                    <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(30,30,30,0.7)' }}>Sepetiniz</Text>
                    <Text style={{ fontSize: 15, fontFamily: 'Gilroy-Bold' }}>{MainStore.cartItem[0].restaurantName}</Text>
                </View>
                :
                <></>
            }

            <View style={{ marginLeft: 8 }}>
                {MainStore.cartItem.map((item, index) =>
                    <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 10, borderBottomWidth: 0.9, borderBottomColor: 'rgba(30,30,30,0.2)' }}>
                        <View style={{ marginRight: 8 }}>
                            <Image source={{ uri: item.foodImage }} style={{ width: 95, height: 95, borderRadius: 1 }} />
                        </View>
                        <View >
                            <View style={{ marginTop: 3 }}>
                                <Text style={{ fontFamily: 'Gilroy-SemiBold', fontSize: 16 }}>{item.name}</Text>
                            </View>
                            <View style={{ marginTop: 3 }}>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 13 }}>{item.description}</Text>
                            </View>
                            <View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: 90, height: 27, borderRadius: 6, borderWidth: 0.3, borderColor: 'rgba(30,30,30,0.6)', alignItems: 'center' }}>
                                        <View style={{ marginLeft: 6, alignItems: 'center', justifyContent: 'center' }}>
                                            {item.amount > 1
                                                ?
                                                <TouchableOpacity onPress={() => {
                                                    MainStore.addCart(item, -1)
                                                }} style={{ width: 20, height: 27, alignItems: 'center', justifyContent: 'center' }}>
                                                    <MinusIcon width={10} height={2} />
                                                </TouchableOpacity>
                                                :
                                                <TouchableOpacity onPress={() => {
                                                    MainStore.addCart(item, -1)
                                                }} style={{ width: 20, height: 27, alignItems: 'center', justifyContent: 'center' }}>
                                                    <TrashIcon width={14} height={14} />
                                                </TouchableOpacity>
                                            }
                                        </View>
                                        <View>
                                            <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.textColor }}>{item.amount}</Text>
                                        </View>
                                        <View style={{ marginRight: 6, alignItems: 'center' }}>
                                            <TouchableOpacity style={{ width: 20, height: 27, alignItems: 'center', justifyContent: 'center' }} onPress={() => MainStore.addCart(item, 1)}>
                                                <PlusIcon width={10} height={10} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>

                                </View>
                                <View style={{ marginRight: 8, marginTop: 3 }}>
                                    <Text style={{ fontFamily: 'Gilroy-SemiBold', fontSize: 15 }}>{`${item.price} TL`}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        </View>
    )
}

export default observer(WebCartSheet)