import {View, Text, Dimensions, Image, ScrollView} from 'react-native';
import React, {useState, useEffect} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import WebHeader from '../../components/header/WebHeader';
import {TouchableOpacity} from 'react-native';
import {Feather} from '@expo/vector-icons';
import GoBackTitle from '../../components/header/GoBackTitle';
import MainStore from '../../store/MainStore';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import LastStarIcon from '../../assets/svg/LastStarIcon';
import colorSchema from '../../config/colorSchema';
const {width, height} = Dimensions.get('window');

const ReviewList = ({navigation, route}) => {
  const [appReady, setAppReady] = useState(false);
  const [orderID, setOrderID] = useState(route.params.id);
  const [orderData, setOrderData] = useState(null);
  useEffect(() => {
    axios
      .get(`${axiosConfig.serverIp}/getOrderDetails/${orderID}`, {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setOrderData(res.data);
        setAppReady(true);
      });
  }, []);
  return appReady ? (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      {width > 1279 ? <WebHeader navigation={navigation} /> : <></>}
      <View>
        <GoBackTitle
          title={MainStore.langPref == 'tr' ? 'Yorum Listesi' : 'Review List'}
          navigation={navigation}
        />
      </View>

      <View style={{marginTop: 17}}>
        <View style={{flexDirection: 'row'}}>
          <View>
            <Image
              src='https://www.foodservicerewards.com/cdn/shop/t/262/assets/fsr-placeholder.png?v=45093109498714503231652397781'
              style={{width: 100, height: 100, borderRadius: 8, marginLeft: 12}}
            />
          </View>
          <View style={{marginTop: 9, marginLeft: 14}}>
            <View>
              <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 19}}>{orderData.restaurantData.rName}</Text>
            </View>
            <View style={{marginTop: 23}}>
              {orderData.review ? (
                <TouchableOpacity
                  style={{
                    paddingVertical: 9,
                    width: width > 1279 ? 500 : width / 2 - 65,
                    backgroundColor: 'rgba(211,214,219,0.3)',
                    paddingLeft: 10,
                    borderRadius: 8,
                  }}
                >
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Feather
                      name='check'
                      style={{fontSize: 22}}
                    />
                    <Text
                      style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5}}
                    >
                      {MainStore.langPref == 'tr' ? 'Yorum Yapıldı' : 'Commented'}
                    </Text>
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('ReviewRestaurant', {
                      orderID: orderData._id,
                      restaurantName: orderData.restaurantData.rName,
                    })
                  }
                  style={{
                    paddingVertical: 9,
                    width: width > 1279 ? 500 : width / 2 - 35,
                    backgroundColor: 'rgba(211,214,219,0.3)',
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                >
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <LastStarIcon />
                    <Text
                      style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5}}
                    >
                      {MainStore.langPref == 'tr' ? 'Restoranı Değerlendir' : 'Review the Restaurant'}
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </View>

      <View style={{width: '100%', height: 0.4, marginVertical: 17, backgroundColor: 'rgba(0,0,0,0.1)'}} />

      <ScrollView>
        {orderData?.orderedFood?.map(
          (item, index) =>
            item.category != 'İçecek' && (
              <View
                key={index}
                style={{flexDirection: 'row', marginBottom: 17}}
              >
                <View>
                  <Image
                    src={
                      item.foodImage
                        ? item.foodImage
                        : 'https://www.foodservicerewards.com/cdn/shop/t/262/assets/fsr-placeholder.png?v=45093109498714503231652397781'
                    }
                    style={{width: 100, height: 100, borderRadius: 8, marginLeft: 12}}
                  />
                </View>
                <View style={{marginTop: 9, marginLeft: 14}}>
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 18}}>{item?.name}</Text>
                  </View>
                  <View style={{marginTop: 23}}>
                    {item.review ? (
                      <TouchableOpacity
                        
                        style={{
                          paddingVertical: 9,
                          width: width > 1279 ? 500 : width / 2 - 65,
                          backgroundColor: 'rgba(211,214,219,0.3)',
                          paddingLeft: 10,
                          borderRadius: 8,
                        }}
                      >
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <Feather
                            name='check'
                            style={{fontSize: 22}}
                          />
                          <Text
                            style={{
                              fontSize: 14,
                              fontFamily: 'Gilroy-Regular',
                              color: colorSchema.textColor,
                              marginLeft: 5,
                            }}
                          >
                            {MainStore.langPref == 'tr' ? 'Yorum Yapıldı' : 'Commented'}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate('ReviewFood', {
                            foodID: item._id,
                            orderID: orderData._id,
                            name: item.name,
                            restaurantName: orderData.restaurantData.rName,
                          })
                        }
                        style={{
                          paddingVertical: 9,
                          width: width > 1279 ? 500 : width / 2 - 35,
                          backgroundColor: 'rgba(211,214,219,0.3)',
                          alignItems: 'center',
                          borderRadius: 8,
                        }}
                      >
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <LastStarIcon />
                          <Text
                            style={{
                              fontSize: 14,
                              fontFamily: 'Gilroy-Regular',
                              color: colorSchema.textColor,
                              marginLeft: 5,
                            }}
                          >
                            {MainStore.langPref == 'tr' ? 'Restoranı Değerlendir' : 'Review the Restaurant'}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>
            )
        )}
      </ScrollView>
    </SafeAreaView>
  ) : (
    <LoadingSpinner />
  );
};

export default ReviewList;
