import * as React from "react";
import Svg, { Path } from "react-native-svg";

function MapPin(props) {
    return (
        <Svg
            width={18}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.75 9.083c0 4.582-5.688 11.084-8.75 11.084C5.937 20.167.25 13.21.25 8.63.25 3.833 4.49.333 9 .333s8.75 3.5 8.75 8.75zM11.917 8.5a2.917 2.917 0 11-5.834 0 2.917 2.917 0 015.834 0zM2 22.792a.875.875 0 100 1.75h14a.875.875 0 000-1.75H2z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default MapPin;