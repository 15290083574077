import * as React from "react"
import Svg, { Ellipse, Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const TakeAwayIcon2 = (props) => (
    <Svg
        width={19}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M8.661 6.947a24.386 24.386 0 0 0 2.135-.434c1.66-.418 3.745-1.12 5.47-2.349.387-.276.446-.775.134-1.116-.313-.34-.879-.394-1.265-.118-1.422 1.02-3.307 1.677-4.83 2.056a22.47 22.47 0 0 1-2.459.47l-.11.012H3.45l-.047.002a2.37 2.37 0 0 0-.918.212c-.604.264-1.256.843-1.712 1.87C.31 8.58 0 10.068 0 12.316c0 .581.021 1.213.067 1.9.028.423.424.748.897.748l.053-.001c.496-.026.874-.401.845-.839a27.41 27.41 0 0 1-.064-1.808c-.003-2.402.393-3.751.783-4.456v5.159c0 .03.003.061.007.091.009 1.718.277 4.117.543 6.14.076.58.152 1.125.222 1.61a8.144 8.144 0 0 0 1.803-2.592 7.78 7.78 0 0 0 .491-1.585 46.232 46.232 0 0 1-.233-2.897h.519c.053.36.094.797.094 1.286 0 .955-.156 2.1-.661 3.267-.508 1.17-1.355 2.369-2.803 3.485-.585.45-.646 1.233-.136 1.75.278.28.668.425 1.06.425.327 0 .656-.1.922-.305 1.804-1.385 2.93-2.957 3.582-4.47.654-1.514.846-2.963.846-4.152 0-.79-.085-1.469-.18-1.985a.69.69 0 0 0 .004-.068V6.947ZM5.35 4.721c1.477 0 2.674-1.056 2.674-2.36C8.024 1.057 6.827 0 5.35 0 3.872 0 2.675 1.057 2.675 2.36c0 1.305 1.197 2.361 2.675 2.361Z"
            fill="black"
        />
        <Path fill="black" d="M12.911 6.72h5.533v6.72h-5.533z" />
        <Path
            stroke="black"
            strokeWidth={0.3}
            strokeLinecap="round"
            d="M15.828 3.99v3.54"
        />
    </Svg>

)

export default TakeAwayIcon2
