import { View, Text, SafeAreaView, Keyboard, Dimensions, StyleSheet, TextInput, TouchableOpacity } from 'react-native'
import React, { useState,useEffect } from 'react'
import LogoHeader from '../../components/header/LogoHeader'
import colorSchema from '../../config/colorSchema'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage'
import AsyncStorage from '@react-native-async-storage/async-storage'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import WebHeader from '../../components/header/WebHeader'
import { useToast } from '@gluestack-ui/themed'
import * as Notifications from 'expo-notifications';

const { width, height } = Dimensions.get('window')

const Verification = ({ navigation, route }) => {
    const [authCode, setAuthCode] = useState('')
    const [timeInterval,setTimeInterval] = useState(30)
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            if (timeInterval > 0) {
                setTimeInterval((prevInterval) => prevInterval - 1);
            } else {
                setTimerActive(false);
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [timeInterval]);

    const handleResendCode = () => {
        if (!timerActive) {
            axios.post(`${axiosConfig.serverIp}/sendDigitCode`, {
                phoneNumber:`${route.params.countryCode}${route.params.phoneNumber}`
            }, ).then(res => {
                console.log(res.data)
                if(res.data.success)
                {
                    setTimeInterval(timeInterval+30);
                    setTimerActive(true);
                }
            })
        }
    };

    const registerForPushNotificationsAsync = async (authToken) => {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }

        if (finalStatus !== 'granted') {
            return;
        }

        //Notifications
         const token = (await Notifications.getExpoPushTokenAsync()).data;
          axios.post(`${axiosConfig.serverIp}/editMyProfile`, {
              expoPushToken: token
          }, { headers: { Authorization: `Bearer ${authToken}` } })
    };

    const toast = useToast()
    return (
        <SafeAreaView style={style.main}>
            <View onStartShouldSetResponder={() => Keyboard.dismiss()} style={style.main}>

                {width > 1279 ?
                    <View style={{ marginLeft: 80, marginRight: 80 }}>
                        <WebHeader navigation={navigation} />
                    </View>
                    :
                    <View>
                        <LogoHeader />
                    </View>}
                <View style={style.board}>
                    <View style={style.headerArea}>
                        <Text style={style.headerText}>Tek Kullanımlık Kod</Text>
                    </View>
                    <View style={{ alignItems: 'center', marginBottom: 47 }}>
                        <Text style={style.infoText}>{`${route.params.phoneNumber.slice(0, 3)}******* numaralı telefonunuza`}</Text>
                        <Text style={style.infoText}>gönderilen tek kullanımlık kodu aşağıya giriniz</Text>
                    </View>
                    <View>
                        <TextInput autoComplete='sms-otp' textContentType='oneTimeCode' keyboardType='number-pad' style={style.input} value={authCode} onChangeText={(text) => setAuthCode(text)} placeholder='Kod' placeholderTextColor='rgba(30,30,30,0.6)' />
                    </View>

                    <View style={style.buttonArea}>
                        <TouchableOpacity style={style.registerButton} onPress={() => {
                            axios.post(`${axiosConfig.serverIp}/login`, {
                                phoneNumber: `${route.params.countryCode}${route.params.phoneNumber}`,
                                authCode
                            }).then(res => {
                                if (res.data.error) {
                                    ErrorToastMessage(toast, res.data.error)
                                }
                                else {
                                    //NotificationsMobile
                                      registerForPushNotificationsAsync(res.data.token)
                                    AsyncStorage.setItem('token', res.data.token)
                                    MainStore.updateSignValue(res.data.token, res.data.user)
                                }
                            }).catch(error => {
                                if (error.response && error.response.data && error.response.data.error) {
                                    const errorMessage = error.response.data.error;
                                    ErrorToastMessage(toast, errorMessage)
                                } else {
                                    ErrorToastMessage(toast, 'Bir sorun oluştu lütfen daha sonra tekrar deneyiniz.')
                                }
                            })
                        }}>
                            <Text style={style.registerText}>Onayla ve Devam Et</Text>
                        </TouchableOpacity>
                        
                        <View style={{marginTop:21,flexDirection:'row',justifyContent:'center'}}>
                           <Text style={{fontFamily:'Gilroy-Medium',color:'rgba(30,30,30,0.8)'}}>SMS gelmedi mi ? </Text>
                           <TouchableOpacity onPress={handleResendCode} disabled={timerActive}>
                              <Text style={{fontFamily:'Gilroy-Medium',color:timeInterval>0 ? 'rgba(30,30,30,0.8)' : colorSchema.mainColor}}>{timeInterval > 0 ? `${timeInterval} saniye sonra tekrar kod gönder` : 'Tekrar kod gönder'}</Text>
                           </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}

const style = StyleSheet.create({
    main: { backgroundColor: 'white', flex: 1 },
    board: { alignItems: width > 1279 ? 'flex-start' : 'center', marginLeft: width > 1279 ? 80 : 0, flex: 1, marginTop: 47 },
    headerText: { fontFamily: 'Gilroy-Bold', fontSize: 24 },
    headerArea: { marginBottom: 39 },
    infoText: { fontFamily: 'Gilroy-Medium', fontSize: 16 },
    input: { borderRadius: 10, width: width > 1279 ? 700 : width - 100, height: 58, backgroundColor: 'rgba(211,214,219,0.4)', paddingLeft: 10, fontFamily: 'Gilroy-Medium', fontSize: 15, marginBottom: 20, borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
    buttonArea: { flex: 1, position: width > 1279 ? 'relative' : 'absolute', marginTop: 100, bottom: 80 },
    registerButton: { borderRadius: 20, backgroundColor: colorSchema.mainColor, width: width > 1279 ? 700 : width - 28, height: 43, alignItems: 'center', justifyContent: 'center' },
    registerText: { color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 },



})
export default observer(Verification)