import {View, Text, ImageBackground, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import colorSchema from '../../config/colorSchema';
import StarIcon from '../../assets/svg/StarIcon';
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon';
import ErrorToastMessage from '../feedback/ErrorToastMessage';
import {useToast} from '@gluestack-ui/themed';
import MainStore from '../../store/MainStore';

const HomeFoodCard = (props) => {
  const toast = useToast();

  return (
    <TouchableOpacity
      onPress={() => {
        if (props.restaurantOpen) {
          MainStore.changeIsTakeAway(false);
          props.navigation.navigate('FoodDetail', {id: props.id});
        } else {
          ErrorToastMessage(toast, 'Restorant şuan açık değil lütfen daha sonra tekrar deneyiniz.');
        }
      }}
    >
      <View style={{marginLeft: 5, marginRight: 13, backgroundColor: colorSchema.cartColor, paddingBottom: 12}}>
        <View>
          <ImageBackground
            source={{uri: props.foodImage}}
            style={{width: 278, height: 137}}
          ></ImageBackground>
        </View>
        <View style={{marginLeft: 10, marginTop: 9}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', marginRight: 6}}>
            <View>
              <View>
                <Text
                  numberOfLines={1}
                  style={{fontFamily: 'Gilroy-ExtraBold', fontSize: 14, flex: 1, width: 250}}
                >
                  {props.name}{' '}
                  <Text
                    numberOfLines={1}
                    style={{fontFamily: 'Gilroy-Light'}}
                  >
                    ({props.restaurantName})
                  </Text>
                </Text>
              </View>
              <View>
                <Text style={{fontSize: 10, fontWeight: '400', lineHeight: 14}}>
                  {props.deliveryTime} dk · Min{' '}
                  {typeof props.minPrice == 'number'
                    ? props.minPrice.toFixed(2)
                    : parseFloat(props.minPrice).toFixed(2)}{' '}
                  TL
                </Text>
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 17}}>
                <View style={{marginRight: 6}}>
                  <StarIcon />
                </View>
                <View>
                  <Text style={{fontSize: 12, fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.5)'}}>
                    {props.rating} <Text style={{color: 'rgba(0,0,0,0.25)'}}>({props.comments})</Text>
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <TouchableOpacity
                onPress={() => {
                  if (props.restaurantOpen) {
                    MainStore.changeIsTakeAway(false);
                    props.navigation.navigate('FoodDetail', {id: props.id});
                  } else {
                    ErrorToastMessage(toast, 'Restorant şuan açık değil lütfen daha sonra tekrar deneyiniz.');
                  }
                }}
              >
                <PlusCircleIcon />
              </TouchableOpacity>
            </View>
          </View>

          <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <View>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 10}}>{props.category}</Text>
            </View>
            <View style={{marginRight: 9}}>
              <View style={{marginRight: 9}}>
                {props.discountType == 'fixed' ? (
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: 'Gilroy-Medium',
                        color: 'gray',
                        lineHeight: 15,
                        textDecorationLine: 'line-through',
                      }}
                    >
                      {' '}
                      {`${props.price} TL`}
                    </Text>
                    <Text
                      style={{fontSize: 13, fontFamily: 'Gilroy-Medium', color: colorSchema.greenColor, lineHeight: 15}}
                    >
                      {' '}
                      {`${parseFloat(props.price - props.discount).toFixed(2)} TL`}
                    </Text>
                  </View>
                ) : props.discountType == 'percent' ? (
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: 'Gilroy-Medium',
                        color: 'gray',
                        lineHeight: 15,
                        textDecorationLine: 'line-through',
                      }}
                    >
                      {' '}
                      {`${props.price} TL`}
                    </Text>
                    <Text
                      style={{fontSize: 13, fontFamily: 'Gilroy-Medium', color: colorSchema.greenColor, lineHeight: 15}}
                    >
                      {' '}
                      {`${parseFloat(props.price - (props.price * props.discount) / 100).toFixed(2)} TL`}
                    </Text>
                  </View>
                ) : (
                  <View style={{flexDirection: 'row'}}>
                    <Text
                      style={{fontSize: 13, fontFamily: 'Gilroy-Medium', color: colorSchema.greenColor, lineHeight: 15}}
                    >
                      {' '}
                      {`${props.price} TL`}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default HomeFoodCard;
