import { View, Text, TouchableOpacity, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
import CartIcon from '../../assets/svg/CartIcon'
const { width, height } = Dimensions.get('window')
const RedButtonCart = (props) => {
    return (
        <TouchableOpacity onPress={props.clickEvent} style={{ borderRadius: 20, backgroundColor: colorSchema.mainColor, width: props.width, height: 43, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{marginLeft:12}}>
                <CartIcon />
            </View>
            <View style={{marginRight:12}}>
                <Text style={{ color: 'white', fontFamily: 'Gilroy-SemiBold', fontSize: 19 }}>{props.text}</Text>
            </View>
        </TouchableOpacity>
    )
}


export default RedButtonCart