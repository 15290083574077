import {View, Text, SafeAreaView, Dimensions, TouchableOpacity, TextInput, ScrollView, Modal} from 'react-native';
import React, {useState, useEffect} from 'react';
import {Feather} from '@expo/vector-icons';
import {Shadow} from 'react-native-shadow-2';
import colorSchema from '../../config/colorSchema';
import RedButton from '../../components/button/RedButton';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import WebHeader from '../../components/header/WebHeader';
import {
  Center,
  SelectScrollView,
  Select,
  SelectTrigger,
  SelectInput,
  SelectIcon,
  SelectPortal,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicatorWrapper,
  SelectDragIndicator,
  SelectItem,
  Spinner,
  useToast,
} from '@gluestack-ui/themed';
import SuccessToastMessage from '../../components/feedback/SuccessToastMessage';
import {Heading} from '@gluestack-ui/config/build/theme';

const {width, height} = Dimensions.get('window');
const AddAddressGuest = ({navigation}) => {
  const [title, setTitle] = useState('Ev');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [neighbourhoodList, setNeighbourhoodList] = useState([]);
  const [neighbourhood, setNeighbourhood] = useState('');
  const [fullAdress, setFullAdress] = useState('');
  const [loading, setLoading] = useState(false);
  const [primary, setPrimary] = useState(false);
  const [visible, setVisible] = useState(false);

  const toast = useToast();

  const toggleBottomNavigationView = () => {
    setVisible(!visible);
  };

  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Amasya',
    'Ankara',
    'Antalya',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkari',
    'Hatay',
    'Isparta',
    'Mersin',
    'Istanbul',
    'Izmir',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırklareli',
    'Kırşehir',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Kahramanmaraş',
    'Mardin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Şanlıurfa',
    'Uşak',
    'Van',
    'Yozgat',
    'Zonguldak',
    'Aksaray',
    'Bayburt',
    'Karaman',
    'Kırıkkale',
    'Batman',
    'Şırnak',
    'Bartın',
    'Ardahan',
    'Iğdır',
    'Yalova',
    'Karabük',
    'Kilis',
    'Osmaniye',
    'Düzce',
  ];

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
        }}
      >
        {width > 1279 ? <WebHeader navigation={navigation} /> : <></>}

        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={{marginLeft: 16}}
        >
          <Feather
            name='chevron-left'
            size={26}
          />
        </TouchableOpacity>

        <View style={{marginLeft: 12, marginTop: 60}}>
          <Shadow style={{borderRadius: 8}}>
            <Select
              selectedValue={title}
              placeholder='Seçiniz'
              placeholderTextColor={colorSchema.textColor}
              width={width > 1279 ? 1279 / 3 - 28 : width / 3 - 28}
              height={60}
              position='relative'
              pt={12}
              pl={2}
              borderWidth={0}
              onValueChange={(e) => setTitle(e)}
            >
              <SelectTrigger borderWidth={0}>
                <SelectInput
                  fontSize={14}
                  fontFamily='Gilroy-Regular'
                  placeholder={MainStore.langPref == 'tr' ? 'Başlık Seçiniz' : 'Select Title'}
                />
                <SelectIcon mr='$3'></SelectIcon>
              </SelectTrigger>
              <SelectPortal>
                <SelectBackdrop />
                <SelectContent>
                  <SelectDragIndicatorWrapper>
                    <SelectDragIndicator />
                  </SelectDragIndicatorWrapper>
                  <SelectItem
                    label={'Ev'}
                    value={'Ev'}
                  />
                  <SelectItem
                    label={'Ofis'}
                    value={'Ofis'}
                  />
                  <SelectItem
                    label={'İş'}
                    value={'İş'}
                  />
                  <SelectItem
                    label={'Diğer'}
                    value={'Diğer'}
                  />
                </SelectContent>
              </SelectPortal>
            </Select>

            <View style={{position: 'absolute', top: 5, left: 13}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 13,
                  color: colorSchema.mainColor,
                }}
              >
                {MainStore.langPref == 'tr' ? 'Başlık' : 'Title'}
              </Text>
            </View>
          </Shadow>
        </View>

        <View
          style={{
            flexDirection: 'row',
            marginLeft: 12,
            marginTop: 27,
            justifyContent: 'space-between',
            marginRight: 12,
          }}
        >
          <Shadow style={{borderRadius: 8}}>
            <Select
              selectedValue={province}
              placeholder='Seçiniz'
              placeholderTextColor={colorSchema.textColor}
              width={width > 1279 ? 1279 / 3 - 28 : width / 3 - 28}
              height={60}
              position='relative'
              pt={12}
              pl={2}
              borderWidth={0}
              onValueChange={(itemValue) => {
                setLoading(true);
                axios.get(`https://api.yemek.club/user/api/getAddress/${itemValue}`).then((res) => {
                  setDistrictList(res.data);
                  setProvince(itemValue);
                  setLoading(false);
                });
              }}
            >
              <SelectTrigger borderWidth={0}>
                <SelectInput
                  fontSize={14}
                  fontFamily='Gilroy-Regular'
                  placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                />
                <SelectIcon mr='$3'></SelectIcon>
              </SelectTrigger>
              <SelectPortal>
                <SelectBackdrop />
                <SelectContent>
                  <SelectDragIndicatorWrapper>
                    <SelectDragIndicator />
                  </SelectDragIndicatorWrapper>
                  <SelectScrollView>
                    {cities.map((item, index) => (
                      <SelectItem
                        label={item}
                        value={item}
                        key={index}
                      />
                    ))}
                  </SelectScrollView>
                </SelectContent>
              </SelectPortal>
            </Select>
            <View style={{position: 'absolute', top: 5, left: 13}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 13,
                  color: colorSchema.mainColor,
                }}
              >
                {MainStore.langPref == 'tr' ? 'İl' : 'City'}
              </Text>
            </View>
          </Shadow>

          <Shadow style={{borderRadius: 8}}>
            <Select
              selectedValue={district}
              placeholder='Seçiniz'
              placeholderTextColor={colorSchema.textColor}
              width={width > 1279 ? 1279 / 3 - 28 : width / 3 - 28}
              height={60}
              position='relative'
              pt={12}
              pl={2}
              borderWidth={0}
              onValueChange={(itemValue) => {
                setLoading(true);
                axios
                  .get(`https://api.yemek.club/user/api/getAddress/${province}?district=${itemValue}`)
                  .then((res) => {
                    setNeighbourhoodList(res.data);
                    setDistrict(itemValue);
                    setLoading(false);
                  });
              }}
            >
              <SelectTrigger borderWidth={0}>
                <SelectInput
                  fontSize={14}
                  fontFamily='Gilroy-Regular'
                  placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                />
                <SelectIcon mr='$3'></SelectIcon>
              </SelectTrigger>
              <SelectPortal>
                <SelectBackdrop />
                <SelectContent>
                  <SelectDragIndicatorWrapper>
                    <SelectDragIndicator />
                  </SelectDragIndicatorWrapper>
                  <SelectScrollView>
                    {province && province !== 'none' ? (
                      districtList.map((item, index) => (
                        <SelectItem
                          label={item.name}
                          value={item.name}
                          key={index}
                        />
                      ))
                    ) : (
                      <SelectItem
                        label={MainStore.langPref == 'tr' ? 'İl Seçiniz ' : 'Please Select a City'}
                        value='none'
                      />
                    )}
                  </SelectScrollView>
                </SelectContent>
              </SelectPortal>
            </Select>
            <View style={{position: 'absolute', top: 5, left: 13}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 13,
                  color: colorSchema.mainColor,
                }}
              >
                {MainStore.langPref == 'tr' ? 'İlçe' : 'District'}
              </Text>
            </View>
          </Shadow>

          <Shadow style={{borderRadius: 8}}>
            <Select
              selectedValue={neighbourhood}
              placeholder='Seçiniz'
              placeholderTextColor={colorSchema.textColor}
              width={width > 1279 ? 1279 / 3 - 28 : width / 3 - 28}
              height={60}
              position='relative'
              pt={12}
              pl={2}
              borderWidth={0}
              onValueChange={(itemValue) => {
                setLoading(true);
                setNeighbourhood(itemValue);
                setLoading(false);
              }}
            >
              <SelectTrigger borderWidth={0}>
                <SelectInput
                  fontSize={14}
                  fontFamily='Gilroy-Regular'
                  placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                />
                <SelectIcon mr='$3'></SelectIcon>
              </SelectTrigger>
              <SelectPortal>
                <SelectBackdrop />
                <SelectContent>
                  <SelectDragIndicatorWrapper>
                    <SelectDragIndicator />
                  </SelectDragIndicatorWrapper>
                  <SelectScrollView>
                    {district && district !== 'none' ? (
                      neighbourhoodList.map((item, index) => (
                        <Select.Item
                          label={item.name}
                          value={item.name}
                          key={index}
                        />
                      ))
                    ) : (
                      <Select.Item
                        label={MainStore.langPref == 'tr' ? 'İlçe Seçiniz ' : 'Please Select a District'}
                        value='none'
                      />
                    )}
                  </SelectScrollView>
                </SelectContent>
              </SelectPortal>
            </Select>
            <View style={{position: 'absolute', top: 5, left: 13}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 13,
                  color: colorSchema.mainColor,
                }}
              >
                {MainStore.langPref == 'tr' ? 'Mahalle' : 'Neighbourhood'}
              </Text>
            </View>
          </Shadow>
        </View>

        <View style={{marginLeft: 14, marginTop: 27}}>
          <Shadow style={{borderRadius: 8}}>
            <View style={{position: 'absolute', left: 12, top: 8}}>
              <Text
                style={{
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 13,
                  color: colorSchema.mainColor,
                }}
              >
                Adres
              </Text>
            </View>
            <View>
              <TextInput
                value={fullAdress}
                onChangeText={(text) => setFullAdress(text)}
                style={{
                  width: width > 1279 ? 1279 - 28 : width - 28,
                  borderRadius: 8,
                  alignItems: 'center',
                  height: 60,
                  paddingLeft: 12,
                  paddingTop: 8,
                  fontFamily: 'Gilroy-Regular',
                  fontSize: 14,
                  color: colorSchema.textColor,
                }}
                placeholder={MainStore.langPref == 'tr' ? 'Adres Giriniz' : 'Enter Address'}
              />
            </View>
          </Shadow>
        </View>

        <View style={{marginTop: 27, marginLeft: 12}}>
          <Shadow>
            <View
              style={{
                width: 246,
                height: 60,
                borderRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: 14,
                    color: colorSchema.textColor,
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Bunu Varsayılan Adresim Yap' : 'Make this my default address'}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  onPress={() => setPrimary(!primary)}
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#D3D6DB',
                    borderRadius: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 16,
                      height: 16,
                      backgroundColor: primary ? colorSchema.mainColor : '#D3D6DB',
                      borderRadius: 50,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Shadow>
        </View>
      </ScrollView>
      <View
        style={{
          position: width > 1279 ? 'relative' : 'absolute',
          marginTop: 200,
          alignSelf: 'center',
          bottom: 40,
        }}
      >
        <RedButton
          clickEvent={() => {
            if (fullAdress.length > 0 && province && district && neighbourhood) {
              MainStore.guestAddAddress(title, province, district, neighbourhood, fullAdress);
            } else {
              ErrorToastMessage(toast, 'Lütfen tüm alanları doldurun');
            }
          }}
          text={MainStore.langPref == 'tr' ? 'Kaydet' : 'Save'}
        />
      </View>

      <Modal
        visible={loading}
        transparent={true}
      >
        <View
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Center>
            <Spinner
              size={'large'}
              color={colorSchema.mainColor}
              accessibilityLabel=''
            />
            <Text
              style={{
                fontFamily: 'Gilroy-Bold',
                fontSize: 21,
                color: colorSchema.mainColor,
              }}
            >
              {MainStore.auth ? `${MainStore.langPref == 'tr' ? 'Yükleniyor...' : 'Loading...'}` : 'Yükleniyor...'}
            </Text>
          </Center>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

export default observer(AddAddressGuest);
