import { View, Text, Image, ImageBackground, Dimensions } from 'react-native'
import React, { useState } from 'react'
import { SafeAreaView } from 'react-native'
import colorSchema from '../../config/colorSchema'
import { TouchableOpacity } from 'react-native'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react-lite'

const { width, height } = Dimensions.get('window')

const FirstLogin = ({ navigation }) => {
    const [step, setStep] = useState(1)
    return (
        step == 1
            ?

            <SafeAreaView style={{ flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity onPress={() => setStep(2)}>
                    <ImageBackground source={require('../../assets/image/FirstLogin.png')} style={{ width: 250, height: 225 }}>
                        <View style={{ position: 'absolute', bottom: 23, right: 30 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, color: colorSchema.mainColor }}>Yemek.Club</Text>
                        </View>
                    </ImageBackground>
                </TouchableOpacity>
            </SafeAreaView>
            :
            step == 2 ?
                <SafeAreaView style={{ flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => setStep(3)} style={{ width: 250, height: 225, borderRadius: 55, borderColor: colorSchema.textColor, borderWidth: 5, alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ alignItems: 'center', width: 200 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, textAlign: 'center', color: colorSchema.mainColor }}>Aramıza hoş geldin YemekClub'lı</Text>
                        </View>
                        <View style={{ alignItems: 'center', width: 200, marginTop: 17 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, textAlign: 'center', color: colorSchema.textColor }}>Seni burda görüğümüze göre tutarsız sepet tutarlarından sıkılmış olmalısın...</Text>
                        </View>
                    </TouchableOpacity>
                </SafeAreaView>
                :
                <SafeAreaView style={{ flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => { MainStore.changeFirstLogin() }} style={{ width: 250, height: 225, borderRadius: 55, borderColor: colorSchema.textColor, borderWidth: 5, alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ alignItems: 'center', width: 200 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, textAlign: 'center', color: colorSchema.textColor }}>Artık bütün siparişlerinde</Text>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, textAlign: 'center', color: colorSchema.mainColor }}>"Sen nasıl istersen!"</Text>
                        </View>
                        <View style={{ alignItems: 'center', width: 200, marginTop: 17 }}>
                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16, textAlign: 'center', color: colorSchema.mainColor }}>Yemek.Club <Text style={{ color: colorSchema.textColor }}> avantajlarıyla en sevdiğin yemekler uygun fiyata kapında!</Text></Text>
                        </View>
                    </TouchableOpacity>
                </SafeAreaView>
    )
}

export default observer(FirstLogin)