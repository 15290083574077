import * as React from "react";
import Svg, { Path } from "react-native-svg";

function TrashIcon(props) {
    return (
        <Svg
            width={props.width}
            height={props.height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M1.917 4.667V10.5a2.333 2.333 0 002.333 2.333h3.5a2.333 2.333 0 002.333-2.333V4.667m-2.916 1.75v3.5m-2.334-3.5v3.5m3.5-7l-.82-1.23a1.167 1.167 0 00-.97-.52H5.457c-.39 0-.755.195-.971.52l-.82 1.23m4.666 0H3.667m4.666 0h2.917m-7.583 0H.75"
                stroke="#1E1E1E"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default TrashIcon;