import {View, Text, Dimensions, SafeAreaView, TouchableOpacity, ScrollView} from 'react-native';
import React, {useState, useEffect} from 'react';
import {BottomSheet} from 'react-native-btr';
const {width, height} = Dimensions.get('window');
import {Shadow} from 'react-native-shadow-2';
import {Feather} from '@expo/vector-icons';
import RedButton from '../button/RedButton';
import colorSchema from '../../config/colorSchema';
import StarIconSort from '../../assets/svg/StarIconSort';
import HalfStarIconSort from '../../assets/svg/HalfStarIconSort';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
const FilterSheet = (props) => {
  const [selected, setSelected] = useState({
    category: '',
    rating: '',
    sort: '',
  });
  const [categoryList, setCategoryList] = useState(null);
  const [appReady, setAppReady] = useState(false);

  const toggleBottomNavigationView = () => {
    props.setVisible(!props.visible);
  };

  useEffect(() => {
    if (props.selectedFilter == 'category') {
      axios.get(`${axiosConfig.serverIp}/getCategory`).then((res) => {
        setCategoryList(res.data);
        setAppReady(true);
      });
    }
  }, []);

  if (!appReady && props.selectedFilter == 'category') {
    return null;
  }
  return (
    <View>
      <BottomSheet
        onBackButtonPress={toggleBottomNavigationView}
        onBackdropPress={toggleBottomNavigationView}
        visible={props.visible}
      >
        {props.selectedFilter == 'category' ? (
          <SafeAreaView style={{width: '100%', height: '90%', backgroundColor: 'white'}}>
            <Shadow
              style={{
                width,
                height: 57,
                flexDirection: 'row',
                marginBottom: 40,
                alignItems: 'center',
                backgroundColor: '#fff',
                justifyContent: 'space-between',
              }}
            >
              <View style={{marginLeft: 9, flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 24}}>
                    {MainStore.langPref == 'tr' ? 'Mutfak' : 'Cuisines'}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => toggleBottomNavigationView()}
                style={{marginRight: 24}}
              >
                <Feather
                  name='chevron-down'
                  color={'black'}
                  size={20}
                />
              </TouchableOpacity>
            </Shadow>

            <ScrollView>
              {categoryList?.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => setSelected((selected) => ({...selected, ...{category: item.name}}))}
                  style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>{item.name}</Text>
                  </View>

                  <TouchableOpacity
                    onPress={() => setSelected((selected) => ({...selected, ...{category: item.name}}))}
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#D3D6DB',
                      borderRadius: 50,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 16,
                    }}
                  >
                    <View
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: selected.category == item.name ? colorSchema.mainColor : '#D3D6DB',
                        borderRadius: 50,
                      }}
                    />
                  </TouchableOpacity>
                </TouchableOpacity>
              ))}

              <View style={{marginBottom: 30}} />
            </ScrollView>

            <View style={{position: 'absolute', bottom: 20, alignSelf: 'center'}}>
              <RedButton
                clickEvent={() => {
                  props.navigation.navigate('FilterRest', {selected});
                  toggleBottomNavigationView();
                }}
                text={MainStore.langPref == 'tr' ? 'Uygula' : 'Apply'}
              />
            </View>
          </SafeAreaView>
        ) : props.selectedFilter == 'sort' ? (
          <SafeAreaView style={{width: '100%', height: '90%', backgroundColor: 'white'}}>
            <Shadow
              style={{
                width,
                height: 57,
                flexDirection: 'row',
                marginBottom: 40,
                alignItems: 'center',
                backgroundColor: '#fff',
                justifyContent: 'space-between',
              }}
            >
              <View style={{marginLeft: 9, flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 24}}>
                    {MainStore.langPref == 'tr' ? 'Sıralama' : 'Sort'}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => toggleBottomNavigationView()}
                style={{marginRight: 24}}
              >
                <Feather
                  name='chevron-down'
                  color={'black'}
                  size={20}
                />
              </TouchableOpacity>
            </Shadow>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Sure'}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View>
                <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>Teslimat Süresi</Text>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Sure'}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.sort == 'Sure' ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Varsayilan'}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View>
                <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>Önerilen Sıralama (Varsayılan)</Text>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Varsayilan'}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.sort == 'Varsayilan' ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Alfabetik'}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View>
                <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>Alfabetik Sıralama</Text>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Alfabetik'}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.sort == 'Alfabetik' ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Degerlendirme'}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View>
                <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>En Fazla Değerlendirme Alanlar</Text>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{sort: 'Degerlendirme'}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.sort == 'Degerlendirme' ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <View style={{position: 'absolute', bottom: 20, alignSelf: 'center'}}>
              <RedButton
                clickEvent={() => {
                  props.navigation.navigate('FilterRest', {selected});
                  toggleBottomNavigationView();
                }}
                text={MainStore.langPref == 'tr' ? 'Uygula' : 'Apply'}
              />
            </View>
          </SafeAreaView>
        ) : props.selectedFilter == 'rating' ? (
          <SafeAreaView style={{width: '100%', height: '90%', backgroundColor: 'white'}}>
            <Shadow
              style={{
                width,
                height: 57,
                flexDirection: 'row',
                marginBottom: 40,
                alignItems: 'center',
                backgroundColor: '#fff',
                justifyContent: 'space-between',
              }}
            >
              <View style={{marginLeft: 9, flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 24}}>
                    {MainStore.langPref == 'tr' ? 'Restoran Puanı' : 'Top Rated Restaurants'}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => toggleBottomNavigationView()}
                style={{marginRight: 24}}
              >
                <Feather
                  name='chevron-down'
                  color={'black'}
                  size={20}
                />
              </TouchableOpacity>
            </Shadow>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{rating: 3}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View style={{flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>3.0 ve Üstü</Text>
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
              </View>

              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{rating: 3}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.rating == 3 ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{rating: 3.5}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View style={{flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>3.5 ve Üstü</Text>
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <HalfStarIconSort />
                </View>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{rating: 3.5}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.rating == 3.5 ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{rating: 4}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View style={{flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>4.0 ve Üstü</Text>
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{rating: 4}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.rating == 4 ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => setSelected((selected) => ({...selected, ...{rating: 4.5}}))}
              style={{flexDirection: 'row', marginLeft: 12, justifyContent: 'space-between', marginBottom: 26}}
            >
              <View style={{flexDirection: 'row'}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>4.5 ve Üstü</Text>
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <StarIconSort />
                </View>
                <View style={{marginLeft: 8}}>
                  <HalfStarIconSort />
                </View>
              </View>
              <TouchableOpacity
                onPress={() => setSelected((selected) => ({...selected, ...{rating: 4.5}}))}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#D3D6DB',
                  borderRadius: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: selected.rating == 4.5 ? colorSchema.mainColor : '#D3D6DB',
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            </TouchableOpacity>

            <View style={{position: 'absolute', bottom: 20, alignSelf: 'center'}}>
              <RedButton
                clickEvent={() => {
                  props.navigation.navigate('FilterRest', {selected});
                  toggleBottomNavigationView();
                }}
                text={MainStore.langPref == 'tr' ? 'Uygula' : 'Apply'}
              />
            </View>
          </SafeAreaView>
        ) : (
          <></>
        )}
      </BottomSheet>
    </View>
  );
};

export default observer(FilterSheet);
