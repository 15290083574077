import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SeachIcon2(props) {
    return (
        <Svg
            width={22}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M21 21l-2-2M1 10.5a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z"
                stroke="#1E1E1E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default SeachIcon2