import * as React from "react"
import { Svg, Path } from 'react-native-svg'
const RepeatIcon = (props) => (
    <Svg
        width={18}
        height={18}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M16.5 9a7.5 7.5 0 1 1-1.003-3.75m0 0V1.5m0 3.75h-3.684"
            stroke="#1E1E1E"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default RepeatIcon
