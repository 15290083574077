import * as React from "react";
import Svg, { Path } from "react-native-svg";
import colorSchema from "../../config/colorSchema";

function MapPinIcon2(props) {
    return (
        <Svg
            width={22}
            height={27}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M3.538 25.391a.75.75 0 000 1.5v-1.5zm15.077 1.5a.75.75 0 100-1.5v1.5zm1.135-16.455c0 2.21-1.403 5.026-3.305 7.342-.938 1.143-1.965 2.123-2.937 2.81-.996.704-1.84 1.034-2.431 1.034v1.5c1.058 0 2.216-.546 3.296-1.309 1.104-.78 2.227-1.858 3.232-3.083 1.985-2.418 3.645-5.57 3.645-8.294h-1.5zm-8.673 11.186c-.576 0-1.413-.346-2.414-1.097-.973-.731-2.002-1.771-2.943-2.977-1.907-2.442-3.316-5.383-3.316-7.6h-1.5c0 2.716 1.653 5.987 3.633 8.523 1.003 1.285 2.124 2.426 3.226 3.253 1.076.808 2.24 1.398 3.314 1.398v-1.5zM2.404 9.947c0-4.684 4.16-8.184 8.673-8.184v-1.5C5.875.263.904 4.302.904 9.947h1.5zm8.673-8.184c4.485 0 8.673 3.474 8.673 8.673h1.5C21.25 4.327 16.306.263 11.077.263v1.5zm3.02 8.045a3.02 3.02 0 01-3.02 3.019v1.5a4.52 4.52 0 004.52-4.52h-1.5zm-3.02 3.019a3.02 3.02 0 01-3.02-3.02h-1.5a4.52 4.52 0 004.52 4.52v-1.5zm-3.02-3.02a3.02 3.02 0 013.02-3.018v-1.5a4.52 4.52 0 00-4.52 4.519h1.5zm3.02-3.018a3.02 3.02 0 013.02 3.019h1.5a4.52 4.52 0 00-4.52-4.52v1.5zM3.538 26.89h15.077v-1.5H3.538v1.5z"
                fill={props.selected ? colorSchema.mainColor : "#1E1E1E"}
            />
        </Svg>
    );
}

export default MapPinIcon2;