import * as React from "react"
import { Svg, Path } from 'react-native-svg'
const PlusIcon = (props) => (
    <Svg
        width={props.width}
        height={props.height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M3.833 8.5a1.167 1.167 0 1 0 2.334 0V6.167H8.5a1.167 1.167 0 1 0 0-2.334H6.167V1.5a1.167 1.167 0 1 0-2.333 0v2.333H1.5a1.167 1.167 0 0 0 0 2.334h2.333V8.5Z"
            fill="#1E1E1E"
        />
    </Svg>
)

export default PlusIcon
