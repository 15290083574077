import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import React, { useEffect } from 'react'
import colorSchema from '../../config/colorSchema'
import HomeIcon from '../../assets/svg/HomeIcon'
import { observer } from 'mobx-react-lite'
import TakeAwayIcon from '../../assets/svg/TakeAwayIcon'
import LastOrderIcon from '../../assets/svg/LastOrderIcon'
import ProfileIcon from '../../assets/svg/ProfileIcon'
import MainStore from '../../store/MainStore'
import MapPinIcon2 from '../../assets/svg/MapPinIcon2'
import WorldIcon from '../../assets/svg/WorldIcon'
import { useRoute } from '@react-navigation/native'
const WebHeader = (props) => {
    const route = useRoute()
    const isHome = route.name == 'Home'
    const isTakeAway = route.name == 'TakeAway'
    const isTakeAwayRest = route.name == 'TakeAwayRestorant'
    const isLastOrder = route.name == 'LastOrder'
    const isLastOrderDetail = route.name == 'LastOrderDetail'
    const isProfile = route.name == 'Profile'
    const isMyAddress = route.name == 'MyAddress'
    const isLanguage = route.name == 'Language'
    return (

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 17, backgroundColor: 'white' }}>
                <TouchableOpacity onPress={() => MainStore.auth ? props.navigation.navigate('Home') : props.navigation.navigate('Splash')}>
                    <Text style={{ color: colorSchema.mainColor, fontSize: 38, fontFamily: 'Gilroy-Bold' }}>Yemek.Club</Text>
                </TouchableOpacity>
                {
                    MainStore.auth ?
                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => props.navigation.navigate('Home')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <HomeIcon selected={isHome ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.langPref == 'tr' ? 'Ana Sayfa' : 'Home'}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => props.navigation.navigate('TakeAway')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <TakeAwayIcon selected={isTakeAway ? true : isTakeAwayRest ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.langPref == 'tr' ? 'Gel-Al' : 'Take-Away'}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => props.navigation.navigate('LastOrder')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <LastOrderIcon selected={isLastOrder ? true : isLastOrderDetail ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.langPref == 'tr' ? 'Geçmiş' : 'Last Order'}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => props.navigation.navigate('Profile')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <ProfileIcon selected={isProfile ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.myData.name}</Text>
                                </View>
                            </TouchableOpacity>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => {
                                    if (!MainStore.guestLogin)
                                        props.navigation.navigate('MyAddress')
                                }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <View style={{ marginRight: 7 }}>
                                            <MapPinIcon2 selected={isMyAddress ? true : false} />
                                        </View>
                                        <View>
                                            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.selectedAddress.title}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <TouchableOpacity onPress={() => props.navigation.navigate('Language')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <WorldIcon selected={isLanguage ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{MainStore.langPref.toUpperCase()}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        :


                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => props.navigation.navigate('SignIn')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <ProfileIcon selected={isHome ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>Giriş Yap</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => props.navigation.navigate('Register')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                <View style={{ marginRight: 4 }}>
                                    <ProfileIcon selected={isHome ? true : false} />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>Üye Ol</Text>
                                </View>
                            </TouchableOpacity>

                        </View>
                }
            </View>
    )
}

export default observer(WebHeader)