import {View, Text, StyleSheet, Dimensions, ScrollView} from 'react-native';
import React from 'react';
import {SafeAreaView} from 'react-native';
import colorSchema from '../../config/colorSchema';
import LogoHeader from '../../components/header/LogoHeader';
import WebFooter from '../../components/footer/WebFooter';
import WebHeaderB1 from '../../components/header/WebHeaderB1';
import LogoBackHeader from '../../components/header/LogoBackHeader';

const {width, height} = Dimensions.get('window');

const AboutUs = ({navigation, route}) => {
  return (
    <View
      showsVerticalScrollIndicator={false}
      style={{backgroundColor: 'white'}}
    >
      <SafeAreaView style={{}}>
        <View>
          {width > 1279 ? <WebHeaderB1 navigation={navigation} /> : <LogoBackHeader navigation={navigation} />}
        </View>

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginBottom: 120}}
        >
          <View style={{width: width > 1279 ? 894 : width - 34, marginLeft: width > 1279 ? 80 : 17}}>
            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Hikayemiz
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Hikayemiz aslında uzun yıllara dayanan köklü Türk yemek kültürü ile başlar. Bizim de amacımız Türk ve
                  diğer yemek kültürlerini sizlere en uygun fiyatla sunabilmek için aracı olmaktır.
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Biz de bu yola çıkarken en uygun fiyatı sunacağımız sistemimizle semtinizdeki restoran iş
                  ortaklarımızın da karlılığını düşünerek bir ilke imza atıyoruz. Türkiye’nin en uygun fiyatlı online
                  yemek sipariş uygulaması olarak sipariş ettiğiniz yemekleri güvenilir bir şekilde sizlere sunuyoruz.
                  Tüketicimizin Ne İstediğini Biliyoruz
                </Text>
              </View>
              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Kullanıcı dostu arayüzü ile yemek siparişi vermenizi çok kolaylaştırdık. Dilediğiniz yemeği binlerce
                  seçenek arasından saniyeler içinde bulabilirsiniz. Yemeğinizi bulduktan sonra da gerisini bize
                  bırakın! Kapıda veya online ödedikten sonra hızlı servis sistemimizle en sevdiğiniz restoran anında
                  kapınızda belirebilir!
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Değerlerimiz
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Bizim için dürüstlük ve güvenilirlik en önemli erdemdir. Bu nedenle kullanıcılarımıza yemek
                  seçenekleri sunarken adil olmayan fiyatlandırmaya karşı çıkarak hem işletme dostu hem de tüketici
                  dostu olmayı hedefliyoruz. Yoğun denetleme sistemimizle de her restoranın uygun fiyatı verdiğinden ve
                  hijyen kurallarına dikkat ettiğinden emin oluyoruz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Güvenilir ve Hızlı Teslimat
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Siparişiniz yola çıktıktan sonra kuryeler yemeklerinizi en hızlı bir şekilde size ulaştırdıktan sonra
                  sıcacık yemeğinizin keyfini sürebilirsiniz!
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Müşteri Memnuniyeti
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  İş ortaklarımızla yapacağımız sıkı denetleme ile sizlerin şikayetleri ve önerileri ile iş
                  ortaklarımızla birlikte çözümler geliştirerek size daha iyi bir hizmet sunmak için her zaman
                  çalışacağız.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Restoran İş Ortaklarımızın Memnuniyeti
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Esnaflarımızın ve diğer iş ortaklarımızın karlılığını ön plana koyarak her bir işletme için uygun iş
                  planını hazırlıyoruz. Böylelikle restoran iş ortaklarımız müşterisine daha hızlı ulaşırken onlara daha
                  uygun fiyat sunabiliyor. Ödeme Seçenekleri İster kapıda ister online ödeme yaparak dilediğiniz yemeğin
                  tadını anında çıkarabilirsiniz! YemekClub, anında uygun fiyat
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  İletişim Bilgileri
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Adres: Altındağ Mh 100.Yıl Bulvarı No:12 CityPlaza Kat:5 D:23 Muratpaşa/ANTALYA
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  E-Posta: info@yemek.club
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Müşteri hizmetleri: 0850 888 0707
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Ticari Ünvan: Loc Group Bilişim A.Ş
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Mersis No:
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Sicil No:
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Vergi Dairesi / No:
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      <View style={{marginTop: 34}}>
        <WebFooter navigation={navigation} />
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  headText: {fontFamily: 'Gilroy-Bold', fontSize: 20, color: colorSchema.mainColor},
  descText: {fontFamily: 'Gilroy-Regular', fontSize: 15},
});

export default AboutUs;
