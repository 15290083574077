import * as React from "react";
import Svg, { Path } from "react-native-svg";

function PayDoorIcon(props) {
    return (
        <Svg
            width={30}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M3.791 12.5H26.21c1.275 0 1.776-1.69.715-2.412L16.43 2.942a2.533 2.533 0 00-2.86 0L3.076 10.088c-1.06.723-.56 2.412.715 2.412zM5 12.5h6.25v10H5zM18.75 12.5H25v10h-6.25zM24.382 22.5a1 1 0 01.894.553l1.5 3a1 1 0 01-.894 1.447H4.118a1 1 0 01-.894-1.447l1.5-3a1 1 0 01.894-.553h18.764z"
                stroke="#1E1E1E"
                strokeWidth={1.5}
            />
        </Svg>
    );
}

export default PayDoorIcon;