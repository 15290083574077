import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Image,
  ScrollView,
  TextInput,
  ImageBackground,
  StatusBar,
  Platform,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import LogoHeader from '../../components/header/LogoHeader';
import {Feather} from '@expo/vector-icons';
import HomeTitle from '../../components/title/HomeTitle';
import Top10Food from '../../components/card/Top10Food';
import PopularRest from '../../components/card/PopularRest';
import HomeFoodCard from '../../components/card/HomeFoodCard';
import AllRestaurantCard from '../../components/card/AllRestaurantCard';
import CartSheet from '../../components/cart/CartSheet';
import FilterSheet from '../../components/bottomsheet/FilterSheet';
import SearchBox from '../../components/search/SearchBox';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import colorSchema from '../../config/colorSchema';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import RenderHTML from 'react-native-render-html';
import FilterBox from '../../components/filter/FilterBox';
import MapPinIcon from '../../assets/svg/MapPinIcon';
import FilterBoxWater from '../../components/filter/FilterBoxWater';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon';
import WaterDealerCard from '../../components/card/WaterDealerCard';
import WebHeader from '../../components/header/WebHeader';
import RedButton from '../../components/button/RedButton';
import WebCartButton from '../../components/cart/WebCartButton';
import {useToast} from '@gluestack-ui/themed';
const {width, height} = Dimensions.get('window');

const Home = ({navigation, route}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [appReady, setAppReady] = useState(false);
  const [highDiscountRestaurant, setHighDiscountRestaurant] = useState(null);
  const [popularRest, setPopularRest] = useState(null);
  const [under60Food, setUnder60Food] = useState(null);
  const [randomFood, setRandomFood] = useState(null);
  const [emptyData, setEmptyData] = useState(false);
  const [allRest, setAllRest] = useState(null);
  const [foodScreen, setFoodScreen] = useState(true);
  const [waterData, setWaterData] = useState(null);
  const [waterSelected, setWaterSelected] = useState({});
  const toast = useToast();
  useEffect(() => {
    axios
      .post(`${axiosConfig.serverIp}/getHomePost`, {
        province: MainStore.selectedAddress.province,
        district: MainStore.selectedAddress.district,
        neighbourhood: MainStore.selectedAddress.neighbourhood,
        favoriteRestaurant: MainStore?.myData?.favoriteRestaurant ? MainStore.myData.favoriteRestaurant : [],
      })
      .then((res) => {
        if (res.data.error) {
          setEmptyData(true);
          setAppReady(true);
        } else {
          setHighDiscountRestaurant(res.data.highDiscountRestaurant);
          setPopularRest(res.data.popularRest);
          setUnder60Food(res.data.under60Food);
          setRandomFood(res.data.randomFood);
          setWaterData(res.data.waterDealer);
          setAllRest(res.data.allRest);
          MainStore.updateFavoriteList(res.data.favoriteRestaurant);
          setAppReady(true);
        }
      })
      .catch((err) => {
        alert('Bir hata oluştu lütfen daha sonra tekrar deneyiniz');
      });
    return () => {
      setAppReady(false);
      setEmptyData(false);
      setHighDiscountRestaurant(null);
      setPopularRest(null);
      setUnder60Food(null);
      setRandomFood(null);
      setAllRest(null);
    };
  }, [MainStore.selectedAddress.neighbourhood]);

  return appReady ? (
    !emptyData ? (
      foodScreen ? (
        <SafeAreaView style={style.main}>
          <ScrollView>
            <View
              style={{
                marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0,
              }}
            >
              {width > 1279 ? (
                <View
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 999,
                  }}
                >
                  <WebHeader navigation={navigation} />
                </View>
              ) : (
                <View style={style.logoArea}>
                  <View>
                    <TouchableOpacity>
                      <LogoHeader />
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    style={{alignItems: 'center'}}
                    onPress={() => {
                      if (!MainStore.guestLogin) navigation.navigate('MyAddress');
                    }}
                  >
                    <View style={style.addressArea}>
                      <View style={{marginRight: 7}}>
                        <MapPinIcon />
                      </View>
                      <View>
                        <Text style={style.addressText}>{MainStore.selectedAddress.title}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              )}

              {width > 1279 ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <TouchableOpacity>
                    <SearchBox navigation={navigation} />
                  </TouchableOpacity>
                  {MainStore.cartItem?.length > 0 ? (
                    <View>
                      <WebCartButton navigation={navigation} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <TouchableOpacity>
                  <SearchBox navigation={navigation} />
                </TouchableOpacity>
              )}

              <View style={{paddingBottom: 10, flexDirection: 'row'}}>
                {width > 1279 ? (
                  <></>
                ) : (
                  <TouchableOpacity
                    onPress={() => setFoodScreen(!foodScreen)}
                    style={{
                      paddingHorizontal: 34,
                      paddingVertical: 5,
                      backgroundColor: colorSchema.waterColor,
                      borderBottomEndRadius: 20,
                      borderTopEndRadius: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Bold',
                        fontSize: 15,
                        color: 'white',
                      }}
                    >
                      Su
                    </Text>
                  </TouchableOpacity>
                )}
                <View>
                  <FilterBox
                    setShowFilter={setShowFilter}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </View>
              </View>

              <ScrollView showsVerticalScrollIndicator={false}>
                {highDiscountRestaurant?.length > 0 ? (
                  <View style={{marginTop: 20}}>
                    <View>
                      <HomeTitle
                        food={false}
                        data={highDiscountRestaurant}
                        navigation={navigation}
                        text={
                          MainStore.langPref == 'tr' ? '🔥 Kampanyalı Restoranlar' : '🔥 Restaurants With Campaigns'
                        }
                      />
                    </View>
                    <View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false}
                      >
                        {highDiscountRestaurant.map((item, index) => (
                          <View key={item._id}>
                            <PopularRest
                              restaurantOpen={item.visible}
                              navigation={navigation}
                              deliveryTime={item.deliveryTime}
                              minPrice={item.minPrice}
                              id={item._id}
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              district={item.district}
                              restaurantName={item.restaurantName}
                              restaurantCoverPhoto={item.restaurantCoverPhoto}
                              campaign={item?.campaign}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {randomFood?.length > 0 ? (
                  <View style={{marginTop: 21}}>
                    <View>
                      <HomeTitle
                        food={true}
                        data={randomFood}
                        navigation={navigation}
                        text={MainStore.langPref == 'tr' ? 'Şaşırt Beni' : 'Surprise Me'}
                      />
                    </View>
                    <View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false}
                      >
                        {randomFood.map((item, index) => (
                          <View key={item._id}>
                            <HomeFoodCard
                              restaurantOpen={item.restaurantOpen}
                              discount={item?.discount}
                              discountType={item?.discountType}
                              navigation={navigation}
                              id={item._id}
                              restaurantName={item.restaurantName}
                              price={
                                typeof price == 'number' ? item.price.toFixed(2) : parseFloat(item.price).toFixed(2)
                              }
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              name={item.name}
                              foodImage={item.foodImage}
                              deliveryTime={item.deliveryTime}
                              minPrice={item.minPrice}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {popularRest?.length > 0 ? (
                  <View style={{marginTop: 21}}>
                    <View>
                      <HomeTitle
                        data={popularRest}
                        navigation={navigation}
                        food={false}
                        text={MainStore.langPref == 'tr' ? 'En Popüler Restoranlar' : 'Most Popular Restaurants'}
                      />
                    </View>
                    <View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false}
                      >
                        {popularRest.map((item, index) => (
                          <View key={item._id}>
                            <PopularRest
                              restaurantOpen={item.visible}
                              navigation={navigation}
                              deliveryTime={item.deliveryTime}
                              minPrice={item.minPrice}
                              id={item._id}
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              district={item.district}
                              restaurantName={item.restaurantName}
                              restaurantCoverPhoto={item.restaurantCoverPhoto}
                              campaign={item?.campaign}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {under60Food?.length > 0 ? (
                  <View style={{marginTop: 21}}>
                    <View>
                      <HomeTitle
                        food={true}
                        data={under60Food}
                        navigation={navigation}
                        text={MainStore.langPref == 'tr' ? '120TL ve Altı Menüler' : '120 TL and Under Menus'}
                      />
                    </View>
                    <View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false}
                      >
                        {under60Food.map((item, index) => (
                          <View key={item._id}>
                            <HomeFoodCard
                              restaurantOpen={item.restaurantOpen}
                              discount={item?.discount}
                              discountType={item?.discountType}
                              navigation={navigation}
                              id={item._id}
                              restaurantName={item.restaurantName}
                              price={item.price}
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              name={item.name}
                              foodImage={item.foodImage}
                              deliveryTime={item.deliveryTime}
                              minPrice={item.minPrice}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {MainStore?.myData?.favoriteRestaurant?.length > 0 ? (
                  <View style={{marginTop: 21}}>
                    <View>
                      <HomeTitle
                        data={popularRest}
                        navigation={navigation}
                        food={false}
                        text={MainStore.langPref == 'tr' ? 'Favorilerim' : 'My Favorites'}
                      />
                    </View>
                    <View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false}
                      >
                        {MainStore?.myData?.favoriteRestaurant?.map((item, index) => (
                          <View key={item._id}>
                            <PopularRest
                              restaurantOpen={item.visible}
                              navigation={navigation}
                              deliveryTime={item.deliveryTime}
                              minPrice={item.minPrice}
                              id={item._id}
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              district={item.district}
                              restaurantName={item.restaurantName}
                              restaurantCoverPhoto={item.restaurantCoverPhoto}
                              campaign={item?.campaign}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {allRest?.length > 0 ? (
                  <View style={{marginTop: 21, marginBottom: 70}}>
                    <View style={{marginLeft: 5, marginBottom: 12}}>
                      <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 16}}>
                        {MainStore.langPref == 'tr' ? 'Tüm Restoranlar' : 'All Restaurants'}
                      </Text>
                    </View>
                    <View>
                      <ScrollView
                        showsHorizontalScrollIndicator={width > 500 ? true : false}
                        horizontal={width > 500 ? true : false}
                      >
                        {allRest.map((item, index) => (
                          <View key={item._id}>
                            <AllRestaurantCard
                              restaurantOpen={item.visible}
                              minPrice={item.minPrice}
                              deliveryTime={item.deliveryTime}
                              navigation={navigation}
                              id={item._id}
                              category={item.category}
                              comments={item.comments.length}
                              rating={item.rating}
                              district={item.district}
                              restaurantName={item.restaurantName}
                              restaurantCoverPhoto={item.restaurantCoverPhoto}
                              campaign={item?.campaign}
                            />
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
              </ScrollView>
              {showFilter ? (
                <FilterSheet
                  selectedFilter={selectedFilter}
                  data={allRest}
                  sort={selectedFilter == 'sort' ? true : false}
                  category={selectedFilter == 'category' ? true : false}
                  navigation={navigation}
                  visible={showFilter}
                  setVisible={setShowFilter}
                />
              ) : (
                <></>
              )}
            </View>
          </ScrollView>
          <CartSheet
            foodScreen={foodScreen}
            navigation={navigation}
          />
          {width > 1279 ? (
            <TouchableOpacity
              onPress={() => setFoodScreen(!foodScreen)}
              style={{
                paddingVertical: 10,
                paddingHorizontal: 23,
                backgroundColor: '#4869F3',
                borderRadius: 20,
                transform: 'rotate(-90deg)',
                width: 184,
                position: 'absolute',
                bottom: 80,
                right: -40,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: 20,
                  color: 'white',
                }}
              >
                Yemek.Club Su
              </Text>
            </TouchableOpacity>
          ) : (
            <></>
          )}
        </SafeAreaView>
      ) : (
        <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
          <View style={style.logoArea}>
            <View>
              <TouchableOpacity>
                <LogoHeader water={true} />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => {
                if (!MainStore.guestLogin) navigation.navigate('MyAddress');
              }}
            >
              <View style={style.addressArea}>
                <View style={{marginRight: 7}}>
                  <MapPinIcon />
                </View>
                <View>
                  <Text style={style.addressText}>{MainStore.selectedAddress.title}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          <TouchableOpacity>
            <SearchBox navigation={navigation} />
          </TouchableOpacity>

          <View style={{paddingBottom: 10, flexDirection: 'row'}}>
            <TouchableOpacity
              onPress={() => setFoodScreen(!foodScreen)}
              style={{
                paddingHorizontal: 20,
                paddingVertical: 5,
                backgroundColor: colorSchema.mainColor,
                borderBottomEndRadius: 20,
                borderTopEndRadius: 20,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: 15,
                  color: 'white',
                }}
              >
                Yemek
              </Text>
            </TouchableOpacity>
            <View>
              <FilterBoxWater
                setShowFilter={setShowFilter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </View>
          </View>

          <View>
            {waterData.length > 0 ? (
              waterData.map((item, index) => (
                <WaterDealerCard
                  navigation={navigation}
                  key={index}
                  waterSelected={waterSelected}
                  setWaterSelected={setWaterSelected}
                  dealerName={item.dealerName}
                  indexNumber={index}
                  district={item.district}
                  waterData={item.foodList}
                />
              ))
            ) : (
              <View style={{marginTop: 50, alignItems: 'center'}}>
                <View>
                  <Image
                    source={require('../../assets/image/emptyRest.png')}
                    style={style.emptyImage}
                  />
                </View>
                <View style={{marginVertical: 17}}>
                  <Text style={style.emptyMainText}>Aramıza hosgeldin Yemek.Club’lı!</Text>
                </View>
                <View style={{width: width / 1.5}}>
                  <Text style={style.emptyDescText}>
                    {MainStore.langPref == 'tr'
                      ? 'Bölgendeki bayiler, çok yakında hizmetinde olacaklar.'
                      : 'There are no restaurants at your location.'}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <CartSheet
            foodScreen={foodScreen}
            navigation={navigation}
          />
        </SafeAreaView>
      )
    ) : (
      <SafeAreaView style={style.emptyMain}>
        {width > 1279 ? <WebHeader navigation={navigation} /> : <></>}
        <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
          <View>
            <Image
              source={require('../../assets/image/emptyRest.png')}
              style={style.emptyImage}
            />
          </View>
          <View style={{marginVertical: 17}}>
            {MainStore.langPref == 'tr' ? (
              <Text style={style.emptyMainText}>
                Aramıza hoşgeldin <Text style={{color: colorSchema.mainColor}}>Yemek.Club</Text>
                'lı!
              </Text>
            ) : (
              <Text style={style.emptyMainText}>
                Welcome to <Text style={{color: colorSchema.mainColor}}>Yemek.Club</Text>
              </Text>
            )}
          </View>
          <View style={{width: width / 1.5}}>
            <Text style={style.emptyDescText}>
              {MainStore.langPref == 'tr'
                ? 'Bölgendeki restoranlar, çok yakında hizmetinde olacaklar.'
                : 'Restaurants in your area will be at your service very soon.'}
            </Text>
          </View>
          <View style={{width: width / 1.5, marginTop: 17}}>
            {MainStore.langPref == 'tr' ? (
              <Text style={style.emptyDescText}>
                Burada her şey <Text style={{fontFamily: 'Gilroy-Bold'}}>sen nasıl istersen!</Text> 🫶🏼
              </Text>
            ) : (
              <Text style={[style.emptyDescText, {color: colorSchema.mainColor}]}>
                Yemek.Club /{' '}
                <Text
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    color: 'rgba(30,30,30,0.8)',
                  }}
                >
                  Whatever you like 🫶🏼
                </Text>
              </Text>
            )}
          </View>
          <View style={{marginTop: 17}}>
            <RedButton
              clickEvent={() => {
                MainStore.guestLogin ? MainStore.clearAddressGuest() : navigation.navigate('MyAddress');
              }}
              text={MainStore.langPref == 'tr' ? 'Adresimi Değiştir' : 'Change My Address'}
            />
          </View>
        </View>
      </SafeAreaView>
    )
  ) : (
    <LoadingSpinner />
  );
};

const style = StyleSheet.create({
  main: {flex: 1, backgroundColor: 'white'},
  logoArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  addressArea: {flexDirection: 'row', alignItems: 'center', marginRight: 15},
  addressText: {fontFamily: 'Gilroy-Medium', fontSize: 18},
  emptyMain: {flex: 1, backgroundColor: 'white'},
  emptyImage: {width: 93, height: 93},
  emptyMainText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: colorSchema.textColor,
  },
  emptyDescText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    color: 'rgba(30, 30, 30, 0.8)',
    textAlign: 'center',
  },
});

export default observer(Home);
