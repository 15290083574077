import { View, Text, SafeAreaView, Image } from 'react-native'
import React from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import StarIcon from '../../assets/svg/StarIcon'
import StarIconEmpty from '../../assets/svg/StarIconEmpty'

const CommentCard = (props) => {
    return (
        <View style={{ marginTop: 25, marginLeft: 25 }}>
            <View>
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 10, color: colorSchema.textColor, marginLeft: 35 }}>{`${props.name}*** ${props.surName}***`}</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                <View style={{ flexDirection: 'row', marginLeft: 4, alignItems: 'center' }}>

                    <View style={{ marginRight: 4 }}>
                        <Image source={require('../../assets/image/userCircle.png')} style={{ width: 26, height: 26 }} />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ marginHorizontal: 2 }}>
                            <StarIcon />
                        </View>
                        <View style={{ marginHorizontal: 2 }}>
                            {props.review > 1 ? <StarIcon /> : <StarIconEmpty />}
                        </View>
                        <View style={{ marginHorizontal: 2 }}>
                            {props.review > 2 ? <StarIcon /> : <StarIconEmpty />}
                        </View>
                        <View style={{ marginHorizontal: 2 }}>
                            {props.review > 3 ? <StarIcon /> : <StarIconEmpty />}
                        </View>
                        <View style={{ marginHorizontal: 2 }}>
                            {props.review > 4 ? <StarIcon /> : <StarIconEmpty />}
                        </View>
                    </View>

                </View>
                <View style={{ marginRight: 30 }}>
                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 12, color: colorSchema.textColor }}>{props.date}</Text>
                </View>
            </View>
            <View style={{ marginTop: 5, marginLeft: 3 }}>
                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 10, color: colorSchema.textColor }}>{props.comment}</Text>
            </View>
            {props.restComment ?
                <View style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ backgroundColor: colorSchema.mainColor, width: 23, height: 28, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 23 }}>
                            <Text style={{ color: 'white', fontFamily: 'Gilroy-ExtraBold', fontSize: 21 }}>R</Text>
                        </View>
                        <View style={{ marginTop: 10, marginLeft: 15 }}>
                            <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 10, color: colorSchema.textColor }}>Çok teşekkür ederiz, afiyet olsun.</Text>
                        </View>
                    </View>
                </View>
                :
                <></>
            }


        </View>
    )
}

export default CommentCard