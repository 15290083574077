import { View, Text, TouchableOpacity, Image, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
import RIcon from '../../assets/svg/RIcon'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'

const { width, height } = Dimensions.get('window')

const FilterCard = (props) => {
    return (
        <TouchableOpacity onPress={() => props.navigation.navigate('Restaurant', { id: props.id })} style={{ borderBottomWidth: 0.3, borderBottomColor: '#6B6565', paddingBottom: 18, backgroundColor: colorSchema.cartColor, width: width > 1279 ? 379 : width, marginRight: width > 1279 ? 13 : 0 }}>
            <View>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Image source={{ uri: props.restaurantCoverPhoto }} style={{ width: width > 600 ? 500 : width - 10, alignSelf: 'center', height: 127 }} />

                </View>
            </View>
            <View style={{ marginLeft: 10, marginTop: 9, width: width > 1279 ? 1279 / 3 - 28 : width - 28 }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ marginLeft: 14 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <View style={{ marginRight: 4 }}>
                                    <RIcon />
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: 14, color: colorSchema.textColor }}>{props.restaurantName} <Text style={{ fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.4)' }}>({props.district})</Text></Text>

                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontSize: 10, fontWeight: '400', lineHeight: 14 }}>{`${props.deliveryTime} dk · Min ${props.minPrice} TL`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                            <View style={{ marginRight: 6 }}>
                                <Image source={require('../../assets/image/star.png')} style={{ height: 12.5, width: 12.5 }} />
                            </View>
                            <View>
                                <Text style={{ fontSize: 12, fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.5)' }}>{props.rating} <Text style={{ color: 'rgba(0,0,0,0.25)' }}>({props.comments})</Text></Text>
                            </View>
                        </View>
                    </View>


                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 14 }}>
                    <View>
                        <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 10 }}>{props.category}</Text>
                    </View>
                    <View style={{ marginRight: 4 }}>
                        <TouchableOpacity onPress={() => props.navigation.navigate('Restaurant', { id: props.id })} style={{ backgroundColor: colorSchema.greenColor, paddingHorizontal: 8, paddingVertical: 3, borderRadius: 20 }}>
                            <Text style={{ color: 'white', fontSize: 15, fontFamily: 'Gilroy-Regular' }}>{MainStore.langPref == 'tr' ? 'Hemen Git' : 'Go Now'}</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </TouchableOpacity>
    )
}

export default observer(FilterCard)