import * as React from "react";
import Svg, { Path } from "react-native-svg";

function StarIconSort(props) {
    return (
        <Svg
            width={20}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8.033 1.271a2.158 2.158 0 013.934 0l1.39 2.93c.32.673.938 1.14 1.653 1.248l3.11.47c1.799.272 2.517 2.57 1.215 3.89l-2.25 2.28a2.338 2.338 0 00-.631 2.02l.531 3.22c.308 1.863-1.573 3.284-3.183 2.404l-2.781-1.52a2.12 2.12 0 00-2.042 0l-2.781 1.52c-1.61.88-3.49-.541-3.183-2.404l.531-3.22a2.338 2.338 0 00-.63-2.02L.664 9.81C-.637 8.49.08 6.191 1.88 5.92l3.11-.47A2.202 2.202 0 006.641 4.2l1.39-2.93z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default StarIconSort;