import { View, Text, SafeAreaView, TouchableOpacity, StyleSheet, Image } from 'react-native'
import React from 'react'
import LogoHeader from './LogoHeader'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import MapPinIcon from '../../assets/svg/MapPinIcon'

const HeaderSheet = (props) => {
    return (
        <SafeAreaView style={{ backgroundColor: 'rgba(0,0,0,0.25)' }}>
            <TouchableOpacity onPress={() => props.navigation.navigate('Home')}>
                <View style={style.logoArea}>
                    <View>
                        <LogoHeader />
                    </View>
                    <View>
                        <View style={style.addressArea}>
                            <View style={{ marginRight: 7 }}>
                                <MapPinIcon />
                            </View>
                            <View>
                                <Text style={style.addressText}>{MainStore.selectedAddress.title}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </SafeAreaView>
    )
}
const style = StyleSheet.create({
    main: { flex: 1, backgroundColor: 'white' },
    logoArea: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0 },
    addressArea: { flexDirection: 'row', alignItems: 'center', marginRight: 15 },
    addressText: { fontFamily: 'Gilroy-Medium', fontSize: 18 },
})

export default observer(HeaderSheet)