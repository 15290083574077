import {View, Text, StyleSheet, Dimensions, Modal, TextInput, Image} from 'react-native';
import React, {useState, useEffect} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import colorSchema from '../../config/colorSchema';
import LogoHeader from '../../components/header/LogoHeader';
import WebFooter from '../../components/footer/WebFooter';
import RedButton from '../../components/button/RedButton';
import {
  Select,
  SelectScrollView,
  SelectTrigger,
  SelectInput,
  SelectIcon,
  SelectPortal,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicatorWrapper,
  SelectDragIndicator,
  SelectItem,
} from '@gluestack-ui/themed';
import {TouchableOpacity} from 'react-native';
import WebHeaderB3 from '../../components/header/WebHeaderB3';
const {width, height} = Dimensions.get('window');

const RestaurantApplication = ({navigation}) => {
  const [appReady, setAppReady] = useState(false);
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [neighbourhood, setNeighbourhood] = useState('');
  const [contract, setContract] = useState(false);

  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Amasya',
    'Ankara',
    'Antalya',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkari',
    'Hatay',
    'Isparta',
    'Mersin',
    'Istanbul',
    'Izmir',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırklareli',
    'Kırşehir',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Kahramanmaraş',
    'Mardin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Şanlıurfa',
    'Uşak',
    'Van',
    'Yozgat',
    'Zonguldak',
    'Aksaray',
    'Bayburt',
    'Karaman',
    'Kırıkkale',
    'Batman',
    'Şırnak',
    'Bartın',
    'Ardahan',
    'Iğdır',
    'Yalova',
    'Karabük',
    'Kilis',
    'Osmaniye',
    'Düzce',
  ];

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white', maxWidth: width}}>
      <SafeAreaView>
        <View>{width > 1279 ? <WebHeaderB3 navigation={navigation} /> : <LogoHeader />}</View>

        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <View style={{width: width / 3, marginLeft: 70, marginTop: 68}}>
            <View>
              <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 36}}>İş ortağımız ol</Text>
            </View>
            <View style={{width: 421, marginTop: 34}}>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 24}}>
                Müşterilerinize en kısa sürede ulaşarak onlara en iyi hizmeti verebilirsiniz.
              </Text>
            </View>
          </View>

          <View style={{width: width / 2}}>
            <View>
              <Image
                source={require('../../assets/image/1.webp')}
                style={{width: width / 2, height: 700}}
              />
            </View>
          </View>

          <View
            style={{
              position: 'absolute',
              width: 536,
              height: 543,
              backgroundColor: 'white',
              bottom: -150,
              right: width / 2 - 150,
              borderRadius: 20,
              borderWidth: 0.2,
              borderColor: 'rgba(0,0,0,0.2)',
              padding: 22,
            }}
          >
            <View style={{marginBottom: 17}}>
              <TextInput
                style={style.input}
                placeholder='Restaurant Adı'
                placeholderTextColor={'gray'}
              />
            </View>

            <View style={{flexDirection: 'row', marginBottom: 17}}>
              <View style={{marginRight: 13}}>
                <Select
                  selectedValue={city}
                  placeholder='Seçiniz'
                  placeholderTextColor={colorSchema.textColor}
                  width={225}
                  height={60}
                  position='relative'
                  pt={12}
                  pl={2}
                  borderWidth={0}
                  onValueChange={(itemValue) => {
                    setLoading(true);
                    axios.get(`https://api.yemek.club/user/api/getAddress/${itemValue}`).then((res) => {
                      setDistrictList(res.data);
                      setCity(itemValue);
                      setLoading(false);
                    });
                  }}
                >
                  <SelectTrigger borderWidth={0}>
                    <SelectInput
                      fontSize={14}
                      fontFamily='Gilroy-Regular'
                      placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                    />
                    <SelectIcon mr='$3'></SelectIcon>
                  </SelectTrigger>
                  <SelectPortal>
                    <SelectBackdrop />
                    <SelectContent>
                      <SelectDragIndicatorWrapper>
                        <SelectDragIndicator />
                      </SelectDragIndicatorWrapper>
                      <SelectScrollView>
                        {cities.map((item, index) => (
                          <SelectItem
                            label={item}
                            value={item}
                            key={index}
                          />
                        ))}
                      </SelectScrollView>
                    </SelectContent>
                  </SelectPortal>
                </Select>
              </View>
              <View>
                <Select
                  selectedValue={district}
                  placeholder='Seçiniz'
                  placeholderTextColor={colorSchema.textColor}
                  width={225}
                  height={60}
                  position='relative'
                  pt={12}
                  pl={2}
                  borderWidth={0}
                  onValueChange={(itemValue) => {
                    setLoading(true);
                    axios
                      .get(`https://api.yemek.club/user/api/getAddress/${city}?district=${itemValue}`)
                      .then((res) => {
                        setNeighbourhoodList(res.data);
                        setDistrict(itemValue);
                        setLoading(false);
                      });
                  }}
                >
                  <SelectTrigger borderWidth={0}>
                    <SelectInput
                      fontSize={14}
                      fontFamily='Gilroy-Regular'
                      placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                    />
                    <SelectIcon mr='$3'></SelectIcon>
                  </SelectTrigger>
                  <SelectPortal>
                    <SelectBackdrop />
                    <SelectContent>
                      <SelectDragIndicatorWrapper>
                        <SelectDragIndicator />
                      </SelectDragIndicatorWrapper>
                      <SelectScrollView>
                        {province && province !== 'none' ? (
                          districtList.map((item, index) => (
                            <SelectItem
                              label={item.name}
                              value={item.name}
                              key={index}
                            />
                          ))
                        ) : (
                          <SelectItem
                            label={MainStore.langPref == 'tr' ? 'İl Seçiniz ' : 'Please Select a City'}
                            value='none'
                          />
                        )}
                      </SelectScrollView>
                    </SelectContent>
                  </SelectPortal>
                </Select>
              </View>
            </View>

            <View style={{marginBottom: 17}}>
              <Select
                selectedValue={neighbourhood}
                placeholder='Seçiniz'
                placeholderTextColor={colorSchema.textColor}
                width={225}
                height={60}
                position='relative'
                pt={12}
                pl={2}
                borderWidth={0}
                onValueChange={(itemValue) => {
                  setLoading(true);
                  setNeighbourhood(itemValue);
                  setLoading(false);
                }}
              >
                <SelectTrigger borderWidth={0}>
                  <SelectInput
                    fontSize={14}
                    fontFamily='Gilroy-Regular'
                    placeholder={MainStore.langPref == 'tr' ? 'Seçiniz' : 'Select'}
                  />
                  <SelectIcon mr='$3'></SelectIcon>
                </SelectTrigger>
                <SelectPortal>
                  <SelectBackdrop />
                  <SelectContent>
                    <SelectDragIndicatorWrapper>
                      <SelectDragIndicator />
                    </SelectDragIndicatorWrapper>
                    <SelectScrollView>
                      {district && district !== 'none' ? (
                        neighbourhoodList.map((item, index) => (
                          <Select.Item
                            label={item.name}
                            value={item.name}
                            key={index}
                          />
                        ))
                      ) : (
                        <Select.Item
                          label={MainStore.langPref == 'tr' ? 'İlçe Seçiniz ' : 'Please Select a District'}
                          value='none'
                        />
                      )}
                    </SelectScrollView>
                  </SelectContent>
                </SelectPortal>
              </Select>
            </View>

            <View style={{marginBottom: 17}}>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 18}}>Şirket Yetkili Bilgileri</Text>
            </View>

            <View style={{marginBottom: 17}}>
              <TextInput
                style={style.input}
                placeholder='Adınız Soyadınız'
                placeholderTextColor={'gray'}
              />
            </View>

            <View style={{flexDirection: 'row', marginBottom: 17}}>
              <View style={{marginRight: 13}}>
                <View>
                  <TextInput
                    style={[style.input, {width: 225}]}
                    placeholder='Cep Telefonu'
                    placeholderTextColor={'gray'}
                  />
                </View>
                <View style={{marginLeft: 5, marginTop: 7}}>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 11,
                      color: 'rgba(30,30,30,0.4)',
                    }}
                  >
                    Başvuru Doğrulama Kodu Gönderilecektir.
                  </Text>
                </View>
              </View>
              <View>
                <View>
                  <TextInput
                    style={[style.input, {width: 225}]}
                    placeholder='E-Posta Adresi'
                    placeholderTextColor={'gray'}
                  />
                </View>
                <View style={{marginLeft: 5, marginTop: 7}}>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 11,
                      color: 'rgba(30,30,30,0.4)',
                    }}
                  >
                    Başvuru Linki Bu Adrese Gönderilecektir.
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                width: width - 100,
                marginBottom: 34,
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 20,
              }}
            >
              <View style={{marginRight: 10}}>
                <TouchableOpacity
                  onPress={() => setContract(!contract)}
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    backgroundColor: contract ? colorSchema.mainColor : 'rgba(211,214,219,0.4)',
                    borderWidth: 1,
                    borderColor: 'rgba(211,214,219,1)',
                  }}
                />
              </View>
              <View>
                <TouchableOpacity onPress={() => navigation.navigate('Aydinlatma')}>
                  <Text style={style.radioText}>
                    Aydınlatma Metni
                    <Text style={{color: 'rgba(30,30,30,1)'}}>'ni okudum, kabul ediyorum</Text>
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{alignSelf: 'center'}}>
              <RedButton text='Başvur' />
            </View>
          </View>
        </View>
      </SafeAreaView>

      <View
        style={{
          marginTop: 334,
          flex: 1,
          position: 'relative',
          bottom: 0,
          alignSelf: 'center',
        }}
      >
        <WebFooter navigation={navigation} />
      </View>

      <Modal
        visible={loading}
        transparent={true}
      >
        <View
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Center>
            <Spinner
              size={'large'}
              color={colorSchema.mainColor}
              accessibilityLabel=''
            />
            <Text
              style={{
                fontFamily: 'Gilroy-Bold',
                fontSize: 21,
                color: colorSchema.mainColor,
              }}
            >
              {MainStore.auth ? `${MainStore.langPref == 'tr' ? 'Yükleniyor...' : 'Loading...'}` : 'Yükleniyor...'}
            </Text>
          </Center>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const style = StyleSheet.create({
  input: {
    borderRadius: 10,
    width: width > 500 ? 300 : width - 100,
    height: 43,
    backgroundColor: 'rgba(211,214,219,0.2)',
    paddingLeft: 10,
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
    borderWidth: 0,
    borderColor: 'rgba(30,30,30,0.1)',
    padding: 12,
  },
  radioText: {
    width: width - 130,
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
    color: colorSchema.mainColor,
    fontWeight: '400',
  },
});

export default RestaurantApplication;
