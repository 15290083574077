import {View, Text, SafeAreaView, TouchableOpacity, Dimensions, Image, ScrollView, FlatList, Modal} from 'react-native';
import React, {useState, useEffect, useRef} from 'react';
import {Feather} from '@expo/vector-icons';
import colorSchema from '../../config/colorSchema';
import StarIcon from '../../assets/svg/StarIcon';
import PlusIcon from '../../assets/svg/PlusIcon';
import MinusIcon from '../../assets/svg/MinusIcon';
import CartIcon from '../../assets/svg/CartIcon';
import FoodSheet from '../../components/bottomsheet/FoodSheet';
import {Picker} from '@react-native-picker/picker';
import {BottomSheet} from 'react-native-btr';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import WebHeader from '../../components/header/WebHeader';
import {useToast} from '@gluestack-ui/themed';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';

const {width, height} = Dimensions.get('window');
const FoodDetail = ({navigation, route}) => {
  const {id} = route.params;
  const [amount, setamount] = useState(1);
  const [selected, setSelected] = useState('Seçim');
  const [isRender, setIsRender] = useState(false);
  const [changed, setChanged] = useState(null);
  const [changedIndex, setChangedIndex] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [foodData, setFoodData] = useState(null);
  const [appReady, setAppReady] = useState(false);
  const [step, setStep] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [multiSelectItem, setMultiSelectItem] = useState(null);
  const [multiSelectIndex, setMultiSelectIndex] = useState();
  const pickerRef = useRef();
  const toast = useToast();

  useState(() => {
    axios
      .post(`${axiosConfig.serverIp}/getFood/${id}`, {
        neighbourhood: MainStore.selectedAddress.neighbourhood,
      })
      .then((res) => {
        if (!res.data.restaurantOpen) {
          console.log(res.data.multiSelect);
          setFoodData(res.data);
          setAppReady(true);
        } else {
          ErrorToastMessage(
            toast,
            MainStore.langPref == 'tr'
              ? 'Üzgünüz şuan seçtiğiniz restoran hizmet veremiyor...'
              : 'Sorry, the restaurant you selected is currently unavailable.'
          );
          navigation.goBack();
        }
      });
  }, []);

  const toggleBottomNavigationView = () => {
    setIsRender(!isRender);
    setVisible(!visible);
  };

  const handleItemPress = (index) => {
    const newSelectedItems = [...multiSelectItem.selected];
    if (newSelectedItems.includes(index)) {
      newSelectedItems.splice(newSelectedItems.indexOf(index), 1);
    } else {
      newSelectedItems.push(index);
    }
    const newMultiSelectItem = {
      ...multiSelectItem,
      selected: newSelectedItems,
    };
    setMultiSelectItem(newMultiSelectItem);
    const multiSelectCopy = [...foodData.multiSelect];
    multiSelectCopy[multiSelectIndex] = {
      ...multiSelectCopy[multiSelectIndex],
      selected: newSelectedItems,
    };

    const foodDataCopy = {...foodData, multiSelect: multiSelectCopy};
    setFoodData(foodDataCopy);
  };

  return appReady ? (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
        }}
      >
        {width > 1279 ? (
          <View
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 999,
            }}
          >
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <View style={{marginLeft: 22}}>
            <Feather
              name='chevron-left'
              size={25}
              color='black'
            />
          </View>
        </TouchableOpacity>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginTop: 22, marginLeft: 14}}
        >
          <TouchableOpacity onPress={() => navigation.navigate('Restaurant', {id: foodData.rId})}>
            <Text style={{fontFamily: 'Gilroy-Heavy', fontSize: 32}}>{foodData.restaurantName}</Text>
          </TouchableOpacity>

          <View style={{marginTop: 17}}>
            <Text
              style={{
                fontFamily: 'Gilroy-Medium',
                fontSize: 16,
                color: colorSchema.textColor,
              }}
            >
              {foodData.name}
            </Text>
          </View>

          <View
            style={{
              width: width / 1.7,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={{flexDirection: 'column'}}>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Regular',
                    fontSize: 14,
                    color: '#8E8B8B',
                  }}
                >
                  {foodData.description}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 7,
                }}
              >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View>
                    <StarIcon />
                  </View>
                  <View style={{marginLeft: 4, marginRight: 9}}>
                    <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>
                      {foodData.rating} ({foodData.comments?.length})
                    </Text>
                  </View>
                  <View>
                    <TouchableOpacity onPress={() => navigation.navigate('FoodComment', {foodData})}>
                      <RightArrowIcon />
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  {foodData.discountType == 'fixed' ? (
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: 'Gilroy-Medium',
                          color: 'gray',
                          lineHeight: 15,
                          textDecorationLine: 'line-through',
                        }}
                      >
                        {' '}
                        {`${parseFloat(foodData.price).toFixed(2)} TL`}
                      </Text>
                      <Text
                        style={{
                          color: colorSchema.greenColor,
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 16,
                        }}
                      >
                        {' '}
                        {`${parseFloat(foodData.price - foodData.discount).toFixed(2)} TL`}
                      </Text>
                    </View>
                  ) : foodData.discountType == 'percent' ? (
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: 'Gilroy-Medium',
                          color: 'gray',
                          lineHeight: 15,
                          textDecorationLine: 'line-through',
                        }}
                      >
                        {' '}
                        {`${parseFloat(foodData.price).toFixed(2)} TL`}
                      </Text>
                      <Text
                        style={{
                          color: colorSchema.greenColor,
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 16,
                        }}
                      >
                        {' '}
                        {`${parseFloat(foodData.price - (foodData.price * foodData.discount) / 100).toFixed(2)} TL`}
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text
                        style={{
                          color: colorSchema.greenColor,
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 16,
                        }}
                      >
                        {' '}
                        {`${parseFloat(foodData.price).toFixed(2)} TL`}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
            <View style={{marginLeft: 21}}>
              {foodData.foodImage ? (
                <Image
                  source={{uri: foodData.foodImage}}
                  style={{width: 125, height: 125, borderRadius: 5}}
                />
              ) : (
                <View style={{width: 125, height: 125, borderRadius: 5, backgroundColor: '#F9F9F9'}} />
              )}
            </View>
          </View>

          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: 90,
                height: 27,
                borderRadius: 6,
                borderWidth: 0.3,
                borderColor: 'rgba(30,30,30,0.6)',
                alignItems: 'center',
              }}
            >
              <View style={{marginLeft: 6}}>
                {amount > 1 ? (
                  <TouchableOpacity
                    onPress={() => {
                      setamount(amount - 1);
                    }}
                    style={{
                      width: 20,
                      height: 27,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MinusIcon
                      width={10}
                      height={2}
                    />
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
              </View>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 14,
                    color: colorSchema.textColor,
                  }}
                >
                  {amount}
                </Text>
              </View>
              <View style={{marginRight: 6}}>
                <TouchableOpacity
                  style={{
                    width: 20,
                    height: 27,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onPress={() => setamount(amount + 1)}
                >
                  <PlusIcon
                    width={10}
                    height={10}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {foodData?.selectable?.required?.length > 0 || foodData?.multiSelect?.length > 0 ? (
            <View
              style={{
                marginTop: 17,
                flexDirection: 'row',
                justifyContent: 'center',
                maxWidth: 700,
              }}
            >
              <TouchableOpacity onPress={() => setSelected('Seçim')}>
                <View
                  style={{
                    borderRadius: 10,
                    backgroundColor: selected == 'Seçim' ? colorSchema.textColor : 'white',
                  }}
                >
                  <Text
                    style={{
                      marginHorizontal: 9,
                      marginVertical: 5,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: selected == 'Seçim' ? colorSchema.cartColor : colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Seçim Yapınız ' : 'Select one'}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setSelected('Promosyon')}>
                <View
                  style={{
                    borderRadius: 10,
                    marginHorizontal: 2,
                    backgroundColor: selected == 'Promosyon' ? colorSchema.textColor : 'white',
                  }}
                >
                  <Text
                    style={{
                      marginHorizontal: 9,
                      marginVertical: 5,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: selected == 'Promosyon' ? colorSchema.cartColor : colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Promosyon ' : 'Promotions'}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setSelected('Opsiyonel')}>
                <View
                  style={{
                    borderRadius: 10,
                    backgroundColor: selected == 'Opsiyonel' ? colorSchema.textColor : 'white',
                  }}
                >
                  <Text
                    style={{
                      marginHorizontal: 9,
                      marginVertical: 5,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 15,
                      color: selected == 'Opsiyonel' ? colorSchema.cartColor : colorSchema.textColor,
                    }}
                  >
                    {MainStore.langPref == 'tr' ? 'Opsiyonel ' : 'Optional'}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : (
            <></>
          )}

          {foodData?.removeable?.length > 0 ? (
            <View
              style={{
                marginTop: 17,
                paddingLeft: 17,
                borderWidth: 0.3,
                borderRadius: 5,
                borderColor: 'rgba(0,0,0,0.1)',
                width: width > 1279 ? 700 : width - 28,
              }}
            >
              <View style={{marginTop: 17}}>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 14,
                    color: colorSchema.textColor,
                  }}
                >
                  {MainStore.langPref == 'tr'
                    ? 'Lütfen çıkarmak istediğiniz malzemeyi seçin '
                    : 'Select the material you want to extract'}
                </Text>
              </View>
              <View style={{marginVertical: 17}}>
                <FlatList
                  data={foodData.removeable}
                  showsHorizontalScrollIndicator={false}
                  horizontal
                  extraData={isRender}
                  renderItem={({item, index}) => (
                    <TouchableOpacity
                      onPress={() => {
                        const newObject = foodData;
                        newObject.removeable[index].removed = !item.removed;
                        setFoodData(newObject);
                        setIsRender(!isRender);
                      }}
                      key={index}
                      style={{
                        borderWidth: 0.3,
                        borderRadius: 5,
                        borderColor: 'rgba(0,0,0,0.1)',
                        paddingHorizontal: 36,
                        paddingVertical: 4,
                        marginRight: 15,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Gilroy-Bold',
                          fontSize: 15,
                          color: item.removed ? colorSchema.mainColor : colorSchema.textColor,
                          textDecorationLine: item.removed ? 'line-through' : 'none',
                        }}
                      >
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  )}
                />
              </View>
            </View>
          ) : (
            <></>
          )}

          <View
            style={{
              marginTop: 17,
              justifyContent: 'center',
              alignItems: width > 1279 ? 'flex-start' : 'center',
              marginBottom: 100,
            }}
          >
            {selected == 'Seçim' ? (
              <View>
                {foodData?.selectable?.required?.map((item, index) =>
                  item?.templateID ? (
                    item.active && (
                      <TouchableOpacity
                        onPress={() => {
                          setChanged(item);
                          setChangedIndex(index);
                          toggleBottomNavigationView();
                        }}
                        key={index}
                        style={{
                          marginTop: 17,
                          borderWidth: 0.3,
                          borderRadius: 5,
                          borderColor: 'rgba(0,0,0,0.1)',
                          width: width > 1279 ? 700 : width - 60,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginVertical: 17,
                          }}
                        >
                          <View style={{marginLeft: 5}}>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                              </Text>
                            </View>
                            <View style={{marginRight: 23, marginLeft: 12}}>
                              <Feather
                                name='chevron-down'
                                color={'black'}
                                size={20}
                              />
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  ) : (
                    <TouchableOpacity
                      onPress={() => {
                        setChanged(item);
                        setChangedIndex(index);
                        toggleBottomNavigationView();
                      }}
                      key={index}
                      style={{
                        marginTop: 17,
                        borderWidth: 0.3,
                        borderRadius: 5,
                        borderColor: 'rgba(0,0,0,0.1)',
                        width: width > 1279 ? 700 : width - 60,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginVertical: 17,
                        }}
                      >
                        <View style={{marginLeft: 5}}>
                          <Text
                            style={{
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 14,
                              color: colorSchema.textColor,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                          <View>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                            </Text>
                          </View>
                          <View style={{marginRight: 23, marginLeft: 12}}>
                            <Feather
                              name='chevron-down'
                              color={'black'}
                              size={20}
                            />
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                )}

                {foodData?.multiSelect?.map((item, index) =>
                  item.selectType == 'required' ? (
                    item?.templateID ? (
                      item.active && (
                        <TouchableOpacity
                          onPress={() => {
                            setMultiSelectItem(item);
                            setMultiSelectIndex(index);
                            setModalVisible(!modalVisible);
                          }}
                          key={index}
                          style={{
                            marginTop: 17,
                            borderWidth: 0.3,
                            borderRadius: 5,
                            borderColor: 'rgba(0,0,0,0.1)',
                            width: width > 1279 ? 700 : width - 60,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginVertical: 17,
                            }}
                          >
                            <View style={{marginLeft: 5}}>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.name}
                              </Text>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                              <View>
                                <Text
                                  style={{
                                    fontFamily: 'Gilroy-Medium',
                                    fontSize: 14,
                                    color: colorSchema.textColor,
                                  }}
                                >
                                  {item.selected == 0 ? 'Lütfen Seçiniz' : `${item.selected.length} Adet Seçildi`}
                                </Text>
                              </View>
                              <View style={{marginRight: 23, marginLeft: 12}}>
                                <Feather
                                  name='chevron-down'
                                  color={'black'}
                                  size={20}
                                />
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      )
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setMultiSelectItem(item);
                          setMultiSelectIndex(index);
                          setModalVisible(!modalVisible);
                        }}
                        key={index}
                        style={{
                          marginTop: 17,
                          borderWidth: 0.3,
                          borderRadius: 5,
                          borderColor: 'rgba(0,0,0,0.1)',
                          width: width > 1279 ? 700 : width - 60,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginVertical: 17,
                          }}
                        >
                          <View style={{marginLeft: 5}}>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.selected == 0 ? 'Lütfen Seçiniz' : `${item.selected.length} Adet Seçildi`}
                              </Text>
                            </View>
                            <View style={{marginRight: 23, marginLeft: 12}}>
                              <Feather
                                name='chevron-down'
                                color={'black'}
                                size={20}
                              />
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  ) : (
                    <View key={index} />
                  )
                )}
              </View>
            ) : (
              <></>
            )}

            {selected == 'Promosyon' ? (
              foodData?.selectable?.promotion?.map((item, index) =>
                item?.templateID ? (
                  item.active && (
                    <TouchableOpacity
                      onPress={() => {
                        setChanged(item);
                        setChangedIndex(index);
                        toggleBottomNavigationView();
                      }}
                      key={index}
                      style={{
                        marginTop: 17,
                        borderWidth: 0.3,
                        borderRadius: 5,
                        borderColor: 'rgba(0,0,0,0.1)',
                        width: width > 1279 ? 700 : width - 60,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginVertical: 17,
                        }}
                      >
                        <View style={{marginLeft: 5}}>
                          <Text
                            style={{
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 14,
                              color: colorSchema.textColor,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                          <View>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                            </Text>
                          </View>
                          <View style={{marginRight: 23, marginLeft: 12}}>
                            <Feather
                              name='chevron-down'
                              color={'black'}
                              size={20}
                            />
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      setChanged(item);
                      setChangedIndex(index);
                      toggleBottomNavigationView();
                    }}
                    key={index}
                    style={{
                      marginTop: 17,
                      borderWidth: 0.3,
                      borderRadius: 5,
                      borderColor: 'rgba(0,0,0,0.1)',
                      width: width > 1279 ? 700 : width - 60,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginVertical: 17,
                      }}
                    >
                      <View style={{marginLeft: 5}}>
                        <Text
                          style={{
                            fontFamily: 'Gilroy-Medium',
                            fontSize: 14,
                            color: colorSchema.textColor,
                          }}
                        >
                          {item.name}
                        </Text>
                      </View>
                      <View style={{flexDirection: 'row'}}>
                        <View>
                          <Text
                            style={{
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 14,
                              color: colorSchema.textColor,
                            }}
                          >
                            {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                          </Text>
                        </View>
                        <View style={{marginRight: 23, marginLeft: 12}}>
                          <Feather
                            name='chevron-down'
                            color={'black'}
                            size={20}
                          />
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                )
              )
            ) : (
              <></>
            )}

            {selected == 'Opsiyonel' ? (
              <View>
                {foodData?.selectable?.optional?.map((item, index) =>
                  item?.templateID ? (
                    item.active && (
                      <TouchableOpacity
                        onPress={() => {
                          setChanged(item);
                          setChangedIndex(index);
                          toggleBottomNavigationView();
                        }}
                        key={index}
                        style={{
                          marginTop: 17,
                          borderWidth: 0.3,
                          borderRadius: 5,
                          borderColor: 'rgba(0,0,0,0.1)',
                          width: width > 1279 ? 700 : width - 60,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginVertical: 17,
                          }}
                        >
                          <View style={{marginLeft: 5}}>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                              </Text>
                            </View>
                            <View style={{marginRight: 23, marginLeft: 12}}>
                              <Feather
                                name='chevron-down'
                                color={'black'}
                                size={20}
                              />
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  ) : (
                    <TouchableOpacity
                      onPress={() => {
                        setChanged(item);
                        setChangedIndex(index);
                        toggleBottomNavigationView();
                      }}
                      key={index}
                      style={{
                        marginTop: 17,
                        borderWidth: 0.3,
                        borderRadius: 5,
                        borderColor: 'rgba(0,0,0,0.1)',
                        width: width > 1279 ? 700 : width - 60,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginVertical: 17,
                        }}
                      >
                        <View style={{marginLeft: 5}}>
                          <Text
                            style={{
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 14,
                              color: colorSchema.textColor,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                          <View>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.selected == 0 ? 'Lütfen Seçiniz' : item.value[item.selected].name}
                            </Text>
                          </View>
                          <View style={{marginRight: 23, marginLeft: 12}}>
                            <Feather
                              name='chevron-down'
                              color={'black'}
                              size={20}
                            />
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                )}

                {foodData?.multiSelect?.map((item, index) =>
                  item.selectType == 'optional' ? (
                    item?.templateID ? (
                      item.active && (
                        <TouchableOpacity
                          onPress={() => {
                            setMultiSelectItem(item);
                            setMultiSelectIndex(index);
                            setModalVisible(!modalVisible);
                          }}
                          key={index}
                          style={{
                            marginTop: 17,
                            borderWidth: 0.3,
                            borderRadius: 5,
                            borderColor: 'rgba(0,0,0,0.1)',
                            width: width > 1279 ? 700 : width - 60,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginVertical: 17,
                            }}
                          >
                            <View style={{marginLeft: 5}}>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.name}
                              </Text>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                              <View>
                                <Text
                                  style={{
                                    fontFamily: 'Gilroy-Medium',
                                    fontSize: 14,
                                    color: colorSchema.textColor,
                                  }}
                                >
                                  {item.selected == 0 ? 'Lütfen Seçiniz' : `${item.selected.length} Adet Seçildi`}
                                </Text>
                              </View>
                              <View style={{marginRight: 23, marginLeft: 12}}>
                                <Feather
                                  name='chevron-down'
                                  color={'black'}
                                  size={20}
                                />
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      )
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setMultiSelectItem(item);
                          setMultiSelectIndex(index);
                          setModalVisible(!modalVisible);
                        }}
                        key={index}
                        style={{
                          marginTop: 17,
                          borderWidth: 0.3,
                          borderRadius: 5,
                          borderColor: 'rgba(0,0,0,0.1)',
                          width: width > 1279 ? 700 : width - 60,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginVertical: 17,
                          }}
                        >
                          <View style={{marginLeft: 5}}>
                            <Text
                              style={{
                                fontFamily: 'Gilroy-Medium',
                                fontSize: 14,
                                color: colorSchema.textColor,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View>
                              <Text
                                style={{
                                  fontFamily: 'Gilroy-Medium',
                                  fontSize: 14,
                                  color: colorSchema.textColor,
                                }}
                              >
                                {item.selected == 0 ? 'Lütfen Seçiniz' : `${item.selected.length} Adet Seçildi`}
                              </Text>
                            </View>
                            <View style={{marginRight: 23, marginLeft: 12}}>
                              <Feather
                                name='chevron-down'
                                color={'black'}
                                size={20}
                              />
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  ) : (
                    <View key={index} />
                  )
                )}
              </View>
            ) : (
              <></>
            )}
          </View>
        </ScrollView>

        <View
          style={{
            position: 'absolute',
            flex: 1,
            bottom: 20,
            alignSelf: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (step == 1) {
                let emptyRequired = false;
                if (foodData.selectable.required && foodData.selectable.required.length > 0) {
                  foodData?.selectable?.required.map((item) => {
                    console.log(item);
                    if (item.selected == 0) {
                      emptyRequired = true;
                    }
                  });
                }
                foodData?.multiSelect?.map((item) => {
                  if (item.selected.length == 0 && item.selectType == 'required') {
                    emptyRequired = true;
                  } else {
                  }
                });
                if (emptyRequired) {
                  ErrorToastMessage(toast, 'Lütfen Zorunlu Alanları Doldurun');
                } else {
                  MainStore.addCart(foodData, amount);
                  setStep(2);
                }
              } else {
                navigation.navigate('Cart');
              }
            }}
            style={{
              width: width > 1279 ? 700 : width - 28,
              backgroundColor: step == 1 ? 'rgba(30,30,30,0.8)' : colorSchema.mainColor,
              borderRadius: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{marginLeft: 11, marginVertical: 9}}>
              <CartIcon />
            </View>
            <View style={{justifyContent: 'center', alignSelf: 'center'}}>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 12,
                    color: colorSchema.cartColor,
                    textAlign: 'center',
                  }}
                >
                  {foodData.name}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 19,
                    color: colorSchema.cartColor,
                  }}
                >
                  {step == 1
                    ? MainStore.langPref == 'tr'
                      ? 'Sepete Ekle'
                      : 'Add to cart'
                    : MainStore.langPref == 'tr'
                    ? 'Sepete Git'
                    : 'Go to cart'}
                </Text>
              </View>
            </View>
            <View style={{marginLeft: 40}} />
          </TouchableOpacity>
        </View>
        {visible ? (
          <View>
            <BottomSheet
              visible={visible}
              onBackButtonPress={toggleBottomNavigationView}
              onBackdropPress={toggleBottomNavigationView}
            >
              <SafeAreaView
                style={{
                  width: '100%',
                  height: '30%',
                  backgroundColor: 'white',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => toggleBottomNavigationView()}
                    style={{marginLeft: 5}}
                  >
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 15,
                        color: colorSchema.textColor,
                      }}
                    >
                      {MainStore.langPref == 'tr' ? 'Kapat' : 'Close'}
                    </Text>
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 15,
                        color: colorSchema.textColor,
                      }}
                    >
                      {changed.name}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => toggleBottomNavigationView()}
                    style={{marginRight: 6}}
                  >
                    <Text
                      style={{
                        fontFamily: 'Gilroy-Medium',
                        fontSize: 15,
                        color: colorSchema.textColor,
                      }}
                    >
                      {MainStore.langPref == 'tr' ? 'Seç' : 'Pick'}
                    </Text>
                  </TouchableOpacity>
                </View>

                <Picker
                  selectedValue={selectedLanguage}
                  style={
                    width > 1279
                      ? {
                          borderWidth: 0.3,
                          borderRadius: 5,
                          borderColor: 'rgba(0,0,0,0.1)',
                          width: width > 1279 ? 700 : width - 60,
                          padding: 10,
                        }
                      : {}
                  }
                  onValueChange={(itemValue, itemIndex) => {
                    const newObject = foodData;

                    if (selected == 'Seçim') {
                      newObject.selectable.required[changedIndex].selected = itemIndex;
                      setFoodData(newObject);
                      setIsRender(!isRender);
                    } else if (selected == 'Promosyon') {
                      newObject.selectable.promotion[changedIndex].selected = itemIndex;
                      setFoodData(newObject);
                      setIsRender(!isRender);
                    } else {
                      newObject.selectable.optional[changedIndex].selected = itemIndex;
                      setFoodData(newObject);
                      setIsRender(!isRender);
                    }
                    setSelectedLanguage(itemValue);
                  }}
                >
                  {changed.value.map(
                    (item, index) =>
                      (item.active || index == 0) && (
                        <Picker.Item
                          key={item._id}
                          label={`${item.name} ${index == 0 ? '' : '+'} ${
                            item.price != -1 ? parseFloat(item.price).toFixed(2) + ' TL' : ''
                          }`}
                          value={item.name}
                        />
                      )
                  )}
                </Picker>
              </SafeAreaView>
            </BottomSheet>
          </View>
        ) : (
          <></>
        )}

        {modalVisible ? (
          <Modal
            animationType='slide'
            transparent
            visible={modalVisible}
            onRequestClose={() => setModalVisible(!modalVisible)}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 22,
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}
            >
              <ScrollView
                style={{
                  width: 300,
                  maxHeight: 500,
                  backgroundColor: 'white',
                  borderRadius: 8,
                }}
              >
                <View
                  style={{
                    margin: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: 'Gilroy-SemiBold',
                        fontSize: 16,
                        color: colorSchema.textColor,
                      }}
                    >
                      {multiSelectItem?.name}
                    </Text>
                  </View>
                  <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                    <Feather
                      name='x-circle'
                      size={17}
                      color='black'
                    />
                  </TouchableOpacity>
                </View>
                <View>
                  {multiSelectItem?.value.map((item, index) =>
                    index == 0 ? (
                      <View key={index}></View>
                    ) : (
                      <View
                        key={index}
                        style={{
                          flexDirection: 'row',
                          marginLeft: 10,
                          justifyContent: 'space-between',
                          marginTop: 8,
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 15,
                            }}
                          >
                            {item.name}
                            <Text style={{fontSize: 12, color: 'gray'}}>
                              {' '}
                              ({`+${parseFloat(item.price).toFixed(2)} TL`})
                            </Text>
                          </Text>
                        </View>
                        <TouchableOpacity
                          onPress={() => handleItemPress(index)}
                          style={{
                            borderRadius: 50,
                            width: 17,
                            height: 17,
                            borderWidth: 0.3,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderColor: 'gray',
                            marginRight: 17,
                          }}
                        >
                          <View
                            style={{
                              width: 13,
                              height: 13,
                              borderRadius: 50,
                              backgroundColor: multiSelectItem.selected.includes(index)
                                ? colorSchema.mainColor
                                : 'white',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    )
                  )}
                </View>
              </ScrollView>
            </View>
          </Modal>
        ) : (
          <></>
        )}
      </ScrollView>
    </SafeAreaView>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(FoodDetail);
