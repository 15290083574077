import { View, Text, TouchableOpacity, SafeAreaView, Keyboard, ScrollView, Image, Dimensions, StyleSheet, TextInput } from 'react-native'
import React, { useState, useEffect } from 'react'
import LogoHeader from '../../components/header/LogoHeader'
import { BottomSheet } from 'react-native-btr'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import colorSchema from '../../config/colorSchema';
import countryCode from '../../config/countryCode'
import { Feather } from '@expo/vector-icons'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import { Picker } from '@react-native-picker/picker'
import WebHeader from '../../components/header/WebHeader'
import { useToast } from '@gluestack-ui/themed'
const { width, height } = Dimensions.get('window')



const SignIn = ({ navigation }) => {
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState('90')
    const toggleBottomNavigationView = () => {
        setVisible(!visible);
    }
    const toast = useToast()
    return (
        <SafeAreaView style={style.main}>
            <View onStartShouldSetResponder={() => Keyboard.dismiss()} style={style.main}>

                {width > 1279 ?
                    <View style={{ marginLeft: 80, marginRight: 80 }}>
                        <WebHeader navigation={navigation} />
                    </View>
                    :
                    <View>
                        <LogoHeader />
                    </View>}

                <View style={style.infoTextArea}>
                    <Text style={style.infoText}>Giriş</Text>
                </View>

                <Formik
                    initialValues={{ phoneNumber: '' }}
                    onSubmit={value => {
                        axios.post(`${axiosConfig.serverIp}/sendDigitCode`, {
                            phoneNumber: `${selected}${value.phoneNumber}`
                        }, { httpsAgent: false }).then(res => {
                            if (res.data.success) {
                                navigation.navigate('Verification', {
                                    countryCode:selected,
                                    phoneNumber: value.phoneNumber,
                                    countryCode: selected

                                })
                            }
                            else {
                                ErrorToastMessage(toast, 'Lütfen telefon numaranızı kontrol edin.')
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    }}

                    validationSchema={Yup.object().shape({
                        phoneNumber: Yup.string().required('Telefon Numarası Alanı Zorunlu'),

                    })}
                >
                    {({ values, handleSubmit, handleChange, errors }) => (
                        <View style={style.board}>

                            <View style={{ flexDirection: 'row' }}>
                                <View style={style.mainCodeSelectArea}>
                                    <TouchableOpacity onPress={toggleBottomNavigationView}>
                                        <View style={style.secCodeSelectArea}>
                                            <View>
                                                <Text style={style.codeSelectText}>+{selected}</Text>
                                            </View>
                                            <View>
                                                <Feather name='chevron-down' size={20} color='#28303F' />
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <View style={style.phoneInputBoard}>
                                    <TextInput value={values.phoneNumber} onChangeText={handleChange('phoneNumber')} keyboardType='number-pad' style={style.phoneInput} placeholder='Cep Telefonu' placeholderTextColor='rgba(30,30,30,0.6)' />
                                </View>

                            </View>
                            <View style={style.radioArea}>
                                <Text style={style.radioText}>Giriş için cep telefonunuza tek kullanımlık şifre göndereceğiz.</Text>
                            </View>



                            <View style={style.buttonArea}>
                                <TouchableOpacity onPress={() => {
                                    if (errors.name && errors.email && errors.phoneNumber) {

                                        if (errors.phoneNumber)
                                            ErrorToastMessage(toast, errors.phoneNumber)

                                    }
                                    else {
                                        handleSubmit()

                                    }
                                }} style={style.registerButton}>
                                    <Text style={style.registerText}>Giriş Yap</Text>
                                </TouchableOpacity>
                                <View style={{ alignSelf: 'center', marginTop: 10 }}>
                                    <Text style={style.loginText}>Hesabınız yok mu? <Text onPress={() => navigation.navigate('Register')} style={{ color: colorSchema.mainColor }}>Üye ol</Text></Text>
                                </View>
                            </View>



                        </View>
                    )}
                </Formik>

                <BottomSheet visible={visible} onBackButtonPress={toggleBottomNavigationView} onBackdropPress={toggleBottomNavigationView}>
                    <View style={style.bottomNavigationView}>
                        <Picker
                            selectedValue={selected}
                            onValueChange={(itemValue) => setSelected(itemValue)}
                        >
                            {countryCode.map((item, index) =>
                                <Picker.Item key={index} label={`${item.country} (${item.code})`} value={item.code} />

                            )}
                        </Picker>
                    </View>
                </BottomSheet>
            </View>
        </SafeAreaView>
    )
}

const style = StyleSheet.create({
    bottomNavigationView: {
        backgroundColor: '#fff',
        width: '100%',
        height: 300,
    },
    main: { backgroundColor: 'white', flex: 1 },
    board: { alignItems: width > 1279 ? 'flex-start' : 'center', flex: 1, marginTop: 47, margin: width > 1279 ? 80 : 0 },
    codeBoard: { marginBottom: 8, marginLeft: 10 },
    codeText: { fontFamily: 'Gilroy-Bold', fontSize: 18, color: 'gray' },
    infoText: { fontFamily: 'Gilroy-Bold', fontSize: 24, color: colorSchema.mainColor },
    infoTextArea: { marginTop: 60, marginLeft: width > 1279 ? 80 : 17 },
    mainCodeSelectArea: { borderRadius: 10, width: 70, height: 43, backgroundColor: 'rgba(211,214,219,0.4)', alignItems: 'center', justifyContent: 'center', borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
    secCodeSelectArea: { flexDirection: 'row', alignItems: 'center' },
    codeSelectText: { fontFamily: 'Gilroy-Medium', fontSize: 15 },
    phoneInputBoard: { marginLeft: 5 },
    phoneInput: { borderRadius: 10, width: width > 1279 ? 340 : width - 175, height: 43, backgroundColor: 'rgba(211,214,219,0.4)', paddingLeft: 10, fontFamily: 'Gilroy-Medium', fontSize: 15, marginBottom: 20, borderWidth: 0.5, borderColor: 'rgba(30,30,30,0.1)' },
    buttonArea: { flex: 1, position: width > 1279 ? 'relative' : 'absolute', bottom: 80, marginTop: 200 },
    registerButton: { borderRadius: 20, backgroundColor: colorSchema.mainColor, width: width > 400 ? 400 : width - 28, height: 43, alignItems: 'center', justifyContent: 'center' },
    registerText: { color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 },
    loginText: { color: 'rgba(30,30,30,0.8)', fontFamily: 'Gilroy-Regular', fontSize: 15 },
    registerText: { color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 },
    radioText: { width: width - 110, fontFamily: 'Gilroy-Medium', fontSize: 13, color: colorSchema.textColor }

})

export default SignIn