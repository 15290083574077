import { View, Text, Image, TouchableOpacity } from 'react-native'
import React from 'react'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'

const HomeTitle = (props) => {
    return (
        <View style={{ marginBottom: 11, flexDirection: 'row',alignItems:'center',justifyContent:'space-between' }}>
            <View style={{ marginLeft: 5 }}>
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 16 }}>{props.text}</Text>
            </View>
            <View style={{marginRight:14}}>
                <TouchableOpacity onPress={()=>props.navigation.navigate('SeeMore',{title:props.text,food:props.food,data:props.data})}>
                    <RightArrowIcon />
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default HomeTitle