import { View, Text, TouchableOpacity, Image, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import colorSchema from '../../config/colorSchema'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import CartIcon from '../../assets/svg/CartIcon'
const { width, height } = Dimensions.get('window')

const CartSheet = (props) => {
    return (
        MainStore.cartItem?.length > 0
            ?
            width > 1279 ?
                <></>
                :
                <View style={{ width: width - 15, alignSelf: 'center', position: 'absolute', bottom: 19 }}>
                    <TouchableOpacity onPress={() => { props.navigation.navigate('Cart') }} style={{ paddingVertical: 3, borderRadius: 20, backgroundColor: props.foodScreen ? colorSchema.mainColor : colorSchema.waterColor }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <View style={{ marginLeft: 11, marginTop: 5, alignItems: 'center', justifyContent: 'center' }}>
                                <CartIcon />
                            </View>
                            <View style={{ flexDirection: 'column', alignItems: 'center', marginLeft: 15 }}>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 12, color: 'white' }}>{MainStore.cartItem[0].restaurantName}</Text>
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 19, color: 'white' }} >Sepete Git</Text>
                                </View>
                            </View>
                            <View style={{ marginRight: 10 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15, color: 'white' }}>{MainStore.calcutePrice} TL</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            :
            <></>
    )
}

export default observer(CartSheet)