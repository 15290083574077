import { View, ScrollView, Image, Text, RefreshControl, Dimensions } from 'react-native'
import React, { useState, useEffect } from 'react'
import HeaderSheet from '../../components/header/HeaderSheet'
import ScreenTitle from '../../components/header/ScreenTitle'
import LastOrderCard from '../../components/card/LastOrderCard'
import colorSchema from '../../config/colorSchema'
import RedButton from '../../components/button/RedButton'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react-lite'
import LoadingSpinner from '../../components/feedback/LoadingSpinner'
import WebHeader from '../../components/header/WebHeader'

const { width, height } = Dimensions.get('window')

const LastOrder = ({ navigation }) => {

    const [appReady, setAppReady] = useState(false)
    const [refreshing,setRefreshing] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${axiosConfig.serverIp}/getMyLastOrder`, {
            headers: { Authorization: `Bearer ${MainStore.token}` },
        }).then((res) => {
            setData(res.data.reverse());
            setAppReady(true);
        });
    }, []);

    function formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        return formattedDate
    }

    function formatTime(date) {
        const hours = date.getHours().toString().padStart(2, '0'); 
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const onRefresh = ()=>{
        setRefreshing(true)
        setAppReady(false)
        axios.get(`${axiosConfig.serverIp}/getMyLastOrder`, {
            headers: { Authorization: `Bearer ${MainStore.token}` },
        }).then((res) => {
            setData(res.data.reverse());
            setAppReady(true);
            setRefreshing(false)
        });
    }



    return (
        appReady
            ?
            !data?.length > 0 ?
                <View style={{ flex: 1 }}>
                    {width > 1279 ?
                        <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
                            <WebHeader navigation={navigation} />
                        </View>
                        :
                        <View>
                            <HeaderSheet navigation={navigation} />
                        </View>
                    }

                    <View style={{ flex: 1, backgroundColor: 'white' }}>
                        <View>
                            <ScreenTitle title={MainStore.langPref == 'tr' ? 'Geçmiş Siparişlerim' : 'History'} navigation={navigation} />
                        </View>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 28 }}>
                            <View>
                                <Image source={require('../../assets/image/emptyOrder.png')} style={{ width: 71, height: 93 }} />
                            </View>
                            <View style={{ marginTop: 18 }}>
                                <Text style={{ color: colorSchema.textColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }} >{MainStore.langPref == 'tr' ? 'Henüz geçmiş siparişin yok' : "You don't have any previous orders yet"}</Text>
                            </View>
                            <View style={{ marginTop: 18 }}>
                                <Text style={{ color: colorSchema.textColor, fontFamily: 'Gilroy-Regular', fontSize: 14 }} >{MainStore.langPref == 'tr' ? 'Sipariş oluşturmak için “Sipariş ver” butonuna tıkla!' : 'Place order'}</Text>
                            </View>
                        </View>

                        <View style={{ position: 'absolute', bottom: 20, alignSelf: 'center' }}>
                            <RedButton clickEvent={() => navigation.navigate('Home')} text={MainStore.langPref == 'tr' ? 'Sipariş Ver' : 'Order Now'} />
                        </View>
                    </View>


                </View>
                :

                <View style={{ flex: 1, backgroundColor: 'white' }}>
                    <View style={{ paddingHorizontal: width > 1279 ? (width - 1279) / 2 : 0 }}>

                        {width > 1279 ?
                            <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
                                <WebHeader navigation={navigation} />
                            </View>
                            :
                            <View>
                                <HeaderSheet navigation={navigation} />
                            </View>
                        }
                        <View>
                            <View>
                                <ScreenTitle title={MainStore.langPref == 'tr' ? 'Geçmiş Siparişlerim' : 'History'} navigation={navigation} />
                            </View>
                            <ScrollView refreshControl={
                              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                                }>
                                {data?.map((item, index) =>
                                    <View key={index}>
                                        <LastOrderCard data={item} navigation={navigation} orderTime={formatTime(new Date(item.created_at))} orderStatus={item.orderStatus} totalPaid={item.totalPaid} foodName={item.orderedFood[0].name} orderDate={formatDate(new Date(item.created_at))} restaurantName={item.restaurantData.rName} review={item.orderStatus == 'Teslim Edildi' && item.review ? true : false} />
                                    </View>
                                )}
                                <View style={{ marginTop: 300 }} />
                            </ScrollView>
                        </View>
                    </View>
                </View>

            :
            <LoadingSpinner />
    )
}

export default observer(LastOrder)