import * as React from "react";
import { Svg, Path } from 'react-native-svg'
function MinusIcon(props) {
    return (
        <Svg
            width={props.width}
            height={props.height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8.5.833a1.167 1.167 0 010 2.334h-7a1.167 1.167 0 010-2.334h7z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default MinusIcon