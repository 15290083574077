import * as React from "react"
import Svg, { Path } from "react-native-svg"
import colorSchema from "../../config/colorSchema"

const HomeIcon = (props) => (
    props.selected ?
        <Svg
            width={24}
            height={26}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.25 10.688v9.77c0 2.785-2.239 5.042-5 5.042H5.75c-2.761 0-5-2.257-5-5.041v-9.771c0-1.514.675-2.947 1.837-3.905l6.25-5.146a4.968 4.968 0 0 1 6.326 0l6.25 5.146a5.058 5.058 0 0 1 1.837 3.905ZM7.366 17.137a.938.938 0 0 0-.732 1.726c2.036.864 3.713 1.329 5.419 1.324 1.707-.004 3.35-.478 5.318-1.326a.938.938 0 0 0-.742-1.722c-1.862.803-3.236 1.17-4.58 1.173-1.346.004-2.755-.357-4.683-1.175Z"
                fill={colorSchema.mainColor}
            />
        </Svg>
        :
        <Svg
            width={24}
            height={28}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M7 19c3.964 1.682 6.17 1.651 10 0m6.25-7.312v9.77c0 2.785-2.239 5.042-5 5.042H5.75c-2.761 0-5-2.257-5-5.041v-9.771c0-1.514.675-2.947 1.837-3.905l6.25-5.146a4.968 4.968 0 0 1 6.326 0l6.25 5.146a5.058 5.058 0 0 1 1.837 3.905Z"
                stroke="#1E1E1E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
)

export default HomeIcon
