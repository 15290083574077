import { View, Text, TouchableOpacity, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
import { Feather } from '@expo/vector-icons'

const { width, height } = Dimensions.get('window')

const ScreenTitle = (props) => {
    return (
        <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <View style={{ marginLeft: 10, marginVertical: 14 }}>
                    <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 28, color: colorSchema.textColor }}>{props.title}</Text>
                </View>
                {width > 1279
                    ?
                    <></>
                    :
                    <TouchableOpacity onPress={() => props.navigation.navigate('Home')}>
                        <View style={{ marginRight: 20 }}>
                            <Feather name='chevron-down' size={25} color='black' />
                        </View>
                    </TouchableOpacity>
                }

            </View>
        </View>
    )
}

export default ScreenTitle