import * as React from "react"
import { Svg, Path } from 'react-native-svg'
const PlusCircleIcon = (props) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.667 12c0 6.443-5.224 11.667-11.667 11.667S.333 18.443.333 12 5.557.333 12 .333C18.444.333 23.667 5.557 23.667 12ZM6.583 12a.75.75 0 0 1 .75-.75h3.917V7.333a.75.75 0 1 1 1.5 0v3.917h3.917a.75.75 0 0 1 0 1.5H12.75v3.917a.75.75 0 0 1-1.5 0V12.75H7.333a.75.75 0 0 1-.75-.75Z"
            fill="#1E1E1E"
        />
    </Svg>
)

export default PlusCircleIcon
