import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  Dimensions,
  TextInput,
  ScrollView,
  Modal,
  Platform,
} from 'react-native';
import React, {useState} from 'react';
import {Feather} from '@expo/vector-icons';
import RedButton from '../../components/button/RedButton';
import axios from 'axios';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import axiosConfig from '../../config/axiosConfig';
import WebHeader from '../../components/header/WebHeader';
import {WebView} from 'react-native-webview';
import io from 'socket.io-client';
import {useToast} from '@gluestack-ui/themed';
const socket = io.connect(`https://api.yemek.club`);

const {width, height} = Dimensions.get('window');
const OnlinePayment = ({navigation, route}) => {
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expireMonth, setExpireMonth] = useState('');
  const [expireYear, setExpireYear] = useState('');
  const [cvc, setCvc] = useState('');
  const [htmlContent, setHtmlContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const {customerNote, takeAwayTime, paymentType, deliveryOptions, promoCode} = route.params;
  const toast = useToast();
  const sendOrder = async () => {
    if (cardHolderName.length == 0) {
      ErrorToastMessage(toast, 'Kart sahibinin adı alanı zorunludur');
    } else if (cardNumber.length != 16) {
      ErrorToastMessage(toast, 'Kart numarası 16 haneli olmalıdır');
    } else if (expireMonth.length != 2) {
      ErrorToastMessage(toast, 'Ay değeri 2 haneli olmalıdır');
    } else if (expireYear.length != 4) {
      ErrorToastMessage(toast, 'Yıl değeri 4 haneli olmalıdır örnek : 2025');
    } else if (cvc.length != 3) {
      ErrorToastMessage(toast, 'CVV değeri 3 haneli olmalıdır');
    } else {
      axios
        .post(
          `${axiosConfig.serverIp}/createOrderW3D`,
          {
            customerData: {
              customerName: MainStore.myData.name + MainStore.myData.surName,
              customerId: MainStore.myData._id,
              city: MainStore.myData.city ? MainStore.myData.city : 'istanbul',
              customerAddress: MainStore.selectedAddress.fullAdress,
            },
            restaurantData: {
              rId: MainStore.cartItem[0].rId,
              rName: MainStore.cartItem[0].restaurantName,
            },
            deliveryOptions,
            promoCode,
            paymentType,
            orderedFood: MainStore.cartItem,
            totalPaid: MainStore.calcutePrice,
            customerNote,
            takeAwayTime: `${takeAwayTime.hours}:${takeAwayTime.minutes}`,
            cardHolderName,
            cardNumber,
            expireMonth,
            expireYear,
            cvc,
          },
          {headers: {Authorization: `Bearer ${MainStore.token}`}}
        )
        .then((res) => {
          if (res.data.status == 'failure') {
            ErrorToastMessage(toast, res.data.errorMessage);
          } else if (res.data.error) {
            ErrorToastMessage(toast, res.data.error);
          } else if (res.data.status == 'success') {
            if (Platform.OS == 'web') {
              window.location.href = `https://api.yemek.club/user/api/redirect3DScreen/${res.data.threeDSHtmlContent}`;
            } else {
              setHtmlContent(res.data.threeDSHtmlContent);
              setModalVisible(!modalVisible);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleLoadEnd = ({nativeEvent}) => {
    const {url} = nativeEvent;
    if (url.includes('api/paymentStatus/success')) {
      setModalVisible(!modalVisible);
      socket.emit('newOrder', {
        rId: MainStore.cartItem[0].rId,
      });
      navigation.navigate('SuccessScreen');
    } else if (url.includes('/api/paymentStatus/failure')) {
      const splittedUrl = url.split('/');
      const splittedText = splittedUrl[splittedUrl.length - 1].replace(/%20/g, ' ');
      ErrorToastMessage(toast, splittedText);
      setModalVisible(!modalVisible);
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
        }}
      >
        {width > 1279 ? <WebHeader navigation={navigation} /> : <></>}
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={{marginLeft: 16}}
        >
          <Feather
            name='chevron-left'
            size={26}
          />
        </TouchableOpacity>

        <View style={{marginTop: 55, alignItems: 'center'}}>
          <View>
            <TextInput
              value={cardHolderName}
              onChangeText={(text) => setCardHolderName(text)}
              placeholder='Kart Sahibinin Adı'
              style={{
                width: width > 1279 ? 1279 : width - 28,
                backgroundColor: 'rgba(211,214,219,0.3)',
                height: 60,
                borderRadius: 10,
                paddingLeft: 12,
                fontFamily: 'Gilroy-Regular',
              }}
              placeholderTextColor='rgba(30,30,30,0.6)'
            />
          </View>
          <View style={{marginTop: 17}}>
            <TextInput
              value={cardNumber}
              onChangeText={(text) => setCardNumber(text)}
              placeholder='Kart Numarası'
              style={{
                width: width > 1279 ? 1279 : width - 28,
                backgroundColor: 'rgba(211,214,219,0.3)',
                height: 60,
                borderRadius: 10,
                paddingLeft: 12,
                fontFamily: 'Gilroy-Regular',
              }}
              placeholderTextColor='rgba(30,30,30,0.6)'
            />
          </View>
          <View style={{alignSelf: 'flex-start', marginLeft: 17, marginTop: 17}}>
            <Text
              style={{
                fontFamily: 'Gilroy-Regular',
                color: 'rgba(30,30,30,0.6)',
              }}
            >
              Kartın Son Kullanma Tarihi:
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 17,
            }}
          >
            <View style={{marginRight: 28}}>
              <TextInput
                value={expireMonth}
                onChangeText={(text) => setExpireMonth(text)}
                placeholder='Ay'
                style={{
                  width: width > 1279 ? 1279 / 2 - 28 : width / 2 - 28,
                  backgroundColor: 'rgba(211,214,219,0.3)',
                  height: 60,
                  borderRadius: 10,
                  paddingLeft: 12,
                  fontFamily: 'Gilroy-Regular',
                }}
                placeholderTextColor='rgba(30,30,30,0.6)'
              />
            </View>
            <View>
              <TextInput
                value={expireYear}
                onChangeText={(text) => setExpireYear(text)}
                placeholder='Yıl'
                style={{
                  width: width > 1279 ? 1279 / 2 - 28 : width / 2 - 28,
                  backgroundColor: 'rgba(211,214,219,0.3)',
                  height: 60,
                  borderRadius: 10,
                  paddingLeft: 12,
                  fontFamily: 'Gilroy-Regular',
                }}
                placeholderTextColor='rgba(30,30,30,0.6)'
              />
            </View>
          </View>
          <View style={{marginTop: 17, alignSelf: 'flex-start', marginLeft: 14}}>
            <TextInput
              value={cvc}
              onChangeText={(text) => setCvc(text)}
              placeholder='CVV'
              style={{
                width: width > 1279 ? 1279 / 2 - 28 : width / 2,
                backgroundColor: 'rgba(211,214,219,0.3)',
                height: 60,
                borderRadius: 10,
                paddingLeft: 12,
                fontFamily: 'Gilroy-Regular',
              }}
              placeholderTextColor='rgba(30,30,30,0.6)'
            />
          </View>
        </View>

        <View
          style={{
            position: width > 1279 ? 'relative' : 'absolute',
            bottom: -70,
            marginTop: 50,
            alignSelf: 'center',
          }}
        >
          <RedButton
            clickEvent={() => sendOrder()}
            text='Ödemeyi Gerçekleştir'
          />
        </View>
        <Modal
          animationType='slide'
          transparent
          visible={modalVisible}
          onRequestClose={() => setModalVisible(!modalVisible)}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 22,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            <ScrollView
              style={{
                maxWidth: 400,
                maxHeight: 700,
                backgroundColor: 'white',
                borderRadius: 8,
              }}
            >
              <View style={{margin: 10}}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View></View>
                  <TouchableOpacity
                    style={{height: 30, width: 30}}
                    onPress={() => setModalVisible(!modalVisible)}
                  >
                    <Feather
                      name='x-circle'
                      size={20}
                      color={'black'}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View style={{marginTop: 17}}>
                <WebView
                  source={{
                    uri: `https://api.yemek.club/user/api/redirect3DScreen/${htmlContent}`,
                  }}
                  style={{flex: 1, width: 300, height: 600}}
                  onLoadEnd={handleLoadEnd}
                />
              </View>
            </ScrollView>
          </View>
        </Modal>
      </ScrollView>
    </SafeAreaView>
  );
};

export default observer(OnlinePayment);
