import { View, Text, StyleSheet, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native'
import colorSchema from '../../config/colorSchema'
import WebHeader from '../../components/header/WebHeader'
import LogoHeader from '../../components/header/LogoHeader'
import WebFooter from '../../components/footer/WebFooter'
import LogoBackHeader from '../../components/header/LogoBackHeader'

const { width, height } = Dimensions.get('window')

const SalesAgree = ({ navigation, route }) => {
    return (
        <ScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: 'white' }}>
            <SafeAreaView style={{ marginLeft: width > 1279 ? 80 : 17 }}>
                <View>
                    {width > 1279 ?
                        <WebHeader navigation={navigation} />
                        :
                        <LogoBackHeader navigation={navigation} />
                    }
                </View>

                <View style={{ width: width > 1279 ? 894 : width - 34 }}>
                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>MESAFELİ SATIŞ SÖZLEŞMESİ</Text>
                        </View>

                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>1. KONU</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>ASözleşme’nin konusu, Müşteri’nin Satıcı’ya Uygulama üzerinden türünü/adını, adedini, satış bedelini, teslimat bedelini, ödeme şeklini belirlemek suretiyle siparişini verdiği Ürün’ün, Satıcı tarafından Müşteri’ye satışı ve teslimi hakkında 6502 sayılı Tüketicinin Korunması Hakkında Kanun, Mesafeli Sözleşmeler Yönetmeliği ve ilgili diğer yasal düzenlemeler uyarınca Taraflar’ın hak ve yükümlülüklerinin belirlenmesine ilişkindir.
                                Sözleşme’de büyük harf ile başlayan ifadeler Ön Bilgilendirme Formu’nda belirlenen anlamı taşımaktadır.
                            </Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>2. SATICI, ARACI HİZMET SAĞLAYICI, MÜŞTERİ VE ÜRÜN’E İLİŞKİN BİLGİLER</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>{`
ARACI HİZMET SAĞLAYICI BİLGİLERİ;\n
Unvan : LOC GROUP BİLİŞİM A.Ş. (YemekClub)\n
Adres : Altındağ Mah. 100. Yıl Blv. City Plaza No: 12/501 Muratpaşa/ANTALYA\n
Telefon	: +90 (850) 888 07 07\n
E-posta	: info@yemek.club.com.tr\n
Mersis No : 0609122022800001\n
SATICI BİLGİLERİ;\n
Unvan   : \n
Adres	: \n
Telefon		:\n
E-posta		: \n
Mersis No		:\n\n
MÜŞTERİ BİLGİLERİ;\n
Teslim edilecek kişi	: \n
Teslimat Adresi	: \n
Telefon		: \n
E-posta		: \n\n
FATURA BİLGİLERİ;\n
Adı/Soyadı/Unvanı	:\n
Adres			:\n
Telefon		: \n
E-posta		: \n\n
ÜRÜN AÇIKLAMASI\n
Ürün Açılaması : \n
Adet : \n
Peşin Fiyatı : \n
Satış Bedeli : \n `}</Text>
                        </View>

                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>3. ÜCRETLER VE TAHSİLİ</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>{`
3.1. Müşteri, sipariş öncesinde Uygulama üzerinden, Ürün satış fiyatı, teslimat masrafları, taşıma araçlarına ilişkin bedeller ve diğer her türlü vergi, resim, harç gibi ek ücretlere ilişkin olarak bilgilendirilmiştir.\n
3.2. Kredi kartı/banka kartı veya yemek kartı ile ödemeyi gerçekleştirmek için, Müşteri’nin, Uygulama’ya kayıt olurken kart bilgilerini girmesi gerekmektedir. Uygulama, sipariş esnasında Müşteri’nin kart bilgilerini gizli ve güvenli bir biçimde girmesini sağlayacak şekilde düzenlenmiştir.\n
3.3. Ürün satış fiyatı, teslimat masrafları, taşıma araçlarına ilişkin bedeller ve diğer her türlü vergi, resim, harç gibi ek ücretler, Müşteri’nin seçtiği kredi kartından/banka kartından veya yemek kartından, sipariş verildiğinde derhal tahsil edilecektir. Ürün satış fiyatı, YemekClub tarafından Satıcı adına tahsil edilmektedir. Müşteri kapıda ödeme seçeneğini de tercih edebilecektir. Müşteri tarafından kapıda ödeme seçeneğinin seçilmesi halinde, Ürün satış fiyatı ve varsa diğer her türlü bedeller ve her türlü vergi, resim, harç gibi ek ücretler Müşteri’den teslimat sırasında nakit olarak, kredi veya banka kartından veya yemek kartından tahsil edilecektir.\n
3.4. Herhangi bir nedenle Müşteri tarafından Ürün bedeli ödenmez veya yapılan ödeme banka/finans kuruluşu tarafından iptal edilirse, YemekClub veya Satıcı, teslim yükümlülüğünden kurtulmuş kabul edilir.\n
3.5. Her koşulda Müşteri, Satıcı’ya olan borçlarını ifada temerrüde düşmesi halinde, Satıcı’nın uğradığı zarar ve ziyanı tazmin edeceğini kabul eder.\n
3.6. Müşteri, kredi kartı ile yapılan ödemelerde kredi kartı faiz oranlarının, Müşteri ile banka arasındaki sözleşme kapsamında uygulanacağını kabul eder.\n
3.7. Kredi kartı veya banka kartı veya yemek kartı ile alınmış Ürün için cayma hakkının kullanılması veya Ürün’ün başka herhangi bir sebeple iadesi veya siparişin iptal edilmesi durumunda Müşteri’ye nakit para ile ödeme yapılmamaktadır. Ürün bedelinin kredi kartına veya banka kartına iadesi, ilgili bankanın prosedürlerine göre yapılmaktadır. Müşteri’nin tanımlı yemek kartı ile online ödeme yapması halinde, iadeler ilgili karta ilgili kuruluşun prosedürlerine göre yapılmaktadır Kapıda nakit ödeme yapılan hallerde, Müşteri tarafından ödenen ücret Satıcı tarafından Müşteri’ye iade edilecektir. Kapıda kredi kartı veya banka kartıyla ödeme yapılan hallerde, aynı gün içerisinde gelen talepler kapsamında Satıcı tarafından işlem pos cihazı üzerinden iptal edilecek ve ücret Müşteri’ye iade edilecektir. Aynı gün içerisinde yapılmayan talepler için iadeler ilgili bankanın prosedürlerine göre yapılacaktır. Kapıda ödeme esnasında, yemek kartı ile yapılan ödemelere ilişkin iadeler, Satıcı tarafından belirlenen prosedürlere göre yapılacaktır.\n
                            
                            `}</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>4. KULLANIM ŞARTLARI VE GENEL HÜKÜMLER</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>{`
4.1. YemekClub ve Satıcı, Ön Bilgilendirme Formu’nda yer alan kendisine ilişkin bilgilerin doğru ve güncel olduğunu, Müşteri’yi sahip olduğu tüm haklara ilişkin olarak Ön Bilgilendirme Formu ve Sözleşme ile bilgilendirdiğini kabul eder.
4.2. Satıcı, Ürün’ün mevzuata uygun olarak, sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım kılavuzları ile Müşteri’ye teslim edilmesinden sorumludur.
4.3. YemekClub veya Satıcı, Ön Bilgilendirme Formu ve Sözleşme’de ilettiği iletişim bilgileri üzerinden siparişe ilişkin sorularını yanıtlayacaktır. Bu kapsamda, Müşteri’nin kimliğini teyit etmeye yarayacak bilgileri, kişisel verilerin işlenmesine ilişkin prosedürleri uyarınca talep edilebilecektir.
4.4. Uygulama’da yer alan, ücretler dahil olmak üzere tüm bilgiler, Uygulama üzerinden değiştirilene kadar geçerlidir.
4.5. Uygulama’da kullanılan görsel öğeler temsili olabilir. Uygulama’da yer alan içerik ve görsel öğelerin tüm telif hakları saklı olup, kısmen veya tamamen kullanılması, çoğaltılması, yayınlanması veya yeniden işlenmesi yasaktır.
4.6. Müşteri, Uygulama’da Ürün’ün temel özellik ve nitelikleri, satış fiyatı, ödeme şekli, teslimatına ilişkin Ön Bilgilendirme Formunu okuyup bilgi sahibi olduğunu ve teyit ettiğini kabul eder. Müşteri’nin Ön Bilgilendirme Formunu elektronik ortamda teyit etmesi, mesafeli satış sözleşmesinin kurulmasından evvel, Müşteri’ye verilmesi gereken adresi, Ürün’e ait temel özellikleri, ürünlerin vergiler dahil fiyatını ve teslimata ilişkin diğer bedelleri, ödeme ve teslimat bilgileri de dahil olmak üzere mevzuat uyarınca verilmesi zorunlu tüm bilgileri doğru ve eksiksiz olarak edindiğini kabul eder.
4.7. Müşteri cayma hakkının kapsamı ve kullanılma koşullarına ilişkin olarak Ön Bilgilendirme Formu’nda detaylı olarak bilgilendirilmiştir.
4.8. Müşteri’ye, bir veya birden fazla ürünün bedelini ödemesine yarayan bir hediye çeki tanımlanmış olması halinde, hediye çekinin süresi içinde kullanılmaması veya Müşteri tarafından kullanım koşullarına aykırı ya da suiistimal edecek şekilde kullanılması nedeniyle siparişin iptal edilmesi durumunda, Müşteri hiçbir geri ödeme veya telafi talep edemez.
4.9. Müşteri ile sipariş esnasında kullanılan kredi kartı veya banka kartı hamilinin aynı kişi olmaması ve Ürün’ün, Uygulama üzerinden belirlenen alıcıya tesliminden evvel siparişte kullanılan karta ilişkin güvenlik açığı tespit edilmesi halinde, YemekClub, siparişi iptal edebilir.
4.10. Uygulama üzerinden YemekClub’ın kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine, elektronik uygulamalara ve/veya başka içeriklere bağlantı verilebilir. Bu bağlantılar Müşteri’ye yönlendirme kolaylığı sağlamak amacıyla konmuş olup, YemekClub’ın bağlantı verilen içeriğe yönelik herhangi bir sorumluluğu bulunmamaktadır. Uygulamadan ulaşılan diğer sitelerde veya uygulamalarda kendilerine ait gizlilik-güvenlik politikaları geçerlidir.
                            `}</Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>5. TESLİMAT</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>{`
5.1. Müşteri’nin Satıcı’dan YemekClub üzerinden satın almış olduğu Ürün, Uygulama üzerinden belirlediği, Ön Bilgilendirme Formu’nda açıkça yer alan adreste bulunan kişi/kişilere teslim edilecektir.
5.2. Uygulama üzerinden aksi açıkça belirtilmediği takdirde, Ürün’ün teslimat masrafları, taşıma araçlarına ilişkin bedeller ve diğer her türlü vergi, resim, harç gibi ek ücretler Müşteri’ye aittir.
5.3. Ürün’ün teslimatı, Satıcı’nın stokunun müsait olması ve ödemenin gerçekleşmesinden sonra Ön Bilgilendirme Formu’nda belirtilen süre içerisinde gerçekleştirir.
5.4. Herhangi bir nedenle Müşteri tarafından Ürün bedeli ödenmez veya yapılan ödeme banka/finans kuruşu tarafından iptal edilirse, YemekClub veya Satıcı, teslim yükümlülüğünden kurtulmuş kabul edilir. Herhangi bir sebeple banka/finans kuruluşu tarafından başarısız kodu gönderilen ancak banka/finans kuruşu tarafından YemekClub’a yapılan ödemelere ilişkin Müşteri, YemekClub’ın herhangi bir sorumluluğunun bulunmadığını kabul eder.
5.5. Müşteri tarafından kapıda ödeme seçeneğinin seçilmesi halinde, Ürün satış fiyatı ve varsa diğer her türlü bedeller ve her türlü vergi, resim, harç gibi ek ücretler Müşteri’den teslimat sırasında nakit olarak, kredi veya banka kartından veya yemek kartından tahsil edilecektir.
                            `}</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>6. MÜŞTERİ’NİN BEYAN VE TAAHHÜTLERİ</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>{`
6.1. Müşteri, Uygulama’ya üye olurken verdiği; vergi numarası, vergi dairesi bilgileri ile diğer tüm bilgilerin gerçeğe uygun olduğunu, YemekClub’ın/Satıcı’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, YemekClub’ın/Satıcı’nın ilk bildirimi üzerine derhal tazmin edeceğini taahhüt eder.
6.2. Müşteri, Ürün’ün temel nitelikleri, satış fiyatı ve ödeme şekli, teslimatı, cayma hakkı ve diğer hususlara ilişkin olarak sunulan Ön Bilgilendirme Formu’nu incelediğini, kendisine mevzuatta öngörülen gerekli tüm bilgilendirmenin yapıldığını ve elektronik ortamda teyit ettiğini beyan eder.
6.3. Müşteri, Uygulama’yı kullanırken mevzuata uygun davranmayı kabul eder. Müşteri, Uygulama’yı hiçbir şekilde kamu düzenine ve genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara uygun olmayan bir amaç için, başkalarının maddi ve manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, başkalarının Uygulama’yı kullanmasını önleyici veya zorlaştırıcı (spam, virüs, truva atı vb.) işlemlerde bulunamaz. Aksi takdirde, tüm hukuki ve cezai yükümlülük münhasıran Müşteri’ye aittir. Ayrıca mevzuat veya Sözleşme’nin ihlali nedeniyle, YemekClub’ın Müşteri’ye karşı yasal yollara başvurma hakkı saklıdır.
6.4. YemekClub’ın Uygulama’da ilan ettiği promosyonları/kampanyaları ve hediye çeklerinin geçerliğini dilediği zaman durdurma, güncelleme veya koşullarını değiştirme hakkı saklıdır. Müşteri’nin Uygulama üzerinden vereceği her sipariş öncesinde promosyon/kampanya, hediye çeki kullanım koşullarını incelemesi gerekmektedir.
6.5. Müşteri, aksi Uygulama üzerinden açıkça bildirilmediği takdirde, aynı siparişte birden fazla promosyon, kampanya veya hediye çekinden yararlanamaz.
6.6. Müşteri, Sözleşme konusu Ürünler’i teslim almadan önce muayene etmelidir. Tahrip olmuş, ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı Ürün’ü teslim alması veya eksik bir Ürüne ilişkin YemekClub’a veya Satıcı’ya derhal bildirimde bulunmaması halinde, sorumluluk Müşteri’ye aittir. Aksi takdirde, Müşteri tarafından teslim alınan Ürün’ün hasarsız, eksiksiz, sağlam ve Siparişteki taleplerine uygun olduğu kabul edilecektir.
6.7. Satıcı ve YemekClub, YemekClub’ın kendi belirlediği ve Uygulama üzerinden sipariş verilebilen kapsama alanlarında teslimat yapmaktadır. Uygulama üzerinden sipariş alınabilen bu kapsama alanları haricinde teslimat gerçekleştirilmez. Ürün, Müşteri’nin sipariş verirken bildirdiği adrese teslim edilecektir. Müşteri’nin sonradan adresi değiştirebilmesi mümkün değildir. Müşteri veya belirlediği alıcının teslimat adresinde bulunmaması, Ürün’ü teslim almaması veya hatalı adres bildirilmesi durumunda YemekClub veya Satıcı, hiçbir sorumluluk kabul etmez, bu durumda YemekClub veya Satıcı, edimini tam ve eksiksiz olarak yerine getirmiş kabul edilecektir. Bu nedenle, Müşteri’nin Ürün’ü geç teslim almasından kaynaklanan her türlü zarar ile Ürün’ün Müşteri’nin kusurundan dolayı beklemiş olması ve/veya Ürün’ün teslim edilememesi nedeniyle YemekClub’a veya Satıcı’ya iade edilmesinden dolayı oluşan giderler de Müşteri’ye aittir.
                            `}</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>7. SORUMLULUK</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>YemekClub’ın ve Satıcı’nın, Ön Bilgilendirme Formu ve Sözleşme’de yer alan yükümlülüklerinin Mücbir Sebep Halleri veya objektif olarak kendi kusurundan kaynaklanmayan diğer nedenlerle yerine getirilememesi durumunda hiçbir sorumluluğu bulunmamaktadır.</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>8. İLETİŞİM</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>Siparişe ilişkin tüm sorular için müşteri hizmetleri ile +90 (850) 888 07 07 numaralı telefon veya info@yemek.club.com.tr e-posta adresi ile irtibata geçilebilir.</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>9. YETKİLİ YARGILAMA MAKAMLARI</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>Taraflar, Sözleşme’den doğan ihtilaflarda mevzuat çerçevesinde belirlenen parasal sınırlar dahilinde Müşteri ve Satıcı’nın kayıtlı adresinin bulunduğu yerdeki Tüketici Hakem Heyetlerinin, parasal sınırları aşan uyuşmazlıklarda Müşteri’nin ve Satıcı’nın bulunduğu yerdeki Tüketici Mahkemelerinin yetkili olacağını kabul etmiştir.</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>10. DELİL SÖZLEŞMESİ</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>Müşteri, Sözleşme’den doğabilecek her türlü ihtilafta YemekClub’ın veya Satıcı’nın resmi defter ve ticari kayıtlarıyla, veri tabanında ve sunucularında tuttuğu elektronik bilgilerin, bilgisayar ve ses kayıtlarının, delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 34 }}>
                        <View>
                            <Text style={style.headText}>11. YÜRÜRLÜK</Text>
                        </View>
                        <View>
                            <Text style={style.descText}>Müşteri,’nin Uygulama’da “Ön Bilgilendirme Formu ve Mesafeli Satış Sözleşmesi’ni okudum ve kabul ediyorum” seçeneğini onaylaması ile Form ve Sözleşme’nin tüm koşulları derhal yürürlüğe girer.</Text>
                        </View>
                    </View>
                </View>

            </SafeAreaView >

            <View style={{ marginTop: 34 }}>
                <WebFooter navigation={navigation} />
            </View>


        </ScrollView >
    )
}

const style = StyleSheet.create({
    headText: { fontFamily: 'Gilroy-Bold', fontSize: 20, color: colorSchema.mainColor },
    descText: { fontFamily: 'Gilroy-Regular', fontSize: 15 }
})

export default SalesAgree