import {View, Text, ScrollView, Dimensions, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import colorSchema from '../../config/colorSchema';
import CheckIcon from '../../assets/svg/CheckIcon';
import RepeatIcon from '../../assets/svg/RepeatIcon';
import {Feather} from '@expo/vector-icons';
import {observer} from 'mobx-react-lite';
import MainStore from '../../store/MainStore';
import StarIcon from '../../assets/svg/StarIcon';
import LastStarIcon from '../../assets/svg/LastStarIcon';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import {useToast} from '@gluestack-ui/themed';
import ErrorToastMessage from '../feedback/ErrorToastMessage';
const {width, height} = Dimensions.get('window');

const LastOrderCard = (props) => {
  const toast = useToast();

  return (
    <TouchableOpacity
      onPress={() => props.navigation.navigate('LastOrderDetail', {id: props.data._id})}
      style={{
        width: width > 1279 ? 1279 : width - 16,
        alignSelf: 'center',
        backgroundColor: 'white',
        marginLeft: 8,
        marginTop: 17,
        borderBottomWidth: 1.5,
        borderBottomColor: '#00000033',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
      }}
    >
      <View style={{marginLeft: 3, marginTop: 10}}>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 11}}>
          <View>
            <Text
              style={{fontFamily: 'Gilroy-Regular', fontSize: 13, color: 'rgba(30,30,30,0.5)'}}
            >{`${props.orderDate} | ${props.orderTime} | ${props.restaurantName}`}</Text>
          </View>

          <View style={{marginRight: 22}}>
            <Feather
              name='chevron-right'
              size={18}
              color='black'
            />
          </View>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <View>
            <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
              {props.foodName}
            </Text>
          </View>
          <View style={{marginRight: 22}}>
            <Text
              style={{fontFamily: 'Gilroy-Bold', fontSize: 15, color: colorSchema.textColor, marginBottom: 6}}
            >{`${props.totalPaid} TL`}</Text>
          </View>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 13}}>
          <View>
            <CheckIcon />
          </View>
          <View style={{marginLeft: 25}}>
            <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
              {props.orderStatus}
            </Text>
          </View>
        </View>
        {!props.review && props.orderStatus == 'Teslim Edildi' ? (
          <View style={{flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10}}>
            <TouchableOpacity
              onPress={() => props.navigation.navigate('ReviewList', {id: props.data._id})}
              style={{
                paddingVertical: 9,
                width: width > 1279 ? 500 : width / 2 - 35,
                backgroundColor: 'rgba(211,214,219,0.3)',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <LastStarIcon />
                <Text style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5}}>
                  {MainStore.langPref == 'tr' ? 'Değerlendir' : 'Rate your order'}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                axios
                  .get(`${axiosConfig.serverIp}/repeatOrder/${props.data._id}`, {
                    headers: {Authorization: `Bearer ${MainStore.token}`},
                  })
                  .then((res) => {
                    if (res.data.error) {
                      ErrorToastMessage(toast, res.data.error);
                    } else {
                      if (res.data.handleError) {
                        ErrorToastMessage(
                          toast,
                          'Önceki siparişlerinizdeki ürünlerin bazıları kaldırılmış bazılarının fiyat bilgisi değiştirilmiş. Fiyat farkı olanlar güncellendi kaldırılmış olanlar eklenemedi.'
                        );
                        MainStore.repeatCart(res.data.orderedFood);
                        props.navigation.navigate('Cart');
                      }
                    }
                  });
              }}
              style={{
                paddingVertical: 9,
                width: width > 1279 ? 500 : width / 2 - 35,
                backgroundColor: 'rgba(211,214,219,0.3)',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <RepeatIcon />
                <Text style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5}}>
                  {MainStore.langPref == 'tr' ? 'Siparişimi Tekrarla' : 'Reorder'}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={{marginBottom: 10, alignItems: 'center'}}>
            <TouchableOpacity
              onPress={() => {
                axios
                  .get(`${axiosConfig.serverIp}/repeatOrder/${props.data._id}`, {
                    headers: {Authorization: `Bearer ${MainStore.token}`},
                  })
                  .then((res) => {
                    if (res.data.error) {
                      ErrorToastMessage(toast, res.data.error);
                    } else {
                      if (res.data.orderedFood) {
                        if (res.data.handleError) {
                          ErrorToastMessage(
                            toast,
                            'Önceki siparişlerinizdeki ürünlerin bazıları kaldırılmış bazılarının fiyat bilgisi değiştirilmiş. Fiyat farkı olanlar güncellendi kaldırılmış olanlar eklenemedi.'
                          );
                        }
                        MainStore.repeatCart(res.data.orderedFood);
                        props.navigation.navigate('Cart');
                      }
                    }
                  });
              }}
              style={{
                paddingVertical: 9,
                width: width / 2 - 35,
                backgroundColor: 'rgba(211,214,219,0.3)',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <RepeatIcon />
                <Text style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5}}>
                  Siparişimi Tekrarla
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default observer(LastOrderCard);
