import { View, Text, TextInput, StyleSheet, Image, Dimensions, TouchableOpacity } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import SearchIcon2 from '../../assets/svg/SearchIcon2'
const { width, height } = Dimensions.get('window')
const SearchBox = (props) => {
    return (
        <TouchableOpacity onPress={() => props.navigation.navigate('Search')} style={style.searchBoxArea}>
            <View>
                <View style={style.searchBox} >
                    <Text style={style.searchBoxText}>{MainStore.langPref == 'tr' ? 'Canın ne çekiyor' : 'What would I like to eat?'}</Text>

                </View>
            </View>
            <View style={style.searchBoxIconArea}>
                <SearchIcon2 />
            </View>
        </TouchableOpacity>
    )
}

const style = StyleSheet.create({
    searchBoxArea: { alignItems: width > 1279 ? 'flex-start' : 'center' },
    searchBox: { width: width > 1279 ? 743 : width - 48, height: 34, backgroundColor: 'rgba(211,214,219,0.30)', justifyContent: 'center', borderRadius: 20, paddingLeft: 44, marginBottom: 22 },
    searchBoxIconArea: { position: 'absolute', left: width > 1279 ? 10 : 30, top: 6 },
    searchBoxText: { color: 'rgba(30,30,30,0.5)', fontSize: 10, fontFamily: 'Gilroy-Light' }
})

export default observer(SearchBox)