import * as React from "react";
import Svg, { Path } from "react-native-svg";

function MapPinIcon(props) {
    return (
        <Svg
            width={14}
            height={19}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.34 6.837c0 3.398-4.219 8.22-6.49 8.22C4.578 15.058.36 9.9.36 6.5.36 2.942 3.503.346 6.85.346c3.345 0 6.49 2.596 6.49 6.49zm-4.327-.433a2.163 2.163 0 11-4.327 0 2.163 2.163 0 014.327 0zm-7.356 10.6a.65.65 0 000 1.299h10.385a.649.649 0 000-1.298H1.657z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default MapPinIcon;