import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import colorSchema from '../../config/colorSchema';
import RIcon from '../../assets/svg/RIcon';
import StarIcon from '../../assets/svg/StarIcon';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import {StyleSheet} from 'react-native';
import {FontAwesome5} from '@expo/vector-icons';
const PopularRest = (props) => {
  return (
    <TouchableOpacity
      onPress={() => {
        MainStore.changeIsTakeAway(false);
        props.navigation.navigate('Restaurant', {id: props.id});
      }}
    >
      <View style={{paddingBottom: 18, backgroundColor: colorSchema.cartColor}}>
        <View style={{marginLeft: 5, marginRight: 13}}>
          <Image
            source={{uri: props.restaurantCoverPhoto}}
            style={{width: 339, height: 132}}
          />
          {props.restaurantOpen ? (
            <></>
          ) : (
            <View
              style={{
                position: 'absolute',
                width: 339,
                height: 132,
                backgroundColor: 'rgba(0,0,0,0.7)',
              }}
            />
          )}
          {props.restaurantOpen ? (
            props?.campaign &&
            props.campaign.discount > 0 && (
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  left: 10,
                  top: 10,
                  backgroundColor: colorSchema.greenColor,
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                  borderRadius: 10,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FontAwesome5
                  name='percent'
                  color='white'
                  size={10}
                  style={{marginRight: 6}}
                />
                <Text
                  style={{
                    fontFamily: 'Gilroy-SemiBold',
                    color: 'white',
                    fontSize: 11,
                  }}
                >
                  Tüm Ürünlerde {props.campaign.discountType == 'percent' ? '%' : '₺'}
                  {props.campaign.discount} İndirim
                </Text>
              </TouchableOpacity>
            )
          ) : (
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                backgroundColor: colorSchema.mainColor,
                paddingHorizontal: 6,
                paddingVertical: 3,
                borderRadius: 10,
              }}
            >
              <Text style={{fontFamily: 'Gilroy-SemiBold', color: 'white'}}>Kapalı</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={{marginLeft: 10, marginTop: 9}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginRight: 6,
            }}
          >
            <View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{marginRight: 5}}>
                  <RIcon />
                </View>
                <View>
                  <Text
                    style={{
                      fontFamily: 'Gilroy-ExtraBold',
                      fontSize: 14,
                      color: colorSchema.textColor,
                    }}
                  >
                    {props.restaurantName} <Text style={{fontFamily: 'Gilroy-Regular'}}>{props.district}</Text>
                  </Text>
                </View>
              </View>
              <View>
                <Text style={{fontSize: 10, fontWeight: '400', lineHeight: 14}}>{`${props.deliveryTime}dk · Min ${
                  typeof props.minPrice == 'number' ? props.minPrice.toFixed(2) : parseFloat(props.minPrice).toFixed(2)
                } TL`}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 17,
                }}
              >
                <View style={{marginRight: 6}}>
                  <StarIcon />
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'Gilroy-Regular',
                      color: 'rgba(0,0,0,0.5)',
                    }}
                  >
                    {props.rating} <Text style={{color: 'rgba(0,0,0,0.25)'}}>({props.comments})</Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <View>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 10}}>{props.category}</Text>
            </View>
            <View style={{marginRight: 13}}>
              <TouchableOpacity
                onPress={() => {
                  MainStore.changeIsTakeAway(false);
                  props.navigation.navigate('Restaurant', {id: props.id});
                }}
                style={{
                  backgroundColor: colorSchema.greenColor,
                  paddingHorizontal: 8,
                  paddingVertical: 3,
                  borderRadius: 20,
                }}
              >
                <Text
                  style={{
                    color: 'white',
                    fontSize: 15,
                    fontFamily: 'Gilroy-Regular',
                  }}
                >
                  {MainStore.langPref == 'tr' ? 'Hemen Git' : 'Go Now'}{' '}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default observer(PopularRest);
