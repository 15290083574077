import {View, Text, StyleSheet, Dimensions, ScrollView} from 'react-native';
import React from 'react';
import {SafeAreaView} from 'react-native';
import colorSchema from '../../config/colorSchema';
import WebHeader from '../../components/header/WebHeader';
import LogoHeader from '../../components/header/LogoHeader';
import WebFooter from '../../components/footer/WebFooter';
import LogoBackHeader from '../../components/header/LogoBackHeader';

const {width, height} = Dimensions.get('window');

const Privacy = ({navigation, route}) => {
  return (
    <View style={{backgroundColor: 'white'}}>
      <SafeAreaView style={{marginLeft: width > 1279 ? 80 : 17}}>
        <View>{width > 1279 ? <WebHeader navigation={navigation} /> : <LogoBackHeader navigation={navigation} />}</View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginBottom: 120}}
        >
          <View style={{width: width > 1279 ? 894 : width - 34}}>
            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>Kişisel Verilerin Korunması ve Gizlilik Politikası</Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>İşbu Kişisel Verilerin Korunması ve Gizlilik Politikası;</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  ALTINDAĞ MAH. 100. YIL BLV. NO: 12 İÇ KAPI NO: 501 MURATPAŞA/ ANTALYA adresinde mukim LOC DANIŞMANLIK
                  A.Ş’nin (YemekClub) işbu Web Sitesi, Mobil Web, Mobil Uygulama siteleri aracılığıyla ve sair yollarla;
                  ne tür kişisel veriler topladığını, Bu kişisel verilerin nasıl ve ne amaçla kullanıldığını, Şirket’in
                  bu kişisel verileri kimlerle paylaşabileceğini, Şirket’in Web Sitesi, Mobil Web, Mobil Uygulama ve
                  sair yollarla topladığı kişisel veriler ile ilgili olarak kullanıcılarının/üyelerinin/müşterilerinin
                  (Kullanıcı) haklarının neler olduğunu ve bu hakları nasıl kullanabilecekleri konusunda bilgilendirmeyi
                  içermektedir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>Kişisel Verilerin Korunması Hakkında Bilgilendirme</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK yada Kanun) uyarınca; aşağıda belirtilen işleme
                  amacı ile bağlantılı ve sınırlı olacak şekilde Kullanıcıların Şirket ile paylaşmış olduğu işbu Kişisel
                  Verilerin Korunması ve Gizlilik Politikasında belirtilen kişisel verilerinin, işlenmelerini gerektiren
                  amaç çerçevesinde kaydedilecek, depolanacak, muhafaza edilecek, değiştirilecek, yeniden düzenlenecek,
                  kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacak, KVKK’nın öngördüğü
                  hallerde ve koşullarda, yurt içi veya yurt dışında üçüncü kişilere aktarılacak, devredilebilecek, elde
                  edilebilir hale getirilecek, sınıflandırılabilecek ve KVKK’da sayılan sair şekillerde işlenebilecek
                  olduğunu bildiririz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>İşlenen Kişisel Veriler, Kişisel Veri İşleme Amacı ve Dayanağı</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kanun ve hukuki prensipler gereği kişisel veriler; kişisel veri sahiplerinin açık rıza ve kabullerinin
                  alınması ile işlenebilmektedir. Kanun’un 6. maddesinde ‘özel nitelikli kişisel veriler’ sınırlı bir
                  şekilde sayılmıştır ve bunlar kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini,
                  mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel
                  hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik
                  verilerini içermektedir. Hazırlamış olduğumuz politika kapsamında belirtilen şekilde müşteri ve
                  kullanıcıların özel nitelikli kişisel verilerini işlememekteyiz. Genel nitelikteki kişisel
                  verilerinizi ise aşağıda belirttiğimiz hususlar sebebiyle işlemekteyiz.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Kanunlarda açıkça belirtilen sebeplerden kaynaklı YemekClub’ın verilerinizi işlemesi.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · YemekClub’ın kişisel veri sahibinin mücbir sebeplerden ötürü can ve mal güvenliğinin tehlikeye
                  girmesi halinde kişisel veri işleme faaliyetinin zorunlu olması ve kişisel veri sahibinin açık
                  rızasının mümkün olmaması.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Kişisel verilerinizin YemekClub tarafından işlenmesinin bir sözleşmenin kurulması veya ifasıyla
                  doğrudan doğruya ilgili ve gerekli olması.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Kişisel verilerinizin işlenmesinin YemekClub’ın hukuki yükümlülüğünü yerine getirebilmesi için
                  zorunlu olması.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Kişisel verilerinizin YemekClub tarafından işlenmesinin YemekClub’ın, sizlerin veya üçüncü kişilerin
                  haklarının tesisi, kullanılması veya korunması için zorunlu olması.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · YemekClub’ın meşru menfaatleri için kişisel veri işleme faaliyetinde bulunulmasının zorunlu olması.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası ile finans, muhasebe ve hukuk işlerinin
                  takibi.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Kurumsal iletişim faaliyetlerinin planlanması ve icrası ve kullanıcı talep ve/veya şikayetlerin
                  takibi.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>· Sözleşme süreçlerinin ve/veya hukuki taleplerin takibi.</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  · Sosyal medya hesaplarıyla bağlanılması durumunda Kullanıcının o kanallar aracılığıyla paylaşılmasına
                  onay verdiği bilgiler gibi Kullanıcıyı doğrudan veya dolaylı olarak tanımlamaya yönelik kişisel
                  veriler, Kullanıcıya daha iyi hizmet verebilmek, Kullanıcı ile sözleşme akdetmek, Kullanıcıya verilen
                  hizmetin devamı ve iyileştirilmesi, satış, pazarlama ve ticari iletişim yapılabilmesi, buna bağlı
                  operasyonel faaliyetlerin yürütülmesi/devam etmesi, istatistiki çalışmalar ve pazar araştırması
                  yapılabilmesi gibi amaçlarla işlenebilmektedir.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kişisel Veriler; Kullanıcılara daha iyi hizmet verebilmemiz adına Kullanıcıların, gerekli olduğu
                  durumlarda açık rızalarına istinaden, yasal düzenlemeler uyarınca veri işleme amacı ve hukuki sebepler
                  ile sınırlı olarak, bağlı ortaklıklarımıza ve/veya doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimize,
                  Şirketin faaliyetlerini yürütmek üzere ilgili sözleşmeler kapsamında hizmet aldığı ya da işbirliği
                  yaptığı yurt içi veya yurt dışındaki üçüncü kişilere aktarılabilecek, işbu Kişisel Verilerin Korunması
                  ve Gizlilik Politikası ile belirlenen amaçlar ve kapsam dışında kullanılmamak kaydı ile KVKK’nın
                  öngördüğü hallerde veri güvenliğine ilişkin tedbirler de alınarak KVKK’da sayılan şekillerde
                  işlenebilecektir.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kullanıcıya ait Kişisel Veriler ile gezinme ve trafik bilgileri; güvenlik ve yasalar karşısındaki
                  yükümlülüğümüzü ifa etmek amacıyla (suçla mücadele, devlet ve kamu güvenliğinin tehdidi benzeri ve
                  fakat bununla sınırlı olmamak üzere yasal veya idari olarak bildirim veya bilgi verme yükümlülüğümüzün
                  mevcut olduğu durumlarda) yasal olarak bu bilgileri talep etmeye yetkili olan ilgili kurum ve
                  kuruluşlar ile paylaşılabilecektir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>Kişisel Verilerle İlgili Haklar</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kullanıcı, işlenen Kişisel Verilerinin neler olduğunu ve işlenip işlenmediğini, işleme amacı
                  doğrultusunda kullanılıp kullanılmadığını öğrenebilir, verileri işlenmişse bu hususta bilgi talep
                  edebilir, yurt içi veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri öğrenebilir, eksik
                  veya yanlış işlenen Kişisel Veriler söz konusu ise veya işlenen Kişisel Verilerinde herhangi bir
                  değişiklik olması durumunda bu verilerin düzeltilmesini, değiştirilmesini, güncellenmesini, KVKK’nın
                  7. maddesinde öngörülen şartlar çerçevesinde silinmesini/yok edilmesini veya anonim hale
                  getirilmesini, bu işlemlerin (eksik veya yanlış işlenen Kişisel Verileri söz konusu ise, bu verilerin
                  düzeltilmesini veya KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini/yok edilmesini
                  veya anonim hale getirilmesini) Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini talep
                  edebilir; işlenen verilerin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhine bir
                  sonucun ortaya çıkmasına itiraz edebilir ve Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep edebilir.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>Kullanıcı, bu kapsamdaki haklarını KVKK madde 13/f.1 uyarınca:</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  o Şirketin kayıtlı e-posta adresine, Kullanıcıya ait güvenli elektronik imza ile imzalanmış bir
                  e-posta göndermek veya, o Kargo ile ıslak imzasını taşıyan bir dilekçe göndermek suretiyle Şirkete
                  başvuru yaparak, taleplerini iletebilir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>Kişisel Veri Saklama Süresi</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Şirket, KVKK uyarınca işlediği Kişisel Verileri yalnızca ilgili mevzuatta öngörülen veya mevzuatta bir
                  süre öngörülmemiş ise kişisel veri işleme amacının gerektirdiği süre kadar muhafaza eder. Tutulan
                  veriler, verinin işlenmesini gerektiren sebepler sona erdikten sonra silinir, yok edilir veya anonim
                  hale getirilir.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Örnek vermek gerekirse 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ile buna bağlı
                  yönetmelik uyarınca Kişisel Verilerin pazarlama veya tanıtım amaçları için kullanılacağına ilişkin
                  Kullanıcının onayının geri alındığı durumlarda, Kişisel Verilerin kayıtları bu tarihten itibaren 1 yıl
                  saklanır. Ticari elektronik iletinin içeriği ve gönderiye ilişkin diğer her türlü kayıt ise
                  gerektiğinde ilgili bakanlığa sunulmak üzere 3 yıl saklanır. 5651 Sayılı İnternet Ortamında Yapılan
                  Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun
                  uyarınca ise işlediğimiz trafik verileri 2 yıl saklanır ve süre bittikten sonra anonim hale getirilir.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Her halükârda Kişisel Verilerin birden fazla sebeple işlenmiş olması halinde, söz konusu verinin
                  işlenmesini gerektiren tüm nedenler ortadan kalktığında ilgili veri silinir, yok edilir veya anonim
                  hale getirilir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>Kişisel Verilerin Korunmasına İlişkin Önlemler</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kişisel Verilerin korunması Şirket için önemli bir konudur. Kişisel Verilere yetkisiz erişim veya bu
                  bilgilerin kaybı, hatalı kullanımı, ifşa edilmesi, değiştirilmesi veya imha edilmesine karşı
                  Kullanıcıyı korumak için gerekli teknik ve idari her türlü önlemi almaktayız. Kişisel Verileri
                  işlerken genel kabul görmüş güvenlik teknolojisi standartlarını kullanmaktayız.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kişisel Verileri gizli tutmayı, gizliliğin sağlanması ve güvenliği için gerekli teknik ve idari her
                  türlü tedbiri almayı ve gerekli özeni göstermeyi taahhüt etmekteyiz. Gerekli bilgi güvenliği
                  önlemlerini almamıza karşın, Şirkete ait Web Sitesine, Mobil Web’e ve sisteme yapılan saldırılar
                  sonucunda Kişisel Verilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, bu durumu
                  derhal sizlere ve Kişisel Verileri Koruma Kurulu’na bildirir ve gerekli önlemleri alırız.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>
                  Kişisel Verilerin Korunması ve Gizlilik Politikasında Yapılacak Değişiklikler
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  İşbu Kişisel Verilerin Korunması ve Gizlilik Politikasında zaman zaman değişiklik yapılabilir. Bu
                  değişiklikler, değiştirilmiş yeni Kişisel Verilerin Korunması ve Gizlilik Politikasının Web
                  Sitesinde/Mobil Web’de yayınlanmasıyla birlikte derhal geçerlilik kazanır. Bu sebeple Kullanıcının
                  işbu metni periyodik olarak gözden geçirmesini öneririz. Kullanıcı, varsa Kişisel Bilgileri de içeren
                  üyelik bilgilerini ve iletişim tercihlerini her zaman Web Sitesinde yer alan iletişim bilgilerinden
                  Şirkete ulaşarak da iletebilir. Talepler en kısa sürede değerlendirilerek uygulamaya alınacaktır.
                </Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Uygulanacak Hukuk, Yetkili Mahkeme ve İcra Daireleri İşbu Kişisel Verilerin Korunması ve Gizlilik
                  Politikası Türkiye Cumhuriyeti kanunlarına tabidir. Kişisel Verilerin Korunması ve Gizlilik
                  Politikasının uygulanmasından doğabilecek her türlü uyuşmazlığın çözümünde Antalya Mahkeme ve İcra
                  Daireleri yetkilidir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text style={style.headText}>İletişim İzni</Text>
              </View>
              <View>
                <Text style={style.descText}>
                  Kullanıcının Şirket ile paylaşmış olduğu Kişisel Veriler; satış-pazarlama amacıyla diğer şirketlerden
                  topladığımız bilgiler ile birleştirerek hizmetlerimizin içerik ve tanıtımlarımızın geliştirilmesi ve
                  kişileştirilmesi veya yeni hizmetler sunabilmek amacıyla ve her türlü iletişim mesajlarının
                  gönderilmesi amacıyla kullanılabilir. Bu veriler, aynı zamanda sadece Kullanıcıya sağlanacak
                  hizmetlerin kusursuz sunulabilmesi, olası gönderilerinizin sağlıklı şekilde teslim edilmesi, telefon,
                  sms ve/veya e-posta yoluyla bildirimlerimizin zamanında ulaştırılabilmesi amacıyla, sözleşme ilişkisi
                  içinde olduğumuz, veri güvenliği konusundaki hassasiyetimizi paylaşan, ilgili mevzuat hükümlerine
                  riayet eden 3. kişilerle, yalnızca ihtiyaç durumunda ve gerekli ölçüde paylaşılacaktır. Kullanıcı,
                  pazarlama amaçlı bu tanıtımları almamak veya söz konusu reklam, kişiselleştirme, yeniden hedefleme
                  uygulamalarında yer almak istemediği takdirde, taleplerini her zaman Web Sitesinde yer alan iletişim
                  bilgilerinden Şirkete ulaşarak da iletebilir.
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>

      <View style={{marginTop: 34}}>
        <WebFooter navigation={navigation} />
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  headText: {fontFamily: 'Gilroy-Bold', fontSize: 20, color: colorSchema.mainColor},
  descText: {fontFamily: 'Gilroy-Regular', fontSize: 15},
});

export default Privacy;
