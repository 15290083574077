import { View, Text, SafeAreaView, TouchableOpacity, ScrollView, Image, TextInput, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import SeeMoreCardR from '../../components/card/SeeMoreCardR'
import SeeMoreCardF from '../../components/card/SeeMoreCardF'
import FilterBox from '../../components/filter/FilterBox'
import FilterSheet from '../../components/bottomsheet/FilterSheet'
import { SearchBar } from 'react-native-screens'
import SearchBox from '../../components/search/SearchBox'
import WebHeader from '../../components/header/WebHeader'

const { width, height } = Dimensions.get('window')
const SeeMore = ({ navigation, route }) => {
    const { data } = route.params
    const [showFilter, setShowFilter] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('')
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView style={{ maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0 }}>
                {width > 1279 ?
                    <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
                        <WebHeader navigation={navigation} />
                    </View>
                    :
                    <></>
                }
                <View style={{ marginBottom: 17 }}>
                    <View style={{ marginTop: 10, marginLeft: 25 }}>
                        <TouchableOpacity onPress={() => navigation.goBack()}>
                            <Feather name='chevron-left' size={25} color='black' />
                        </TouchableOpacity>
                    </View>

                    <View style={{ marginTop: 10, marginLeft: 12 }}>
                        <Text style={{ color: colorSchema.textColor, fontSize: 28, fontFamily: 'Gilroy-Bold' }}>{route.params.title}</Text>
                    </View>

                    <View style={{ marginTop: 17 }}>
                        <SearchBox navigation={navigation} />
                    </View>

                    <View>
                        {!route.params.food ? <FilterBox setShowFilter={setShowFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} /> : <></>}
                    </View>

                </View>
                <View>
                    {
                        route.params.food ?
                            width > 1279 ?
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {data.map((item) =>
                                        <TouchableOpacity style={{ marginBottom: 17 }} onPress={() => navigation.navigate('FoodDetail', { id: item._id })} key={item._id}>
                                            <SeeMoreCardF category={item.category} comments={item.comments.length} rating={item.rating} district={item.district} name={item.name} restaurantName={item.restaurantName} foodImage={item.foodImage} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                                :

                                data.map((item) =>
                                    <TouchableOpacity onPress={() => navigation.navigate('FoodDetail', { id: item._id })} key={item._id}>
                                        <SeeMoreCardF category={item.category} comments={item.comments.length} rating={item.rating} district={item.district} name={item.name} restaurantName={item.restaurantName} foodImage={item.foodImage} />
                                    </TouchableOpacity>
                                )



                            :
                            width > 1279 ?
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {data.map((item) =>
                                        <View key={item._id}>
                                            <SeeMoreCardR navigation={navigation} deliveryTime={item.deliveryTime} minPrice={item.minPrice} id={item._id} category={item.category} comments={item.comments.length} rating={item.rating} district={item.district} restaurantName={item.restaurantName} restaurantCoverPhoto={item.restaurantCoverPhoto} />
                                        </View>

                                    )}

                                </View>
                                :
                                <View>
                                    {data.map((item) =>
                                        <View key={item._id}>
                                            <SeeMoreCardR navigation={navigation} deliveryTime={item.deliveryTime} minPrice={item.minPrice} id={item._id} category={item.category} comments={item.comments.length} rating={item.rating} district={item.district} restaurantName={item.restaurantName} restaurantCoverPhoto={item.restaurantCoverPhoto} />
                                        </View>

                                    )}

                                </View>
                    }

                </View>
            </ScrollView>
            {showFilter ? <FilterSheet selectedFilter={selectedFilter} data={data} sort={selectedFilter == 'sort' ? true : false} category={selectedFilter == 'category' ? true : false} navigation={navigation} visible={showFilter} setVisible={setShowFilter} /> : <></>}
        </SafeAreaView>
    )
}

export default SeeMore