import { View, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import colorSchema from '../../config/colorSchema'
import CartIcon from '../../assets/svg/CartIcon'

const WebCartButton = (props) => {
    return (
        <View>
            <TouchableOpacity onPress={() => props.navigation.navigate('Cart')} style={{ borderRadius: 20, backgroundColor: colorSchema.mainColor, width: 129, height: 36, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ marginLeft: 12 }}>
                    <CartIcon />
                </View>
                <View style={{ marginRight: 12 }}>
                    <Text style={{ color: 'white', fontFamily: 'Gilroy-SemiBold', fontSize: 19 }}>{`${MainStore.calcutePrice} TL`}</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export default observer(WebCartButton)