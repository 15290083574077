import {View, Text, ImageBackground, Dimensions} from 'react-native';
import React from 'react';
import colorSchema from '../../config/colorSchema';
import StarIcon from '../../assets/svg/StarIcon';
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon';
const {width, height} = Dimensions.get('window');

const RestFoodCard = (props) => {
  return width > 1279 ? (
    <View style={{flexDirection: 'row', marginTop: 19}}>
      <View>
        <ImageBackground
          source={{uri: props.foodImage}}
          style={{width: 125, height: 125, borderRadius: 5}}
        >
          <View style={{position: 'absolute', right: 4.33, top: 4.33}}>
            {props.restaurantOpen ? <PlusCircleIcon /> : <></>}
          </View>
        </ImageBackground>
        {props.restaurantOpen ? (
          <></>
        ) : (
          <View
            style={{
              position: 'absolute',
              width: 125,
              height: 125,
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}
          />
        )}
      </View>
      <View style={{width: width - 160, marginLeft: 15}}>
        <View style={{marginBottom: 22}}>
          <Text
            style={{
              fontFamily: 'Gilroy-Medium',
              fontSize: 16,
              color: colorSchema.textColor,
              lineHeight: 19,
            }}
          >
            {props.name}
          </Text>
        </View>
        <View style={{marginBottom: 4}}>
          <Text
            numberOfLines={1}
            style={{
              fontFamily: 'Gilroy-Regular',
              fontSize: 14,
              color: '#9E9B9B',
            }}
          >
            {props.description}
          </Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flexDirection: 'row'}}>
            <View>
              <StarIcon />
            </View>
            <View style={{marginLeft: 4}}>
              <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>
                {props.rating} ({props.comments})
              </Text>
            </View>
          </View>
          <View style={{marginLeft: 30}}>
            <View style={{marginRight: 9}}>
              {props?.discountType == 'fixed' ? (
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      fontSize: 11,
                      fontFamily: 'Gilroy-Medium',
                      color: 'gray',
                      lineHeight: 15,
                      textDecorationLine: 'line-through',
                    }}
                  >
                    {' '}
                    {`${props.price} TL`}
                  </Text>
                  <Text
                    style={{
                      color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    {`${props.price - props.discount} TL`}
                  </Text>
                </View>
              ) : props?.discountType == 'percent' ? (
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      fontSize: 11,
                      fontFamily: 'Gilroy-Medium',
                      color: 'gray',
                      lineHeight: 15,
                      textDecorationLine: 'line-through',
                    }}
                  >
                    {' '}
                    {`${props.price} TL`}
                  </Text>
                  <Text
                    style={{
                      color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    {`${props.price - (props.price * props.discount) / 100} TL`}
                  </Text>
                </View>
              ) : (
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                      fontFamily: 'Gilroy-Medium',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    {`${props.price} TL`}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View style={{flexDirection: 'row', marginTop: 19}}>
      <View style={{width: width - 160}}>
        <View style={{marginBottom: 22}}>
          <Text
            style={{
              fontFamily: 'Gilroy-Medium',
              fontSize: 16,
              color: colorSchema.textColor,
              lineHeight: 19,
            }}
          >
            {props.name}
          </Text>
        </View>
        <View style={{marginBottom: 4}}>
          <Text
            style={{
              fontFamily: 'Gilroy-Regular',
              fontSize: 14,
              color: '#9E9B9B',
            }}
          >
            {props.description}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View style={{flexDirection: 'row'}}>
            <View>
              <StarIcon />
            </View>
            <View style={{marginLeft: 4}}>
              <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>
                {props.rating} ({props.comments})
              </Text>
            </View>
          </View>
          <View style={{marginRight: 9}}>
            {props?.discountType == 'fixed' ? (
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: 'Gilroy-Medium',
                    color: 'gray',
                    lineHeight: 15,
                    textDecorationLine: 'line-through',
                  }}
                >
                  {' '}
                  {`${props.price} TL`}
                </Text>
                <Text
                  style={{
                    color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 16,
                  }}
                >
                  {' '}
                  {`${parseFloat(props.price - props.discount).toFixed(2)} TL`}
                </Text>
              </View>
            ) : props?.discountType == 'percent' ? (
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: 'Gilroy-Medium',
                    color: 'gray',
                    lineHeight: 15,
                    textDecorationLine: 'line-through',
                  }}
                >
                  {' '}
                  {`${props.price} TL`}
                </Text>
                <Text
                  style={{
                    color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 16,
                  }}
                >
                  {' '}
                  {`${parseFloat(props.price - (props.price * props.discount) / 100).toFixed(2)} TL`}
                </Text>
              </View>
            ) : (
              <View style={{flexDirection: 'row'}}>
                <Text
                  style={{
                    color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.textColor,
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 16,
                  }}
                >
                  {' '}
                  {`${props.price} TL`}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <View>
        {props.foodImage ? (
          <ImageBackground
            source={{uri: props?.foodImage}}
            style={{width: 125, height: 125, borderRadius: 5}}
          >
            <View style={{position: 'absolute', right: 4.33, top: 4.33}}>
              {props.restaurantOpen ? <PlusCircleIcon /> : <></>}
            </View>
          </ImageBackground>
        ) : (
          <View style={{width: 125, height: 125, borderRadius: 5, backgroundColor: '#F9F9F9'}}>
            <View style={{position: 'absolute', right: 4.33, top: 4.33}}>
              {props.restaurantOpen ? <PlusCircleIcon /> : <></>}
            </View>
          </View>
        )}
        {props.restaurantOpen ? (
          <></>
        ) : (
          <View
            style={{
              position: 'absolute',
              width: 125,
              height: 125,
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}
          />
        )}
      </View>
    </View>
  );
};

export default RestFoodCard;
