import { View, Text, TextInput, ScrollView, Dimensions } from 'react-native'
import React, { useState } from 'react'
import GoBackTitle from '../../components/header/GoBackTitle'
import colorSchema from '../../config/colorSchema'
import RedButton from '../../components/button/RedButton'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage'
import WebHeader from '../../components/header/WebHeader'
import SuccessToastMessage from '../../components/feedback/SuccessToastMessage'
import { useToast } from '@gluestack-ui/themed'

const EditProfile = ({ navigation }) => {
    const { name, surName, email } = MainStore.myData
    const [nameArea, setNameArea] = useState(name)
    const [surNameArea, setSurNameArea] = useState(surName)
    const [emailArea, setEmailArea] = useState(email)
    const toast = useToast()
    const editProfile = () => {
        axios.post(`${axiosConfig.serverIp}/editMyProfile`, {
            name: nameArea,
            surName: surNameArea,
            email: emailArea
        }, { headers: { Authorization: `Bearer ${MainStore.token}` } }).then(res => {
            if (res.data.success) {
                MainStore.EditProfile(nameArea, surNameArea, emailArea)
                SuccessToastMessage(toast, MainStore.langPref == 'tr' ? 'İşleminiz başarıyla gerçekleşmiştir' : 'Your transaction was successful')
                navigation.goBack()
            }
            else if (res.data.error) {
                ErrorToastMessage(toast, res.data.error)

            }
            else {
                ErrorToastMessage(toast, MainStore.langPref == 'tr' ? MainStore.langPref == 'tr' ? 'Bir sorunla karşılaştık lütfen daha sonra tekrar deneyiniz.' : 'We ran into a problem, please try again later' : 'We ran into a problem, please try again later.')

            }
        })
    }

    const { width, height } = Dimensions.get('window')

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView showsVerticalScrollIndicator={false} style={{ marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0 }}>

                <View>
                    {
                        width > 1279 ?
                            <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
                                <WebHeader navigation={navigation} />
                            </View>
                            :
                            <></>
                    }
                    <View>
                        <GoBackTitle title={MainStore.langPref == 'tr' ? 'Hesap Bilgilerim' : 'My Account Details'} navigation={navigation} />
                    </View>

                    <View>
                        <View style={{ marginTop: 27, marginLeft: 11 }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(30,30,30,0.8)' }}>{MainStore.langPref == 'tr' ? 'Ad' : 'Name'}</Text>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <TextInput style={{ paddingLeft: 5, fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor, padding: 10 }} placeholder={MainStore.langPref == 'tr' ? 'Adınız' : 'Name'} value={nameArea} onChangeText={(text) => setNameArea(text)} />
                            </View>
                        </View>

                        <View style={{ marginTop: 27, marginLeft: 11 }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(30,30,30,0.8)' }}>{MainStore.langPref == 'tr' ? 'Soyad' : 'Surname'}</Text>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <TextInput style={{ paddingLeft: 5, fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor, padding: 10 }} placeholder={MainStore.langPref == 'tr' ? 'Soyadınız' : 'Surname'} value={surNameArea} onChangeText={(text) => setSurNameArea(text)} />
                            </View>
                        </View>

                        <View style={{ marginTop: 27, marginLeft: 11 }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(30,30,30,0.8)' }}>{MainStore.langPref == 'tr' ? 'E-Posta' : 'E-Mail'}</Text>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <TextInput style={{ paddingLeft: 5, fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor, padding: 10 }} placeholder={MainStore.langPref == 'tr' ? 'E-Posta Adresiniz' : 'E-Mail'} value={emailArea} onChangeText={(text) => setEmailArea(text)} />
                            </View>
                        </View>




                    </View>

                </View>


            </ScrollView>
            <View style={{ position: width > 1279 ? 'relative' : 'absolute', marginTop: 50, bottom: 20, alignSelf: 'center' }}>
                <RedButton clickEvent={editProfile} text={MainStore.langPref == 'tr' ? 'Kaydet' : 'Save'} />
            </View>
        </View>
    )
}

export default observer(EditProfile)