import {
  View,
  Text,
  ImageBackground,
  Dimensions,
  Image,
  TextInput,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Feather, FontAwesome} from '@expo/vector-icons';
import HeartIcon from '../../assets/svg/HeartIcon';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';
import StarIcon from '../../assets/svg/StarIcon';
import colorSchema from '../../config/colorSchema';
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon';
import RestFoodCard from '../../components/card/RestFoodCard';
import SearchBox from '../../components/search/SearchBox';
import axios from 'axios';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import axiosConfig from '../../config/axiosConfig';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import ToastMessage from '../../components/feedback/ErrorToastMessage';
import RedButtonCart from '../../components/button/RedButtonCart';
import MinusIcon from '../../assets/svg/MinusIcon';
import TrashIcon from '../../assets/svg/TrashIcon';
import PlusIcon from '../../assets/svg/PlusIcon';
import WebCartSheet from '../../components/cart/WebCartSheet';
import WebHeader from '../../components/header/WebHeader';
import TimeIcon from '../../assets/svg/TimeIcon';
const {width, height} = Dimensions.get('window');
const Restaurant = ({route, navigation}) => {
  const getDayIndexMondaySunday = (date) => (date.getDay() === 0 ? 6 : date.getDay() - 1);
  const [appReady, setAppReady] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(true);
  const [restData, setRestData] = useState(null);
  const [foodData, setFoodData] = useState(null);
  const [liked, setLiked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const {id} = route.params;
  useEffect(() => {
    if (MainStore.auth) {
      axios
        .post(
          `${axiosConfig.serverIp}/getAuthRestaurant/${id}`,
          {neighbourhood: MainStore.selectedAddress.neighbourhood},
          {headers: {Authorization: `Bearer ${MainStore.token}`}}
        )
        .then((res) => {
          setRestData(res.data.restaurantData);
          setFoodData(res.data.foodData);
          setLiked(res.data.restaurantData.favorite);
          res.data.restaurantData.visible ? setRestaurantOpen(true) : setRestaurantOpen(false);
          setAppReady(true);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`${axiosConfig.serverIp}/getRestaurant/${id}`)
        .then((res) => {
          setRestData(res.data.restaurantData);
          setFoodData(res.data.foodData);
          res.data.restaurantData.visible ? setRestaurantOpen(true) : setRestaurantOpen(false);
          setAppReady(true);
        })
        .catch((err) => console.log(err));
    }
  }, [appReady]);

  return appReady ? (
    <ScrollView style={{backgroundColor: 'white'}}>
      <ScrollView style={{marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0}}>
        {width > 1279 ? (
          <View>
            <WebHeader navigation={navigation} />
            <SearchBox navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View style={{flexDirection: width > 1279 ? 'row' : 'column'}}>
          <View style={{maxWidth: width > 1279 ? 955 : width, marginRight: 26}}>
            <View>
              <ImageBackground
                source={{uri: restData?.restaurantCoverPhoto}}
                style={style.restCover}
              >
                <View style={{marginTop: 45, flexDirection: 'row', justifyContent: 'space-between'}}>
                  <TouchableOpacity
                    onPress={() => navigation.goBack()}
                    style={{
                      backgroundColor: 'white',
                      width: 30,
                      height: 30,
                      borderRadius: 50,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: 18,
                    }}
                  >
                    <Feather
                      name='chevron-left'
                      color='black'
                      size={23}
                    />
                  </TouchableOpacity>
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        if (MainStore.auth) {
                          axios
                            .get(`${axiosConfig.serverIp}/addFavoriteRestaurant/${id}`, {
                              headers: {Authorization: `Bearer ${MainStore.token}`},
                            })
                            .then((res) => {
                              if (res.data.error) {
                              } else {
                                MainStore.updateFavoriteList(res.data);
                                setLiked(!liked);
                              }
                            });
                        } else {
                        }
                      }}
                      style={{marginRight: 18}}
                    >
                      <HeartIcon liked={liked} />
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>
            </View>

            <View style={{marginLeft: 19}}>
              <View
                style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 29}}
              >
                <View>
                  <Text style={{fontFamily: 'Gilroy-Heavy', fontSize: 24, color: '#1E1E1E'}}>
                    {restData.restaurantName}{' '}
                    <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 22, color: 'rgba(0,0,0,0.5)'}}>
                      ({restData.district})
                    </Text>
                  </Text>
                </View>
                <View style={{marginRight: 19}}>
                  <TouchableOpacity onPress={() => navigation.navigate('RestaurantAbout', {data: restData})}>
                    <RightArrowIcon />
                  </TouchableOpacity>
                </View>
              </View>

              <View style={{flexDirection: 'row', marginTop: 17, justifyContent: 'space-between'}}>
                <View>
                  <View>
                    <Text
                      style={{fontSize: 10, fontWeight: '400', lineHeight: 14}}
                    >{`${restData.deliveryTime}dk · Min ${restData.minPrice} TL`}</Text>
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View>
                      <StarIcon />
                    </View>
                    <View style={{marginLeft: 5, marginRight: 9}}>
                      <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>
                        {`${restData.rating} (${restData.comments.length})`}{' '}
                      </Text>
                    </View>
                    <View>
                      <TouchableOpacity
                        onPress={() => navigation.navigate('RestaurantComment', {data: restData.comments, restData})}
                      >
                        <RightArrowIcon />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View>
                    <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>{restData.category}</Text>
                  </View>
                </View>
                <View>
                  <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 28}}>
                    <View>
                      <TimeIcon color={restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor} />
                    </View>
                    <View>
                      <Text
                        style={{
                          fontFamily: restaurantOpen ? 'Gilroy-Regular' : 'Gilroy-Bold',
                          fontSize: 13,
                          color: restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor,
                        }}
                      >
                        {restaurantOpen
                          ? MainStore.langPref == 'tr'
                            ? 'Açık'
                            : 'Open'
                          : MainStore.langPref
                          ? 'Kapalı'
                          : 'Close'}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 10, color: colorSchema.textColor}}>
                      {Object.values(restData.workingHours)[getDayIndexMondaySunday(new Date())]}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{marginTop: 14, marginLeft: 13}}>
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
              >
                <TouchableOpacity
                  onPress={() => {
                    setSelectedCategory('All');
                    foodData.map((item) => {
                      item.visible = true;
                    });
                  }}
                  style={{
                    backgroundColor: selectedCategory == 'All' ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)',
                    paddingHorizontal: 12,
                    paddingVertical: 4,
                    borderRadius: 10,
                    marginRight: 5,
                  }}
                >
                  <Text
                    style={{
                      color: selectedCategory == 'All' ? 'white' : 'black',
                      fontFamily: 'Gilroy-Bold',
                      fontSize: 15,
                    }}
                  >
                    Hepsi
                  </Text>
                </TouchableOpacity>

                {restData.subCategory.map((item, index) => (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedCategory(item);
                      foodData.map((element) => {
                        if (element.subCategory == item) {
                          element.visible = true;
                        } else {
                          element.visible = false;
                        }
                      });
                    }}
                    key={index}
                    style={{
                      backgroundColor: selectedCategory == item ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)',
                      paddingHorizontal: 12,
                      paddingVertical: 4,
                      borderRadius: 10,
                      marginRight: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: selectedCategory == item ? 'white' : 'black',
                        fontFamily: 'Gilroy-Bold',
                        fontSize: 15,
                      }}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            </View>

            <View style={{marginTop: 17}}>
              <View>
                <View style={{marginTop: 20, marginLeft: 14}}>
                  <Text style={{color: colorSchema.textColor, fontSize: 18, fontFamily: 'Gilroy-SemiBold'}}>
                    {selectedCategory == 'All' ? 'Tümünü Gör' : selectedCategory}
                  </Text>
                </View>
                <View style={{marginLeft: 14}}>
                  {foodData.map((item, index) =>
                    item.visible ? (
                      <View key={item._id}>
                        <TouchableOpacity
                          onPress={() =>
                            restaurantOpen ? navigation.navigate('FoodDetail', {id: item._id}) : console.log('kapali')
                          }
                        >
                          <RestFoodCard
                            discount={item?.discount}
                            discountType={item?.discountType}
                            rating={item.rating}
                            comments={item.comments.length}
                            restaurantOpen={restaurantOpen}
                            name={item.name}
                            description={item.description}
                            foodImage={item?.foodImage}
                            price={
                              typeof item.price == 'number' ? item.price.toFixed(2) : parseFloat(item.price).toFixed(2)
                            }
                          />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View key={index}></View>
                    )
                  )}
                </View>
              </View>
            </View>
          </View>

          {width > 1279 ? <WebCartSheet navigation={navigation} /> : <></>}
        </View>
      </ScrollView>
    </ScrollView>
  ) : (
    <LoadingSpinner />
  );
};

const style = StyleSheet.create({
  restCover: {width: width > 1279 ? 1000 : width, height: width > 1279 ? 320 : 230},
});

export default observer(Restaurant);
