import { View, Text, SafeAreaView, Dimensions } from 'react-native'
import React from 'react'
import { WebView } from 'react-native-webview';

const { width, height } = Dimensions.get('window')
const TDSecure = () => {

    return (
        <SafeAreaView style={{ flex: 1 }}>
           
        </SafeAreaView>
    )
}

export default TDSecure