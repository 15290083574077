import {View, Text, ScrollView, TouchableOpacity, Image, Dimensions} from 'react-native';
import React, {useEffect, useState} from 'react';
import HeaderSheet from '../../components/header/HeaderSheet';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
import SearchBox from '../../components/search/SearchBox';
import FilterBox from '../../components/filter/FilterBox';
import AllRestaurantCard from '../../components/card/AllRestaurantCard';
import ScreenTitle from '../../components/header/ScreenTitle';
import SearchBoxInput from '../../components/search/SearchBoxInput';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import MainStore from '../../store/MainStore';
import FilterSheet from '../../components/bottomsheet/FilterSheet';
import {observer} from 'mobx-react';
import WebHeader from '../../components/header/WebHeader';

const {width, height} = Dimensions.get('window');

const Search = ({navigation}) => {
  const [value, setValue] = useState('');
  const [searched, setSearched] = useState(null);
  const [appReady, setAppReady] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');

  const onChangeText = async (text) => {
    setValue(text);
    await axios
      .post(`${axiosConfig.serverIp}/searchRestaurant/${text}`, {
        province: MainStore.selectedAddress.province,
        district: MainStore.selectedAddress.district,
        neighbourhood: MainStore.selectedAddress.neighbourhood,
      })
      .then((res) => {
        setSearched(res.data);
      })
      .catch((e) => {});
  };
  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView style={{maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0}}>
        {width > 1279 ? (
          <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <View>
            <HeaderSheet navigation={navigation} />
          </View>
        )}
        <View style={{backgroundColor: 'white', flex: 1}}>
          {width > 1279 ? (
            <></>
          ) : (
            <View>
              <ScreenTitle
                title={MainStore.langPref == 'tr' ? 'Arama' : 'Search'}
                navigation={navigation}
              />
            </View>
          )}

          <ScrollView>
            <View style={{marginTop: 10, marginLeft: 5}}>
              <SearchBoxInput
                value={value}
                onChangeText={(text) => onChangeText(text)}
              />
            </View>

            <View>
              <FilterBox
                setShowFilter={setShowFilter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </View>

            <View style={{marginTop: 17}}>
              <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal={width > 500 ? true : false}
              >
                {value.length > 0 ? (
                  searched?.map((item, index) => (
                    <View key={index}>
                      <AllRestaurantCard
                        restaurantOpen={item.visible}
                        id={item._id}
                        deliveryTime={item.deliveryTime}
                        minPrice={item.minPrice}
                        navigation={navigation}
                        category={item.category}
                        comments={item.comments.length}
                        rating={item.rating}
                        district={item.district}
                        restaurantName={item.restaurantName}
                        restaurantCoverPhoto={item.restaurantCoverPhoto}
                      />
                    </View>
                  ))
                ) : (
                  <></>
                )}
              </ScrollView>
            </View>
          </ScrollView>
          {showFilter ? (
            <FilterSheet
              selectedFilter={selectedFilter}
              data={searched}
              sort={selectedFilter == 'sort' ? true : false}
              category={selectedFilter == 'category' ? true : false}
              navigation={navigation}
              visible={showFilter}
              setVisible={setShowFilter}
            />
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default observer(Search);
