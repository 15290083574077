import {View, Text, Dimensions, Image, TouchableOpacity, ScrollView, Alert} from 'react-native';
import React, {useRef, useState} from 'react';
import GoBackTitle from '../../components/header/GoBackTitle';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import {SafeAreaView} from 'react-native-safe-area-context';
import TrashIcon from '../../assets/svg/TrashIcon';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import WebHeader from '../../components/header/WebHeader';
import {
  ButtonGroup,
  AlertDialog,
  Button,
  ButtonText,
  CloseIcon,
  AlertDialogBackdrop,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogCloseButton,
  Icon,
  AlertDialogBody,
  AlertDialogFooter,
} from '@gluestack-ui/themed';
import {useToast} from '@gluestack-ui/themed';

const {width, height} = Dimensions.get('window');
const MyAddress = ({navigation}) => {
  const [selected, setSelected] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isChangeDialog, setIsChangeDialog] = useState(false);
  const onClose = () => setIsOpen(false);
  const onClose2 = () => setIsChangeDialog(false);
  const cancelRef = useRef(null);
  const cancelRef2 = useRef(null);
  const toast = useToast();
  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0}}
      >
        {width > 1279 ? (
          <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View>
          <View>
            {MainStore.myData.adress.length > 1 ? (
              <GoBackTitle
                title={MainStore.langPref == 'tr' ? 'Adreslerim' : 'My Addresses'}
                navigation={navigation}
              />
            ) : (
              <SafeAreaView style={{marginTop: 10, marginLeft: 12}}>
                <Text style={{color: colorSchema.textColor, fontSize: 28, fontFamily: 'Gilroy-Bold'}}>Adreslerim</Text>
              </SafeAreaView>
            )}
          </View>

          <View style={{marginTop: 17, marginLeft: 12}}>
            <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 18, color: '#393939'}}>
              {MainStore.langPref == 'tr' ? 'Kayıtlı Adreslerim' : 'My Addresses'}
            </Text>
          </View>

          <ScrollView>
            {MainStore.myData.adress.map((item, index) => (
              <TouchableOpacity
                onPress={() => {
                  if (MainStore?.cartItem?.length > 0) {
                    setSelected({item, index});
                    setIsChangeDialog(!isChangeDialog);
                  } else {
                    MainStore.changeAddress(index);
                    navigation.goBack();
                  }
                }}
                key={index}
                style={{marginTop: 17, marginLeft: 12}}
              >
                <View>
                  <Text style={{fontSize: 20, fontFamily: 'Gilroy-Medium', color: colorSchema.textColor}}>
                    {item.title}
                  </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <View style={{width: width / 2}}>
                    <Text
                      style={{fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor}}
                    >{`${item.neighbourhood}. ${item.fullAdress} ${item.province}/${item.district}`}</Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate('EditAddress', {
                          title: item.title,
                          province: item.province,
                          district: item.district,
                          neighbourhood: item.neighbourhood,
                          fullAdress: item.fullAdress,
                          primary: item.primary,
                          index,
                          _id: item._id,
                        });
                      }}
                      style={{alignItems: 'center', width: 30, height: 30, flexDirection: 'row'}}
                    >
                      <View>
                        <Feather
                          name='edit'
                          size={14}
                          color='#1E1E1E'
                        />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setSelected({item, index});
                        setIsOpen(!isOpen);
                      }}
                      style={{alignItems: 'center', width: 30, height: 30, marginRight: 17, flexDirection: 'row'}}
                    >
                      <View style={{marginRight: 17}}>
                        <TrashIcon
                          width={16}
                          height={16}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </TouchableOpacity>
            ))}

            <TouchableOpacity
              onPress={() => navigation.navigate('AddAddress')}
              style={{marginTop: 17}}
            >
              <View style={{flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 36}}>
                <View>
                  <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 16, color: '#393939'}}>
                    {MainStore.langPref == 'tr' ? 'Yeni Adres Ekle' : 'Add New Address'}
                  </Text>
                </View>
                <View>
                  <Feather
                    name='chevron-down'
                    size={20}
                    color='black'
                  />
                </View>
              </View>
            </TouchableOpacity>
            <View style={{marginBottom: 200}} />
          </ScrollView>

          <AlertDialog
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <AlertDialogBackdrop />
            <AlertDialogContent>
              <AlertDialogHeader>
                <Heading size='lg'>Adresimi Kaldır</Heading>
                <AlertDialogCloseButton>
                  <Icon as={CloseIcon} />
                </AlertDialogCloseButton>
              </AlertDialogHeader>
              <AlertDialogBody>
                <Text size='sm'>{`"${selected?.item?.title}" Adresinizi kaldırmak istediğinize emin misiniz ?`}</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <ButtonGroup space='lg'>
                  <Button
                    variant='outline'
                    action='secondary'
                    onPress={() => {
                      setIsOpen(false);
                    }}
                  >
                    <ButtonText>Vazgeç</ButtonText>
                  </Button>
                  <Button
                    bg='$error600'
                    action='negative'
                    onPress={() => {
                      if (MainStore.myData.adress.length > 1) {
                        axios
                          .get(`${axiosConfig.serverIp}/deleteMyAdress/${selected.index}`, {
                            headers: {Authorization: `Bearer ${MainStore.token}`},
                          })
                          .then((res) => {
                            if (res.data.success) {
                              MainStore.deleteMyAddress(selected.index);
                            }
                          });
                      } else {
                        ErrorToastMessage(toast, 'Yeni bir adres girmeden bu adresinizi silemezsiniz.');
                      }
                      setIsOpen(false);
                    }}
                  >
                    <ButtonText>Sil</ButtonText>
                  </Button>
                </ButtonGroup>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <AlertDialog
            isOpen={isChangeDialog}
            onClose={() => {
              setIsChangeDialog(false);
            }}
          >
            <AlertDialogBackdrop />
            <AlertDialogContent>
              <AlertDialogHeader>
                <Heading size='lg'>Adresimi Değiştir</Heading>
                <AlertDialogCloseButton>
                  <Icon as={CloseIcon} />
                </AlertDialogCloseButton>
              </AlertDialogHeader>
              <AlertDialogBody>
                <Text size='sm'>{`"${selected?.item?.title}" Adresinizi değiştirmek istediğinize emin misiniz ?`}</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <ButtonGroup space='lg'>
                  <Button
                    variant='outline'
                    action='secondary'
                    onPress={() => {
                      setIsChangeDialog(false);
                    }}
                  >
                    <ButtonText>Vazgeç</ButtonText>
                  </Button>
                  <Button
                    bg='$error600'
                    action='negative'
                    onPress={() => {
                      MainStore.changeAddress(selected.index);
                      navigation.replace('Home');
                      setIsChangeDialog(false);
                    }}
                  >
                    <ButtonText>Değiştir</ButtonText>
                  </Button>
                </ButtonGroup>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </View>
      </ScrollView>
    </View>
  );
};

export default observer(MyAddress);
