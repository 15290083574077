import { View, Text, TextInput, StyleSheet, Image, Dimensions, TouchableOpacity } from 'react-native'
import React from 'react'
import MainStore from '../../store/MainStore'
import { observer } from 'mobx-react'
import SeachIcon2 from '../../assets/svg/SearchIcon2'
const { width, height } = Dimensions.get('window')
const SearchBoxInput = (props) => {
    return (
        <View style={style.searchBoxArea}>
            <View>
                <TextInput value={props.value} onChangeText={props.onChangeText} placeholder={MainStore.langPref == 'tr' ? 'Canın ne çekiyor' : 'What would I like to eat?'} placeholderTextColor={'rgba(30,30,30,0.5)'} style={style.searchBox} />
            </View>
            <View style={style.searchBoxIconArea}>
                <SeachIcon2 />
            </View>
        </View>
    )
}

const style = StyleSheet.create({
    searchBoxArea: { alignItems: width > 1279 ? 'flex-start' : 'center' },
    searchBox: { width: width > 1279 ? 743 : width - 48, height: 34, backgroundColor: 'rgba(211,214,219,0.30)', justifyContent: 'center', borderRadius: 20, paddingLeft: 44, marginBottom: 22, color: 'rgba(30,30,30,0.5)', fontSize: 10, fontFamily: 'Gilroy-Light' },
    searchBoxIconArea: { position: 'absolute', left: width > 1279 ? 10 : 36, top: 6 },
})

export default observer(SearchBoxInput)
