import { View, Text, Dimensions, Platform } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { WebView } from 'react-native-webview'

const { width, height } = Dimensions.get('window')

const LiveChat = () => {
    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            {Platform.OS == 'web'
                ?
                <iframe src="https://tawk.to/chat/63ea98c64742512879132121/1gp669hr2" style={{ width: '100%', height: '100vh' }} />
                :
                <WebView
                    source={{ uri: `https://tawk.to/chat/63ea98c64742512879132121/1gp669hr2` }}
                    style={{ flex: 1, width, height }}
                />
            }

        </View>
    )
}

export default LiveChat                                                                                                               