import { View, Text, SafeAreaView, Dimensions, TouchableOpacity, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import GoBackTitle from '../../components/header/GoBackTitle';
import colorSchema from '../../config/colorSchema';
import RepeatIcon from '../../assets/svg/RepeatIcon';
import { observer } from 'mobx-react';
import MainStore from '../../store/MainStore';
import WebHeader from '../../components/header/WebHeader';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import { useToast } from '@gluestack-ui/themed';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage'

const { width, height } = Dimensions.get('window');
const LastOrderDetail = ({ navigation, route }) => {
  const { id } = route.params;
  const toast = useToast();

  const [appReady, setAppReady] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`${axiosConfig.serverIp}/getOrderDetails/${id}`, { headers: { Authorization: `Bearer ${MainStore.token}` } })
      .then((res) => {
        setData(res.data);
        setAppReady(true);
      });
  }, []);

  function formatDate(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate;
  }

  function formatTime(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  return appReady ? (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <ScrollView style={{ maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0 }}>
        {width > 1279 ? (
          <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View>
          <GoBackTitle
            title={MainStore.langPref == 'tr' ? 'Geçmiş Siparişlerim' : 'History'}
            navigation={navigation}
          />
        </View>

        <View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginHorizontal: 7,
              marginBottom: 7,
            }}
          >
            <View>
              <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>
                {MainStore.langPref == 'tr' ? 'Sipariş Numarası:' : 'Order Number:'}
              </Text>
            </View>
            <View>
              <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>{data._id}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginHorizontal: 7,
              marginBottom: 7,
            }}
          >
            <View>
              <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>
                {MainStore.langPref == 'tr' ? 'Sipariş Tarihi:' : 'Order Date:'}
              </Text>
            </View>
            <View>
              <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>{`${formatDate(
                new Date(data.created_at)
              )} | ${formatTime(new Date(data.created_at))}`}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginHorizontal: 7,
              marginBottom: 7,
            }}
          >
            <View>
              <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>
                {MainStore.langPref == 'tr' ? 'Restoran:' : 'Restaurant:'}
              </Text>
            </View>
            <View>
              <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15 }}>{data.restaurantData.rName}</Text>
            </View>
          </View>

          {data.paymentType !== 'takeAway' && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginHorizontal: 7,
                marginBottom: 7,
              }}
            >
              <View>
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>
                  {MainStore.langPref == 'tr' ? 'Sipariş Durumu:' : 'Order Status:'}
                </Text>
              </View>
              <View>
                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15, color: colorSchema.mainColor }}>
                  {data.orderStatus}
                </Text>
              </View>
            </View>
          )}

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 7, marginBottom: 7 }}>
            <View>
              <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15 }}>
                {MainStore.langPref == 'tr' ? 'Teslimat Adresi:' : 'Delivery Address:'}
              </Text>
            </View>
            <View style={{ width: width / 2, paddingRight: width > 1279 ? 0 : 0 }}>
              <Text style={{ textAlign: 'right', fontFamily: 'Gilroy-Medium', fontSize: 15 }}>
                {data.customerData.customerAddress}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginLeft: 7 }}>
          <View style={{ marginTop: 21 }}>
            <View style={{ marginBottom: 11 }}>
              <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: colorSchema.textColor, lineHeight: 19 }}>
                {MainStore.langPref == 'tr' ? 'Sepet Detayı' : 'Basket Detail'}
              </Text>
            </View>

            <View style={{ marginBottom: 28 }}>
              {data.orderedFood.map((item) => (
                <View key={item._id}>
                  <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor }}>
                    {item.name}
                  </Text>
                </View>
              ))}
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 7 }}>
              <View>
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 14, color: colorSchema.textColor }}>
                  {MainStore.langPref == 'tr' ? 'Toplam Tutar:' : 'Total Amount:'}{' '}
                  <Text style={{ fontFamily: 'Gilroy-Regular' }}>{`${data.totalPaid} TL`}</Text>
                </Text>
              </View>
              <View>
                <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 14, color: colorSchema.textColor }}>
                  {MainStore.langPref == 'tr' ? 'Ödenen Tutar:' : 'Paid Amount:'}{' '}
                  <Text style={{ fontFamily: 'Gilroy-Regular' }}>{`${data.totalPaid} TL`}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 39, marginLeft: 7 }}>
          <View style={{ marginBottom: 11 }}>
            <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: colorSchema.textColor, lineHeight: 19 }}>
              {MainStore.langPref == 'tr' ? 'Ödeme Detayı' : 'Payment Detail'}
            </Text>
          </View>
          <View>
            <Text style={{ fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, fontSize: 15 }}>
              {data.paymentType == 'takeAway'
                ? 'Gel-Al'
                : data.paymentType == 'onlinePayment'
                  ? 'Online Ödeme'
                  : 'Kapıda Ödeme'}
            </Text>
          </View>
        </View>

        <View style={{ marginTop: 39 }}>
          <TouchableOpacity
            onPress={() => {
              axios
                .get(`${axiosConfig.serverIp}/repeatOrder/${props.data._id}`, {
                  headers: { Authorization: `Bearer ${MainStore.token}` },
                })
                .then((res) => {
                  if (res.data.error) {
                    ErrorToastMessage(toast, res.data.error);
                  } else {
                    if (res.data.orderedFood) {
                      if (res.data.handleError) {
                        ErrorToastMessage(
                          toast,
                          'Önceki siparişlerinizdeki ürünlerin bazıları kaldırılmış bazılarının fiyat bilgisi değiştirilmiş. Fiyat farkı olanlar güncellendi kaldırılmış olanlar eklenemedi.'
                        );
                      }
                      MainStore.repeatCart(res.data.orderedFood);
                      props.navigation.navigate('Cart');
                    }
                  }
                });
            }}
            style={{
              paddingVertical: 9,
              width,
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: width > 1279 ? 100 : 0,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <RepeatIcon />
              <Text style={{ fontSize: 14, fontFamily: 'Gilroy-Regular', color: colorSchema.textColor, marginLeft: 5 }}>
                {MainStore.langPref == 'tr' ? 'Siparişimi Tekrarla' : 'Reorder'}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(LastOrderDetail);
