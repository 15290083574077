import {View, Text, StyleSheet, Dimensions, ScrollView} from 'react-native';
import React from 'react';
import {SafeAreaView} from 'react-native';
import colorSchema from '../../config/colorSchema';
import WebHeader from '../../components/header/WebHeader';
import LogoHeader from '../../components/header/LogoHeader';
import WebFooter from '../../components/footer/WebFooter';
import WebHeaderB1 from '../../components/header/WebHeaderB1';
import LogoBackHeader from '../../components/header/LogoBackHeader';

const {width, height} = Dimensions.get('window');

const FAQ = ({navigation, route}) => {
  return (
    <View
      style={{backgroundColor: 'white'}}
    >
      <SafeAreaView>
        <View>
          {width > 1279 ? <WebHeaderB1 navigation={navigation} /> : <LogoBackHeader navigation={navigation} />}
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{marginBottom: 120}}
        >
          <View style={{width: width > 1279 ? 894 : width - 34, marginLeft: width > 1279 ? 80 : 17}}>
            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Yemek.Club’a nasıl üye olunur?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Yemek.Club uygulaması üzerinden ilk sayfada çıkacak olan “Kayıt ol” butonuna bastığınızda üye formumuz
                  çıkacaktır. Üye formunda gerekli bilgileri tamamladıktan sonra üye olmak istediğin e-posta adresiniz
                  ve belirlediğiniz şifreniz ile dilediğinizde giriş yapabilirsiniz. Yemek.Club üzerinden gerçekleşen
                  işlemler sipariş içeriğine göre Mesafeli Sözleşmeler Yönetmeliği kapsamında olmayabilecektir.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Yemek.Club’da yemek seçimi nasıl yapılır?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Yemek.Club’da yemek seçimi yapabilmek için üye girişi yapmanız gerekmektedir. Üye girişi yaptıktan
                  sonra yemek siparişi vermek istediğiniz restoranı seçebilir veya filtreleri kullanarak arama
                  çubuğundan dilediğiniz restoranı bulabilirsiniz. Restoranı seçtikten sonra restoran menüsünde sipariş
                  etmek istediğiniz ürünün üzerine tıklayarak sepetinize ekleyebilirsiniz. Yemek siparişi verebilmek
                  için en az bir ürün sepetinize eklemelisiniz ve minimum sepet tutarını tamamlamalısınız. Son olarak
                  ödemeye geçmeden önce isterseniz ürün notu ekleyerek sipariş verdiğiniz restorana not yazabilirsiniz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Üyeliğimi nasıl silebilirim?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Üyeliğinizi dilediğiniz zaman “Hesabım” sekmesindeki “Hesabımı sil” butonundan sonlandırabilirsiniz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Yemek.Club şifremi nasıl değiştirebilirim?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Şifrenizi değiştirmek için “Hesabım” sekmesinden “Şifremi değiştir” butonuna tıklamanız yeterli.
                  Mevcut şifrenizi girdikten sonra yeni şifrenizi kolaylıkla değiştirebilirsiniz. Eski şifrenizi
                  kullanmamaya ve şifrenizi sık sık değiştirmeye özen göstermenizi tavsiye ederiz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  Yemek.Club’da verilen siparişin ödemesi nasıl yapılır?
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Yemek siparişinizin ödemesi sipariş anında veya kapıda ödeme yöntemiyle yapılabilir. Online Kredi
                  Kartı veya restoranın tercih ettiği ödeme yöntemleriyle ödeminizi yapabilirsiniz. Sipariş vermek
                  istediğiniz yemeği seçtikten sonra “Ödeme Şekli” butonuna tıklayarak istediğiniz ödeme yöntemini
                  tercih edebilirsiniz. Online kredi kartı yöntemini tercih ettiğinizde kart numaranızı ve CVV bilgisini
                  girerek ödemenizi yapabilirsiniz.
                </Text>
              </View>
            </View>

            <View style={{marginTop: 34}}>
              <View>
                <Text
                  selectable={false}
                  style={style.headText}
                >
                  İlave sorularınız için aşağıdaki adreslerden bize ulaşabilirsiniz.
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  İletişim Bilgileri;
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Mail: info@yemek.club
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Telefon: 0850 888 0707
                </Text>
              </View>

              <View style={{marginTop: 17}}>
                <Text
                  selectable={false}
                  style={style.descText}
                >
                  Kişisel Verilerin Korunması, Kullanıcı Sözleşmesi –Gizlilik Politikası gibi metinlerimize sitemizin
                  alt sekmesindeki başlıklardan ulaşabilirsiniz.
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      {width > 1279 && (
        <View style={{marginTop: 34}}>
          <WebFooter navigation={navigation} />
        </View>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  headText: {fontFamily: 'Gilroy-Bold', fontSize: 20, color: colorSchema.mainColor},
  descText: {fontFamily: 'Gilroy-Regular', fontSize: 15},
});

export default FAQ;
