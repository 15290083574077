import { View, Text, TouchableOpacity, ScrollView, ImageBackground } from 'react-native'
import React from 'react'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon'
import colorSchema from '../../config/colorSchema'


const WaterDealerCard = (props) => {




    return (
        <View key={props.indexNumber} style={{ marginTop: 17, borderBottomWidth: 1, borderBottomColor: 'rgba(211,214,219,0.3)', paddingBottom: 5 }}>
            <View style={{ marginTop: 6 }}>
                <TouchableOpacity onPress={() => {

                }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ marginLeft: 12 }}>
                        </View>
                        <View >
                            <Text style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: 15, lineHeight: 19 }}>{props.dealerName} <Text style={{ fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.4)' }}>({props.district})</Text></Text>
                        </View>

                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 12 }}>
                        <View>
                            <RightArrowIcon />
                        </View>
                    </View>
                </TouchableOpacity>

                <View style={{ marginLeft: 12 }}>
                    <Text style={{ fontFamily: 'Gilroy-Light', fontSize: 10 }}>30-40 dk</Text>
                </View>

                <View style={{ marginTop: 16 }}>
                    <ScrollView horizontal showsHorizontalScrollIndicator={false}>

                        {props.waterData.map((item, index) =>
                            <View key={index} style={{ marginLeft: 12 }}>
                                <TouchableOpacity onPress={() => props.navigation.navigate('WaterDetail', { id: item._id })}>
                                    <ImageBackground source={{ uri: item.foodImage }} style={{ width: 150, height: 150 }} >

                                        <TouchableOpacity onPress={()=>console.log(item._id)} style={{ position: 'absolute', bottom: 10, right: 10 }}>
                                            <PlusCircleIcon />
                                        </TouchableOpacity>


                                    </ImageBackground>
                                </TouchableOpacity>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 14 }}>{item.name}</Text>
                                </View>
                                <View>
                                    <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 14, color: colorSchema.greenColor }}>{`${item.price} TL`}</Text>
                                </View>
                            </View>
                        )}




                    </ScrollView>
                </View>
            </View>
        </View>
    )
}

export default WaterDealerCard