import * as React from "react"
import Svg, { Path } from "react-native-svg"

const StarIcon = (props) => (
    <Svg
        width={12}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M4.852.908a1.259 1.259 0 0 1 2.295 0l.812 1.71c.186.392.546.664.963.727l1.814.274c1.05.159 1.469 1.5.71 2.27l-1.313 1.33c-.302.306-.44.746-.368 1.178l.31 1.878c.179 1.087-.918 1.916-1.857 1.403l-1.623-.887a1.236 1.236 0 0 0-1.19 0l-1.623.887c-.939.513-2.036-.316-1.857-1.403l.31-1.878a1.364 1.364 0 0 0-.368-1.178L.554 5.889c-.76-.77-.34-2.111.71-2.27l1.814-.274c.416-.063.777-.335.963-.728L4.852.908Z"
            fill="#1E1E1E"
            
        />
    </Svg>
)

export default StarIcon
