import {View, Text, Image, ScrollView, TouchableOpacity, Dimensions} from 'react-native';
import React, {useState} from 'react';
import TakeAwayIcon2 from '../../assets/svg/TakeAwayIcon2';
import colorSchema from '../../config/colorSchema';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';
import StarIcon from '../../assets/svg/StarIcon';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import TimeIcon from '../../assets/svg/TimeIcon';

const {width, height} = Dimensions.get('window');
const TakeAwayCard = (props) => {
  return (
    <View style={{marginTop: 17}}>
      <TouchableOpacity
        onPress={() => {
          if (props.restaurantOpen) {
            MainStore.changeIsTakeAway(true);
            props.navigation.navigate('TakeAwayRestorant', {id: props.restData._id});
          }
        }}
        style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7}}
      >
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{marginLeft: 12}}>
            <TakeAwayIcon2 />
          </View>
          <View style={{marginLeft: 6}}>
            <Text style={{fontFamily: 'Gilroy-ExtraBold', fontSize: 15, lineHeight: 19}}>
              {props.restData.restaurantName}{' '}
              <Text style={{fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.4)'}}>({props.restData.district})</Text>
            </Text>
          </View>
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 12}}>
          <View>
            <TimeIcon color={props.restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor} />
          </View>
          <View style={{marginRight: 14}}>
            {MainStore.langPref == 'tr' ? (
              <Text
                style={{
                  fontFamily: props.restaurantOpen ? 'Gilroy-Regular' : 'Gilroy-Regular',
                  fontSize: 13,
                  color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor,
                }}
              >
                {props.restaurantOpen ? 'Açık' : 'Kapalı'}
              </Text>
            ) : (
              <Text
                style={{
                  fontFamily: props.restaurantOpen ? 'Gilroy-Regular' : 'Gilroy-Regular',
                  fontSize: 13,
                  color: props.restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor,
                }}
              >
                {props.restaurantOpen ? 'Open' : 'Closed'}
              </Text>
            )}
          </View>
          <View>
            <RightArrowIcon />
          </View>
        </View>
      </TouchableOpacity>
      <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 12, marginBottom: 30}}>
        <View style={{marginRight: 6}}>
          <StarIcon />
        </View>
        <View style={{marginRight: 3}}>
          <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(0,0,0,0.5)'}}>
            {props.restData.rating}
            <Text style={{color: 'rgba(0,0,0,0.25)'}}> ({props.restData.comments.length})</Text>
          </Text>
        </View>
        <View>
          <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 10, color: 'rgba(0,0,0,0.8)'}}></Text>
        </View>
      </View>
      <View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {props.foodList.map((item) => (
            <TouchableOpacity
              onPress={() => {
                MainStore.changeIsTakeAway(true);
                props.navigation.navigate('FoodDetail', {id: item._id});
              }}
              key={item._id}
              style={{marginLeft: 12}}
            >
              <View>
                {item.foodImage ? (
                  <Image
                    source={{uri: item.foodImage}}
                    style={{width: width > 1279 ? 200 : 100, height: width > 1279 ? 200 : 100}}
                  />
                ) : (
                  <View
                    style={{
                      width: width > 1279 ? 200 : 100,
                      height: width > 1279 ? 200 : 100,
                      backgroundColor: '#F9F9F9',
                    }}
                  />
                )}
              </View>
              <View>
                <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 14}}>{item.name}</Text>
              </View>
              <View>
                <Text
                  style={{fontFamily: 'Gilroy-Regular', fontSize: 14, color: colorSchema.greenColor}}
                >{`${item.price} TL`}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

export default observer(TakeAwayCard);
