import {View, Text, TouchableOpacity, Dimensions, Image} from 'react-native';
import React, {useState} from 'react';
import {SafeAreaView} from 'react-native';
import GoBackTitle from '../../components/header/GoBackTitle';
import CartIcon from '../../assets/svg/CartIcon';
import {Feather} from '@expo/vector-icons';
import WalletIcon from '../../assets/svg/WalletIcon';
import SuccessIcon from '../../assets/svg/SuccessIcon';
import colorSchema from '../../config/colorSchema';
import MinusIcon from '../../assets/svg/MinusIcon';
import PlusIcon from '../../assets/svg/PlusIcon';
import TrashIcon from '../../assets/svg/TrashIcon';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import {ScrollView} from 'react-native';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import WebHeader from '../../components/header/WebHeader';
import {useToast} from '@gluestack-ui/themed';
const Cart = ({navigation}) => {
  const {width, height} = Dimensions.get('window');
  const [amount, setAmount] = useState(1);

  const toast = useToast();
  return MainStore.cartItem.length > 0 ? (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView style={{maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0}}>
        {width > 1279 ? (
          <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View>
          <GoBackTitle
            navigation={navigation}
            title='Sepet'
          />
        </View>

        <View style={{marginLeft: 12}}>
          <View
            style={{
              marginTop: 17,
              flexDirection: 'row',
              justifyContent: width > 1279 ? 'center' : 'space-evenly',
              alignItems: 'center',
            }}
          >
            <View style={{backgroundColor: 'black', padding: 6, borderRadius: 50}}>
              <CartIcon />
            </View>
            <View style={{marginHorizontal: 30}}>
              <Feather
                name='chevron-right'
                size={13}
                color={'black'}
              />
            </View>
            <View>
              <WalletIcon black />
            </View>
            <View style={{marginHorizontal: 30}}>
              <Feather
                name='chevron-right'
                size={13}
                color={'black'}
              />
            </View>
            <View style={{marginRight: 12}}>
              <SuccessIcon black />
            </View>
          </View>

          <View style={{marginTop: 17}}>
            <TouchableOpacity>
              <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 18, color: colorSchema.textColor}}>
                {MainStore.cartItem[0]?.restaurantName}
                <Text style={{fontFamily: 'Gilroy-Medium', color: 'rgba(30,30,30,0.5)'}}>
                  {' '}
                  ({MainStore.cartItem[0]?.district})
                </Text>
              </Text>
            </TouchableOpacity>
            <View>
              <Text
                style={{fontSize: 10, color: 'rgba(0,0,0,0.8)'}}
              >{`${MainStore.cartItem[0]?.deliveryTime} dk · Min ${MainStore.cartItem[0]?.minPrice} TL`}</Text>
            </View>
          </View>

          <View style={{marginTop: 34}}>
            <ScrollView style={{marginBottom: 60}}>
              {MainStore.cartItem.map((item, index) => (
                <View
                  key={index}
                  style={{marginTop: 17}}
                >
                  <View>
                    <View>
                      <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.textColor}}>
                        {item.name}
                      </Text>
                    </View>
                    <View style={{marginTop: 10}}>
                      {item?.description && (
                        <Text
                          style={{fontFamily: 'Gilroy-Regular', fontSize: 14, color: 'rgba(142,139,139,1)'}}
                        >{`${item.description.slice(0, 35)}...`}</Text>
                      )}
                    </View>
                  </View>
                  <View>
                    <View style={{marginLeft: 5, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: 90,
                          height: 27,
                          borderRadius: 6,
                          borderWidth: 0.3,
                          borderColor: 'rgba(30,30,30,0.6)',
                          alignItems: 'center',
                          marginLeft: 15,
                        }}
                      >
                        <View style={{marginLeft: 6, alignItems: 'center', justifyContent: 'center'}}>
                          {item.amount > 1 ? (
                            <TouchableOpacity
                              onPress={() => {
                                MainStore.addCart(item, -1);
                              }}
                              style={{width: 20, height: 27, alignItems: 'center', justifyContent: 'center'}}
                            >
                              <MinusIcon
                                width={10}
                                height={2}
                              />
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity
                              onPress={() => {
                                MainStore.addCart(item, -1);
                              }}
                              style={{width: 20, height: 27, alignItems: 'center', justifyContent: 'center'}}
                            >
                              <TrashIcon
                                width={14}
                                height={14}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                        <View>
                          <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.textColor}}>
                            {item.amount}
                          </Text>
                        </View>
                        <View style={{marginRight: 6, alignItems: 'center'}}>
                          <TouchableOpacity
                            style={{width: 20, height: 27, alignItems: 'center', justifyContent: 'center'}}
                            onPress={() => MainStore.addCart(item, 1)}
                          >
                            <PlusIcon
                              width={10}
                              height={10}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{marginRight: 8}}>
                        {item.discountType == 'percent' ? (
                          <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: 'Gilroy-Medium',
                                color: 'gray',
                                lineHeight: 15,
                                textDecorationLine: 'line-through',
                              }}
                            >
                              {' '}
                              {`${parseFloat(item.price * item.amount).toFixed(2)} TL`}
                            </Text>
                            <Text
                              style={{fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.greenColor}}
                            >{`${parseFloat((item.price - (item.price * item.discount) / 100) * item.amount).toFixed(
                              2
                            )} TL`}</Text>
                          </View>
                        ) : item.discountType == 'fixed' ? (
                          <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: 'Gilroy-Medium',
                                color: 'gray',
                                lineHeight: 15,
                                textDecorationLine: 'line-through',
                              }}
                            >
                              {' '}
                              {`${parseFloat(item.price * item.amount).toFixed(2)} TL`}
                            </Text>
                            <Text
                              style={{fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.greenColor}}
                            >{`${parseFloat((item.price - item.discount) * item.amount).toFixed(2)} TL`}</Text>
                          </View>
                        ) : (
                          <View>
                            <Text
                              style={{fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.greenColor}}
                            >{`${parseFloat(item.price * item.amount).toFixed(2)} TL`}</Text>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </ScrollView>
          </View>
        </View>
      </ScrollView>
      <View style={{position: width > 1279 ? 'relative' : 'absolute', flex: 1, bottom: 20, alignSelf: 'center'}}>
        {MainStore.isTakeAway ? (
          <></>
        ) : MainStore.calcutePrice >= parseFloat(MainStore.cartItem[0].minPrice) ? (
          <></>
        ) : (
          <View style={{marginLeft: 12, position: 'absolute', bottom: 80, marginRight: 82}}>
            <Text style={{color: colorSchema.mainColor, fontFamily: 'Gilroy-Regular', fontSize: 15}}>{`${
              MainStore.langPref == 'tr'
                ? 'Minimum sepet tutarının altındasın, sepetine daha fazla ürün ekleyerek siparişini tamamla.'
                : 'You are below the minimum cart amount, add more items to your cart and complete your order.'
            }`}</Text>
          </View>
        )}
        <TouchableOpacity
          onPress={() => {
            if (MainStore.auth) {
              if (MainStore.isTakeAway) {
                navigation.navigate('Checkout');
              } else {
                if (MainStore.calcutePrice >= parseFloat(MainStore.cartItem[0]?.minPrice)) {
                  navigation.navigate('Checkout');
                } else {
                  ErrorToastMessage(
                    toast,
                    MainStore.langPref == 'tr'
                      ? 'Minimum sepet tutarının altındasın, sepetine daha fazla ürün ekleyerek siparişini tamamla.'
                      : 'You are below the minimum cart amount, add more items to your cart and complete your order.'
                  );
                }
              }
            } else {
              ErrorToastMessage(toast, 'Lütfen sipariş vermeden önce giriş yapınız...');
              navigation.navigate('Profile');
            }
          }}
          style={{
            width: width > 1279 ? 400 : width - 28,
            marginTop: 15,
            backgroundColor: MainStore.isTakeAway
              ? colorSchema.mainColor
              : MainStore.calcutePrice >= parseFloat(MainStore.cartItem[0]?.minPrice)
              ? colorSchema.mainColor
              : 'rgba(30,30,30,0.8)',
            borderRadius: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{marginLeft: 11, marginVertical: 9}}>
            <CartIcon />
          </View>
          <View style={{justifyContent: 'center', alignSelf: 'center', marginLeft: 30}}>
            <View>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 19, color: colorSchema.cartColor}}>{`${
                MainStore.langPref == 'tr' ? 'Ödemeye Geç' : 'Proceed with the payment'
              }`}</Text>
            </View>
          </View>
          <View style={{alignItems: 'center', justifyContent: 'center', marginRight: 5}}>
            <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.cartColor}}>{`${parseFloat(
              MainStore.calcutePrice
            ).toFixed(2)} TL`}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  ) : (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <ScrollView style={{maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0, flex: 1}}>
        {width > 1279 ? (
          <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View>
          <GoBackTitle
            navigation={navigation}
            title='Sepet'
          />
        </View>

        <View style={{marginLeft: 12}}>
          <View
            style={{
              marginTop: 17,
              flexDirection: 'row',
              justifyContent: width > 1279 ? 'center' : 'space-evenly',
              alignItems: 'center',
            }}
          >
            <View style={{backgroundColor: 'black', padding: 6, borderRadius: 50}}>
              <CartIcon black={false} />
            </View>
            <View style={{marginHorizontal: 30}}>
              <Feather
                name='chevron-right'
                size={13}
                color={'black'}
              />
            </View>
            <View>
              <WalletIcon black={true} />
            </View>
            <View style={{marginHorizontal: 30}}>
              <Feather
                name='chevron-right'
                size={13}
                color={'black'}
              />
            </View>
            <View style={{marginRight: 12}}>
              <SuccessIcon black={true} />
            </View>
          </View>
        </View>

        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 100}}>
          <View style={{alignItems: 'center'}}>
            <Image
              source={require('../../assets/image/cart.png')}
              style={{width: 83, height: 86}}
            />
          </View>
          <View style={{marginTop: 10}}>
            <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 16}}>Henüz sepetinize birşey eklemediniz</Text>
          </View>
          <View style={{marginTop: 10}}>
            <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 14}}>
              Sipariş verebilmek için sepetinize ürün ekleyin.
            </Text>
          </View>
        </View>
      </ScrollView>
      <View style={{position: width > 1279 ? 'relative' : 'absolute', flex: 1, bottom: 20, alignSelf: 'center'}}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
          style={{
            width: width > 1279 ? 400 : width - 28,
            marginTop: 15,
            backgroundColor:
              parseFloat(MainStore.calcutePrice) >= parseFloat(MainStore.cartItem[0]?.minPrice)
                ? colorSchema.mainColor
                : 'rgba(30,30,30,0.8)',
            borderRadius: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{marginLeft: 11, marginVertical: 9}}>
            <CartIcon />
          </View>
          <View style={{justifyContent: 'center', alignSelf: 'center', marginLeft: 30}}>
            <View>
              <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 19, color: colorSchema.cartColor}}>{`${
                MainStore.langPref == 'tr' ? 'Sepete Ürün Ekle' : 'Add Product to Cart'
              }`}</Text>
            </View>
          </View>
          <View style={{alignItems: 'center', justifyContent: 'center', marginRight: 5}}>
            <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 14, color: colorSchema.cartColor}}>{` `}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default observer(Cart);
