import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Modal,
  Image,
  Dimensions,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import LogoHeader from '../../components/header/LogoHeader';
import colorSchema from '../../config/colorSchema';
import {Feather} from '@expo/vector-icons';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';
import StarIcon from '../../assets/svg/StarIcon';
import TakeAwayIcon2 from '../../assets/svg/TakeAwayIcon2';
import SearchBox from '../../components/search/SearchBox';
import RestFoodCard from '../../components/card/RestFoodCard';
import HeaderSheet from '../../components/header/HeaderSheet';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import LoadingSpinner from '../../components/feedback/LoadingSpinner';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import WebCartSheet from '../../components/cart/WebCartSheet';
import WebHeader from '../../components/header/WebHeader';
import {useToast} from '@gluestack-ui/themed';
import TimeIcon from '../../assets/svg/TimeIcon';
import RedButton from '../../components/button/RedButton';
const TakeAwayRestorant = ({navigation, route}) => {
  const [restaurantOpen, setRestaurantOpen] = useState(true);
  const [appReady, setAppReady] = useState(false);
  const [foodData, setFoodData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [restData, setRestData] = useState(null);
  const [restaurantCloseModal, setRestaurantCloseModel] = useState(true);
  const getDayIndexMondaySunday = (date) => (date.getDay() === 0 ? 6 : date.getDay() - 1);

  const {id} = route.params;
  useEffect(() => {
    axios.get(`${axiosConfig.serverIp}/getRestaurant/${id}`).then((res) => {
      setRestData(res.data.restaurantData);
      setFoodData(res.data.foodData);
      res.data.restaurantData.visible ? setRestaurantOpen(true) : setRestaurantOpen(false);
      setAppReady(true);
    });
  }, []);

  const toast = useToast();

  const {width, height} = Dimensions.get('window');
  return appReady ? (
    <View style={{backgroundColor: 'white', flex: 1}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0}}
      >
        {width > 1279 ? (
          <View style={{marginTop: 17}}>
            <View style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999}}>
              <WebHeader navigation={navigation} />
            </View>
            <SearchBox navigation={navigation} />
          </View>
        ) : (
          <View>
            <HeaderSheet navigation={navigation} />
          </View>
        )}

        <View style={{flexDirection: width > 1279 ? 'row' : 'column'}}>
          <View style={{maxWidth: width > 1279 ? 1025 : width}}>
            <View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <View style={{marginRight: 20}}>
                    <Feather
                      name='chevron-left'
                      size={25}
                      color='black'
                    />
                  </View>
                </TouchableOpacity>
                <View style={{marginLeft: 10, marginVertical: 14}}>
                  <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 28, color: colorSchema.textColor}}>Gel-Al</Text>
                </View>
              </View>
            </View>
            <ScrollView>
              <View>
                <View style={{marginLeft: 19}}>
                  <View
                    style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 17}}
                  >
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <View style={{marginRight: 6}}>
                        <TakeAwayIcon2 />
                      </View>
                      <View>
                        <Text style={{fontFamily: 'Gilroy-Heavy', fontSize: 24, color: '#1E1E1E'}}>
                          {restData.restaurantName}{' '}
                          <Text style={{fontFamily: 'Gilroy-Medium', fontSize: 22, color: 'rgba(0,0,0,0.5)'}}>
                            ({restData.district})
                          </Text>
                        </Text>
                      </View>
                    </View>

                    <View style={{marginRight: 19}}>
                      <TouchableOpacity onPress={() => navigation.navigate('RestaurantAbout', {data: restData})}>
                        <RightArrowIcon />
                      </TouchableOpacity>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 17,
                      justifyContent: width > 1279 ? 'flex-start' : 'space-between',
                    }}
                  >
                    <View>
                      <View>
                        <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 28}}>
                          <View>
                            <TimeIcon color={restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor} />
                          </View>
                          <View>
                            <Text
                              style={{
                                fontFamily: restaurantOpen ? 'Gilroy-Regular' : 'Gilroy-Bold',
                                fontSize: 13,
                                color: restaurantOpen ? colorSchema.greenColor : colorSchema.mainColor,
                              }}
                            >
                              {restaurantOpen ? 'Açık' : 'Kapalı'}
                            </Text>
                          </View>
                        </View>
                        <View style={{marginVertical: 7}}>
                          <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 10, color: colorSchema.textColor}}>
                            {Object.values(restData.workingHours)[getDayIndexMondaySunday(new Date())]}
                          </Text>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <View>
                          <StarIcon />
                        </View>
                        <View style={{marginLeft: 5, marginRight: 9}}>
                          <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>
                            {restData.rating} ({restData.comments.length})
                          </Text>
                        </View>
                        <TouchableOpacity
                          style={{marginLeft: 17}}
                          onPress={() => navigation.navigate('RestaurantComment', {data: restData.comments, restData})}
                        >
                          <RightArrowIcon />
                        </TouchableOpacity>
                      </View>
                      <View style={{marginTop: 5}}>
                        <Text style={{fontSize: 10, fontFamily: 'Gilroy-Light'}}>{restData.category}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {width > 1279 ? (
                <></>
              ) : (
                <View style={{marginTop: 17}}>
                  <SearchBox navigation={navigation} />
                </View>
              )}

              <View style={{marginTop: 14, marginLeft: 13}}>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedCategory('All');
                      foodData.map((item) => {
                        item.visible = true;
                      });
                    }}
                    style={{
                      backgroundColor: selectedCategory == 'All' ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)',
                      paddingHorizontal: 12,
                      paddingVertical: 4,
                      borderRadius: 10,
                      marginRight: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: selectedCategory == 'All' ? 'white' : 'black',
                        fontFamily: 'Gilroy-Bold',
                        fontSize: 15,
                      }}
                    >
                      Hepsi
                    </Text>
                  </TouchableOpacity>

                  {restData.subCategory.map((item, index) => (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedCategory(item);
                        foodData.map((element) => {
                          if (element.subCategory == item) {
                            element.visible = true;
                          } else {
                            element.visible = false;
                          }
                        });
                      }}
                      key={index}
                      style={{
                        backgroundColor: selectedCategory == item ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)',
                        paddingHorizontal: 12,
                        paddingVertical: 4,
                        borderRadius: 10,
                        marginRight: 5,
                      }}
                    >
                      <Text
                        style={{
                          color: selectedCategory == item ? 'white' : 'black',
                          fontFamily: 'Gilroy-Bold',
                          fontSize: 15,
                        }}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              </View>

              <View style={{marginTop: 17}}>
                <View>
                  <View style={{marginTop: 20, marginLeft: 14}}>
                    <Text style={{color: colorSchema.textColor, fontSize: 18, fontFamily: 'Gilroy-SemiBold'}}>
                      {selectedCategory == 'All' ? 'Tümünü Gör' : selectedCategory}
                    </Text>
                  </View>
                  <View style={{marginLeft: 14}}>
                    {foodData.map((item, index) =>
                      item.visible ? (
                        <View key={item._id}>
                          <TouchableOpacity
                            onPress={() => {
                              if (restaurantOpen) {
                                MainStore.changeIsTakeAway(true);
                                navigation.navigate('FoodDetail', {id: item._id});
                              } else {
                                ErrorToastMessage(toast, 'Seçtiğiniz restoran şuanlık hizmet veremiyor');
                              }
                            }}
                          >
                            <RestFoodCard
                              rating={item.rating}
                              comments={item.comments.length}
                              restaurantOpen={restaurantOpen}
                              name={item.name}
                              description={item.description}
                              foodImage={item.foodImage}
                              price={item.price}
                            />
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View key={index}></View>
                      )
                    )}
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
          {width > 1279 ? <WebCartSheet navigation={navigation} /> : <></>}
        </View>

        <Modal
          animationType='slide'
          transparent={true}
          visible={!restaurantOpen}
        >
          <View
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                marginHorizontal: 20,
                backgroundColor: 'white',
                borderRadius: 5,
                paddingHorizontal: 4,
                paddingVertical: 17,
              }}
            >
              <View style={{alignItems: 'center'}}>
                <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 17}}>Üzgünüz Seçtiğiniz Restaurant Kapalı.</Text>

                <View style={{alignItems: 'center', marginTop: 17}}>
                  <Text style={{textAlign: 'center', fontFamily: 'Gilroy-Medium', fontSize: 14}}>
                    {`Seçtiğiniz restoran şu anda kapalı. Restoranın çalışma saatleri ${
                      Object.values(restData.workingHours)[getDayIndexMondaySunday(new Date())]
                    } arasındadır. Dilerseniz geriye dönerek diğer restoranlarımızı inceleyebilirsiniz.`}
                  </Text>
                </View>

                <View style={{marginTop: 17}}>
                  <TouchableOpacity
                    onPress={() => navigation.goBack()}
                    style={{
                      borderRadius: 20,
                      backgroundColor: colorSchema.mainColor,
                      width: 350,
                      height: 43,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 17}}>Geri Dön</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(TakeAwayRestorant);
//{Object.values(restData.workingHours)[getDayIndexMondaySunday(new Date())]}
