import * as React from "react"
import { Svg, Path } from 'react-native-svg'
const CheckIcon = (props) => (
    <Svg
        width={12}
        height={9}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="m.75 5.75 2.645 2.116a1.5 1.5 0 0 0 2.052-.168L11.25 1.25"
            stroke="#1E1E1E"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default CheckIcon
