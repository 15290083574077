import { View, Text, SafeAreaView, Image, TouchableOpacity, ScrollView, Dimensions } from 'react-native'
import React from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import StarIcon from '../../assets/svg/StarIcon'
import CommentCard from '../../components/card/CommentCard'
import GoBackTitle from '../../components/header/GoBackTitle'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import WebHeader from '../../components/header/WebHeader'

const { width, height } = Dimensions.get('window')

const RestaurantComment = ({ navigation, route }) => {
    const { data, restData } = route.params
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView style={{ maxWidth: 1279, marginLeft: width > 1279 ? (width - 1279) / 2 : 0 }}>
                {width > 1279 ?
                    <View style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 999 }}>
                        <WebHeader navigation={navigation} />
                    </View>
                    :
                    <></>
                }
                <View>
                    <View>
                        <GoBackTitle title={MainStore.langPref == 'tr' ? 'Restoran Yorumları' : 'Restaurant reviews'} navigation={navigation} />
                    </View>
                    <View style={{ marginLeft: 20, marginTop: 29 }}>
                        <Text style={{ fontFamily: 'Gilroy-Heavy', fontSize: 24, color: '#1E1E1E' }}>{restData.restaurantName} <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 22, color: 'rgba(0,0,0,0.5)' }}>({restData.district})</Text></Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 20, marginTop: 15 }}>
                        <View>
                            <StarIcon />
                        </View>
                        <View style={{ marginLeft: 5, marginRight: 9 }}>
                            <Text style={{ fontSize: 10, fontFamily: 'Gilroy-Light' }}>{restData.rating} ({restData.comments.length})</Text>
                        </View>
                    </View>
                </View>

                <View>
                    {data.map((item, index) =>
                        <View key={index}>
                            <CommentCard name={item.name.slice(0, 2).toUpperCase()} surName={item.surName.slice(0, 2).toUpperCase()} review={item.review} date={item.date} comment={item.comment} />
                        </View>
                    )}


                </View>

            </ScrollView>
        </SafeAreaView>
    )
}

export default observer(RestaurantComment)