import { View, Text, TouchableOpacity, Image, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
import RIcon from '../../assets/svg/RIcon'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'

const { width, height } = Dimensions.get('window')

const SeeMoreCardR = (props) => {
    return (
        <TouchableOpacity onPress={() => props.navigation.navigate('Restaurant', { id: props.id })} style={{ paddingBottom: 18, backgroundColor: colorSchema.cartColor }}>
            <View>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Image source={{ uri: props.restaurantCoverPhoto }} style={{ width: width > 1279 ? 379 : width - 14, alignSelf: 'center', height: width > 1279 ? 165 : 127 }} />

                </View>
            </View>
            <View style={{ marginLeft: 10, marginTop: 9 }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 6 }}>
                    <View>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                            <View>
                                <Text style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: 14, color: colorSchema.textColor }}>{props.restaurantName} <Text style={{ fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.4)' }}>({props.district})</Text></Text>
                            </View>
                        </View>
                        {props.deliveryTime ?
                            <View>
                                <Text style={{ fontSize: 10, fontWeight: '400', lineHeight: 14 }}>{`${props.deliveryTime} dk · Min ${props.minPrice} TL`}</Text>
                            </View>
                            :
                            <></>
                        }
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 17 }}>
                            <View style={{ marginRight: 6 }}>
                                <Image source={require('../../assets/image/star.png')} style={{ height: 12.5, width: 12.5 }} />
                            </View>
                            <View>
                                <Text style={{ fontSize: 12, fontFamily: 'Gilroy-Regular', color: 'rgba(0,0,0,0.5)' }}>{props.rating} <Text style={{ color: 'rgba(0,0,0,0.25)' }}>({props.comments})</Text></Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ marginRight: 20 }}>
                        <RightArrowIcon />
                    </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View>
                        <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 10 }}>{props.category}</Text>
                    </View>


                </View>
            </View>
        </TouchableOpacity>
    )
}

export default SeeMoreCardR