import * as React from "react";
import Svg, { Path } from "react-native-svg";

function LastStarIcon(props) {
    return (
        <Svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M7.36 1.726a1.798 1.798 0 013.28 0l1.158 2.442c.266.56.781.95 1.377 1.04l2.591.39c1.5.227 2.098 2.143 1.013 3.243l-1.875 1.9a1.948 1.948 0 00-.526 1.683l.443 2.683c.256 1.553-1.312 2.737-2.653 2.004l-2.317-1.267a1.766 1.766 0 00-1.702 0L5.831 17.11c-1.34.733-2.908-.451-2.652-2.004l.442-2.683a1.948 1.948 0 00-.525-1.683l-1.875-1.9C.136 7.74.734 5.825 2.234 5.599l2.591-.392a1.835 1.835 0 001.377-1.04l1.159-2.44z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default LastStarIcon;