import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  Image,
  TextInput,
  Dimensions,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Feather } from "@expo/vector-icons";
import colorSchema from "../../config/colorSchema";
import SeeMoreCardR from "../../components/card/SeeMoreCardR";
import SeeMoreCardF from "../../components/card/SeeMoreCardF";
import FilterBox from "../../components/filter/FilterBox";
import HeaderSheet from "../../components/header/HeaderSheet";
import FilterSheet2 from "../../components/bottomsheet/FilterSheet2";
import LoadingSpinner from "../../components/feedback/LoadingSpinner";
import TrashIcon from "../../assets/svg/TrashIcon";
import SearchBox from "../../components/search/SearchBox";
import FilterCard from "../../components/card/FilterCard";
import MinusIcon from "../../assets/svg/MinusIcon";
import MainStore from "../../store/MainStore";
import { observer } from "mobx-react";
import axios from "axios";
import axiosConfig from "../../config/axiosConfig";

const FilterRest = ({ navigation, route }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [data, setData] = useState(null);
  const [appReady, setAppReady] = useState(false);
  const [selected, setSelected] = useState(route.params.selected);

  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    axios
      .post(`${axiosConfig.serverIp}/getFilteredData`, {
        province: MainStore.selectedAddress.province,
        district: MainStore.selectedAddress.district,
        neighbourhood: MainStore.selectedAddress.neighbourhood,
        category: selected.category,
        sort: selected.sort,
        rating: selected.rating,
      })
      .then((res) => {
        setData(res.data);
        setAppReady(true);
      });
  }, [selected]);

  return appReady ? (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <ScrollView
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
        }}
      >
        <View style={{ marginBottom: 17 }}>
          {width > 1279 ? (
            <></>
          ) : (
            <View>
              <HeaderSheet navigation={navigation} />
            </View>
          )}

          <TouchableOpacity
            onPress={() => navigation.navigate("Search")}
            style={{ marginTop: 17 }}
          >
            <SearchBox navigation={navigation} />
          </TouchableOpacity>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",

              marginRight: 26,
            }}
          >
            <View>
              <FilterBox
                selectedFilter={selectedFilter}
                setShowFilter={setShowFilter}
                setSelectedFilter={setSelectedFilter}
                category={selected.category}
                rating={selected.rating}
                sort={selected.sort}
              />
            </View>

            <TouchableOpacity
              style={{ marginRight: 21 }}
              onPress={() => {
                setSelected({
                  category: "",
                  rating: "",
                  sort: "",
                });
              }}
            >
              <Feather name="trash-2" size={21} color="black" />
            </TouchableOpacity>
          </View>
        </View>
        <View>
          <View>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: 14,
                }}
              >
                <View>
                  {selected.category ? (
                    <View style={{ marginVertical: 17, marginLeft: 12 }}>
                      <Text
                        style={{
                          fontFamily: "Gilroy-Bold",
                          fontSize: 16,
                          color: colorSchema.textColor,
                        }}
                      >
                        {MainStore.langPref == "tr"
                          ? `${selected.category} için ${data.length} sonuç bulundu.`
                          : `${data.length} restaurants with "${selected.category}"`}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              </View>

              {data?.length > 0 ? (
                width > 1279 ? (
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {data.map((item) => (
                      <View key={item._id}>
                        <FilterCard
                          deliveryTime={item.deliveryTime}
                          minPrice={item.minPrice}
                          navigation={navigation}
                          id={item._id}
                          category={item.category}
                          comments={item.comments.length}
                          rating={item.rating}
                          district={item.district}
                          restaurantName={item.restaurantName}
                          restaurantCoverPhoto={item.restaurantCoverPhoto}
                        />
                      </View>
                    ))}
                  </View>
                ) : (
                  <View>
                    {data.map((item) => (
                      <View key={item._id}>
                        <FilterCard
                          deliveryTime={item.deliveryTime}
                          minPrice={item.minPrice}
                          navigation={navigation}
                          id={item._id}
                          category={item.category}
                          comments={item.comments.length}
                          rating={item.rating}
                          district={item.district}
                          restaurantName={item.restaurantName}
                          restaurantCoverPhoto={item.restaurantCoverPhoto}
                        />
                      </View>
                    ))}
                  </View>
                )
              ) : (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Text style={{ fontFamily: "Gilroy-Bold", fontSize: 19 }}>
                    {MainStore.langPref == "tr"
                      ? "Üzgünüz seçtiğiniz kategoride restoran bulamadık"
                      : "Sorry! We couldn't find any results"}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      {showFilter ? (
        <FilterSheet2
          selected={selected}
          setSelected={setSelected}
          selectedFilter={selectedFilter}
          navigation={navigation}
          visible={showFilter}
          setVisible={setShowFilter}
        />
      ) : (
        <></>
      )}
    </View>
  ) : (
    <LoadingSpinner />
  );
};

export default observer(FilterRest);
