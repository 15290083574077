const colorSchema = {
    mainColor:'#FA0C08',
    priceColor:'#17B978',
    cartColor:'#FCFCFC',
    secondaryColor:'#17B978',
    textColor:'#1E1E1E',
    greenColor:'#1A8F53',
    waterColor:'#0C8CE9'
    
}

export default colorSchema