import { View, Text, TouchableOpacity, SafeAreaView } from 'react-native'
import React from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'

const GoBackTitle = (props) => {
    return (
        <SafeAreaView style={{ marginBottom: 17 }}>
            <View style={{ marginTop: 10, marginLeft: 25 }}>
                <TouchableOpacity onPress={() => props.navigation.goBack()}>
                    <Feather name='chevron-left' size={25} color='black' />
                </TouchableOpacity>
            </View>
            <View style={{ marginTop: 10, marginLeft: 12 }}>
                <Text style={{ color: colorSchema.textColor, fontSize: 28, fontFamily: 'Gilroy-Bold' }}>{props.title}</Text>
            </View>
        </SafeAreaView>
    )
}

export default GoBackTitle