import { View, Text, TouchableOpacity } from 'react-native'
import React, { useEffect } from 'react'
import colorSchema from '../../config/colorSchema'
import HomeIcon from '../../assets/svg/HomeIcon'
import { observer } from 'mobx-react-lite'
import TakeAwayIcon from '../../assets/svg/TakeAwayIcon'
import LastOrderIcon from '../../assets/svg/LastOrderIcon'
import ProfileIcon from '../../assets/svg/ProfileIcon'
import MainStore from '../../store/MainStore'
import MapPinIcon2 from '../../assets/svg/MapPinIcon2'
import WorldIcon from '../../assets/svg/WorldIcon'
import { useRoute } from '@react-navigation/native'
import ProfileIconWhite from '../../assets/svg/ProfileIconWhite'
const WhiteHeader = (props) => {
    const route = useRoute()
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 17 }}>
            <TouchableOpacity onPress={() => MainStore.auth ? props.navigation.navigate('Home') : props.navigation.navigate('Splash')}>
                <Text style={{ color: 'white', fontSize: 38, fontFamily: 'Gilroy-Bold' }}>Yemek.Club</Text>
            </TouchableOpacity>



            <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity onPress={() => props.navigation.navigate('SignIn')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                    <View style={{ marginRight: 4 }}>
                        <ProfileIconWhite />
                    </View>
                    <View>
                        <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: 'white' }}>Giriş Yap</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => props.navigation.navigate('Register')} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                    <View style={{ marginRight: 4 }}>
                        <ProfileIconWhite />
                    </View>
                    <View>
                        <Text style={{ fontFamily: 'Gilroy-Bold', fontSize: 15, color: 'white' }}>Üye Ol</Text>
                    </View>
                </TouchableOpacity>

            </View>


        </View>
    )
}

export default observer(WhiteHeader)