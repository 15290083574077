import {View, Text, Image, Dimensions} from 'react-native';
import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Splash from '../screens/auth/Splash';
import {NavigationContainer} from '@react-navigation/native';
import Register from '../screens/auth/Register';
import SignIn from '../screens/auth/SignIn';
import Verification from '../screens/auth/Verification';
import NotificationScreen from '../screens/feedback/NotificationScreen';
import Home from '../screens/home/Home';
import {Feather} from '@expo/vector-icons';
import colorSchema from '../config/colorSchema';
import * as Svg from 'react-native-svg';
import HomeIcon from '../assets/svg/HomeIcon';
import TakeAwayIcon from '../assets/svg/TakeAwayIcon';
import LastOrderIcon from '../assets/svg/LastOrderIcon';
import ProfileIcon from '../assets/svg/ProfileIcon';
import SearchIcon from '../assets/svg/SearchIcon';
import Restaurant from '../screens/restaurant/Restaurant';
import RestaurantComment from '../screens/restaurant/RestaurantComment';
import RestaurantAbout from '../screens/restaurant/RestaurantAbout';
import SeeMore from '../screens/home/SeeMore';
import TakeAway from '../screens/takeaway/TakeAway';
import TakeAwayRestorant from '../screens/takeaway/TakeAwayRestorant';
import Search from '../screens/search/Search';
import LastOrder from '../screens/lastorder/LastOrder';
import GoBackTitle from '../components/header/GoBackTitle';
import LastOrderDetail from '../screens/lastorder/LastOrderDetail';
import Profile from '../screens/profile/Profile';
import EditProfile from '../screens/profile/EditProfile';
import MyAddress from '../screens/profile/MyAddress';
import Favorite from '../screens/profile/Favorite';
import Help from '../screens/profile/Help';
import Language from '../screens/profile/Language';
import {observer} from 'mobx-react';
import MainStore from '../store/MainStore';
import FoodDetail from '../screens/restaurant/FoodDetail';
import Cart from '../screens/cart/Cart';
import Checkout from '../screens/cart/Checkout';
import FilterRest from '../screens/home/FilterRest';
import FirstLogin from '../screens/home/FirstLogin';
import AddAddress from '../screens/profile/AddAddress';
import AddAddressGuest from '../screens/profile/AddAddressGuest';
import OnlinePayment from '../screens/cart/OnlinePayment';
import TDSecure from '../screens/cart/TDSecure';
import SuccessScreen from '../screens/cart/SuccessScreen';
import WaterDetail from '../screens/water/WaterDetail';
import AboutUs from '../screens/Discover/AboutUs';
import FAQ from '../screens/Discover/FAQ';
import KVKK from '../screens/Discover/KVKK';
import Aydinlatma from '../screens/Discover/Aydinlatma';
import Privacy from '../screens/Discover/Privacy';
import Contact from '../screens/Discover/Contact';
import RestaurantApplication from '../screens/Discover/RestaurantApplication';
import WaterDealerApplication from '../screens/Discover/WaterDealerApplication';
import LiveChat from '../screens/profile/LiveChat';
import SalesAgree from '../screens/Discover/SalesAgree';
import ReviewList from '../screens/lastorder/ReviewList';
import ReviewRestaurant from '../screens/lastorder/ReviewRestaurant';
import ReviewFood from '../screens/lastorder/ReviewFood';
import FoodComment from '../screens/restaurant/FoodComment';
import EditAddress from '../screens/profile/EditAddress';

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

const {width, height} = Dimensions.get('window');

const AuthNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='Splash'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='Splash'
        component={Splash}
        options={{title: 'Ana Sayfa'}}
      />
      <Stack.Screen
        name='Register'
        component={Register}
        options={{title: 'Kayıt Ol'}}
      />
      <Stack.Screen
        name='SignIn'
        component={SignIn}
        options={{title: 'Giriş Yap'}}
      />
      <Stack.Screen
        name='Verification'
        component={Verification}
        options={{title: 'Hesap Doğrulama'}}
      />
      <Stack.Screen
        name='NotificationScreen'
        component={NotificationScreen}
        options={{title: 'Bildirim Ayarları'}}
      />
    </Stack.Navigator>
  );
};

const HomeNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='HomeScreen'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='HomeScreen'
        component={Home}
        options={{title: 'Ana Sayfa'}}
      />
      <Stack.Screen
        name='FirstLogin'
        component={FirstLogin}
        options={{title: 'Hoşgeldiniz'}}
      />
      <Stack.Screen
        name='SeeMore'
        component={SeeMore}
        options={{title: 'Detay'}}
      />
      <Stack.Screen
        name='Restaurant'
        component={Restaurant}
        options={{title: 'Restorant'}}
      />
      <Stack.Screen
        name='RestaurantComment'
        component={RestaurantComment}
        options={{title: 'Restorant Yorumları'}}
      />
      <Stack.Screen
        name='FoodComment'
        component={FoodComment}
        options={{title: 'Yemek Yorumları'}}
      />
      <Stack.Screen
        name='RestaurantAbout'
        component={RestaurantAbout}
        options={{title: 'Restorant Hakkında'}}
      />
      <Stack.Screen
        name='FoodDetail'
        component={FoodDetail}
        options={{title: 'Yemek Detayı'}}
      />
      <Stack.Screen
        name='MyAddress'
        component={MyAddress}
        options={{title: 'Adreslerim'}}
      />
      <Stack.Screen
        name='EditAddress'
        component={EditAddress}
        options={{title: 'Adresimi Düzenle'}}
      />
      <Stack.Screen
        name='Cart'
        component={Cart}
        options={{title: 'Sepet'}}
      />
      <Stack.Screen
        name='Checkout'
        component={Checkout}
        options={{title: 'Ödeme'}}
      />
      <Stack.Screen
        name='SalesAgree'
        component={SalesAgree}
        options={{title: 'Mesafeli Satış Sözleşmesi'}}
      />
      <Stack.Screen
        name='SuccessScreen'
        component={SuccessScreen}
        options={{title: 'Başarılı'}}
      />
      <Stack.Screen
        name='OnlinePayment'
        component={OnlinePayment}
        options={{title: 'Online Ödeme'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
      <Stack.Screen
        name='AddAddress'
        component={AddAddress}
        options={{title: 'Adres Ekle'}}
      />
      <Stack.Screen
        name='WaterDetail'
        component={WaterDetail}
        options={{title: 'Su Detay'}}
      />
    </Stack.Navigator>
  );
};

const HomeNotAuthNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='HomeScreen'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='HomeScreen'
        component={Home}
        options={{title: 'Ana Sayfa'}}
      />
      <Stack.Screen
        name='FirstLogin'
        component={FirstLogin}
        options={{title: 'Hoşgeldiniz'}}
      />
      <Stack.Screen
        name='SeeMore'
        component={SeeMore}
        options={{title: 'Detay'}}
      />
      <Stack.Screen
        name='Restaurant'
        component={Restaurant}
        options={{title: 'Restorant'}}
      />
      <Stack.Screen
        name='RestaurantComment'
        component={RestaurantComment}
        options={{title: 'Restorant Yorumları'}}
      />
      <Stack.Screen
        name='FoodComment'
        component={FoodComment}
        options={{title: 'Yemek Yorumları'}}
      />
      <Stack.Screen
        name='RestaurantAbout'
        component={RestaurantAbout}
        options={{title: 'Restorant Hakkında'}}
      />
      <Stack.Screen
        name='FoodDetail'
        component={FoodDetail}
        options={{title: 'Yemek Detayı'}}
      />
      <Stack.Screen
        name='MyAddress'
        component={MyAddress}
        options={{title: 'Adreslerim'}}
      />
      <Stack.Screen
        name='EditAddress'
        component={EditAddress}
        options={{title: 'Adresimi Düzenle'}}
      />
      <Stack.Screen
        name='Cart'
        component={Cart}
        options={{title: 'Sepet'}}
      />
      <Stack.Screen
        name='Checkout'
        component={AuthNavigation}
        options={{title: 'Giriş Yap'}}
      />
      <Stack.Screen
        name='SuccessScreen'
        component={AuthNavigation}
        options={{title: 'Giriş Yap'}}
      />
      <Stack.Screen
        name='OnlinePayment'
        component={AuthNavigation}
        options={{title: 'Giriş Yap'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
      <Stack.Screen
        name='AddAddress'
        component={AddAddress}
        options={{title: 'Adres Ekle'}}
      />
      <Stack.Screen
        name='AddAddressGuest'
        component={AddAddressGuest}
        options={{title: 'Adres Ekle'}}
      />
      <Stack.Screen
        name='WaterDetail'
        component={WaterDetail}
        options={{title: 'Su Detay'}}
      />
    </Stack.Navigator>
  );
};

const TakeAwayNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='TakeAwayScreen'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='TakeAwayScreen'
        component={TakeAway}
        options={{title: 'Gel Al'}}
      />
      <Stack.Screen
        name='TakeAwayRestorant'
        component={TakeAwayRestorant}
        options={{title: 'Restorant'}}
      />
      <Stack.Screen
        name='FoodDetail'
        component={FoodDetail}
        options={{title: 'Yemek Detay'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
    </Stack.Navigator>
  );
};

const SearchNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='TakeAwayRestorant'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='SearchScreen'
        component={Search}
        options={{title: 'Ara'}}
      />
      <Stack.Screen
        name='Restaurant'
        component={Restaurant}
        options={{title: 'Restorant'}}
      />
      <Stack.Screen
        name='RestaurantComment'
        component={RestaurantComment}
        options={{title: 'Restorant Yorumları'}}
      />
      <Stack.Screen
        name='FoodComment'
        component={FoodComment}
        options={{title: 'Yemek Yorumları'}}
      />
      <Stack.Screen
        name='RestaurantAbout'
        component={RestaurantAbout}
        options={{title: 'Restorant Hakkında'}}
      />
      <Stack.Screen
        name='FoodDetail'
        component={FoodDetail}
        options={{title: 'Yemek Detay'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
    </Stack.Navigator>
  );
};
const LastOrderNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='LastOrderScreen'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='LastOrderScreen'
        component={LastOrder}
        options={{title: 'Geçmiş Siparişlerim'}}
      />
      <Stack.Screen
        name='ReviewRestaurant'
        component={ReviewRestaurant}
        options={{title: 'Restoran Yorumla'}}
      />
      <Stack.Screen
        name='ReviewFood'
        component={ReviewFood}
        options={{title: 'Yemek Yorumla'}}
      />
      <Stack.Screen
        name='ReviewList'
        component={ReviewList}
        options={{title: 'Yorum Listesi'}}
      />
      <Stack.Screen
        name='LastOrderDetail'
        component={LastOrderDetail}
        options={{title: 'Geçmiş Siparişim'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
    </Stack.Navigator>
  );
};

const ProfileNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName='ProfileScreen'
      screenOptions={{headerShown: false}}
    >
      <Stack.Screen
        name='ProfileScreen'
        component={Profile}
        options={{title: 'Profilim'}}
      />
      <Stack.Screen
        name='EditProfile'
        component={EditProfile}
        options={{title: 'Profilimi Düzenle'}}
      />
      <Stack.Screen
        name='MyAddress'
        component={MyAddress}
        options={{title: 'Adreslerim'}}
      />
      <Stack.Screen
        name='EditAddress'
        component={EditAddress}
        options={{title: 'Adresimi Düzenle'}}
      />
      <Stack.Screen
        name='LiveChat'
        component={LiveChat}
        options={{title: 'Canlı Destek'}}
      />
      <Stack.Screen
        name='AddAddress'
        component={AddAddress}
        options={{title: 'Adres Ekle'}}
      />
      <Stack.Screen
        name='Favorite'
        component={Favorite}
        options={{title: 'Favorilerim'}}
      />
      <Stack.Screen
        name='Help'
        component={Help}
        options={{title: 'Yardım'}}
      />
      <Stack.Screen
        name='Language'
        component={Language}
        options={{title: 'Dil Seçenekleri'}}
      />
      <Stack.Screen
        name='Restaurant'
        component={Restaurant}
        options={{title: 'Restorant'}}
      />
      <Stack.Screen
        name='RestaurantComment'
        component={RestaurantComment}
        options={{title: 'Restorant Yorumları'}}
      />
      <Stack.Screen
        name='FoodComment'
        component={FoodComment}
        options={{title: 'Yemek Yorumları'}}
      />
      <Stack.Screen
        name='RestaurantAbout'
        component={RestaurantAbout}
        options={{title: 'Restorant Hakkında'}}
      />
      <Stack.Screen
        name='FoodDetail'
        component={FoodDetail}
        options={{title: 'Yemek Detay'}}
      />
      <Stack.Screen
        name='FilterRest'
        component={FilterRest}
        options={{title: 'Restorant Filtrele'}}
      />
      <Stack.Screen
        name='AboutUs'
        component={AboutUs}
        options={{title: 'Hakkımızda'}}
      />
      <Stack.Screen
        name='KVKK'
        component={KVKK}
        options={{title: 'KVKK'}}
      />
      <Stack.Screen
        name='FAQ'
        component={FAQ}
        options={{title: 'SSS'}}
      />
      <Stack.Screen
        name='Aydinlatma'
        component={Aydinlatma}
        options={{title: 'Aydinlatma Metni'}}
      />
      <Stack.Screen
        name='Privacy'
        component={Privacy}
        options={{title: 'Gizlilik Politikası'}}
      />
    </Stack.Navigator>
  );
};

const Route = () => {
  return MainStore.auth ? (
    !MainStore.selectedAddress ? (
      <NavigationContainer
        linking={{
          prefixes: [''],
          config: {
            screens: {
              MyAddress: '',
              Home: 'home',
              AddAddress: 'addAddress',
              EditAddress: 'editAddress',
            },
          },
        }}
      >
        <Stack.Navigator
          initialRouteName='MyAddress'
          screenOptions={screenOptions}
        >
          <Stack.Screen
            name='MyAddress'
            component={MyAddress}
          />
          <Stack.Screen
            name='Home'
            component={Home}
          />
          <Stack.Screen
            name='AddAddress'
            component={AddAddress}
          />
          <Stack.Screen
            name='EditAddress'
            component={EditAddress}
          />
        </Stack.Navigator>
      </NavigationContainer>
    ) : (
      <NavigationContainer
        linking={{
          prefixes: [''],
          config: {
            screens: {
              Home: '',
              FirstLogin: 'firstLogin',
              SeeMore: 'seeMore',
              Restaurant: 'restaurant',
              RestaurantComment: 'restaurantComment',
              FoodComment: 'foodComment',
              RestaurantAbout: 'restaurantAbout',
              FoodDetail: 'foodDetail',
              MyAddress: 'myAddress',
              editAddress: 'editAddress',
              Cart: 'cart',
              Checkout: 'checkout',
              SuccessScreen: 'success',
              OnlinePayment: 'onlinePayment',
              FilterRest: 'filterRestaurant',
              AddAddress: 'addAddress',
              WaterDetail: 'waterDetail',
              TakeAwayRestorant: 'takeAwayRestaurant',
              ReviewRestaurant: 'reviewRestaurant',
              ReviewList: 'reviewList',
              LastOrderDetail: 'lastOrderDetail',
              EditProfile: 'editProfile',
              Favorite: 'favorite',
              Help: 'help',
              Language: 'language',
              TakeAway: 'takeAway',
              Search: 'search',
              LastOrder: 'LastOrder',
              Profile: 'profile',
              AboutUs: 'aboutus',
              LiveChat: 'liveChat',
            },
          },
        }}
      >
        {width > 1279 ? (
          <Stack.Navigator
            initialRouteName='Home'
            screenOptions={screenOptions}
          >
            <Stack.Screen
              name='Home'
              component={Home}
              options={{title: 'Ana Sayfa'}}
            />
            <Stack.Screen
              name='FirstLogin'
              component={FirstLogin}
              options={{title: 'Hoşgeldiniz'}}
            />
            <Stack.Screen
              name='SeeMore'
              component={SeeMore}
              options={{title: 'Detay'}}
            />
            <Stack.Screen
              name='Restaurant'
              component={Restaurant}
              options={{title: 'Restorant'}}
            />
            <Stack.Screen
              name='RestaurantComment'
              component={RestaurantComment}
              options={{title: 'Restorant Yorumları'}}
            />
            <Stack.Screen
              name='FoodComment'
              component={FoodComment}
              options={{title: 'Yemek Yorumları'}}
            />
            <Stack.Screen
              name='RestaurantAbout'
              component={RestaurantAbout}
              options={{title: 'Restorant Hakkında'}}
            />
            <Stack.Screen
              name='FoodDetail'
              component={FoodDetail}
              options={{title: 'Yemek Detayı'}}
            />
            <Stack.Screen
              name='MyAddress'
              component={MyAddress}
              options={{title: 'Adreslerim'}}
            />
            <Stack.Screen
              name='EditAddress'
              component={EditAddress}
              options={{title: 'Adresimi Düzenle'}}
            />

            <Stack.Screen
              name='Cart'
              component={Cart}
              options={{title: 'Sepet'}}
            />
            <Stack.Screen
              name='Checkout'
              component={Checkout}
              options={{title: 'Ödeme'}}
            />
            <Stack.Screen
              name='SuccessScreen'
              component={SuccessScreen}
              options={{title: 'Başarılı'}}
            />
            <Stack.Screen
              name='OnlinePayment'
              component={OnlinePayment}
              options={{title: 'Online Ödeme'}}
            />
            <Stack.Screen
              name='FilterRest'
              component={FilterRest}
              options={{title: 'Restorant Filtrele'}}
            />
            <Stack.Screen
              name='AddAddress'
              component={AddAddress}
              options={{title: 'Adres Ekle'}}
            />
            <Stack.Screen
              name='WaterDetail'
              component={WaterDetail}
              options={{title: 'Su Detay'}}
            />
            <Stack.Screen
              name='TakeAway'
              component={TakeAway}
              options={{title: 'Gel Al'}}
            />
            <Stack.Screen
              name='TakeAwayRestorant'
              component={TakeAwayRestorant}
              options={{title: 'Restorant'}}
            />
            <Stack.Screen
              name='Search'
              component={Search}
              options={{title: 'Ara'}}
            />
            <Stack.Screen
              name='LastOrder'
              component={LastOrder}
              options={{title: 'Geçmiş Siparişlerim'}}
            />
            <Stack.Screen
              name='ReviewRestaurant'
              component={ReviewRestaurant}
              options={{title: 'Restaurant Yorumla'}}
            />
            <Stack.Screen
              name='ReviewList'
              component={ReviewList}
              options={{title: 'Yorum Listesi'}}
            />
            <Stack.Screen
              name='LastOrderDetail'
              component={LastOrderDetail}
              options={{title: 'Geçmiş Siparişim'}}
            />
            <Stack.Screen
              name='Profile'
              component={Profile}
              options={{title: 'Profilim'}}
            />
            <Stack.Screen
              name='EditProfile'
              component={EditProfile}
              options={{title: 'Profilimi Düzenle'}}
            />
            <Stack.Screen
              name='Favorite'
              component={Favorite}
              options={{title: 'Favorilerim'}}
            />
            <Stack.Screen
              name='Help'
              component={Help}
              options={{title: 'Yardım'}}
            />
            <Stack.Screen
              name='Language'
              component={Language}
              options={{title: 'Dil Seçenekleri'}}
            />
            <Stack.Screen
              name='AboutUs'
              component={AboutUs}
              options={{title: 'Hakkımızda'}}
            />
            <Stack.Screen
              name='Aydinlatma'
              component={Aydinlatma}
              options={{title: 'Aydinlatma Metni'}}
            />
            <Stack.Screen
              name='LiveChat'
              component={LiveChat}
              options={{title: 'Canlı Destek'}}
            />
          </Stack.Navigator>
        ) : MainStore.myData?.firstLogin ? (
          <Stack.Navigator
            initialRouteName='FirstLogin'
            screenOptions={screenOptions}
          >
            <Stack.Screen
              name='FirstLogin'
              component={FirstLogin}
            />
          </Stack.Navigator>
        ) : (
          <Tab.Navigator
            initialRouteName='Home'
            screenOptions={screenOptions}
          >
            <Tab.Screen
              name='Home'
              component={HomeNavigation}
              options={{
                tabBarIcon: ({focused}) => (
                  <View>
                    <HomeIcon selected={focused ? true : false} />
                  </View>
                ),
              }}
            />
            <Tab.Screen
              name='TakeAway'
              component={TakeAwayNavigation}
              options={{
                tabBarIcon: ({focused}) => (
                  <View>
                    <TakeAwayIcon selected={focused ? true : false} />
                  </View>
                ),
              }}
            />
            <Tab.Screen
              name='Search'
              component={SearchNavigation}
              options={{
                tabBarIcon: ({focused}) => (
                  <View>
                    <Feather
                      name='search'
                      size={25}
                      color={focused ? colorSchema.mainColor : colorSchema.textColor}
                    />
                  </View>
                ),
              }}
            />
            <Tab.Screen
              name='LastOrder'
              component={LastOrderNavigation}
              options={{
                tabBarIcon: ({focused}) => (
                  <View>
                    <LastOrderIcon selected={focused ? true : false} />
                  </View>
                ),
              }}
            />
            <Tab.Screen
              name='Profile'
              component={ProfileNavigation}
              options={{
                tabBarIcon: ({focused}) => (
                  <View>
                    <ProfileIcon selected={focused ? true : false} />
                  </View>
                ),
              }}
            />
          </Tab.Navigator>
        )}
      </NavigationContainer>
    )
  ) : MainStore.guestLogin ? (
    MainStore.selectedAddress ? (
      <NavigationContainer
        linking={{
          prefixes: [''],
          config: {
            screens: {
              Home: '',
              TakeAway: 'takeAway',
              Search: 'search',
              LastOrder: 'lastOrder',
              Profile: 'profile',
            },
          },
        }}
      >
        <Tab.Navigator
          initialRouteName='Home'
          screenOptions={screenOptions}
        >
          <Tab.Screen
            name='Home'
            component={HomeNotAuthNavigation}
            options={{
              tabBarIcon: ({focused}) => (
                <View>
                  <HomeIcon selected={focused ? true : false} />
                </View>
              ),
            }}
          />
          <Tab.Screen
            name='TakeAway'
            component={TakeAwayNavigation}
            options={{
              tabBarIcon: ({focused}) => (
                <View>
                  <TakeAwayIcon selected={focused ? true : false} />
                </View>
              ),
            }}
          />
          <Tab.Screen
            name='Search'
            component={AuthNavigation}
            options={{
              tabBarIcon: ({focused}) => (
                <View>
                  <Feather
                    name='search'
                    size={25}
                    color={focused ? colorSchema.mainColor : colorSchema.textColor}
                  />
                </View>
              ),
            }}
          />
          <Tab.Screen
            name='LastOrder'
            component={AuthNavigation}
            options={{
              tabBarIcon: ({focused}) => (
                <View>
                  <LastOrderIcon selected={focused ? true : false} />
                </View>
              ),
            }}
          />
          <Tab.Screen
            name='Profile'
            component={AuthNavigation}
            options={{
              tabBarIcon: ({focused}) => (
                <View>
                  <ProfileIcon selected={focused ? true : false} />
                </View>
              ),
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    ) : (
      <NavigationContainer
        linking={{
          prefixes: [''],
          config: {
            screens: {
              AddAddressGuest: '',
            },
          },
        }}
      >
        <Stack.Navigator
          initialRouteName='AddAddressGuest'
          screenOptions={screenOptions}
        >
          <Stack.Screen
            name='AddAddressGuest'
            component={AddAddressGuest}
          />
        </Stack.Navigator>
      </NavigationContainer>
    )
  ) : (
    <NavigationContainer
      linking={{
        prefixes: [''],
        config: {
          screens: {
            Splash: '',
            Register: 'register',
            SignIn: 'login',
            Verification: 'verification',
            NotificationScreen: 'notification',
            AboutUs: 'aboutus',
            FAQ: 'faq',
            KVKK: 'kvkk',
            Aydinlatma: 'aydinlatma',
            Privacy: 'privacy',
            Contact: 'contact',
            RestaurantApplication: 'restaurantApplicaton',
            WaterDealerApplication: 'WaterDealerApplication',
          },
        },
      }}
    >
      <Stack.Navigator
        initialRouteName='Splash'
        screenOptions={{headerShown: false}}
      >
        <Stack.Screen
          name='Splash'
          component={Splash}
          options={{title: 'Ana Sayfa'}}
        />
        <Stack.Screen
          name='Register'
          component={Register}
          options={{title: 'Kayıt Ol'}}
        />
        <Stack.Screen
          name='SignIn'
          component={SignIn}
          options={{title: 'Giriş Yap'}}
        />
        <Stack.Screen
          name='Verification'
          component={Verification}
          options={{title: 'Hesap Doğrulama'}}
        />
        <Stack.Screen
          name='NotificationScreen'
          component={NotificationScreen}
          options={{title: 'Bildirim Ayarları'}}
        />
        <Stack.Screen
          name='AboutUs'
          component={AboutUs}
          options={{title: 'Hakkımızda'}}
        />
        <Stack.Screen
          name='FAQ'
          component={FAQ}
          options={{title: 'SSS'}}
        />
        <Stack.Screen
          name='KVKK'
          component={KVKK}
          options={{title: 'KVKK'}}
        />
        <Stack.Screen
          name='Aydinlatma'
          component={Aydinlatma}
          options={{title: 'Aydinlatma Metni'}}
        />
        <Stack.Screen
          name='Privacy'
          component={Privacy}
          options={{title: 'Gizlilik Sözleşmesi'}}
        />
        <Stack.Screen
          name='Contact'
          component={Contact}
          options={{title: 'İletişim'}}
        />
        <Stack.Screen
          name='RestaurantApplication'
          component={RestaurantApplication}
          options={{title: 'Üye İş Yerimiz Olun'}}
        />
        <Stack.Screen
          name='WaterDealerApplication'
          component={WaterDealerApplication}
          options={{title: 'Su Bayisi İş Ortağımız Olun'}}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const screenOptions = {
  headerShown: false,
  tabBarShowLabel: false,
  tabBarStyle: {
    borderTopWidth: 0,
    elevation: 0,
  },
};

export default observer(Route);
