import {observable, action, autorun, makeObservable, computed} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {configure} from 'mobx';
import axios from 'axios';
import {Platform, Alert} from 'react-native';

class MainStore {
  @observable token;
  @observable myData;
  @observable auth;
  @observable selectedAddress;
  @observable address = false;
  @observable cartItem = [];
  @observable guestLogin = false;
  @observable tempAddress = false;
  @observable langPref = 'tr';
  @observable isWeb = Platform.OS == 'web';
  @observable isTakeAway = false;
  @action changeGuestLogin() {
    this.guestLogin = true;
  }

  @action changeIsTakeAway(takeAway) {
    this.isTakeAway = takeAway;
  }

  @action changeMyLanguage(lang) {
    axios
      .get(`https://api.yemek.club/user/api/changeMyLang/${lang}`, {
        headers: {Authorization: `Bearer ${this.token}`},
      })
      .then((res) => {
        if (res.data.success) {
          this.langPref = lang;
        }
      });
  }

  @action clearAddressGuest() {
    this.selectedAddress = undefined;
  }

  @action deleteMyAddress(index) {
    this.myData.adress.splice(index, 1);
    if (this.myData.selectedAddress == index) {
      this.cartItem = [];
      this.myData.selectedAddress = 0;
      axios
        .get(`https://api.yemek.club/user/api/changeSelectedAddress/0`, {
          headers: {Authorization: `Bearer ${this.token}`},
        })
        .then((res) => {
          if (res.data.success) {
          }
        });
    }
  }

  @action guestAddAddress(title, province, district, neighbourhood, fullAdress) {
    let address = {
      title,
      province,
      district,
      neighbourhood,
      fullAdress,
    };
    this.selectedAddress = address;
  }

  @action changeAuth(value) {
    this.auth = value;
  }

  @action updateSignValue(utoken, data) {
    this.token = utoken;
    this.myData = data;
    this.guestLogin = false;
    if (data.adress.length > 0) {
      this.selectedAddress = data.adress[data.selectedAddress];
      this.auth = true;
    } else {
      this.selectedAddress = false;
      this.auth = true;
    }
  }

  @action changeAddressValue(value) {
    this.cartItem = [];
    this.address = value;
  }

  @action firstLoginCart(data) {
    this.cartItem = data;
  }

  @computed get calcutePrice() {
    try {
      let price = 0;
      this.cartItem?.map((item) => {
        let tempPrice = 0;

        tempPrice =
          item.discountType == 'fixed'
            ? (item.price - item.discount) * item.amount
            : item.discountType == 'percent'
            ? (item.price - (item.price * item.discount) / 100) * item.amount
            : item.price * item.amount;
        if (item.selectable) {
          item.selectable?.required?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
          item.selectable?.promotion?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
          item.selectable?.optional?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
        }

        if (item.multiSelect) {
          item.multiSelect?.map((element) => {
            if (element.selected.length == 0) {
            } else {
              element.selected.map((elements) => {
                tempPrice += element.value[elements].price;
              });
            }
          });
        }

        price += tempPrice;
      });
      return price.toFixed(2);
    } catch (e) {
      console.log(e);
    }
  }

  @computed get calcuteTotalPrice() {
    try {
      let price = 0;
      this.cartItem?.map((item) => {
        let tempPrice = 0;

        tempPrice = item.price * item.amount;

        if (item.selectable) {
          item.selectable?.required?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
          item.selectable?.promotion?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
          item.selectable?.optional?.map((element) => {
            element.selected == 0 ? 0 : (tempPrice += element.value[element.selected].price);
          });
        }

        if (item.multiSelect) {
          item.multiSelect?.map((element) => {
            if (element.selected.length == 0) {
            } else {
              element.selected.map((elements) => {
                tempPrice += element.value[elements].price;
              });
            }
          });
        }

        price += tempPrice;
      });
      return price.toFixed(2);
    } catch (e) {
      console.log(e);
    }
  }

  @action emptyCart() {
    this.cartItem = [];
    AsyncStorage.setItem('cart', JSON.stringify([]));
  }

  @action repeatCart(data) {
    this.cartItem = data;
    AsyncStorage.setItem('cart', JSON.stringify(data));
  }

  @action changeFirstLogin() {
    axios
      .get(`https://api.yemek.club/user/api/changeFirstLogin`, {
        headers: {Authorization: `Bearer ${this.token}`},
      })
      .then((res) => {
        if (res.data.success) {
          this.myData.firstLogin = false;
        }
      });
  }

  @action addCart(item, amount) {
    if (this.cartItem.length == 0) {
      item.amount = amount;
      this.cartItem.push(item);
      AsyncStorage.setItem('cart', JSON.stringify(this.cartItem));
    } else {
      if (item.rId == this.cartItem[0]?.rId) {
        let increase = false;
        let indexNumber = -1;
        this.cartItem?.map((element, index) => {
          if (element._id == item._id) {
            let tempAmount = element.amount;
            delete element.amount;
            if (JSON.stringify(element) == JSON.stringify(item)) {
              element.amount = tempAmount + amount;
              if (element.amount == 0) {
                indexNumber = index;
              }
              increase = true;
            } else {
              element.amount = tempAmount;
            }
          }
        });
        if (indexNumber != -1) {
          this.cartItem.splice(indexNumber, 1);
        } else if (!increase) {
          item.amount = amount;
          this.cartItem.push(item);
        }
        AsyncStorage.setItem('cart', JSON.stringify(this.cartItem));
      } else {
        Alert.alert(
          'Uyarı',
          'Sepetinizde başka bir restoranttan ürün bulunmaktadır. Sepetinizi temizleyip seçiminizi sepetinize eklememizi ister misiniz?',
          [
            {
              text: 'Sepetimi Temizle ve Ekle',
              onPress: () => {
                this.cartItem = [];
                item.amount = amount;
                this.cartItem.push(item);
                AsyncStorage.setItem('cart', JSON.stringify(this.cartItem));
              },
            },
            {
              text: 'Vazgeç',
            },
          ]
        );
        return 'error';
      }
    }
  }

  @action EditProfile(name, surName, email) {
    this.myData.name = name;
    this.myData.surName = surName;
    this.myData.email = email;
  }

  @action logOut() {
    AsyncStorage.clear();
    this.selectedAddress = false;
    this.auth = false;
  }

  @action changeAddress(index) {
    this.selectedAddress = this.myData.adress[index];
    this.cartItem = [];
    axios
      .get(`https://api.yemek.club/user/api/changeSelectedAddress/${index}`, {
        headers: {Authorization: `Bearer ${this.token}`},
      })
      .then((res) => {});
  }

  @action firstLoginAddress(address) {
    this.selectedAddress = address;
  }

  @action addAddress(data) {
    this.myData.adress.push(data);
    this.cartItem = [];
    this.selectedAddress = this.myData.adress[this.myData.adress.length - 1];
  }

  @action editAddress(data, index, primary) {
    const newAdress = [...this.myData.adress];
    newAdress[index] = data;
    this.myData.adress = newAdress;
    if (primary) {
      this.cartItem = [];
      this.selectedAddress = this.myData.adress[this.myData.adress.length - 1];
    }
  }

  @action updateFavoriteList(data) {
    this.myData.favoriteRestaurant = data;
  }

  constructor() {
    configure({
      enforceActions: 'never',
    });
    makeObservable(this);
    autorun(async () => {});
  }
}
export default new MainStore();
