import * as React from "react";
import Svg, { Path } from "react-native-svg";

function FullStarIcon(props) {
    return (
        <Svg
            width={22}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8.869 1.544a2.337 2.337 0 014.262 0l1.507 3.174a2.385 2.385 0 001.789 1.352l3.369.509c1.95.294 2.727 2.785 1.317 4.214l-2.438 2.47a2.532 2.532 0 00-.683 2.188l.575 3.488c.333 2.019-1.705 3.558-3.448 2.605l-3.013-1.647a2.297 2.297 0 00-2.212 0L6.88 21.544c-1.744.953-3.781-.586-3.448-2.605l.575-3.488a2.532 2.532 0 00-.683-2.187l-2.438-2.47c-1.41-1.43-.632-3.921 1.317-4.215l3.369-.51a2.385 2.385 0 001.79-1.351l1.506-3.174z"
                fill="#1E1E1E"
            />
        </Svg>
    );
}

export default FullStarIcon;