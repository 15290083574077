import { View, Text, TouchableOpacity, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
const { width, height } = Dimensions.get('window')
const RedButton = (props) => {
    return (
        <TouchableOpacity onPress={props.clickEvent} style={{ borderRadius: 20, backgroundColor: colorSchema.mainColor, width: width > 1279 ? 400 : width - 28, height: 43, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: 'Gilroy-Regular', fontSize: 15 }}>{props.text}</Text>
        </TouchableOpacity>
    )
}


export default RedButton