import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Image,
  TouchableOpacity,
  Alert,
  Dimensions,
  Linking,
} from 'react-native';
import React, {useState, useEffect, useRef} from 'react';
import HeaderSheet from '../../components/header/HeaderSheet';
import ScreenTitle from '../../components/header/ScreenTitle';
import {Feather} from '@expo/vector-icons';
import colorSchema from '../../config/colorSchema';
import {observer} from 'mobx-react';
import MainStore from '../../store/MainStore';
import axios from 'axios';
import axiosConfig from '../../config/axiosConfig';
import ErrorToastMessage from '../../components/feedback/ErrorToastMessage';
import RightArrowIcon from '../../assets/svg/RightArrowIcon';
import WebHeader from '../../components/header/WebHeader';
import {
  ButtonGroup,
  AlertDialog,
  Button,
  ButtonText,
  CloseIcon,
  AlertDialogBackdrop,
  useToast,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogCloseButton,
  Icon,
  AlertDialogBody,
  AlertDialogFooter,
} from '@gluestack-ui/themed';

const Profile = ({navigation}) => {
  const {name, surName, email, phoneNumber} = MainStore.myData;
  const {width, height} = Dimensions.get('window');
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);
  const toast = useToast();
  return (
    <View style={{backgroundColor: 'white', flex: 1}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0}}
      >
        <View>
          {width > 1279 ? (
            <WebHeader navigation={navigation} />
          ) : (
            <View>
              <HeaderSheet navigation={navigation} />
            </View>
          )}

          <View>
            <ScreenTitle
              title={MainStore.langPref == 'tr' ? 'Profilim' : 'My Profile'}
              navigation={navigation}
            />
          </View>

          <View style={{marginTop: 47}}>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 18}}>
              <View style={{marginLeft: width > 1279 ? 0 : 47, marginRight: 38}}>
                <Image
                  source={require('../../assets/image/pp.png')}
                  style={{width: 63, height: 70}}
                />
              </View>
              <View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <View>
                    <Text
                      style={{fontFamily: 'Gilroy-Medium', fontSize: 15, color: colorSchema.mainColor}}
                    >{`${name} ${surName}`}</Text>
                  </View>
                  <TouchableOpacity onPress={() => navigation.navigate('EditProfile')}>
                    <View>
                      <Image
                        source={require('../../assets/image/pen.png')}
                        style={{width: 18, height: 18}}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={{marginVertical: 18}}>
                  <Text
                    style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}
                  >{`${email}`}</Text>
                </View>
                <View>
                  <Text
                    style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}
                  >{`+${phoneNumber}`}</Text>
                </View>
              </View>
            </View>
          </View>

          <ScrollView>
            <View style={{marginTop: 47, marginLeft: width > 1279 ? 0 : 26}}>
              <TouchableOpacity onPress={() => Linking.openURL(`https://api.whatsapp.com/send?phone=908508880707`)}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Canlı Destek' : 'Live Support'}
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('MyAddress')}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Adreslerim' : 'My Addresses'}
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Favorite')}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Favroi Restoranlarım' : 'My Favorite Restaurants'}
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Help')}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Yardım' : 'Help'}
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => MainStore.logOut()}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Çıkış Yap' : 'Sign out'}
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Language')}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 21,
                    marginRight: 12,
                    alignItems: 'center',
                    marginTop: 17,
                    maxWidth: width > 1279 ? 200 : width,
                  }}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      Dil - Language
                    </Text>
                  </View>
                  <View>
                    <RightArrowIcon />
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  setIsOpen(true);
                }}
              >
                <View
                  style={{flexDirection: 'row', marginLeft: 21, marginRight: 12, alignItems: 'center', marginTop: 17}}
                >
                  <View>
                    <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                      {MainStore.langPref == 'tr' ? 'Hesabımı Kaldır' : 'Remove My Account'}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>

              <View
                style={{flexDirection: 'row', marginLeft: 21, marginRight: 12, alignItems: 'center', marginTop: 17}}
              >
                <View>
                  <Text style={{fontFamily: 'Gilroy-Regular', fontSize: 15, color: colorSchema.textColor}}>
                    Versiyon - 1.1.5
                  </Text>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>

        <AlertDialog
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <AlertDialogBackdrop />
          <AlertDialogContent>
            <AlertDialogHeader>
              <Heading size='lg'>Hesabımı Kaldır</Heading>
              <AlertDialogCloseButton>
                <Icon as={CloseIcon} />
              </AlertDialogCloseButton>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text size='sm'>
                Hesabınızı kaldırmak istediğinize emin misiniz ? Bu işlemi onayladıktan sonra hesabınız ve verileriniz
                tamamen silinecektir ve birdaha geri almanız mümkün olmayacaktır.
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup space='lg'>
                <Button
                  variant='outline'
                  action='secondary'
                  onPress={() => {
                    setIsOpen(false);
                  }}
                >
                  <ButtonText>Vazgeç</ButtonText>
                </Button>
                <Button
                  bg='$error600'
                  action='negative'
                  onPress={() => {
                    axios
                      .get(`${axiosConfig.serverIp}/deleteMyAccount`, {
                        headers: {Authorization: `Bearer ${MainStore.token}`},
                      })
                      .then((res) => {
                        if (res.data.success) {
                          MainStore.logOut();
                        } else {
                          ErrorToastMessage(toast, 'Bir hata oluştu lütfen daha sonra tekrar deneyiniz.');
                        }
                      })
                      .catch((e) => {
                        ErrorToastMessage(toast, 'Bir hata oluştu lütfen daha sonra tekrar deneyiniz.');
                      });
                    setIsOpen(false);
                  }}
                >
                  <ButtonText>Kaldır</ButtonText>
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </ScrollView>
    </View>
  );
};

export default observer(Profile);
