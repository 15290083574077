import { View, Text } from 'react-native'
import React from 'react'
import { Toast, ToastDescription, ToastTitle, VStack } from '@gluestack-ui/themed'
import colorSchema from '../../config/colorSchema'



const SuccessToastMessage = (toast, toastColor, text) => {
    toast.show({
        placement: "bottom",
        render: ({ id }) => {
            const toastId = "toast-" + id
            return (
                <Toast nativeID={toastId} bg={toastColor} variant="solid">
                    <VStack space="xs">
                        <ToastDescription color='white'>
                            {text}
                        </ToastDescription>
                    </VStack>
                </Toast>
            )
        },
    })
}
export default SuccessToastMessage