import { View, Text, SafeAreaView,Keyboard, TextInput, Dimensions, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { Feather } from '@expo/vector-icons'
import colorSchema from '../../config/colorSchema'
import StarIcon from '../../assets/svg/StarIcon'
import Stars from 'react-native-stars';
import RedButton from '../../components/button/RedButton'
import HalfStarIconSort from '../../assets/svg/HalfStarIconSort'
import LastStarIcon from '../../assets/svg/LastStarIcon'
import EmptyStarIcon from '../../assets/svg/EmptyStarIcon'
import FullStarIcon from '../../assets/svg/FullStarIcon'
import axios from 'axios'
import axiosConfig from '../../config/axiosConfig'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import WebHeader from '../../components/header/WebHeader'
const { width, height } = Dimensions.get('window')
const ReviewRestaurant = ({ navigation, route }) => {
    const [comment, setComment] = useState('')
    const [review, setReview] = useState(1)
    const { orderID,restaurantName } = route.params
    const sendComment = async () => {
        axios.post(`${axiosConfig.serverIp}/createReview/${orderID}`, {
            comment,
            review
        }, { headers: { Authorization: `Bearer ${MainStore.token}` } }).then(res => {
            if (res.data.success) {
                navigation.goBack()
            }
        })
    }


    return (
        <SafeAreaView onStartShouldSetResponder={() => Keyboard.dismiss()} style={{ flex: 1, backgroundColor: 'white' }}>
         


            {width > 1279 ?
                <WebHeader navigation={navigation} />
                :
                <></>
            }
            <TouchableOpacity onPress={() => navigation.goBack()} style={{ marginLeft: 30 }}>
                <Feather name='chevron-left' size={25} color='black' />
            </TouchableOpacity>

            <View style={{ alignItems: 'center', marginTop: 60 }}>
                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15, color: colorSchema.textColor }}>{restaurantName}</Text>
            </View>

            <View style={{ marginTop: 49 }}>
           
                <View style={{ alignItems: 'center' }}>
                    <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 15, color: colorSchema.textColor }}>{MainStore.langPref == 'tr' ? 'Restoranın hizmetini nasıl buldun?' : "How did you find the restaurant's service?"}</Text>
                </View>
            </View>

            <View style={{ marginTop: 18 }}>
                <View>
                    <Stars
                        fullStar={<FullStarIcon />}
                        emptyStar={<EmptyStarIcon />}
                        default={1}
                        update={(val) => setReview(val)}
                        half={false}
                        spacing={5}
                    />
                </View>




            </View>

            <View style={{ marginTop: 17, alignItems: 'center' }}>
                <TextInput value={comment} onChangeText={(text) => setComment(text)} style={{ width: width - 40, height: 118, borderWidth: 1, borderColor: '#D3D6DB', borderRadius: 20, fontFamily: 'Gilroy-Medium', fontSize: 10, paddingTop: 12, paddingLeft: 12 }} multiline placeholder='Yorumunuz' placeholderTextColor={'rgba(30,30,30,0.3)'} />
            </View>

            <View style={{ width: width - 100, marginLeft: 12, marginTop: 17 }}>
                {MainStore.langPref == 'tr' ?
                    <Text style={{ fontFamily: 'Gilroy-Regular', color: 'rgba(30,30,30,0.7)', fontSize: 10, lineHeight: 12 }}>Yorumlarınız, <Text style={{ color: colorSchema.mainColor }}>İletişim Kurallarımız</Text>'a göre kontrol edildikten sonra 1 iş günü içerisinde yayınlanır. Yemek.Club’ın uygun görmediği yorumları yayınlamama hakkı saklıdır.</Text>
                    :
                    <Text style={{ fontFamily: 'Gilroy-Regular', color: 'rgba(30,30,30,0.7)', fontSize: 10, lineHeight: 12 }}>It is shared within 1 business day after it is checked according to our Communication Rules. Yemek.Club reserves the right not to publish comments that it deems inappropriate.</Text>
                }
            </View>

            <View style={{ position: 'absolute', bottom: 20, alignSelf: 'center' }}>
                <RedButton clickEvent={() => sendComment()} text='Gönder' />
            </View>
        </SafeAreaView>
    )
}

export default observer(ReviewRestaurant)