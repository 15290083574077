import * as React from "react";
import Svg, { Path } from "react-native-svg";

function PromotionIcon(props) {
    return (
        <Svg
            width={26}
            height={26}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M13.249 2.708L13 2.969l-.249-.261a1.698 1.698 0 00-2.486 0 1.915 1.915 0 000 2.613l1.74 1.83c.55.577 1.44.577 1.99 0l1.74-1.83a1.915 1.915 0 000-2.613 1.698 1.698 0 00-2.486 0z"
                fill="#FA0C08"
                stroke="#1E1E1E"
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
            <Path
                d="M4.333 10.833v7.43a2 2 0 001.106 1.79l6.666 3.333a2 2 0 001.79 0l6.666-3.333a2 2 0 001.106-1.79v-7.43"
                stroke="#1E1E1E"
                strokeWidth={1.5}
            />
            <Path
                d="M19.5 5.417L21.667 6.5 13 10.833 4.333 6.5 6.5 5.417"
                stroke="#1E1E1E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M21.667 6.5L13 10.833l2.167 3.25 8.666-4.333-2.166-3.25zM4.333 6.5L13 10.833l-2.167 3.25L2.167 9.75 4.333 6.5z"
                stroke="#1E1E1E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default PromotionIcon;