import {useFonts} from 'expo-font';
import {StatusBar} from 'expo-status-bar';
import {StyleSheet, Text, View, Image, Platform, SafeAreaView, Alert, Linking} from 'react-native';
import colorSchema from './src/config/colorSchema';
import Route from './src/routes/Route';
import React, {useState, useEffect, useRef} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {observer} from 'mobx-react-lite';
import MainStore from './src/store/MainStore';
import axios from 'axios';
import * as SplashScreen from 'expo-splash-screen';
import {GluestackUIProvider} from '@gluestack-ui/themed';
import axiosConfig from './src/config/axiosConfig';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import LogoHeader from './src/components/header/LogoHeader';
import {config} from '@gluestack-ui/config';
SplashScreen.preventAutoHideAsync();

//NotificationsMobile
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const App = () => {
  const [appReady, setAppReady] = useState(false);
  const [notification, setNotification] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const checkAppVersion = async () => {
    try {
      axios
        .get(`https://api.yemek.club/user/api/checkAppVersion`)
        .then((res) => {
          if (res.data.version !== '1.1.5' && res.data.forceUpdate) {
            setUpdateAvailable(true);
            Alert.alert('Yeni Güncelleme Mevcut', 'Yeni bir sürüm mevcut, lütfen güncelleyin.', [
              {
                text: 'Güncelle',
                onPress: () => {
                  if (Platform.OS === 'ios') {
                    const iOSAppId = '1672140958';
                    Linking.openURL(`itms-apps://itunes.apple.com/app/id${iOSAppId}`).catch((error) =>
                      console.error('Uygulama mağazası açma hatası:', error)
                    );
                  } else if (Platform.OS === 'android') {
                    const androidPackageName = 'com.loc.yemekclub';
                    Linking.openURL(`market://details?id=${androidPackageName}`).catch((error) =>
                      console.error('Uygulama mağazası açma hatası:', error)
                    );
                  }
                },
              },
            ]);
          }
        })
        .catch((err) => {
          setServerError(true);
        });
    } catch (error) {
      console.error('Sürüm kontrolü başarısız oldu:', error);
    }
  };

  useEffect(() => {
    //NotificationsMobile
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {});

    AsyncStorage.getItem('cart').then((res) => {
      if (res) {
        MainStore.cartItem = JSON.parse(res);
      }
    });

    AsyncStorage.getItem('token').then(async (res) => {
      if (res) {
        axios
          .get(`https://api.yemek.club/user/api/getMyInfo`, {
            headers: {Authorization: `Bearer ${res}`},
          })
          .then(async (response) => {
            if (response.data.error) {
              MainStore.changeAuth(false);
              setAppReady(true);
              await SplashScreen.hideAsync();
            } else {
              MainStore.changeMyLanguage(response.data.langPref);
              if (response.data.adress.length > 0) {
                MainStore.firstLoginAddress(response.data.adress[response.data.selectedAddress]);
                MainStore.updateSignValue(res, response.data);
                setAppReady(true);
                await SplashScreen.hideAsync();
              } else {
                MainStore.updateSignValue(res, response.data);
                MainStore.changeAddressValue(false);
                setAppReady(true);
                await SplashScreen.hideAsync();
              }
            }
          })
          .catch(async (err) => {
            setServerError(true);
            setAppReady(true);
            await SplashScreen.hideAsync();
          });
      } else {
        MainStore.changeAuth(false);
        setAppReady(true);
        await SplashScreen.hideAsync();
      }
    });
    checkAppVersion();
  }, [appReady]);

  const isWeb = Platform.OS == 'web';

  const [fontsLoaded] = useFonts({
    'Gilroy-Bold': require('./src/assets/fonts/Gilroy-Bold.ttf'),
    'Gilroy-Medium': require('./src/assets/fonts/Gilroy-Medium.ttf'),
    'Gilroy-SemiBold': require('./src/assets/fonts/Gilroy-SemiBold.ttf'),
    'Gilroy-Regular': require('./src/assets/fonts/Gilroy-Regular.ttf'),
    'Gilroy-Heavy': require('./src/assets/fonts/Gilroy-Heavy.ttf'),
    'Gilroy-ExtraBold': require('./src/assets/fonts/Gilroy-ExtraBold.ttf'),
    'Gilroy-Light': require('./src/assets/fonts/Gilroy-Light.ttf'),
  });

  if (!fontsLoaded || !appReady) {
    return null;
  }

  if (updateAvailable) {
    return null;
  }

  return !serverError ? (
    <GluestackUIProvider config={config}>
      <View style={{flex: 1, marginTop: Platform.OS === 'android' ? 25 : 0}}>
        <StatusBar style='dark' />
        <Route />
      </View>
    </GluestackUIProvider>
  ) : (
    <SafeAreaView style={{flex: 1, marginTop: Platform.OS == 'android' ? 25 : 0}}>
      <View>
        <LogoHeader />
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <Image
          source={require('./src/assets/image/sad.webp')}
          style={{width: 126, height: 120}}
        />
        <Text style={{marginTop: 34, fontFamily: 'Gilroy-Bold', fontSize: 21}}>Sunucuya Bağlanılmıyor </Text>
        <Text
          style={{
            alignSelf: 'center',
            fontSize: 14,
            fontFamily: 'Gilroy-Medium',
            marginTop: 7,
            paddingHorizontal: 11,
          }}
        >{`Üzgünüz, şu anda sunucumuza bağlanılamıyor. Deneyimlerinizi daha iyi hale getirmek için uygulamamızı güncelliyoruz. Lütfen birkaç dakika sonra tekrar deneyiniz.`}</Text>
        <Text
          style={{
            alignSelf: 'center',
            fontSize: 14,
            fontFamily: 'Gilroy-Medium',
            marginTop: 14,
            paddingHorizontal: 11,
          }}
        >{`Oluşan bu sorun için özür dileriz ve sabrınız için teşekkür ederiz. En kısa sürede sorunu çözeceğimize dair çalışmalarımız devam ediyor. İyi günler dileriz.`}</Text>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default observer(App);
