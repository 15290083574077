import {View, Text, SafeAreaView, TouchableOpacity, ScrollView, Image, TextInput, Dimensions} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Feather} from '@expo/vector-icons';
import SeeMoreCardR from '../../components/card/SeeMoreCardR';
import GoBackTitle from '../../components/header/GoBackTitle';
import FilterBox from '../../components/filter/FilterBox';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
import FilterSheet from '../../components/bottomsheet/FilterSheet';
import WebHeader from '../../components/header/WebHeader';
import HeartIcon from '../../assets/svg/HeartIcon';
import axiosConfig from '../../config/axiosConfig';
import axios from 'axios';
const {width, height} = Dimensions.get('window');

const Favorite = ({navigation}) => {
  const [appReady, setAppReady] = useState(false);
  const [favoriteRestaurant, setFavoriteRestaurant] = useState(null);

  useEffect(() => {
    axios
      .get(`${axiosConfig.serverIp}/getMyFavoriteRestaurant`, {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        console.log(res.data);
        MainStore.updateFavoriteList(res.data);
        setAppReady(true);
      });
  }, []);

  if (!appReady) {
    return null;
  }
  return (
    <SafeAreaView style={{backgroundColor: 'white', flex: 1}}>
      <ScrollView
        style={{
          maxWidth: 1279,
          marginLeft: width > 1279 ? (width - 1279) / 2 : 0,
          flex: 1,
        }}
      >
        {width > 1279 ? (
          <View
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 999,
            }}
          >
            <WebHeader navigation={navigation} />
          </View>
        ) : (
          <></>
        )}
        <View>
          <GoBackTitle
            title={MainStore.langPref == 'tr' ? 'Favorilerim' : 'My Favorite'}
            navigation={navigation}
          />
        </View>

        {MainStore.myData?.favoriteRestaurant?.length > 0 ? (
          <View>
            <View style={{marginTop: 17}}>
              <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal={width > 500 ? true : false}
              >
                {MainStore.myData?.favoriteRestaurant?.map((item, index) => (
                  <View
                    key={item._id}
                    style={{position: 'relative'}}
                  >
                    <SeeMoreCardR
                      navigation={navigation}
                      id={item._id}
                      category={item.category}
                      comments={item.comments.length}
                      rating={item.rating}
                      district={item.district}
                      restaurantName={item.restaurantName}
                      restaurantCoverPhoto={item.restaurantCoverPhoto}
                    />
                    <TouchableOpacity
                      onPress={() => {
                        if (MainStore.auth) {
                          axios
                            .get(`${axiosConfig.serverIp}/addFavoriteRestaurant/${item._id}`, {
                              headers: {
                                Authorization: `Bearer ${MainStore.token}`,
                              },
                            })
                            .then((res) => {
                              if (res.data.error) {
                              } else {
                                MainStore.updateFavoriteList(res.data);
                              }
                            });
                        } else {
                        }
                      }}
                      style={{position: 'absolute', top: 6, right: 18}}
                    >
                      <HeartIcon liked={true} />
                    </TouchableOpacity>
                  </View>
                ))}
              </ScrollView>
            </View>
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 50,
            }}
          >
            <Text style={{fontFamily: 'Gilroy-Bold', fontSize: 18}}>
              {MainStore.langPref == 'tr'
                ? 'Henüz favorilediğiniz restaurant yok.'
                : 'There is no restaurant that you are not a favorite yet.'}
            </Text>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

export default observer(Favorite);
