import { View, Text, Dimensions, Image, Linking } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
import { Feather } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'

const { width, height } = Dimensions.get('window')

const WebFooter = (props) => {
    const { navigation } = props

    const handleRedirect = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
            await Linking.openURL(url);
        } else {
            console.log("Yönlendirme desteklenmiyor.");
        }
    };

    return (
        MainStore.auth ?
            <></>
            :
            <View style={{ width, height: 510, backgroundColor: '#1E1E1E' }}>
                <View style={{ flexDirection: width > 1279 ? 'row' : 'column', marginTop: 92, justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: width > 1279 ? 'row' : 'column' }}>
                        <View style={{ marginLeft: 34 }}>
                            <View>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 22, color: colorSchema.cartColor }}>Yemek.Club'ı keşfedin</Text>
                            </View>
                            <TouchableOpacity onPress={() => navigation.navigate('AboutUs')} style={{ marginTop: 34 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>Hakkımızda</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('Contact')} style={{ marginTop: 17 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>İletişim</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('FAQ')} style={{ marginTop: 17 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>Sıkça Sorulan Sorular</Text>
                            </TouchableOpacity>

                        </View>
                        <View style={{ marginLeft: width > 1279 ? 103 : 34 }}>
                            <TouchableOpacity onPress={() => navigation.navigate('KVKK')} style={{ marginTop: 50 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>KVKK ve Gizlilik Politikası</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('Aydinlatma')} style={{ marginTop: 17 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>Aydınlatma Metni</Text>
                            </TouchableOpacity>

                        </View>
                        <View style={{ marginLeft: width > 1279 ? 103 : 34 }}>
                            <TouchableOpacity onPress={() => navigation.navigate('RestaurantApplication')} style={{ marginTop: 50 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>Üye İş Yerimiz Olun</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('WaterDealerApplication')} style={{ marginTop: 17 }}>
                                <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>Su Bayisi İş Ortağımız Olun</Text>
                            </TouchableOpacity>

                        </View>
                    </View>
                    {width > 1279 ?
                        <View>
                            <TouchableOpacity onPress={() => handleRedirect('https://play.google.com/store/apps/details?id=com.loc.yemekclub')}>
                                <Image source={require('../../assets/image/googlePlay.png')} style={{ width: 215, height: 77 }} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => handleRedirect('https://apps.apple.com/tr/app/yemek-club/id1672140958?l=tr')} style={{ marginTop: 17, marginRight: 34 }}>
                                <Image source={require('../../assets/image/appStore.png')} style={{ width: 215, height: 77 }} />
                            </TouchableOpacity>
                        </View>
                        :
                        <></>
                    }
                </View>

                {width > 1279 ?
                    <View style={{ marginTop: 102, flexDirection: 'row', marginLeft: 34, justifyContent: 'space-between', alignItems: 'center' }}>
                        <TouchableOpacity onPress={() => navigation.navigate('Splash')} style={{ alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 18, color: colorSchema.cartColor }}>2023 Yemek.Club</Text>
                        </TouchableOpacity>
                        <View>
                            <Image source={{ uri: 'https://www.hnn.com.tr/wp-content/uploads/2022/12/logo_band_white@3x.png' }} style={{ width: 469, height: 33 }} />
                        </View>
                        <View style={{ marginRight: 34, flexDirection: 'row', alignItems: 'flex-end', paddingBottom: 20 }}>
                            <View>
                                <Feather name='facebook' size={28} color='white' />
                            </View>
                            <View style={{ marginHorizontal: 30 }}>
                                <Feather name='twitter' size={28} color='white' />
                            </View>
                            <TouchableOpacity onPress={()=>handleRedirect('https://www.instagram.com/yemek.club/')} style={{ marginRight: 30 }}>
                                <Feather name='instagram' size={28} color='white' />
                            </TouchableOpacity>
                            <View>
                                <Image source={require('../../assets/image/etbisQR.jpg')} style={{ width: 90, height: 100 }} />
                            </View>
                        </View>
                    </View>
                    :
                    <></>
                }

            </View>
    )
}

export default observer(WebFooter)