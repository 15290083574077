import { View, Text, ScrollView, TouchableOpacity, Button, Dimensions } from 'react-native'
import React, { useState } from 'react'
import GoBackTitle from '../../components/header/GoBackTitle'
import colorSchema from '../../config/colorSchema'
import RedButton from '../../components/button/RedButton'
import { observer } from 'mobx-react'
import MainStore from '../../store/MainStore'
import WebHeader from '../../components/header/WebHeader'

const Language = ({ navigation }) => {
    const [selected, setSelected] = useState(MainStore.langPref)

    const { width, height } = Dimensions.get('window')
    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <ScrollView showsVerticalScrollIndicator={false} style={{ marginHorizontal: width > 1279 ? (width - 1279) / 2 : 0 }}>
                <View>
                    {
                        width > 1279 ?
                            <WebHeader navigation={navigation} />
                            :
                            <></>
                    }
                    <View>
                        <GoBackTitle navigation={navigation} title={MainStore.langPref == 'tr' ? 'Dil Seçenekleri' : 'Language Preferences'} />
                    </View>
                </View>
                <View>
                    <TouchableOpacity onPress={() => setSelected('tr')} style={{ marginTop: 17, flexDirection: 'row', marginLeft: 12, alignItems: 'center' }}>
                        <View style={{ marginRight: 9 }}>
                            <TouchableOpacity onPress={() => setSelected('tr')} style={{ backgroundColor: selected == 'tr' ? colorSchema.mainColor : '#D3D6DB', width: 20, height: 20, borderRadius: 50 }} />
                        </View>
                        <View>
                            <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 16, color: colorSchema.textColor }}>Türkçe</Text>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => setSelected('eng')} style={{ marginTop: 17, flexDirection: 'row', marginLeft: 12, alignItems: 'center' }}>
                        <View style={{ marginRight: 9 }}>
                            <TouchableOpacity onPress={() => setSelected('eng')} style={{ backgroundColor: selected == 'eng' ? colorSchema.mainColor : '#D3D6DB', width: 20, height: 20, borderRadius: 50 }} />
                        </View>
                        <View>
                            <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 16, color: colorSchema.textColor }}>English</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </ScrollView>
            <View style={{ position: 'absolute', bottom: 20, alignSelf: 'center' }}>
                <RedButton clickEvent={() => MainStore.changeMyLanguage(selected)} text={MainStore.langPref == 'tr' ? 'Güncelle' : 'Update'} />
            </View>
        </View>
    )
}

export default observer(Language)